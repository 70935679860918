/* eslint-disable react/button-has-type */
import { FC } from 'react';
import { ReactComponent as ResetIcon } from 'assets/img/icons/reset.svg';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IActionsButtonReloadProps } from './types';

export const RessetButton: FC<IActionsButtonReloadProps> = ({ resetColdBalance, coldBalance }) => {
	const { checkEditable } = usePermission();

	return (
		<button
			type="button"
			className="dash-actions__item"
			onClick={resetColdBalance}
			disabled={!coldBalance || !checkEditable(permissions.MANAGE_WALLETS)}
			style={{ marginRight: '-10px', opacity: coldBalance ? 1 : 0.5 }}
		>
			<ResetIcon />
		</button>
	);
};
