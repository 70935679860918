import { useEffect, useState, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMarketBotListRequest } from 'redux/reducers/marketBot/reducer';
import { getMarketBotList, getMarketBotListLoader } from 'redux/reducers/marketBot/selectors';
import Loader from 'ui/Loader';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const MarketBot = () => {
	const dispatch = useDispatch();
	const marketBotList = useSelector(getMarketBotList);
	const loader = useSelector(getMarketBotListLoader);
	const [search, setSearch] = useState('');

	const filteredMarketBotList = marketBotList?.filter((item) =>
		item.asset_pair.code.replace('_', '/').includes(search.toLocaleLowerCase()),
	);

	const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
	};

	useEffect(() => {
		dispatch(getMarketBotListRequest());
	}, [dispatch]);

	return (
		<>
			<div className="title-block">
				<p className="title">Market Bot</p>
			</div>
			<div className="count-label count-label--filter-type">
				<div className="input input--search input--search-bigger input--mb-0">
					<label>
						<div className="input-wrapper">
							<input
								className="input-item input-item--right-icon input-item--search "
								type="text"
								placeholder="Search by pair names"
								value={search}
								onChange={handleSearch}
							/>
							<span className="input-icon input-icon--right icon-search-icon" />
						</div>
					</label>
				</div>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--market-bot">
						<TableHeader />
						<div className="table-body">
							{loader ? (
								<div className="list-loader">
									<Loader />
								</div>
							) : (
								filteredMarketBotList?.map((item) => <TableRow key={item.id} item={item} />)
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MarketBot;
