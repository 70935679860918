/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IStakingStore,
	IStakingInfo,
	IGetStakeTransactionsParams,
	IGetStakeTransactionsResponse,
	IStakeTronParams,
	IStakeTronPayload,
	IStakeTransactionItem,
} from './types';

// ==========================================:
export const initialState: IStakingStore = {
	infoLoading: false,
	info: null,
	transactionLoading: false,
	transactions: null,
	stakeLoading: false,
};

// ==========================================:
const referral = createSlice({
	name: '@@referral',
	initialState,
	reducers: {
		getStakingInfoRequest: (state) => {
			const stakingState = state;

			stakingState.infoLoading = true;
		},
		getStakingInfoSuccess: (state, { payload }: PayloadAction<IStakingInfo>) => {
			const stakingState = state;

			stakingState.infoLoading = false;
			stakingState.info = payload;
		},
		getStakingInfoError: (state) => {
			const stakingState = state;

			stakingState.infoLoading = false;
		},
		getStakeTransactionsRequest: (
			state,
			{ payload }: PayloadAction<IGetStakeTransactionsParams>,
		) => {
			const stakingState = state;

			stakingState.transactionLoading = true;
		},
		getStakeTransactionsSuccess: (
			state,
			{ payload }: PayloadAction<IGetStakeTransactionsResponse>,
		) => {
			const stakingState = state;

			stakingState.transactionLoading = false;
			stakingState.transactions = payload;
		},
		getStakeTransactionsError: (state) => {
			const stakingState = state;

			stakingState.transactionLoading = false;
		},
		postStakeRequest: (state, { payload }: PayloadAction<IStakeTronPayload>) => {
			const stakingState = state;

			stakingState.stakeLoading = true;
		},
		postStakeSuccess: (state, { payload }: PayloadAction<IStakeTransactionItem>) => {
			const stakingState = state;

			stakingState.stakeLoading = false;
		},
		postStakeError: (state) => {
			const stakingState = state;

			stakingState.stakeLoading = false;
		},
		postUnstakeRequest: (state, { payload }: PayloadAction<IStakeTronPayload>) => {
			const stakingState = state;

			stakingState.stakeLoading = true;
		},
		postUnstakeSuccess: (state, { payload }: PayloadAction<IStakeTransactionItem>) => {
			const stakingState = state;

			stakingState.stakeLoading = false;
		},
		postUnstakeError: (state) => {
			const stakingState = state;

			stakingState.stakeLoading = false;
		},

		stakingInitState: () => initialState,
	},
});

export default referral.reducer;
export const {
	getStakingInfoRequest,
	getStakingInfoSuccess,
	getStakingInfoError,
	getStakeTransactionsRequest,
	getStakeTransactionsSuccess,
	getStakeTransactionsError,
	postStakeRequest,
	postStakeSuccess,
	postStakeError,
	postUnstakeRequest,
	postUnstakeSuccess,
	postUnstakeError,
} = referral.actions;
