import { ChangeEvent, FC, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { editBankAccountRequest } from 'redux/reducers/bankAccount/reducer';
import { notificationContainer } from 'utils/notificationContainer';
import { useSelect } from 'services/hooks/useSelect';
import { IBankAccountTableItem, TEditValues } from './types';

const BankAccountTableItem: FC<IBankAccountTableItem> = ({
	item,
	active,
	toggleActive,
	onDelete,
}) => {
	const dispatch = useDispatch();
	const {
		id,
		beneficiary_name: beneficiaryName,
		beneficiary_bank: beneficiaryBank,
		beneficiary_bank_address: beneficiaryBankAddress,
		swift,
		iban,
		correspondent_bank: correspondentBank,
		asset_code: assetCode,
		is_partner: isPartner,
	} = item;

	const initialEditValues = {
		beneficiary_name: beneficiaryName,
		beneficiary_bank: beneficiaryBank,
		beneficiary_bank_address: beneficiaryBankAddress,
		swift,
		iban,
		correspondent_bank: correspondentBank,
		is_partner: isPartner,
	};

	const [values, setValues] = useReducer(
		(prev: TEditValues, newValue: Partial<TEditValues>) => ({
			...prev,
			...newValue,
		}),
		initialEditValues,
	);

	console.log('values', values);

	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelectClick = () => {
		toggleOpen();
	};

	const handleSelectChange = (_isPartner: 1 | 0) => {
		setValues({ is_partner: _isPartner });
		setOpen(false);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>, field: keyof TEditValues) => {
		const { value } = e.target;
		setValues({ [field]: value });
	};

	const handleCancel = () => {
		toggleActive(id);
		setValues(initialEditValues);
	};

	const handleEdit = () => {
		dispatch(
			editBankAccountRequest({
				apiParams: {
					id,
					...values,
				},
				onSuccess: () => {
					notificationContainer('Bank account successfully edited', 'success');
					toggleActive(id);
				},
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Beneficiary Name</p>
				{active ? (
					<div className="table-input table-input--start table-input--full-width">
						<input
							type="text"
							placeholder=""
							value={values.beneficiary_name}
							onChange={(e) => handleChange(e, 'beneficiary_name')}
						/>
					</div>
				) : (
					<p>{beneficiaryName}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Beneficiary Bank</p>
				{active ? (
					<div className="table-input table-input--start table-input--full-width">
						<input
							type="text"
							placeholder=""
							value={values.beneficiary_bank}
							onChange={(e) => handleChange(e, 'beneficiary_bank')}
						/>
					</div>
				) : (
					<p>{beneficiaryBank}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				{active ? (
					<div className={`select select--table-type ${open ? 'active' : ''}`}>
						<button
							type="button"
							className="select__current "
							ref={triggerRef}
							onClick={handleSelectClick}
						>
							<span className="select__current-text">
								{values.is_partner === 1 ? 'Partner Bank' : 'Other Banks'}
							</span>
							<span className="select__current-arrow">
								<svg
									className="stroke"
									width="8"
									height="5"
									viewBox="0 0 8 5"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
								</svg>
							</span>
						</button>
						<div className="select__drop" ref={dropRef}>
							<div className="select__drop-scroll">
								<div className="select-drop__item">
									<ul>
										<li>
											<button type="button" onClick={() => handleSelectChange(0)}>
												Other Banks
											</button>
										</li>
										<li>
											<button type="button" onClick={() => handleSelectChange(1)}>
												Partner Bank
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				) : (
					<p>{isPartner === 1 ? 'Partner' : 'Other'} bank</p>
				)}
			</div>
			<div className="td  ">
				<p className="td-hidden-name">SWIFT</p>
				{active ? (
					<div className="table-input table-input--start table-input--full-width">
						<input
							type="text"
							placeholder=""
							value={values.swift}
							onChange={(e) => handleChange(e, 'swift')}
						/>
					</div>
				) : (
					<p>{swift}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Beneficiary IBAN</p>
				{active ? (
					<div className="table-input table-input--start table-input--full-width">
						<input
							type="text"
							placeholder=""
							value={values.iban}
							onChange={(e) => handleChange(e, 'iban')}
						/>
					</div>
				) : (
					<p>{iban}</p>
				)}
			</div>

			<div className="td">
				<p className="td-hidden-name">Beneficiary Bank Address</p>
				{active ? (
					<div className="table-input table-input--start table-input--full-width">
						<input
							type="text"
							placeholder=""
							value={values.beneficiary_bank_address}
							onChange={(e) => handleChange(e, 'beneficiary_bank_address')}
						/>
					</div>
				) : (
					<p>{beneficiaryBankAddress}</p>
				)}
			</div>
			<div className="td  ">
				<p className="td-hidden-name">Currency</p>
				<p>{assetCode?.toUpperCase()}</p>
			</div>
			<div className="td  ">
				<p className="td-hidden-name">Correspondent Bank</p>
				{active ? (
					<div className="table-input table-input--start table-input--full-width">
						<input
							type="text"
							placeholder=""
							value={values.correspondent_bank}
							onChange={(e) => handleChange(e, 'correspondent_bank')}
						/>
					</div>
				) : (
					<p>{correspondentBank}</p>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					{active ? (
						<>
							<button type="button" onClick={handleCancel}>
								<span className="edit-icon icon-cancel-icon" />
							</button>
							<button type="button" onClick={handleEdit}>
								<span className="edit-icon icon-succes-icon" />
							</button>
						</>
					) : (
						<>
							<button type="button" onClick={() => toggleActive(id)}>
								<span className="edit-icon edit-icon--action icon-edit-icon" />
							</button>
							<button type="button">
								<span
									className="trash-icon trash-icon--action icon-trash-icon"
									onClick={() => onDelete(id)}
								/>
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default BankAccountTableItem;
