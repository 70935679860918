/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { notificationContainer } from 'utils/notificationContainer';
import {
	getStakingInfoRequest,
	getStakeTransactionsRequest,
	postStakeRequest,
	postUnstakeRequest,
} from 'redux/reducers/staking/reducer';
import {
	getStakeInfoLoading,
	getStakingInfo,
	getStakingTransactionLoading,
	getStakingTransactions,
} from 'redux/reducers/staking/selectors';
import { IStakeTronParams } from 'redux/reducers/staking/types';
import StakingInfo from './StakingInfo';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import StakingModal from './StakingModal';
// ==========================================:

const Staking: FC = () => {
	const dispatch = useDispatch();
	const infoLoading = useSelector(getStakeInfoLoading);
	const info = useSelector(getStakingInfo);
	const transactionLoading = useSelector(getStakingTransactionLoading);
	const transactions = useSelector(getStakingTransactions);
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const history = useHistory();
	const location = useLocation();
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [stakeModalOpen, setStakeModalOpen] = useState(false);
	const [unstakeModalOpen, setUnstakeModalOpen] = useState(false);

	useEffect(() => {
		dispatch(getStakingInfoRequest());
		dispatch(getStakeTransactionsRequest({}));
	}, [dispatch]);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			per_page: 15,
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getStakeTransactionsRequest(params));
	};

	const handleStake = (params: IStakeTronParams) => {
		dispatch(
			postStakeRequest({
				apiParams: params,
				onSuccess: () => {
					notificationContainer('Already staked', 'success');
					setStakeModalOpen(false);
					dispatch(
						getStakeTransactionsRequest({
							per_page: 15,
							current_page: currentPage,
						}),
					);
				},
			}),
		);
	};

	const handleUnstake = (params: IStakeTronParams) => {
		dispatch(
			postUnstakeRequest({
				apiParams: params,
				onSuccess: () => {
					notificationContainer('Already unstaked', 'success');
					setUnstakeModalOpen(false);
					dispatch(
						getStakeTransactionsRequest({
							per_page: 15,
							current_page: currentPage,
						}),
					);
				},
			}),
		);
	};

	return (
		<>
			<div className="title-block">
				<p className="title">Tron staking</p>
			</div>
			{infoLoading && (
				<div className="list-loader">
					<Loader />
				</div>
			)}

			{!infoLoading && info && <StakingInfo data={info} />}

			<div className="count-label count-label--zindex count-label--filter-type">
				<button
					type="button"
					className="button button--type3"
					onClick={() => setStakeModalOpen(true)}
				>
					Stake
				</button>
				<button
					type="button"
					className="button button--type3"
					onClick={() => setUnstakeModalOpen(true)}
				>
					Unstake
				</button>
			</div>
			<div className="table-block table-block--border-none table-block--big-mt">
				<div className="table-wrapper">
					<div className="table table--tron-staking">
						<TableHeader />
						<div className="table-body">
							{transactionLoading && (
								<div className="list-loader">
									<Loader />
								</div>
							)}
							{transactions?.data?.map((item) => (
								<TableRow key={item.id} item={item} />
							))}
						</div>
					</div>
				</div>
			</div>

			<div className="pagination-block pagination-block--start">
				{(transactions?.last_page ?? 0) > 1 && (
					<Pagination
						pageCount={transactions?.last_page ?? 0}
						forcePage={currentPage - 1}
						onPageChange={handlePageChange}
					/>
				)}
			</div>
			{stakeModalOpen && (
				<StakingModal onClose={() => setStakeModalOpen(false)} onSubmit={handleStake} />
			)}
			{unstakeModalOpen && (
				<StakingModal
					isUnstaking
					onClose={() => setUnstakeModalOpen(false)}
					onSubmit={handleUnstake}
				/>
			)}
		</>
	);
};

export default Staking;
