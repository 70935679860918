import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IUnidentifiedDepositsApi } from './types';

// ==========================================:
export const unidentifiedDeposits: IUnidentifiedDepositsApi = {
	getList: (params) =>
		http
			.get(endpoint.unidentifiedDeposits.GET_UNIDENTIFIED_DEPOSITS, { params })
			.then((response) => response.data),
	confirm: (payload) =>
		http.put(endpoint.unidentifiedDeposits.CONFIRM_UNIDENTIFIED_DEPOSITS, payload),
	reject: (payload) =>
		http.put(endpoint.unidentifiedDeposits.REJECT_UNIDENTIFIED_DEPOSITS, payload),
	findUsers: (params) =>
		http
			.get(endpoint.unidentifiedDeposits.FIND_USERS, { params })
			.then((response) => response.data),
};
