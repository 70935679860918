export const CurrenciesMarginCrossTableHeader = () => (
	<div className="table-header">
		<div className="tr">
			<div className="td td">
				<div className="td-name">
					<p>Currency</p>
				</div>
			</div>
			<div className="td td--right">
				<div className="td-name">
					<p>Cross Margin Leverage</p>
				</div>
			</div>
			<div className="td td--right">
				<div className="td-name">
					<p>Cross Margin Status</p>
				</div>
			</div>
			<div className="td td--right">
				<div className="td-name">
					<p>Action</p>
				</div>
			</div>
		</div>
	</div>
);
