import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFees } from 'redux/reducers/fees/selectors';
import { getCryptoFeesRequest } from 'redux/reducers/fees/reducer';
import Loader from 'ui/Loader';
import CryptoFeesItem from './CryptoFeesItem';

const CryptoFees: FC = () => {
	const cryptoFeesList = useSelector(getFees);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCryptoFeesRequest());
	}, [dispatch]);

	return (
		<div className="content-block content-block--user-management">
			<div className="content-block__inside content-block__inside--bt content-block__inside--login-his">
				<div className="table-block">
					<div className="table-wrapper">
						<div className="table table--new-fee-crypto">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Currency</p>
										</div>
									</div>
									<div className="td ">
										<div className="td-name">
											<p>
												Deposit <br />
												Fixed amount
											</p>
										</div>
									</div>
									<div className="td ">
										<div className="td-name">
											<p>Deposit, %</p>
										</div>
									</div>
									<div className="td ">
										<div className="td-name">
											<p>
												Withdrawal <br />
												Fixed amount
											</p>
										</div>
									</div>
									<div className="td ">
										<div className="td-name">
											<p>Withdrawal, %</p>
										</div>
									</div>
									<div className="td ">
										<div className="td-name">
											<p>
												VIP Deposit <br />
												Fixed amount
											</p>
										</div>
									</div>
									<div className="td ">
										<div className="td-name">
											<p>VIP Deposit, %</p>
										</div>
									</div>
									<div className="td ">
										<div className="td-name">
											<p>
												VIP Withdrawal
												<br />
												Fixed amount
											</p>
										</div>
									</div>
									<div className="td ">
										<div className="td-name">
											<p>VIP Withdrawal, %</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name td-name--action">
											<p>Edit</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body">
								{cryptoFeesList?.feesLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<>
										{cryptoFeesList?.cryptoFees?.map((item) => (
											<CryptoFeesItem item={item} key={item.id} />
										))}
										<div />
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CryptoFees;
