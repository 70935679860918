import { useDispatch } from 'react-redux';
import { ChangeEvent, FC, useState } from 'react';
import {
	changeShoulderCrossRequest,
	toggleActiveCrossRequest,
} from 'redux/reducers/tradingPairs/reducer';
import { IApiMarginCrossItem } from 'services/api/tradingPairs/types';
import { ReactComponent as CheckIcon } from 'assets/img/icons/check.svg';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';

export const CurrenciesMarginCrossTableItem: FC<IApiMarginCrossItem> = ({
	id,
	code,
	active_margin,
	shoulder_cross,
}) => {
	const dispatch = useDispatch();
	const [isValid, setIsValid] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [crossLeverage, setCrossLeverage] = useState<string>(shoulder_cross.toString() || '');
	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.MANAGE_CURRENCIES);

	const handleCrossLeverageChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.replace(/\D/g, '');
		setCrossLeverage(value);
		setIsValid(value === shoulder_cross.toString() ? false : !!value);
	};
	const handleCrossStatusChange = () => {
		if (!canEdit) return;
		dispatch(toggleActiveCrossRequest({ pairId: id }));
	};

	const activateEditMode = () => {
		setIsEdit(true);
		setCrossLeverage(shoulder_cross.toString() || '');
	};
	const disabledEditMode = () => {
		setIsEdit(false);
	};

	const handleEdit = () => {
		dispatch(changeShoulderCrossRequest({ asset_id: id, shoulder: Number(crossLeverage) }));
		disabledEditMode();
	};

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Currency</p>
				<p style={{ textTransform: 'uppercase' }}>{code}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Cross Margin Leverage</p>
				{!isEdit && <p>{shoulder_cross}</p>}
				{isEdit && <input value={crossLeverage} onChange={handleCrossLeverageChange} />}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Cross Margin Status</p>
				<div
					className={`switch switch--type2 user-switcher__switch ${
						!canEdit ? 'switch--disabled' : ''
					}`}
				>
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={!!active_margin}
							onChange={handleCrossStatusChange}
							disabled={!canEdit}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				{isEdit && (
					<div className="table-buttons">
						<button type="button" onClick={disabledEditMode}>
							<span className="edit-icon icon-cancel-icon" />
						</button>

						<button
							type="button"
							onClick={handleEdit}
							className={!isValid ? 'button-not-active' : ''}
							disabled={!isValid}
						>
							<CheckIcon />
						</button>
					</div>
				)}

				{!isEdit && (
					<button type="button" onClick={activateEditMode}>
						<span className="edit-icon icon-edit-icon" />
					</button>
				)}
			</div>
		</div>
	);
};
