import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { getUsersRequest } from 'redux/reducers/userManagement/reducer';
import Dashboard from 'layouts/Dashboard';
import UserManagement from 'components/UserManagement';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const UserManagementPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.MANAGE_USERS}>
			<Dashboard title="User management">
				<UserManagement />
			</Dashboard>
		</PermissionGate>
	);
};

export default UserManagementPage;
