import { FC, useEffect } from 'react';
import Dashboard from 'layouts/Dashboard';
import { useDispatch } from 'react-redux';
import { getConvertSettingsRequest } from 'redux/reducers/settings/reducer';
import ConvertSettings from 'components/ConvertSettings';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const ConvertSettingsPage: FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getConvertSettingsRequest());
	}, [dispatch]);
	return (
		<PermissionGate permissions={permissions.MANAGE_CONVERT_SETTINGS}>
			<Dashboard title="Convert Settings">
				<ConvertSettings />
			</Dashboard>
		</PermissionGate>
	);
};

export default ConvertSettingsPage;
