export const USER_STATUS_APPROVED = 'identified';
export const USER_STATUS_PENDING = 'unidentified';
export const USER_STATUS_REJECTED = 'rejected';

export const USER_STATUS_NUM_APPROVED = 'identified';
export const USER_STATUS_NUM_UNVERIFIED = 'unidentified';
export const USER_STATUS_NUM_REJECTED = 'rejected';

export const USER_STATUS_ARRAY = [
	'all',
	USER_STATUS_NUM_APPROVED,
	USER_STATUS_NUM_UNVERIFIED,
	USER_STATUS_NUM_REJECTED,
];

export const USER_STATUS_TEXT_ARRAY = [
	'All',
	USER_STATUS_APPROVED,
	USER_STATUS_PENDING,
	USER_STATUS_REJECTED,
];

export const USER_SEARCH_ARRAY = [
	'user_id',
	'payment_id',
	'asset_id',
	'sender_name',
	'external_processing_system',
	'sender_bank_name',
	'sender_iban',
];

export const USER_SEARCH_TEXT_ARRAY = [
	'User ID',
	'Payment ID',
	'Asset ID',
	'Sender name',
	'External processing system',
	'Bank name',
	'IBAN',
];
