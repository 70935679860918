import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { appLanguage } from 'redux/reducers/app/selectors';
import { languages } from 'languages';
import { routesList } from 'routes/routesList';

// ==========================================:
const App: FC = () => {
	const language = useSelector(appLanguage);
	L.setTexts(languages[language]);

	return (
		<BrowserRouter>
			<Switch>
				{routesList?.map((route) => {
					return <Route key={route.path} exact path={route.path} component={route.component} />;
				})}
				<Redirect to="/" />
			</Switch>
		</BrowserRouter>
	);
};

export default App;
