import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeCryptoFeesRequest } from 'redux/reducers/fees/reducer';
import { numberFormat8Fraction } from 'utils/numberFormat';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IChangeCryptoFeesValue, ICryptoFeesItemProps, IFiatFeesItemProps } from './types';

const toNumberFormatFraction = (value: number | null | undefined) =>
	typeof value === 'number' ? numberFormat8Fraction(value, 'en-En') : '';

const CryptoFeesItem: FC<ICryptoFeesItemProps> = ({ item }) => {
	const [deposit, setDeposit] = useState(toNumberFormatFraction(item.deposit_fee));
	const [withdrawal, setWithdrawal] = useState(toNumberFormatFraction(item.withdraw_fee));
	const [depositVip, setDepositVip] = useState(toNumberFormatFraction(item.vip_deposit_fee));
	const [withdrawalVip, setWithdrawalVip] = useState(toNumberFormatFraction(item.vip_withdraw_fee));
	const [depositFixed, setDepositFixed] = useState(toNumberFormatFraction(item.fixed_deposit_fee));
	const [withdrawalFixed, setWithdrawalFixed] = useState(
		toNumberFormatFraction(item.fixed_withdraw_fee),
	);
	const [depositFixedVip, setDepositFixedVip] = useState(
		toNumberFormatFraction(item.vip_fixed_deposit_fee),
	);
	const [withdrawalFixedVip, setWithdrawalFixedVip] = useState(
		toNumberFormatFraction(item.vip_fixed_withdraw_fee),
	);
	const [isClosed, setIsClosed] = useState(false);
	const dispatch = useDispatch();
	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.SET_FEES);

	const handleCryptoFeesSubmit = (value: IChangeCryptoFeesValue) => {
		if (deposit === '' || withdrawal === '' || depositVip === '' || withdrawalVip === '') return;
		dispatch(changeCryptoFeesRequest(value));
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>
					{item.asset_code.toUpperCase()}
					<span>(</span>
					<span>{item.network_name.toUpperCase()}</span>
					<span>)</span>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Deposit Fixed amount</p>
				{isClosed ? <div /> : <p>{depositFixed}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={depositFixed}
						onChange={(e) => {
							setDepositFixed(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Deposit, %</p>
				{isClosed ? <div /> : <p>{deposit}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={deposit}
						onChange={(e) => {
							setDeposit(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Withdrawal Fixed amount</p>
				{isClosed ? <div /> : <p>{withdrawalFixed}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={withdrawalFixed}
						onChange={(e) => setWithdrawalFixed(e.target.value.replace(/[^\d\\.]/g, ''))}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Withdrawal, %</p>
				{isClosed ? <div /> : <p>{withdrawal}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={withdrawal}
						onChange={(e) => setWithdrawal(e.target.value.replace(/[^\d\\.]/g, ''))}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">VIP Deposit Fixed amount</p>
				{isClosed ? <div /> : <p>{depositFixedVip}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={depositFixedVip}
						onChange={(e) => {
							setDepositFixedVip(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">VIP Deposit, %</p>
				{isClosed ? <div /> : <p>{depositVip}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={depositVip}
						onChange={(e) => {
							setDepositVip(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">VIP Withdrawal Fixed amount</p>
				{isClosed ? <div /> : <p>{withdrawalFixedVip}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={withdrawalFixedVip}
						onChange={(e) => setWithdrawalFixedVip(e.target.value.replace(/[^\d\\.]/g, ''))}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">VIP Withdrawal, %</p>
				{isClosed ? <div /> : <p>{withdrawalVip}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={withdrawalVip}
						onChange={(e) => setWithdrawalVip(e.target.value.replace(/[^\d\\.]/g, ''))}
					/>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					<button
						type="submit"
						className={`${isClosed ? '' : 'disable'}`}
						onClick={() => {
							setDeposit(toNumberFormatFraction(item.deposit_fee));
							setWithdrawal(toNumberFormatFraction(item.withdraw_fee));
							setDepositVip(toNumberFormatFraction(item.vip_deposit_fee));
							setWithdrawalVip(toNumberFormatFraction(item.vip_withdraw_fee));
							setDepositFixed(toNumberFormatFraction(item.fixed_deposit_fee));
							setWithdrawalFixed(toNumberFormatFraction(item.fixed_withdraw_fee));
							setDepositFixedVip(toNumberFormatFraction(item.vip_fixed_deposit_fee));
							setWithdrawalFixedVip(toNumberFormatFraction(item.vip_fixed_withdraw_fee));
							setIsClosed(!isClosed);
						}}
					>
						<span className="edit-icon icon-cancel-icon" />
					</button>
					{isClosed ? (
						<button
							type="button"
							onClick={() => {
								handleCryptoFeesSubmit({
									id: item.id,
									deposit_fee: deposit ? Number(deposit) : undefined,
									withdraw_fee: withdrawal ? Number(withdrawal) : undefined,
									vip_deposit_fee: depositVip ? Number(depositVip) : undefined,
									vip_withdraw_fee: withdrawalVip ? Number(withdrawalVip) : undefined,
									fixed_deposit_fee: depositFixed ? Number(depositFixed) : undefined,
									fixed_withdraw_fee: withdrawalFixed ? Number(withdrawalFixed) : undefined,
									vip_fixed_deposit_fee: depositFixedVip ? Number(depositFixedVip) : undefined,
									vip_fixed_withdraw_fee: withdrawalFixedVip
										? Number(withdrawalFixedVip)
										: undefined,
								});
							}}
							className={
								deposit === '' || withdrawal === '' || depositVip === '' || withdrawalVip === ''
									? 'button-not-active'
									: ''
							}
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					) : (
						<button type="submit" disabled={!canEdit} onClick={() => setIsClosed(!isClosed)}>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default CryptoFeesItem;
