/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
	getTimeParserDateFromTimestamp,
	getTimeParserTimeFromTimestamp,
} from 'utils/dateAndTimeHelpers';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { updateReferralFeeRequest } from 'redux/reducers/userManagement/reducer';
import {
	USER_STATUS_APPROVED,
	USER_STATUS_APPROVED_CLASS,
	USER_STATUS_PENDING,
	USER_STATUS_PENDING_CLASS,
	USER_STATUS_REJECTED,
	USER_STATUS_ON_HOLD,
	USER_STATUS_REJECTED_CLASS,
	USER_STATUS_UNVERIFIED,
	USER_STATUS_UNVERIFIED_CLASS,
	USER_STATUS_ON_HOLD_CLASS,
} from 'redux/reducers/userManagement/constants';
import { IAdminManagementListItemProps } from './types';
import { BlockedButton } from './BlockedButton';

// ==========================================:
const UserManagementItem: FC<IAdminManagementListItemProps> = ({ data, handleClearSearch }) => {
	const dispatch = useDispatch();
	const [vip] = useState(data.is_vip);
	const [isRedact, setIsRedact] = useState(false);
	const [referralFee, setreferralFee] = useState(String(data.data.referral_fee ?? ''));
	const [notCorrect, setNotCorrect] = useState(false);

	let statusClassName;
	switch (data?.status?.name) {
		case USER_STATUS_APPROVED:
			statusClassName = USER_STATUS_APPROVED_CLASS;
			break;
		case USER_STATUS_PENDING:
			statusClassName = USER_STATUS_PENDING_CLASS;
			break;
		case USER_STATUS_UNVERIFIED:
			statusClassName = USER_STATUS_UNVERIFIED_CLASS;
			break;
		case USER_STATUS_REJECTED:
			statusClassName = USER_STATUS_REJECTED_CLASS;
			break;
		case USER_STATUS_ON_HOLD:
			statusClassName = USER_STATUS_ON_HOLD_CLASS;
			break;
		default:
			statusClassName = '';
			break;
	}

	const textValid = (txt?: string | null) => {
		const txtTrim = txt?.trim();
		if (txtTrim) return txtTrim;
		return '-';
	};

	const handleSwitcherChange = (sw: boolean, text: string) => {
		const body = {
			id: data.id,
			[text]: sw ? 1 : 0,
		};
		// dispatch(changeGeneralRequest(body));
	};

	const handleChange = () => {
		if (notCorrect) return;
		setIsRedact(false);
		dispatch(
			updateReferralFeeRequest({
				id: String(data.id),
				fee: String(referralFee),
			}),
		);
	};

	return (
		<div className="tr">
			<Link className="td" to={`/user-management/${String(data.id)}`}>
				<p className="td-hidden-name">Id</p>
				<p>{data.id}</p>
			</Link>
			<div className="td td--status">
				<p className="td-hidden-name">P2P nickname</p>
				<p>{data.p2p_username}</p>
			</div>
			<div className="td td--status">
				<p className="td-hidden-name">Company name</p>
				<p>{data.data?.company_name}</p>
			</div>
			<Link
				className="td"
				to={`/user-management/${String(data.id)}`}
				style={{ textDecoration: 'none', color: '#011E26' }}
			>
				<p className="td-hidden-name">Full name</p>
				<p>{textValid(data.username)}</p>
			</Link>
			<div className="td td--status">
				<p className="td-hidden-name">Level</p>
				{/* <div className="switch switch--type2">
					<p>VIP</p>
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={!!vip}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								handleSwitcherChange(e.target.checked, 'vip');
							}}
						/>
						<div className="switch__toggler" />
					</label>
				</div> */}
				<p>{data.is_vip ? 'VIP' : 'Regular'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">E-mail</p>
				<p>
					<a href={`mailto:${data.email}`} className="link">
						{textValid(data.email)}
					</a>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Phone number</p>
				<p>{textValid(data.phone)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Country</p>
				<p>{textValid(data.data.country)}</p>
			</div>
			{data.parent_id != null ? (
				<Link className="td" to={`/user-management/${String(data.parent_id)}`}>
					<p className="td-hidden-name">Invited by</p>
					<p>{data.parent_id}</p>
				</Link>
			) : (
				<div className="td">
					<p className="td-hidden-name">Invited by</p>
				</div>
			)}
			<div className="td td--status">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={`status ${statusClassName}`}>
						{data.status?.name && capitalizeFirstLetter(data.status.name.replaceAll('_', ' '))}
					</span>
				</div>
			</div>
			<div className="td td--status">
				<p className="td-hidden-name">Registration date</p>
				<p>
					{getLocaleDateFromTimestamp(data?.created_at)}{' '}
					{getLocaleTimeFromTimestamp(data?.created_at)}
				</p>
			</div>
			<div className="td td--status">
				<p className="td-hidden-name">Verification date</p>
				<p>
					{data.ondato?.created_at && getLocaleDateFromTimestamp(data.ondato?.created_at)}{' '}
					{data.ondato?.created_at && getLocaleTimeFromTimestamp(data.ondato?.created_at)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Referral fess %</p>
				{!isRedact ? (
					<p>{data.data.referral_fee}</p>
				) : (
					<div className="table-input table-input--start table-input--full-width">
						<input
							type="text"
							value={referralFee}
							onChange={(e) => {
								setreferralFee(e.target.value.replace(/[^\d\\.]/g, ''));
								setNotCorrect(e.target.value.replace(/[^\d\\.]/g, '') === '');
							}}
						/>
					</div>
				)}
			</div>

			<div className="td">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					{isRedact ? (
						<>
							<button
								type="button"
								onClick={() => {
									setreferralFee(String(data.data.referral_fee));

									setIsRedact(!isRedact);
								}}
							>
								<span className="edit-icon icon-cancel-icon" />
							</button>

							<button
								type="button"
								onClick={handleChange}
								className={notCorrect ? 'button-not-active' : ''}
							>
								<span className="edit-icon icon-succes-icon" />
							</button>
						</>
					) : (
						<button
							type="button"
							// disabled={!checkEditable(permissions.SET_LIMITS)}
							onClick={() => setIsRedact(!isRedact)}
						>
							<span className="edit-icon edit-icon--action icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Action</p>
				<BlockedButton
					blocked={Boolean(Number(data.blocked))}
					id={data.id}
					handleClearSearch={handleClearSearch}
				/>
			</div>
		</div>
	);
};

export default UserManagementItem;
