export const P2PLimitationsTableHeader = () => (
	<div className="table-header">
		<div className="tr">
			<div className="td">
				<div className="td-name">
					<p>Currency name</p>
				</div>
			</div>
			<div className="td td">
				<div className="td-name">
					<p>Regular Min</p>
				</div>
			</div>
			<div className="td td">
				<div className="td-name">
					<p>Regular Max</p>
				</div>
			</div>
			<div className="td td">
				<div className="td-name">
					<p>VIP Min</p>
				</div>
			</div>
			<div className="td td">
				<div className="td-name">
					<p>VIP Max</p>
				</div>
			</div>
			<div className="td td--right">
				<div className="td-name">
					<p>Action</p>
				</div>
			</div>
		</div>
	</div>
);
