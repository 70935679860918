/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
	provider_name,
	currency_pair_first_id,
	currency_pair_second_id,
	order_type,
	timing_frequency,
	sizing_type,
	sizing_from,
	sizing_to,
	pricing_type,
	price,
	is_active,
} from 'utils/formValidation';
import { getProvidersRequest } from 'redux/reducers/walletManagement/reducer';
import { IExternalMakerUpdatePayload } from 'redux/reducers/externalMaker/types';
import {
	deleteExternalPairRequest,
	patchExternalMakerUppdateStatusRequest,
} from 'redux/reducers/externalMaker/reducer';
import { IEdditExternalMakerPairPopup, TValues } from './types';
import SelectLiquidityProvider from '../PopupSelectors/SelectLiquidityProvider';
import SelectOrderType from '../PopupSelectors/SelectOrderType';
import SelectSizingType from '../PopupSelectors/SelectSizingType';

const EditPair: FC<IEdditExternalMakerPairPopup> = ({ onClose, pairData }) => {
	const dispatch = useDispatch();

	const initialValues: TValues = {
		provider_name: pairData.provider_name,
		currency_pair_first_id: pairData.currency_pair_first.id,
		currency_pair_second_id: pairData.currency_pair_second.id,
		order_type: pairData.order_type,
		timing_frequency: String(pairData.timing_frequency),
		sizing_type: String(pairData.sizing_type),
		sizing_from: pairData.sizing_from === 0 ? 0.00001 : pairData.sizing_from,
		sizing_to: pairData.sizing_to,
		pricing_type: pairData.pricing_type,
		price: pairData.price,
		is_active: !!pairData.is_active,
	};

	const validationSchema = yup.object().shape({
		provider_name,
		currency_pair_first_id,
		currency_pair_second_id,
		order_type,
		timing_frequency,
		sizing_type,
		sizing_from,
		sizing_to,
		pricing_type,
		price,
		is_active,
	});

	useEffect(() => {
		dispatch(getProvidersRequest());
	}, [dispatch]);

	const handleDeletePair = () => {
		dispatch(deleteExternalPairRequest({ pair_id: pairData.id }));
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-890">
					<button type="button" className="popup__close" onClick={onClose}>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15 3L3 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3 3L15 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">Pair ID {pairData.id}</p>
					</div>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values: TValues) => {
							onClose();
							const payload: IExternalMakerUpdatePayload = {
								id: pairData.id,
								body: values,
							};
							dispatch(patchExternalMakerUppdateStatusRequest(payload));
						}}
						validateOnBlur
						enableReinitialize
					>
						{({ errors, touched, values, setFieldValue }) => {
							const showError = (field: keyof TValues) => errors[field] && touched[field];

							return (
								<Form>
									<div className="popup-body popup-body--type2">
										<div className="inputs-two-column">
											<div className="input-group">
												<div className="input ">
													<p className="input__name">Liquidity Provider Name</p>
													<Field
														type="text"
														placeholder="Select Liquidity Provider"
														name="provider_name"
														className={showError('provider_name') ? 'select--error' : ''}
														isDisabled
														component={SelectLiquidityProvider}
													/>
													{showError('provider_name') && (
														<p className="error-text">{errors.provider_name}</p>
													)}
												</div>
												<div className="input ">
													<p className="input__name">Order Type</p>
													<Field
														type="text"
														placeholder="Select"
														name="order_type"
														className={showError('order_type') ? 'select--error' : ''}
														component={SelectOrderType}
													/>
													{showError('order_type') && (
														<p className="error-text">{errors.order_type}</p>
													)}
												</div>
												<div className="input ">
													<p className="input__name input__name--fw-500">
														Timing Frequency (Seconds)
													</p>
													<div
														className={`input-wrapper ${
															showError('timing_frequency') ? 'input--error' : ''
														}`}
													>
														<Field
															type="text"
															placeholder="Enter time"
															name="timing_frequency"
															className="input-item"
														/>
														<ErrorMessage
															name="timing_frequency"
															className="error-text"
															component="p"
														/>
													</div>
												</div>
											</div>
											<div className="input-group">
												<div className="input ">
													<p className="input__name">Sizing</p>
													<Field
														type="text"
														placeholder="Select"
														name="sizing_type"
														className={showError('sizing_type') ? 'select--error' : ''}
														component={SelectSizingType}
													/>
													{showError('sizing_type') && (
														<p className="error-text">{errors.sizing_type}</p>
													)}
												</div>
												{values.sizing_type === 'random' && (
													<>
														<div className="input ">
															<div
																className={`input-wrapper ${
																	showError('sizing_from') ? 'input--error' : ''
																}`}
															>
																<span className="input-badge">
																	From
																	<span className="input-badge_line"> | </span>
																</span>
																<Field
																	type="text"
																	name="sizing_from"
																	className="input-item input-item--left-badge"
																/>
															</div>
															<ErrorMessage
																name="sizing_from"
																className="error-text"
																component="p"
															/>
														</div>
														<div className="input ">
															<div
																className={`input-wrapper ${
																	showError('sizing_to') ? 'input--error' : ''
																}`}
															>
																<span className="input-badge">
																	To
																	<span className="input-badge_line"> | </span>
																</span>
																<Field
																	type="text"
																	name="sizing_to"
																	className="input-item input-item--left-badge"
																/>
															</div>
															<ErrorMessage name="sizing_to" className="error-text" component="p" />
														</div>
													</>
												)}
												{values.sizing_type === 'exact' && (
													<div className="input ">
														<div
															className={`input-wrapper ${
																showError('sizing_from') ? 'input--error' : ''
															}`}
														>
															<span className="input-badge input-badge--long">
																Exact Order
																<span className="input-badge_line"> | </span>
															</span>
															<Field
																type="text"
																name="sizing_to"
																className="input-item input-item--left-badge-long"
															/>
														</div>
													</div>
												)}
												<div className="input ">
													<div className="input__header input__header--with-switcher">
														<p className="input__name input__name--fw-500">Pricing</p>
														<div className="input-switch">
															<div className="input-switch__text">Manual/Auto</div>
															<div className="switch switch--type2">
																<label className="switch__label">
																	<Field
																		type="checkbox"
																		className="hidden"
																		name="pricing_type"
																		checked={values.pricing_type === 'auto'}
																		onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
																			const newPriceValue =
																				values.pricing_type === 'manual' ? 1 : undefined;
																			const newPricingType = e.target.checked ? 'auto' : 'manual';

																			setFieldValue('price', newPriceValue);
																			setFieldValue('pricing_type', newPricingType);
																		}}
																	/>
																	<div className="switch__toggler" />
																</label>
															</div>
														</div>
													</div>
													{values.pricing_type === 'manual' && (
														<div
															className={`input-wrapper ${
																showError('price') ? 'input--error' : ''
															}`}
														>
															<Field
																type="text"
																placeholder="Enter Pricing"
																name="price"
																className="input-item"
															/>
															<ErrorMessage name="price" className="error-text" component="p" />
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="popup-submit popup-submit--type3">
										<div className="popup-submit__group">
											<button
												className="button button--del button--full-width"
												type="button"
												onClick={handleDeletePair}
											>
												Delete position
											</button>
											<button className="button button--delete button--full-width" type="submit">
												Save
											</button>
										</div>
									</div>
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default EditPair;
