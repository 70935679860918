/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { ISearchBarProps } from './types';

const SearchBar: FC<ISearchBarProps> = ({
	setDebouncedTerm,
	debouncedTerm,
	searchFieldArray,
	searchFieldTextArray,
	searchField,
	setSearchField,
	handleClearSearch,
	typeSearch,
	dropdownArray,
	dropdownTextArray,
}) => {
	const [vueSelect, setVueSelect] = useState(false);
	const [vueDropdownSelect, setVueDropdownSelect] = useState(false);
	const [dropdown, setDropdown] = useState(dropdownArray?.[0] ?? 'all');

	const index = searchFieldArray.indexOf(searchField);
	const searchFieldText =
		(searchFieldTextArray?.length ?? 0) > 0
			? searchFieldTextArray?.[index] ?? searchField
			: searchField;
	const normText = (txt: string) => `${txt[0].toUpperCase()}${txt.slice(1)}`.replace('_', ' ');
	return (
		<div className="input-with-select">
			{searchFieldArray.length > 1 && (
				<div className={`select select--height-48 ${vueSelect ? 'active' : ''}`}>
					<button
						style={{ width: '100px' }}
						type="button"
						className="select__current"
						onClick={() => {
							setVueSelect(!vueSelect);
							setVueDropdownSelect(false);
						}}
					>
						{normText(searchFieldText)}
						<span className="select__current-arrow">
							<svg
								width="9"
								height="5"
								viewBox="0 0 9 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1.5 1L4.5 4L7.5 1" stroke="#ffffff" strokeLinecap="square" />
							</svg>
						</span>
					</button>
					<div className="select__drop">
						<div className="select__drop-scroll">
							<div className="select-drop__item">
								<ul>
									{searchFieldArray.map((item, i) => {
										return (
											<li key={item} value={item}>
												<button
													type="button"
													onClick={() => {
														setVueSelect(false);
														if (searchField !== item) {
															setSearchField(item);
															handleClearSearch();
															setDropdown('all');
														}
													}}
												>
													{normText(searchFieldTextArray?.[i] ?? item)}
												</button>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className="input-wrapper">
				<input
					className={`input-item input-item--right-icon ${
						searchFieldArray.length > 1 ? 'input-item-with-select' : ''
					}`}
					type="text"
					placeholder={`Search by ${searchFieldText}`}
					onChange={(e) => setDebouncedTerm(e.target.value)}
					value={debouncedTerm}
				/>
				<span className="input-icon input-icon--right icon-search-icon" />
			</div>
		</div>
	);
};

export default SearchBar;
