import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { api, responseErrors } from 'services';
import axios from 'axios';
import {
	balanceListInitState,
	getBalanceListRequest,
	getHotBalanceListRequest,
	setBalanceListSuccess,
	setHotBalanceListSuccess,
	getTotalFeeCollectedListRequest,
	setTotalFeeCollectedListSuccess,
} from './reducer';
import {
	IBalanceListResponseData,
	IHotBalanceListResponseData,
	ITotalFeeCollectedListResponseData,
} from './types';

// =============================================================:
function* balanceListWorker() {
	try {
		yield put(showLoading());
		const response: IBalanceListResponseData = yield call(api.balance.getBalance);

		yield put(setBalanceListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(balanceListInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* hotBalanceListWorker() {
	try {
		yield put(showLoading());
		const response: IHotBalanceListResponseData = yield call(api.balance.getHotBalance);

		yield put(setHotBalanceListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(balanceListInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* totalFeeCollectedListWorker() {
	try {
		yield put(showLoading());
		const response: ITotalFeeCollectedListResponseData = yield call(
			api.balance.getTotalFeeCollected,
		);

		yield put(setTotalFeeCollectedListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(balanceListInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* balanceSaga() {
	yield takeEvery(getBalanceListRequest.type, balanceListWorker);
	yield takeEvery(getHotBalanceListRequest.type, hotBalanceListWorker);
	yield takeEvery(getTotalFeeCollectedListRequest.type, totalFeeCollectedListWorker);
}
