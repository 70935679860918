/* eslint-disable react/button-has-type */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notificationContainer } from 'utils/notificationContainer';
import {
	approveDepositRequest,
	rejectDepositRequest,
} from 'redux/reducers/pendingDeposits/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { RejectWithdrawalsModal } from './RejectWithdrawalsModal';
import { IActionsButtonProps } from './types';
import { AcceptWithdrawalsModal } from './AcceptWithdrawalsModal';

export const ActionsButtons: FC<IActionsButtonProps> = ({ id, handleClearSearch }) => {
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);
	const [openModalAccept, setOpenModalAccept] = useState(false);
	const { checkEditable } = usePermission();

	const closeModal = () => setOpenModal(false);
	const closeModalAccept = () => setOpenModalAccept(false);

	const handleApprove = () => {
		if (!checkEditable(permissions.MANAGE_PENDING_DEPOSITS)) return;
		dispatch(
			approveDepositRequest({
				apiParams: { id },
			}),
		);
		handleClearSearch();
	};

	const handleReject = (comment: string) => {
		if (!checkEditable(permissions.MANAGE_PENDING_DEPOSITS)) return;
		dispatch(
			rejectDepositRequest({
				apiParams: { id, reject_reason: comment },
			}),
		);
		handleClearSearch();
	};

	return (
		<div className="table-buttons">
			<button
				disabled={!checkEditable(permissions.MANAGE_PENDING_DEPOSITS)}
				onClick={() => setOpenModal(true)}
			>
				<span className="edit-icon icon-cancel-icon" />
			</button>
			<button
				disabled={!checkEditable(permissions.MANAGE_PENDING_DEPOSITS)}
				onClick={() => setOpenModalAccept(true)}
			>
				<span className="edit-icon icon-succes-icon" />
			</button>
			<RejectWithdrawalsModal
				openModal={openModal}
				closeModal={closeModal}
				handleRejectDeposit={handleReject}
			/>
			<AcceptWithdrawalsModal
				openModal={openModalAccept}
				closeModal={closeModalAccept}
				handleAcceptDeposit={handleApprove}
			/>
		</div>
	);
};
