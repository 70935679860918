const TableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Beneficiary Name</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Beneficiary Bank</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Type</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>SWIFT</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Beneficiary IBAN</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Beneficiary Bank Address</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Currency</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Correspondent Bank</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name td-name--action">
						<p>Action</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
