/* eslint-disable react/no-array-index-key */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'ui/Loader';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import {
	getExternalMakerLoader,
	getExternalMakerPairs,
} from 'redux/reducers/externalMaker/selectors';
import { getExternalMakerRequest } from 'redux/reducers/externalMaker/reducer';
import { IExternalMakerPageRequestPayload } from 'redux/reducers/externalMaker/types';
import Pagination from 'ui/Pagination';
import { PairsItem } from './PairsItem';
import { IPairsParams } from './types';

export const Pairs: FC<IPairsParams> = ({ onOpenEditPair }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { search, pathname } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const pairsData = useSelector(getExternalMakerPairs);
	const externalMakerLoader = useSelector(getExternalMakerLoader);

	useEffect(() => {
		dispatch(getExternalMakerRequest({ current_page: currentPage }));
	}, [dispatch, currentPage]);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params: IExternalMakerPageRequestPayload = {
			current_page: pageNumber || 1,
		};
		history.push({
			pathname,
			search: searchParams,
		});

		dispatch(getExternalMakerRequest(params));
	};

	return (
		<>
			<div className="content-block content-block--walletman">
				<div>
					<div className="table-block table-block--mt-0 table-block--border-none">
						<div className="table-wrapper">
							<div className="table table--external-pairs">
								<div className="table-header">
									<div className="tr">
										<div className="td">
											<div className="td-name">
												<p>ID</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Provider Name</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Currency Pair</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Order type</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Timing Frequency (Sec)</p>
											</div>
										</div>
										<div className="td td">
											<div className="td-name">
												<p>Sizing</p>
											</div>
										</div>
										<div className="td td">
											<div className="td-name">
												<p>Pricing</p>
											</div>
										</div>
										<div className="td td">
											<div className="td-name">
												<p>On/Off</p>
											</div>
										</div>
										<div className="td td">
											<div className="td-name">
												<p>Action</p>
											</div>
										</div>
									</div>
								</div>

								{!pairsData?.length && !externalMakerLoader && (
									<div className="user-management-empty-data">
										<p className="user-management-empty__text">No wallets found...</p>
									</div>
								)}

								{externalMakerLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<div className="table-body">
										{pairsData?.map((item) => (
											<PairsItem key={item.id} data={item} onOpenEditPair={onOpenEditPair} />
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* {(pairsData?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={pairsData?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)} */}
		</>
	);
};
