import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	getStakingInfoRequest,
	getStakingInfoSuccess,
	getStakingInfoError,
	getStakeTransactionsRequest,
	getStakeTransactionsSuccess,
	getStakeTransactionsError,
	postStakeRequest,
	postStakeSuccess,
	postStakeError,
	postUnstakeRequest,
	postUnstakeSuccess,
	postUnstakeError,
} from './reducer';
import {
	IStakingInfo,
	IGetStakeTransactionsParams,
	IGetStakeTransactionsResponse,
	IStakeTronParams,
	IStakeTronPayload,
	IStakeTransactionItem,
} from './types';

function* getStakingInfoWorker() {
	try {
		yield put(showLoading());
		const response: IStakingInfo = yield call(api.staking.getStakingInfo);

		yield put(getStakingInfoSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getStakingInfoError());
	} finally {
		yield put(hideLoading());
	}
}

function* getStakeTransactionsWorker({ payload }: PayloadAction<IGetStakeTransactionsParams>) {
	try {
		yield put(showLoading());
		const response: IGetStakeTransactionsResponse = yield call(
			api.staking.getStakeTransactions,
			payload,
		);

		yield put(getStakeTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getStakeTransactionsError());
	} finally {
		yield put(hideLoading());
	}
}

function* postStakeWorker({ payload }: PayloadAction<IStakeTronPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		const response: IStakeTransactionItem = yield call(api.staking.stakeTron, apiParams);
		yield put(postStakeSuccess(response));
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}

		yield put(postStakeError());
	} finally {
		yield put(hideLoading());
	}
}

function* postUntakeWorker({ payload }: PayloadAction<IStakeTronPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		const response: IStakeTransactionItem = yield call(api.staking.unstakeTron, apiParams);
		yield put(postUnstakeSuccess(response));
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}

		yield put(postUnstakeError());
	} finally {
		yield put(hideLoading());
	}
}

export function* stakingSaga() {
	yield takeEvery(getStakingInfoRequest.type, getStakingInfoWorker);
	yield takeEvery(getStakeTransactionsRequest.type, getStakeTransactionsWorker);
	yield takeEvery(postStakeRequest.type, postStakeWorker);
	yield takeEvery(postUnstakeRequest.type, postUntakeWorker);
}
