/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { convertMessage, trimTransactionHash } from 'utils/trimComment';
import { fiatTransactionsStatus } from 'redux/reducers/transactions/constants';
import { notificationContainer } from 'utils/notificationContainer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { Link } from 'react-router-dom';
import { IFiatItemProps } from './types';

export const FiatItem: FC<IFiatItemProps> = ({ data }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);
	const { checkReadable } = usePermission();

	const handleTransactionUrlCopy = () => {
		notificationContainer('Transaction url copied successfully!', 'info');
	};

	const getTransactionType = () => {
		switch (data.external_processing_system) {
			case 'cartu': {
				return 'Cartu';
			}
			case 'fts': {
				return 'FTS';
			}
			default:
				return '';
		}
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">User Full name</p>
				<p>{data.full_name}</p>
			</div>
			{checkReadable(permissions.MANAGE_USERS) ? (
				<Link className="td" to={`/user-management/${String(data.user_id)}`}>
					<p className="td-hidden-name">User ID</p>
					<p>{data.user_id}</p>
				</Link>
			) : (
				<div className="td">
					<p className="td-hidden-name">User ID</p>
					<p>{data.user_id}</p>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Data</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} {dateOffset.toLocaleTimeString()}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{data.asset.code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Payment Method</p>
				<p>
					{convertMessage(data.payment_system)} {data?.is_manual ? '(manual)' : ''}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Transaction type</p>
				<p>{getTransactionType()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">IBAN</p>
				<p>{data.iban}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Personal number ID</p>
				<p>{data.personal_code}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Bank name</p>
				<p>{data.sender_bank_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Reference number</p>
				<p>{data.reference_number}</p>
			</div>
			<div className="td" style={{ maxWidth: 168 }}>
				<p className="td-hidden-name">Comment</p>
				<p>{data.manual_deposit_comment}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Indicated Amount</p>
				<p>{Number(data.indicated_amount)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{Number(data.amount_fee)}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p>{Number(data?.total_amount)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Comment</p>
				<p>{data.comment || '-'}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={`status ${fiatTransactionsStatus?.[data?.status]?.statusClass ?? ''}`}>
						{fiatTransactionsStatus?.[data?.status]?.statusText ?? ''}
					</span>
				</div>
			</div>
		</div>
	);
};
