import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeP2PRequest } from 'redux/reducers/fees/reducer';
import { numberFormat8Fraction } from 'utils/numberFormat';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';

const P2PItem: FC<any> = ({ item }) => {
	const [buyer, setDeposit] = useState(numberFormat8Fraction(item.buyer_fee, 'en-En'));
	const [seller, setWithdrawal] = useState(numberFormat8Fraction(item.seller_fee, 'en-En'));
	const [vipBuyer, setVipDeposit] = useState(numberFormat8Fraction(item.vip_buyer_fee, 'en-En'));
	const [vipSeller, setVipWithdrawal] = useState(
		numberFormat8Fraction(item.vip_seller_fee, 'en-En'),
	);
	const [isClosed, setIsClosed] = useState(false);
	const { checkEditable } = usePermission();
	const canEdit = checkEditable(permissions.SET_FEES);
	const dispatch = useDispatch();

	const handleCryptoFeesSubmit = (value: any) => {
		if (buyer === '' || seller === '') return;

		dispatch(changeP2PRequest(value));
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{item.code.toUpperCase()}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Regular Buyer fee, %</p>
				{isClosed ? <div /> : <p>{buyer}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={buyer}
						onChange={(e) => {
							setDeposit(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Regular Seller fee, %</p>
				{isClosed ? <div /> : <p>{seller}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={seller}
						onChange={(e) => {
							setWithdrawal(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">VIP Buyer fee, %</p>
				{isClosed ? <div /> : <p>{vipBuyer}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={vipBuyer}
						onChange={(e) => {
							setVipDeposit(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">VIP Seller fee, %</p>
				{isClosed ? <div /> : <p>{vipSeller}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={vipSeller}
						onChange={(e) => {
							setVipWithdrawal(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					<button
						type="submit"
						className={`${isClosed ? '' : 'disable'}`}
						onClick={() => {
							setDeposit(numberFormat8Fraction(item.buyer_fee, 'en-En'));
							setWithdrawal(numberFormat8Fraction(item.seller_fee, 'en-En'));
							setVipDeposit(numberFormat8Fraction(item.vip_buyer_fee, 'en-En'));
							setVipWithdrawal(numberFormat8Fraction(item.vip_seller_fee, 'en-En'));
							setIsClosed(!isClosed);
						}}
					>
						<span className="edit-icon icon-cancel-icon" />
					</button>
					{isClosed ? (
						<button
							type="button"
							onClick={() => {
								handleCryptoFeesSubmit({
									code: item.code,
									buyer_fee: Number(buyer),
									seller_fee: Number(seller),
									vip_buyer_fee: Number(vipBuyer),
									vip_seller_fee: Number(vipSeller),
								});
							}}
							className={buyer === '' || seller === '' ? 'button-not-active' : ''}
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					) : (
						<button type="submit" disabled={!canEdit} onClick={() => setIsClosed(!isClosed)}>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default P2PItem;
