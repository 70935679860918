/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { Borrowing } from './MarginBox/Borrowing/Borrowing';
import { OrdersHistory } from './MarginBox/OrdersHistory/OrdersHistory';
import { Repayment } from './MarginBox/Repayment/Repayment';
import { Interest } from './MarginBox/Interest/Interest';
import { Liquidation } from './MarginBox/Liquidation/Liquidation';
import { Balances } from './MarginBox/Balances/Balances';
import { MarginCall } from './MarginBox/MarginCall/MarginСall';

export const Margin: FC = () => {
	const [activeMenu, setActiveMenu] = useState('Balances');
	const [radioCheck, setRadioCheck] = useState('Cross');
	let className = '';
	if (activeMenu === 'Balances') {
		className = 'table--margin-balances';
	} else if (activeMenu === 'Orders history') {
		className = 'table--margin-order_history';
	} else if (activeMenu === 'Borrowing') {
		className = 'table--margin-borrowing';
	} else if (activeMenu === 'Repayment') {
		className = 'table--margin-repayment';
	} else if (activeMenu === 'Interest') {
		className = 'table--margin-interest';
	} else if (activeMenu === 'Margin calls') {
		className = 'table--margin-marginCall';
	} else if (activeMenu === 'Liquidation history') {
		className = 'table--margin-liquidation_history';
	}
	if (radioCheck === 'Isolated') {
		className += '_isolated';
	}
	const arrayMenu = [
		{
			id: 1,
			name: 'Balances',
		},
		{
			id: 2,
			name: 'Orders history',
		},
		{
			id: 3,
			name: 'Borrowing',
		},
		{
			id: 4,
			name: 'Repayment',
		},
		{
			id: 5,
			name: 'Interest',
		},
		{
			id: 6,
			name: 'Margin calls',
		},
		{
			id: 7,
			name: 'Liquidation history',
		},
	];
	const menuTop = () => {
		return arrayMenu.map((e) => {
			return (
				<li
					key={e.id}
					className={`table-panel-tab__item ${activeMenu === e.name ? 'active' : ''}`}
					onClick={() => setActiveMenu(e.name)}
				>
					<span className="table-panel-tab__link">{e.name}</span>
				</li>
			);
		});
	};

	const menuContent = () => {
		switch (activeMenu) {
			case 'Balances':
				return <Balances radioCheck={radioCheck} />;
			case 'Orders history':
				return <OrdersHistory radioCheck={radioCheck} />;
				break;
			case 'Borrowing':
				return <Borrowing radioCheck={radioCheck} />;
				break;
			case 'Repayment':
				return <Repayment radioCheck={radioCheck} />;
				break;
			case 'Interest':
				return <Interest radioCheck={radioCheck} />;
				break;
			case 'Margin calls':
				return <MarginCall radioCheck={radioCheck} />;
				break;
			case 'Liquidation history':
				return <Liquidation radioCheck={radioCheck} />;
				break;
			default:
				null;
		}
		return null;
	};
	return (
		<div className="margin">
			<div className="content-block content-block--user-management">
				<div>
					<div className="content-block__inside content-block__inside--panel-only">
						<div className="table-panel">
							<ul className="table-panel-tab">{menuTop()}</ul>
							<div className="table-panel__radio-group">
								<div className="table-panel__radio-group">
									<div className="radio">
										<label className="radio__label">
											<input
												onChange={() => {
													setRadioCheck('Cross');
												}}
												checked={radioCheck === 'Cross'}
												type="radio"
												className="hidden"
												name="margin-type"
											/>
											<span className="radio__item" />
											<span className="radio__text">Cross </span>
										</label>
									</div>
									<div className="radio">
										<label className="radio__label">
											<input
												onChange={() => {
													setRadioCheck('Isolated');
												}}
												checked={radioCheck === 'Isolated'}
												type="radio"
												className="hidden"
												name="margin-type"
											/>
											<span className="radio__item" />
											<span className="radio__text">Isolated</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-block__inside content-block__inside--login-his">
					<div className="table-block table-block--ip">
						<div className="table-wrapper">
							<div className={`table ${className}`}>{menuContent()}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
