import { FC } from 'react';
import { IUserManagementListProps } from './types';
import Loader from '../../../ui/Loader';
import MarketMakerBotItem from './MarketMakerBotItem';
// ==========================================:

const MarketMakerBotList: FC<IUserManagementListProps> = ({ pairsSettings, editSettings }) => {
	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--market-maker">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>Pair</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Spread % Bot</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Matching spread</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>External price</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Count Orders</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Frequency / Timing (sec)</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Limit Orders</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Market Orders</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name td-name--action">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>
					{pairsSettings.marketMakerLoader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{pairsSettings.pairs_settings?.map((item: any) => (
								<MarketMakerBotItem
									editSettings={editSettings}
									key={item.asset_pair_id}
									item={item}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MarketMakerBotList;
