/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import { getTimeParserDateFromTimestamp, getTimeParserTimeFromTimestamp } from 'utils/numberFormat';
import { TChatItemProps } from '../ChatBuyer/types';

const ChatsSellerMessagesItem: FC<TChatItemProps> = ({ item, status, showImage }) => {
	return (
		<>
			<div
				className={`history-message history-message--${
					status === item.from_username ? 'admin green-bg' : 'user'
				}`}
			>
				<p className="history-message__name">{item.from_username}</p>
				<div className="history-message__text">
					<p>{item.text}</p>
					{item.files.length
						? item.files.map((file) => {
								if (file.file.includes('pdf')) {
									return (
										<a href={file.file} key={file.created_at} target="_blank" rel="noreferrer">
											PDF
										</a>
									);
								}
								return (
									<img
										src={file.file}
										key={file.created_at}
										style={{ cursor: 'pointer' }}
										alt="file"
										onClick={() => showImage(file.file)}
									/>
								);
						  })
						: null}
				</div>
			</div>
			<span
				className={`chat-message__date ${
					status === item.from_username ? 'admin--time' : 'user--time'
				}`}
			>
				{' '}
				{getTimeParserDateFromTimestamp(item.created_at)}{' '}
				{getTimeParserTimeFromTimestamp(item.created_at)} UTC
			</span>
		</>
	);
};

export default ChatsSellerMessagesItem;
