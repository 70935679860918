/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { noExponent } from 'utils/numberFormat';
import { useDispatch } from 'react-redux';

import { setTabMenuUserIndex } from 'redux/reducers/userManagement/reducer';
import { ICryptoDepositProps } from './types';

const FiatDepositTableItem: FC<ICryptoDepositProps> = ({ item }) => {
	const {
		full_name,
		indicated_amount,
		amount_fee,
		status,
		total_amount,
		payment_system,
		external_processing_system,
		iban,
		personal_code,
		sender_bank_name,
		reference_number,
	} = item;
	const dispatch = useDispatch();
	const [id] = useState(item.id);
	const [coin] = useState(item.asset.code);
	const [currency] = useState(item.asset.name);
	const [amount] = useState(item.amount);
	const [userId] = useState(item.user_id);
	const [created] = useState(item.created_at);
	const date = new Date(created * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+created + offset) * 1000);

	const getTransactionType = () => {
		switch (external_processing_system) {
			case 'cartu': {
				return 'Cartu';
			}
			case 'fts': {
				return 'FTS';
			}
			default:
				return '';
		}
	};

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">User Full name</p>
				<p>{full_name}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Asset</p>
				<p style={{ textTransform: 'uppercase' }}>{coin}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Payment Method</p>
				<p>{payment_system}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Transaction type</p>
				<p>{getTransactionType()}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">IBAN</p>
				<p>{iban}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Personal number ID</p>
				<p>{personal_code}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Bank name</p>
				<p>{sender_bank_name}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Reference number</p>
				<p>{reference_number}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Indicated Amount</p>
				<p>{noExponent(Number(indicated_amount).toString())}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Fee</p>
				<p>{noExponent(Number(amount_fee).toString())}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(Number(total_amount).toString())}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Status</p>
				<p>{status}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Created</p>
				<p className="grey-text">
					{dateOffset.toLocaleDateString('en-GB')} {dateOffset.toLocaleTimeString()}
				</p>
			</div>
		</div>
	);
};

export default FiatDepositTableItem;
