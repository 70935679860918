import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { DisputeTub } from 'components/Dispute/DisputeTubs/DisputeTubs';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const DisputeTubPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.MANAGE_DISPUTES}>
			<Dashboard title="Details">
				<DisputeTub />
			</Dashboard>
		</PermissionGate>
	);
};
export default DisputeTubPage;
