/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IHotColdTransactionsRequestPayload,
	IHotColdTransactionsResponsePayload,
	IHotWalletAddressRequestPayload,
	IHotWalletAddressResponsePayload,
	IWalletManagementStore,
	IWalletsRequestPayload,
	IWalletsResponsePayload,
	IWithdrawToColdWalletRequestPayload,
	IWithdrawToColdWalletResponsePayload,
	IBotLiquidityDataResponse,
	IWalletMangmentRequestPayload,
	IResetColdBalancePayload,
	IBotLiquidityUpdateResponse,
	IBotLiqudityUpdatePayload,
	IExternalBalanceDataResponse,
	IExternalBalanceDataUpdatePayload,
	IExternalBalanceRequestPayload,
	IExternalBalanceDataUpdateResponse,
	ILiquiditySettingsRequestPayload,
	ILiquiditySettingsResponse,
	ILiquiditySetitngsUppdatePayload,
	ILiquiditySetitngsUppdateResponse,
	IWalletsListData,
	IDeleteExternalWalletPayload,
	IProviders,
	ICoinListWithChains,
	IAddExternalWalletPayload,
	IUpdateExternalWalletPayload,
	IExternalWaletsItem,
	IExternalWalletHistory,
	IExternalWalletRequestPayload,
	IExternalWaletsHistoryRequestPayload,
	IFilterParams,
	IPutHotWalletsRequest,
	IBankAccountBalanceItem,
	IBankAccountBalanceUpdatePayload,
} from './types';
// ==========================================:
export const initialState: IWalletManagementStore = {
	wallets: null,
	walletsLoader: false,
	walletsExporting: false,
	hotWalletAddress: null,
	hotWalletAddressLoader: false,
	withdrawToColdWalletLoader: false,
	withdrawToColdWallet: null,
	hotColdTransactions: null,
	hotColdTransactionsLoader: false,
	botLiquidity: null,
	botLiquidityLoader: false,
	resetColdBalanceLoader: false,
	externalBalances: null,
	externalBalancesLoader: false,
	externalBalancesExporting: false,
	liquiditySetting: null,
	liquiditySettingLoader: false,
	walletsListData: null,
	walletsListDataLoader: false,
	providers: null,
	coinListWithChains: null,
	chainsFromCoin: null,
	externalWalletHistory: null,
	externalWalletHistoryLoader: false,
	bankAccountBalances: null,
	bankAccountBalancesLoader: false,
	filterParams: null,
};

// ==========================================:
const walletManagement = createSlice({
	name: '@@walletManagement',
	initialState,
	reducers: {
		getWalletsRequest: (state, { payload }: PayloadAction<IWalletsRequestPayload>) => {
			const walletManagementRequestState = state;
			if (payload.export === 1) {
				walletManagementRequestState.walletsExporting = true;
			} else {
				walletManagementRequestState.walletsLoader = true;
			}
		},
		getWalletsSuccess: (state, action: PayloadAction<IWalletsResponsePayload>) => {
			const { payload } = action;
			const walletsSuccessState = state;
			walletsSuccessState.wallets = payload;
			walletsSuccessState.walletsLoader = false;
		},
		exportWalletsFinally: (state) => {
			const exportWalletsState = state;
			exportWalletsState.walletsExporting = false;
		},
		putHotWalletsRequest: (state, action: PayloadAction<IPutHotWalletsRequest>) => {
			const walletManagementRequestState = state;
			walletManagementRequestState.walletsLoader = true;
		},
		putHotWalletsSuccess: (state) => {
			const walletsSuccessState = state;
			walletsSuccessState.walletsLoader = false;
		},

		geHotWalletAddressRequest: (state, action: PayloadAction<IHotWalletAddressRequestPayload>) => {
			const walletManagementRequestState = state;
			walletManagementRequestState.hotWalletAddressLoader = true;
		},
		getHotWalletAddressSuccess: (
			state,
			action: PayloadAction<IHotWalletAddressResponsePayload>,
		) => {
			const { payload } = action;

			const walletsSuccessState = state;
			walletsSuccessState.hotWalletAddress = payload;
			walletsSuccessState.hotWalletAddressLoader = false;
		},

		withdrawToColdWalletRequest: (
			state,
			action: PayloadAction<IWithdrawToColdWalletRequestPayload>,
		) => {
			const walletManagementRequestState = state;
			walletManagementRequestState.withdrawToColdWalletLoader = true;
		},
		withdrawToColdWalletSuccess: (
			state,
			action: PayloadAction<IWithdrawToColdWalletResponsePayload>,
		) => {
			const { payload } = action;

			const walletsSuccessState = state;
			walletsSuccessState.withdrawToColdWallet = payload;
			walletsSuccessState.withdrawToColdWalletLoader = false;
		},

		geHotColdTransactionsRequest: (
			state,
			{ payload }: PayloadAction<IHotColdTransactionsRequestPayload>,
		) => {
			const walletManagementRequestState = state;
			if (payload.export !== 1) {
				walletManagementRequestState.hotColdTransactionsLoader = true;
			}
		},
		getHotColdTransactionsSuccess: (
			state,
			action: PayloadAction<IHotColdTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const walletManagementSuccessState = state;
			walletManagementSuccessState.hotColdTransactions = payload;
			walletManagementSuccessState.hotColdTransactionsLoader = false;
		},
		setFilterParams: (state, action: PayloadAction<IFilterParams>) => {
			const { payload } = action;
			const tradeState = state;
			tradeState.filterParams = payload;
		},
		getLiquidityRequest: (state, action: PayloadAction<IWalletMangmentRequestPayload>) => {
			const RequestState = state;
			RequestState.botLiquidityLoader = true;
		},
		getLiquiditySuccess: (state, action: PayloadAction<IBotLiquidityDataResponse>) => {
			const { payload } = action;
			const RequestState = state;
			RequestState.botLiquidity = payload;
			RequestState.botLiquidityLoader = false;
		},
		resetColdBalance: (state, action: PayloadAction<IResetColdBalancePayload>) => {
			const resetColdBalanceState = state;
			resetColdBalanceState.resetColdBalanceLoader = true;
		},
		disableResetColdBalanceLoader: (state) => {
			const resetColdBalanceState = state;
			resetColdBalanceState.resetColdBalanceLoader = false;
		},
		liquidityDataUpdateRequest: (state, action: PayloadAction<IBotLiqudityUpdatePayload>) => {
			const resetColdBalanceState = state;
		},
		liquidityDataUpdateSuccess: (
			state,
			{ payload }: PayloadAction<IBotLiquidityUpdateResponse>,
		) => {
			const reseponseState = state;

			if (!reseponseState.botLiquidity) {
				return;
			}
			const result = reseponseState.botLiquidity?.data.map((item) => {
				return item.id === payload.id ? { ...payload } : item;
			});
			reseponseState.botLiquidity.data = result;
		},

		// ====================
		getExternalBalancesRequest: (
			state,
			{ payload }: PayloadAction<IExternalBalanceRequestPayload>,
		) => {
			const responseState = state;
			if (payload.export === 1) {
				responseState.externalBalancesExporting = true;
			} else {
				responseState.externalBalancesLoader = true;
			}
		},
		getExternalBalancesSuccess: (
			state,
			{ payload }: PayloadAction<IExternalBalanceDataResponse>,
		) => {
			const externalBalancesSuccessState = state;
			externalBalancesSuccessState.externalBalances = payload;
			externalBalancesSuccessState.externalBalancesLoader = false;
		},
		exportExternalBalancesFinally: (state) => {
			const exportExternalBalancesState = state;
			exportExternalBalancesState.externalBalancesExporting = false;
		},
		externalBalancesDataUppdate: (
			state,
			{ payload }: PayloadAction<IExternalBalanceDataUpdatePayload>,
		) => {},
		externalBalancesDataUppdateSuccess: (
			state,
			{ payload }: PayloadAction<IExternalBalanceDataUpdateResponse>,
		) => {
			const responseState = state;

			if (!responseState.externalBalances) {
				return;
			}
			const result = responseState.externalBalances.data.map((item) => {
				if (item.id === payload.id) {
					return {
						id: payload.id,
						asset_id: payload.asset_id,
						platform_name: payload.platform_name,
						balance: payload.balance,
						min_balance_alert: payload.min_balance_alert,
						max_balance_alert: payload.max_balance_alert,
						asset: item.asset,
					};
				}

				return item;
			});

			responseState.externalBalances.data = result;
		},

		// ========================
		getLiquiditySettingsRequest: (
			state,
			{ payload }: PayloadAction<ILiquiditySettingsRequestPayload>,
		) => {
			const requestState = state;
			requestState.liquiditySettingLoader = true;
		},
		getLiquiditySettingsRequestSuccess: (
			state,
			{ payload }: PayloadAction<ILiquiditySettingsResponse>,
		) => {
			const liquiditySettingsSuccessState = state;
			liquiditySettingsSuccessState.liquiditySetting = payload;
			liquiditySettingsSuccessState.liquiditySettingLoader = false;
		},
		liquiditySettingsUppdate: (
			state,
			{ payload }: PayloadAction<ILiquiditySetitngsUppdatePayload>,
		) => {},
		liquiditySettingsUppdateSuccess: (
			state,
			{ payload }: PayloadAction<ILiquiditySetitngsUppdateResponse>,
		) => {
			const responseState = state;

			if (!responseState.liquiditySetting) {
				return;
			}

			responseState.liquiditySetting = payload;
		},

		// ============================
		getWalletsListRequest: (state, { payload }: PayloadAction<IExternalWalletRequestPayload>) => {
			const requestState = state;
			requestState.walletsListDataLoader = true;
		},
		getWalletsListRequestSuccess: (state, { payload }: PayloadAction<IWalletsListData>) => {
			const responseState = state;

			responseState.walletsListData = payload;
			responseState.walletsListDataLoader = false;
		},
		deleteExternalWalletRequest: (state, action: PayloadAction<IDeleteExternalWalletPayload>) => {},
		deleteExternalWalletSuccess: (state, { payload: id }: PayloadAction<number>) => {
			const externalWalletSuccessState = state;

			if (!externalWalletSuccessState.walletsListData) {
				return;
			}
			externalWalletSuccessState.walletsListData.data =
				externalWalletSuccessState.walletsListData.data.filter((item) => item.id !== id);
		},
		getProvidersRequest: (state) => {},
		getProvidersRequestSuccess: (state, { payload }: PayloadAction<IProviders>) => {
			const responseState = state;

			responseState.providers = payload;
		},
		getCoinsListWithChainsRequest: () => {},
		getCoinsListWithChainsRequestSuccess: (
			state,
			{ payload }: PayloadAction<ICoinListWithChains>,
		) => {
			const responseState = state;

			responseState.coinListWithChains = payload;
		},
		getChainsFromCoin: (state, { payload }: PayloadAction<number>) => {
			const responseState = state;
			if (!responseState.coinListWithChains) {
				return;
			}

			const newChains = responseState.coinListWithChains.find(
				(item) => item.asset_id === payload,
			)?.chains;

			if (!newChains) {
				return;
			}

			responseState.chainsFromCoin = newChains;
		},
		addExternalWalletRequest: (state, { payload }: PayloadAction<IAddExternalWalletPayload>) => {},
		addExternalWalletRequestSuccess: (
			state,
			{ payload }: PayloadAction<IAddExternalWalletPayload>,
		) => {},
		externalWalletStatusUppdate: (
			state,
			{ payload }: PayloadAction<IUpdateExternalWalletPayload>,
		) => {},
		externalWalletStatusUppdateSuccess: (
			state,
			{ payload }: PayloadAction<IExternalWaletsItem>,
		) => {
			const responseState = state;

			if (!responseState.walletsListData) {
				return;
			}

			const result = responseState.walletsListData?.data.map((item) => {
				return item.id === payload.id ? { ...payload } : item;
			});
			responseState.walletsListData.data = result;
		},
		// =========================
		getExternalWalletHistoryRequest: (
			state,
			{ payload }: PayloadAction<IExternalWaletsHistoryRequestPayload>,
		) => {
			const requestState = state;
			if (payload.export !== 1) {
				requestState.externalWalletHistoryLoader = true;
			}
		},
		getExternalWalletHistoryRequestSuccess: (
			state,
			{ payload }: PayloadAction<IExternalWalletHistory>,
		) => {
			const responseState = state;
			responseState.externalWalletHistory = payload;
			responseState.externalWalletHistoryLoader = false;
		},
		getBankAccountBalancesRequest: (state) => {
			const requestState = state;
			requestState.bankAccountBalancesLoader = true;
		},
		getBankAccountBalancesSuccess: (
			state,
			{ payload }: PayloadAction<IBankAccountBalanceItem[]>,
		) => {
			const responseState = state;
			responseState.bankAccountBalances = payload;
			responseState.bankAccountBalancesLoader = false;
		},

		bankAccountBalanceUpdateRequest: (
			state,
			{ payload }: PayloadAction<IBankAccountBalanceUpdatePayload>,
		) => {},
		bankAccountBalanceUpdateSuccess: (
			state,
			{ payload }: PayloadAction<IBankAccountBalanceItem>,
		) => {
			const responseState = state;

			if (!responseState.bankAccountBalances) {
				return;
			}
			const result = responseState.bankAccountBalances.map((item) => {
				if (item.id === payload.id) {
					return {
						...item,
						balance: payload.balance,
					};
				}

				return item;
			});

			responseState.bankAccountBalances = result;
		},

		walletManagementInitState: () => initialState,
	},
});

export default walletManagement.reducer;
export const {
	getWalletsRequest,
	getWalletsSuccess,
	exportWalletsFinally,
	geHotWalletAddressRequest,
	getHotWalletAddressSuccess,
	walletManagementInitState,
	withdrawToColdWalletRequest,
	withdrawToColdWalletSuccess,
	geHotColdTransactionsRequest,
	getHotColdTransactionsSuccess,
	getLiquidityRequest,
	getLiquiditySuccess,
	resetColdBalance,
	disableResetColdBalanceLoader,
	liquidityDataUpdateRequest,
	liquidityDataUpdateSuccess,
	getExternalBalancesRequest,
	getExternalBalancesSuccess,
	exportExternalBalancesFinally,
	externalBalancesDataUppdate,
	externalBalancesDataUppdateSuccess,
	getLiquiditySettingsRequest,
	getLiquiditySettingsRequestSuccess,
	liquiditySettingsUppdate,
	liquiditySettingsUppdateSuccess,
	getWalletsListRequest,
	getWalletsListRequestSuccess,
	deleteExternalWalletRequest,
	deleteExternalWalletSuccess,
	getProvidersRequest,
	getProvidersRequestSuccess,
	getCoinsListWithChainsRequest,
	getCoinsListWithChainsRequestSuccess,
	getChainsFromCoin,
	addExternalWalletRequest,
	externalWalletStatusUppdate,
	externalWalletStatusUppdateSuccess,
	getExternalWalletHistoryRequest,
	getExternalWalletHistoryRequestSuccess,
	setFilterParams,
	putHotWalletsRequest,
	putHotWalletsSuccess,
	getBankAccountBalancesRequest,
	getBankAccountBalancesSuccess,
	bankAccountBalanceUpdateRequest,
	bankAccountBalanceUpdateSuccess,
} = walletManagement.actions;
