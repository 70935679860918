import { FC, useState } from 'react';
import { noExponent } from 'utils/numberFormat';
import { IRepaymentProps } from './types';

const RepaymentTableItem: FC<IRepaymentProps> = ({ item }) => {
	const [id] = useState(item.id);
	const [pair] = useState(item?.pair?.code);
	const [coin] = useState(item.asset.code);
	const [amount] = useState(item.amount);
	const [interestAmount] = useState(item.interest_amount);
	const [created] = useState(item.created_at);
	const date = new Date(created * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+created + offset) * 1000);
	const total = Number(amount) + Number(interestAmount);
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>
			{pair ? (
				<div className="td td--left">
					<p className="td-hidden-name">Pair</p>
					<p style={{ textTransform: 'uppercase' }}>{pair.replace('_', '/')}</p>
				</div>
			) : null}
			<div className="td td--left">
				<p className="td-hidden-name">Coin</p>
				<p style={{ textTransform: 'uppercase' }}>{coin}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Date</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Principal Amount</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Interest</p>
				<p>{noExponent(Number(interestAmount).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Total</p>
				<p>{noExponent(Number(total).toString())}</p>
			</div>
		</div>
	);
};
export default RepaymentTableItem;
