import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeTradeFeesAndLimitsRequest } from 'redux/reducers/fees/reducer';
import { noExponent, numberFormat8Fraction } from 'utils/numberFormat';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IChangeTradeFeesAndLimitsItemProps } from '../types';

const TradeFeesAndLimitsItem: FC<IChangeTradeFeesAndLimitsItemProps> = ({ item }) => {
	const [takerFee, setTakerFee] = useState(String(item.taker_fee));
	const [makerFee, setMakerFee] = useState(String(item.maker_fee));
	const [amountMin, setAmountMin] = useState(String(item.amount_min));
	const [amountMax, setAmountMax] = useState(String(item.amount_max));
	const [isClosed, setIsClosed] = useState(false);
	const dispatch = useDispatch();
	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.SET_FEES);

	const handleTradeFeesAndLimitsSubmit = () => {
		if (makerFee === '' || takerFee === '' || amountMin === '' || amountMax === '') return;
		dispatch(
			changeTradeFeesAndLimitsRequest({
				asset_pair_id: item.id,
				maker_fee: Number(makerFee),
				taker_fee: Number(takerFee),
				amount_min: Number(amountMin),
				amount_max: Number(amountMax),
				vip_amount_max: item.vip_amount_max,
				vip_amount_min: item.vip_amount_min,
				vip_maker_fee: item.vip_maker_fee,
				vip_taker_fee: item.vip_taker_fee,
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<p>{item.code.split('_').join('/').toUpperCase()}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Taker Fee, %</p>
				{isClosed ? <div /> : <p>{takerFee}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={takerFee}
						onChange={(e) => {
							setTakerFee(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Maker Fee, %</p>
				{isClosed ? <div /> : <p>{makerFee}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={makerFee}
						onChange={(e) => {
							setMakerFee(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount Min</p>
				{isClosed ? <div /> : <p>{numberFormat8Fraction(Number(amountMin), 'en-En')}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={noExponent(amountMin)}
						onChange={(e) => {
							setAmountMin(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount Max</p>
				{isClosed ? <div /> : <p>{numberFormat8Fraction(Number(amountMax), 'en-En')}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={amountMax}
						onChange={(e) => {
							setAmountMax(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					<button
						type="button"
						className={`${isClosed ? '' : 'disable'}`}
						onClick={() => {
							setTakerFee(String(item.taker_fee));
							setMakerFee(String(item.maker_fee));
							setAmountMin(String(item.amount_min));
							setAmountMax(String(item.amount_max));
							setIsClosed(!isClosed);
						}}
					>
						<span className="edit-icon icon-cancel-icon" />
					</button>
					{isClosed ? (
						<button
							type="button"
							onClick={() => {
								handleTradeFeesAndLimitsSubmit();
							}}
							className={
								makerFee === '' || takerFee === '' || amountMin === '' || amountMax === ''
									? 'button-not-active'
									: ''
							}
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					) : (
						<button type="submit" disabled={!canEdit} onClick={() => setIsClosed(!isClosed)}>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default TradeFeesAndLimitsItem;
