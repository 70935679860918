/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { InvoicePopup } from './InvoicePopup';
import { IAdminManagementListItemProps } from './types';
import { ActionsButtons } from './ActionButtons';

// ==========================================:
export const WithdrawalItem: FC<IAdminManagementListItemProps> = ({
	data,
	invoiceOpenedId,
	setInvoiceOpenedId,
	onRefetch,
}) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);
	const { checkReadable } = usePermission();

	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">ID</p>
					<p>{data.id}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">User Id</p>
					{checkReadable(permissions.MANAGE_USERS) ? (
						<Link className="td" to={`/user-management/${String(data.user_id)}`}>
							<p className="td-hidden-name">Id</p>
							<p>{data.user_id}</p>
						</Link>
					) : (
						<div className="td">
							<p className="td-hidden-name">Id</p>
							<p>{data.user_id}</p>
						</div>
					)}
				</div>
				<div className="td">
					<p className="td-hidden-name">Currency</p>
					<p>{data.asset?.code.toUpperCase()}</p>
				</div>
				{/* <div className="td">
					<p className="td-hidden-name">Network</p>
					<p>-</p>
				</div> */}
				<div className="td">
					<p className="td-hidden-name">To</p>
					<p>{data.to}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Comment</p>
					<p>{data.message}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Created</p>
					<p>
						{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> &bull; </span>
						{dateOffset.toLocaleTimeString()}
					</p>
				</div>
				<div className="td td--reason">
					<p className="td-hidden-name">Invoice</p>
					<p className="td-hidden-name">Invoice</p>
					<p>
						<button className="link" onClick={() => setInvoiceOpenedId(data.id)}>
							Invoice
						</button>
					</p>
				</div>

				<div className="td">
					<p className="td-hidden-name">Action</p>
					<ActionsButtons id={data.id} onRefetch={onRefetch} />
				</div>
			</div>
			{invoiceOpenedId === data.id &&
				createPortal(
					<InvoicePopup onClose={() => setInvoiceOpenedId(null)} data={data} />,
					document.getElementsByClassName('wrapper')[0],
				)}
		</>
	);
};
