import { FC } from 'react';
import { useSelector } from 'react-redux';
import { get2faData } from 'redux/reducers/settings/selectors';
import Enable2faInfo from 'components/Settings2fa/Enable2faBlock/Enable2faInfo';
import Google2faSteps from 'components/Settings2fa/Google2faSteps';

// ================================================:
const Enable2faBlock: FC = () => {
	const google2faData = useSelector(get2faData);

	return google2faData ? <Google2faSteps data={google2faData} /> : <Enable2faInfo />;
};

export default Enable2faBlock;
