import { TStakingStatus } from 'redux/reducers/staking/types';

export const getStatusInfo = (status: TStakingStatus) => {
	switch (status) {
		case 'stake':
			return {
				name: 'Staked',
				className: 'success',
			};
		case 'unstake':
			return {
				name: 'Unstaked',
				className: 'cancelled',
			};
		case 'receive':
			return {
				name: 'Received',
				className: 'received',
			};
		default:
			return {
				name: '',
				className: '',
			};
	}
};
