/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiDisputeDetails } from 'services/api/dispute/types';
import {
	IDisputeRequestPayload,
	TDisputeResponse,
	IDisputeStore,
	IDetailsActionsPayload,
} from './types';

export const initialState: IDisputeStore = {
	disputeList: null,
	disputeLoading: false,

	detailsItem: null,
	detailsLoading: false,
	detailsActionsLoading: false,
};

const dispute = createSlice({
	name: '@@dispute',
	initialState,
	reducers: {
		getDisputeRequest: (state, action: PayloadAction<IDisputeRequestPayload>) => {
			state.disputeLoading = true;
		},
		getDisputeSuccess: (state, { payload }: PayloadAction<TDisputeResponse>) => {
			state.disputeList = payload;
			state.disputeLoading = false;
		},

		getDetailsRequest: (state, action: PayloadAction<string>) => {
			state.detailsLoading = true;
		},
		getDetailsSuccess: (state, { payload }) => {
			state.detailsItem = payload;
			state.detailsLoading = false;
		},

		acceptDisputeRequest: (state, action: PayloadAction<IDetailsActionsPayload>) => {
			state.detailsActionsLoading = true;
		},
		acceptDisputeSuccess: (state) => {
			state.detailsActionsLoading = false;
		},
		acceptDisputeError: (state) => {
			state.detailsActionsLoading = false;
		},

		rejectDisputeRequest: (state, action: PayloadAction<IDetailsActionsPayload>) => {
			state.detailsActionsLoading = true;
		},
		rejectDisputeSuccess: (state) => {
			state.detailsActionsLoading = false;
		},
		rejectDisputeError: (state) => {
			state.detailsActionsLoading = false;
		},
	},
});

export default dispute.reducer;
export const {
	getDisputeRequest,
	getDisputeSuccess,
	getDetailsRequest,
	getDetailsSuccess,
	acceptDisputeRequest,
	acceptDisputeSuccess,
	acceptDisputeError,
	rejectDisputeRequest,
	rejectDisputeSuccess,
	rejectDisputeError,
} = dispute.actions;
