const TableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>ID</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Admin ID</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Sum in Tron</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Type</p>
					</div>
				</div>
				<div className="td td--status">
					<div className="td-name td-name--status">
						<p>Status</p>
						<span className="arrow-icon">
							<svg
								width="8"
								height="5"
								viewBox="0 0 8 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1 1L4 4L7 1" stroke="#011E26" strokeLinecap="square" />
							</svg>
						</span>
						<div className="status-drop">
							<ul className="status-drop__list status-drop__list--center">
								<li className="status-drop__item">Staked</li>
								<li className="status-drop__item">Received</li>
								<li className="status-drop__item">Unstacked</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="td td--right ">
					<div className="td-name">
						<p>Date</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
