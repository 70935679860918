/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { IMarketBotItem, IUpdateMarketBotResponse, IUpdateMarketBotPayload } from './types';
import {
	getMarketBotListRequest,
	getMarketBotListSuccess,
	getMarketBotListError,
	updateMarketBotRequest,
	updateMarketBotSuccess,
	marketBotInitState,
} from './reducer';

// ===================================================:
function* getMarketBotListWorker() {
	try {
		yield put(showLoading());
		const response: IMarketBotItem[] = yield call(api.marketBot.getMarketBotList);
		yield put(getMarketBotListSuccess(response));
	} catch (error) {
		yield put(getMarketBotListError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* updateMarketBotWorker({ payload }: PayloadAction<IUpdateMarketBotPayload>) {
	const { apiParams, onSuccess, onError } = payload;
	try {
		yield put(showLoading());
		const response: IUpdateMarketBotResponse = yield call(api.marketBot.updateMarketBot, apiParams);
		yield put(updateMarketBotSuccess(response.data));
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		onError?.();
	} finally {
		yield put(hideLoading());
	}
}

// ==================================================:
export function* marketBotSaga() {
	yield takeEvery(getMarketBotListRequest.type, getMarketBotListWorker);
	yield takeEvery(updateMarketBotRequest.type, updateMarketBotWorker);
}
