/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { externalBalancesDataUppdate } from 'redux/reducers/walletManagement/reducer';
import { useDispatch } from 'react-redux';
import { IExternalBalanceDataUpdatePayload } from 'redux/reducers/walletManagement/types';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IItemParams } from './types';

export const KrakenBalanceItem: FC<IItemParams> = ({ data }) => {
	const [isClosed, setIsClosed] = useState(false);
	const [minAlert, setMinAlert] = useState(String(data.min_balance_alert));
	const [maxAlert, setMaxAlert] = useState(String(data.max_balance_alert));

	const dispatch = useDispatch();

	const { checkEditable } = usePermission();

	const handleChangeExchangeFeeSubmit = (value: IExternalBalanceDataUpdatePayload) => {
		if (minAlert === '' || maxAlert === '') return;
		setIsClosed(!isClosed);
		dispatch(externalBalancesDataUppdate({ ...value }));
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{data.asset.code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Balance</p>
				<p style={{ whiteSpace: 'nowrap' }}>{data.balance}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Min alert</p>
				{isClosed ? <div /> : <p>{data.min_balance_alert}</p>}
				<div className={` ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={minAlert}
						onChange={(e) => setMinAlert(e.target.value.replace(/[^\d\\.]/g, ''))}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Max alert</p>
				{isClosed ? <div /> : <p>{data.max_balance_alert}</p>}
				<div className={`${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={maxAlert}
						onChange={(e) => setMaxAlert(e.target.value.replace(/[^\d\\.]/g, ''))}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					<button
						type="submit"
						className={`${isClosed ? '' : 'disable'}`}
						onClick={() => {
							setIsClosed(!isClosed);
						}}
					>
						<span className="edit-icon icon-cancel-icon" />
					</button>
					{isClosed ? (
						<button
							type="button"
							onClick={() => {
								handleChangeExchangeFeeSubmit({
									id: data.id,
									min_balance_alert: Number(minAlert),
									max_balance_alert: Number(maxAlert),
								});
							}}
							className={minAlert === '' || maxAlert === '' ? 'button-not-active' : ''}
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					) : (
						<button
							type="button"
							disabled={!checkEditable(permissions.MANAGE_WALLETS)}
							onClick={() => setIsClosed(!isClosed)}
						>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
