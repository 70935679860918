import { FC } from 'react';
import Popup from 'reactjs-popup';
import { ICheckPopup } from './types';
import dollarSvg from './icons/Dollar.svg';
import euroSvg from './icons/Euro.svg';
import gelSvg from './icons/GEL.svg';

export const CheckPopup: FC<ICheckPopup> = ({ openModal, closeModal, mint, burn, currency }) => {
	const getIcon = () => {
		switch (currency) {
			case 'usd': {
				return dollarSvg;
			}
			case 'eur': {
				return euroSvg;
			}
			case 'gel': {
				return gelSvg;
			}
			default: {
				return null;
			}
		}
	};
	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup">
						<button type="button" className="popup__close" onClick={closeModal}>
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15 3L3 15"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3 3L15 15"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
						<div className="popup-header">
							<div className="popup-header-coin">
								<img src={getIcon() || ''} alt="" />
							</div>
							<p className="popup-header__title popup-header__title--bigger">Check Button</p>
							<p className="popup-header__subtitle">Number of tokens</p>
						</div>
						<div className="popup-body popup-body--type3">
							<div className="token-info-box">
								<div className="token-info">
									<p className="token-info__label">Mint</p>
									<p className="token-info__val">{mint}</p>
								</div>
								<div className="token-info">
									<p className="token-info__label">Burn</p>
									<p className="token-info__val">{burn}</p>
								</div>
							</div>
						</div>
						<div className="popup-submit popup-submit--type2 popup-submit--more-mt">
							<button
								className="button button--check   button--full-width"
								type="button"
								onClick={closeModal}
							>
								Okey
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
