import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChatRequest } from 'redux/reducers/chat/reducer';
import Popup from 'reactjs-popup';
import { getChatListUser } from 'redux/reducers/chat/selectors';
import ChatsUsersMessagesItem from './ChatsUsersMessagesItem';
import { TProps } from '../types';

export const ChatsUsers: FC<TProps> = ({ info }) => {
	const wrapperRef = useRef<null | any>(info);
	const dispatch = useDispatch();
	const chatListUsers = useSelector(getChatListUser);
	const [modalImage, setModalImage] = useState<string | null>(null);
	useEffect(() => {
		wrapperRef?.current?.scroll({
			top: wrapperRef.current.scrollHeight,
			left: 0,
		});
	}, [chatListUsers]);

	return (
		<>
			<div ref={wrapperRef} className="chat-history-box__scroll">
				{chatListUsers?.map((item: any) => {
					return (
						<ChatsUsersMessagesItem
							showImage={setModalImage}
							key={item.id}
							item={item}
							status={chatListUsers[0].from_username}
						/>
					);
				})}
			</div>
			<Popup
				open={!!modalImage}
				onClose={() => setModalImage(null)}
				overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
			>
				<div className="image-modal" onClick={() => setModalImage(null)}>
					<img src={modalImage as string} alt="document" />
				</div>
			</Popup>
		</>
	);
};
