/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getDetailsLoading } from 'redux/reducers/dispute/selectors';
import Loader from 'ui/Loader';
import { getDetailsRequest } from 'redux/reducers/dispute/reducer';
import { Details } from './DisputeTubBox/Details/Details';
import { Chats } from './DisputeTubBox/Chats/Chats';
import { IDisputeTabsRouteParams } from './types';

export const DisputeTub: FC = () => {
	const { disputeId } = useParams<IDisputeTabsRouteParams>();

	const dispatch = useDispatch();
	const detailsLoading = useSelector(getDetailsLoading);

	const [activeMenu, setActiveMenu] = useState('Details');

	useLayoutEffect(() => {
		dispatch(getDetailsRequest(disputeId));
	}, []);

	let className = '';
	if (activeMenu === 'Details') {
		className = 'table--dispute-details';
	} else if (activeMenu === 'Chats') {
		className = 'table--dispute-chats';
	}

	const arrayMenu = [
		{
			id: 1,
			name: 'Details',
		},
		{
			id: 2,
			name: 'Chats',
		},
	];
	const menuTop = () => {
		return arrayMenu.map((e) => {
			return (
				<li
					key={e.id}
					className={`table-panel-tab__item ${activeMenu === e.name ? 'active' : ''}`}
					onClick={() => setActiveMenu(e.name)}
				>
					<span className="table-panel-tab__link">{e.name}</span>
				</li>
			);
		});
	};

	const menuContent = () => {
		switch (activeMenu) {
			case 'Details':
				return <Details />;
				break;
			case 'Chats':
				return <Chats />;
				break;
			default:
				null;
		}

		return null;
	};

	return (
		<div className="disputeTubs">
			<div className="title-block">
				<p className="title">Dispute</p>
			</div>

			{detailsLoading && (
				<div className="list-loader">
					<Loader />
				</div>
			)}

			{!detailsLoading && (
				<div className="content-block content-block--big-mt">
					<div>
						<div className="content-block__inside content-block__inside--panel-only">
							<div className="table-panel">
								<ul className="table-panel-tab">{menuTop()}</ul>
							</div>
						</div>
					</div>
					<div className="content-block__inside content-block__inside--login-his">
						<div className="table-block table-block--ip">
							<div className="table-wrapper">
								<div className={`table ${className}`}>{menuContent()}</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
