import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dashboard from 'layouts/Dashboard';
import Translations from 'components/Translations';
import Sidebar from 'components/Translations/Sidebar';
import { getTranslatesRequest } from 'redux/reducers/translates/reducer';
import { getCurrentLanguage } from 'redux/reducers/translates/selectors';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const TranslationsPage: FC = () => {
	const dispatch = useDispatch();
	const [path, setPath] = useState('');
	const lang = useSelector(getCurrentLanguage);

	useEffect(() => {
		dispatch(getTranslatesRequest({ lang }));
	}, [dispatch, lang]);

	return (
		<PermissionGate permissions={permissions.MANAGE_TRANSLATIONS}>
			<Dashboard title="Translations" beforeContent={<Sidebar path={path} setPath={setPath} />}>
				<Translations path={path} />
			</Dashboard>
		</PermissionGate>
	);
};

export default TranslationsPage;
