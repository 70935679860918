/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getBorrowingMarginIsLoad,
	getBorrowingMarginList,
} from 'redux/reducers/userManagement/selectors';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { getTradesSpotRequest, setFilterParams } from 'redux/reducers/trade/reducer';
import {
	getLoadingTradesSpot,
	getTradesSpotList,
	getFilterParams,
} from 'redux/reducers/trade/selectors';
import { IMarketMakerPageRequestPayload } from 'redux/reducers/trade/types';
import { SpotTradesTableHeader } from './SpotTradesTableHeader';
import { ISpotTradeItem, IMarginProps } from './types';
import SpotTradesTableItem from './SpotTradesTableItem';

export const SpotTrades: FC<IMarginProps> = ({ radioCheck }) => {
	const tradesSpotList = useSelector(getTradesSpotList);
	const tradesSpotIsLoad = useSelector(getLoadingTradesSpot);
	const filterParams = useSelector(getFilterParams);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const paramsMargin = radioCheck;
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params: IMarketMakerPageRequestPayload = {
			current_page: pageNumber || 1,
			start_date: startDate || null,
			end_date: endDate || null,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getTradesSpotRequest(params));
	};
	useEffect(() => {
		const params: IMarketMakerPageRequestPayload = {
			current_page: 1,
			start_date: startDate || null,
			end_date: endDate || null,
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getTradesSpotRequest(params));
	}, [dispatch, radioCheck, startDate, endDate]);

	useEffect(() => {
		return () => {
			dispatch(setFilterParams({ start_date: null, end_date: null }));
		};
	}, [dispatch]);

	return (
		<>
			{tradesSpotIsLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--border-none table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--spot-trades table--with-scroll">
							<SpotTradesTableHeader />
							<div className="table-body">
								{tradesSpotList?.data.map((item: ISpotTradeItem) => (
									<SpotTradesTableItem item={item} key={item.id} />
								))}
								{tradesSpotList?.data.length === 0 ? (
									<p style={{ padding: '20px' }}>Borrowing is empty</p>
								) : null}
							</div>
						</div>
					</div>
					<div style={{ padding: '14px' }}>
						{(tradesSpotList?.last_page ?? 0) > 1 && (
							<Pagination
								pageCount={tradesSpotList?.last_page ?? 0}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
