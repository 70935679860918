import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	getMarginCrossList,
	getMarginIsolatedActionsLoading,
	getMarginIsolatedLoading,
} from 'redux/reducers/tradingPairs/selectors';
import { getMarginIsolatedRequest } from 'redux/reducers/tradingPairs/reducer';
import Loader from 'ui/Loader';

import { CurrenciesMarginCrossTableItem } from './CurrenciesMarginCrossTableItem';
import { CurrenciesMarginCrossTableHeader } from './CurrenciesMarginCrossTableHeader';

export const CurrenciesMarginCross = () => {
	const dispatch = useDispatch();

	const marginIsolatedList = useSelector(getMarginCrossList);
	const marginIsolatedLoading = useSelector(getMarginIsolatedLoading);
	const marginIsolatedActionsLoading = useSelector(getMarginIsolatedActionsLoading);

	useEffect(() => {
		dispatch(getMarginIsolatedRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="trades-marginTrades">
			{marginIsolatedLoading || marginIsolatedActionsLoading ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table currencies-general">
							<CurrenciesMarginCrossTableHeader />
							<div className="table-body">
								{marginIsolatedList?.map((el) => (
									<CurrenciesMarginCrossTableItem {...el} key={el.id} />
								))}
								{marginIsolatedList?.length === 0 ? (
									<p style={{ padding: '20px' }}>Orders history is empty</p>
								) : null}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
