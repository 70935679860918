/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';

export const BalancesTableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Cryptocurrency</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Total balance</p>
					</div>
				</div>
				<div className="td td td--right">
					<div className="td-name">
						<p>Available balance</p>
					</div>
				</div>
			</div>
		</div>
	);
};
