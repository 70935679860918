import { FC, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getTranslates, getCurrentIndex } from 'redux/reducers/translates/selectors';
import { getValueByPath } from 'utils/object';
import TranslatePanelGroup from './TranslatePanelGroup';
import TranslatePanelItem from './TranslatePanelItem';
import { ITranslations } from './types';

const Translations: FC<ITranslations> = ({ path }) => {
	const translates = useSelector(getTranslates);
	const currentIndex = useSelector(getCurrentIndex);

	// const isRootPath = path.split('.').length === 1;

	const [search, setSearch] = useState('');

	const targetPart = translates?.[currentIndex] && getValueByPath(translates?.[currentIndex], path);

	const filteredTargetPart =
		targetPart &&
		Object.keys(targetPart)?.reduce((acc, curr) => {
			if (typeof targetPart[curr] === 'string') {
				return {
					...acc,
					[curr]: targetPart[curr],
				};
			}
			if (typeof targetPart[curr] === 'object') {
				const currSubtree = targetPart[curr];
				const innerTree = Object.keys(currSubtree).reduce((acc2, curr2) => {
					if (typeof currSubtree[curr2] === 'string') {
						return {
							...acc2,
							[curr2]: currSubtree[curr2],
						};
					}
					return acc2;
				}, {});
				return {
					...acc,
					[curr]: innerTree,
				};
			}
			return acc;
		}, {});

	// TODO: Временно убираем ограничение
	// const keys = Object.keys(filteredTargetPart || {}).filter((k) =>
	// 	isRootPath ? typeof filteredTargetPart[k] === 'string' : true,
	// );

	const keys = Object.keys(filteredTargetPart || {});

	const groups = keys.filter((k) => typeof filteredTargetPart[k] === 'object');

	const filteredKeys = useMemo(() => {
		if (!search) return keys;
		return keys.filter((k) => {
			if (groups.includes(k)) {
				const innerValues = Object.values(filteredTargetPart[k] || {});
				return innerValues.some((val) => String(val).toLowerCase().includes(search.toLowerCase()));
			}
			return filteredTargetPart[k].toLowerCase().includes(search.toLowerCase());
		});
	}, [groups, keys, search, filteredTargetPart]);

	return (
		<>
			<div className="title-block">
				<p className="title">{path.split('.').join(' / ')}</p>
			</div>
			<div className="count-label count-label--filter-start">
				<div className="input input--search input--search-translate input--mb-0">
					<label>
						<div className="input-wrapper">
							<input
								className="input-item input-item--right-icon input-item--search "
								type="text"
								placeholder="Search"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
							/>
							<span className="input-icon input-icon--right icon-search-icon" />
						</div>
					</label>
				</div>
			</div>
			<div className="content-block">
				<div className="content-block__inside content-block__inside--type2">
					<div className="translate-box">
						{filteredKeys.map((objKey) => {
							if (groups.includes(objKey)) {
								return (
									<TranslatePanelGroup
										groupName={objKey}
										path={path}
										values={filteredTargetPart[objKey]}
										search={search}
									/>
								);
							}
							return (
								<TranslatePanelItem
									objKey={objKey}
									path={path}
									value={filteredTargetPart[objKey]}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default Translations;
