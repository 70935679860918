import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { Limitations } from 'components/Limitations/Limitations';
// import Limitations from 'components/Limitations';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const LimitationsPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.SET_LIMITS}>
			<Dashboard title="Limitations">
				<Limitations />
			</Dashboard>
		</PermissionGate>
	);
};

export default LimitationsPage;
