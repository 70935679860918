import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ITradingPairsStore, ITradingPairsData, IAssetsData } from './types';

const getTradingPairsState = (state: IStoreState): ITradingPairsStore => state.tradingPairs;

// 📌 Spot

export const getTradingPairs = createSelector(
	[getTradingPairsState],
	(tradingPairs: ITradingPairsStore) => tradingPairs,
);

export const getNotificationsList = createSelector(
	[getTradingPairs],
	(tradingPairs: ITradingPairsStore): ITradingPairsData => tradingPairs.tradingPairs,
);

export const getEditTradingPairsLoading = (state: IStoreState) =>
	state.tradingPairs.editTradingPairsLoader;

// 📌 Margin isolated

export const getMarginCrossList = (state: IStoreState) => state.tradingPairs.marginCrossList;
export const getMarginIsolatedList = (state: IStoreState) => state.tradingPairs.marginIsolatedList;

export const getReversedMarginIsolatedList = createSelector(
	[getMarginIsolatedList],
	(marginIsolatedList) => [...marginIsolatedList].reverse(),
);

export const getMarginIsolatedLoading = (state: IStoreState) =>
	state.tradingPairs.marginIsolatedLoading;

export const getMarginIsolatedActionsLoading = (state: IStoreState) =>
	state.tradingPairs.marginIsolatedActionsLoading;

// 📌 Asset pairs

export const getAssetsList = createSelector(
	[getTradingPairs],
	(assets: ITradingPairsStore): IAssetsData => assets.assets,
);

export const getAddAssetsPairLoading = (state: IStoreState) =>
	state.tradingPairs.addAssetsPairLoader;
