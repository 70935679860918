/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import AdminFormConrol from 'ui/Formik/AdminFormConrol';
import { IAdminInput } from './types';

// ==========================================:
const AdminInput: FC<IAdminInput> = (props) => {
	const { ariaLabel, type, field, placeholder, inputMode, autoFocus } = props;

	return (
		<AdminFormConrol ariaLabel={ariaLabel} {...props}>
			<input
				className="input-item input-item--left-icon input-item--right-icon"
				{...field}
				type={type}
				placeholder={placeholder}
				inputMode={inputMode || null}
				autoFocus={autoFocus}
			/>
		</AdminFormConrol>
	);
};

export default AdminInput;
