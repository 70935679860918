/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import {
	TYPE_TRANSACTIONS_CRYPTO,
	TYPE_TRANSACTIONS_FIAT,
	TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO,
	TYPE_TRANSACTIONS_WITHDRAWALS_FIAT,
} from 'redux/reducers/transactions/constants';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { Fiat } from './Tabs/Fiat';
import { Crypto } from './Tabs/Crypto';
import { IDepositProps } from './types';

export const WithdrawalsCryptoFiat: FC<IDepositProps> = ({ setTypeTransactions }) => {
	const [typeWithdrawals, setTypeWithdrawals] = useState<'withdrawals_crypto' | 'withdrawals_fiat'>(
		'withdrawals_crypto',
	);
	const { checkReadable } = usePermission();

	return (
		<div className="content-block content-block--user-management">
			<div className="content-block content-block--transaction">
				<div className="content-block__inside content-block__inside--deposit-his">
					<div className="deposit-history deposit-history--filter">
						<ul className="deposit-history__list">
							{checkReadable(permissions.MANAGE_CRYPTO_TRANSFERS) && (
								<li
									className={`deposit-history__item ${
										typeWithdrawals === TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO ? 'active' : ''
									}`}
								>
									<a
										className="deposit-history__link"
										onClick={() => {
											setTypeWithdrawals(TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO);
											setTypeTransactions(TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO);
										}}
									>
										Crypto
									</a>
								</li>
							)}
							{checkReadable(permissions.MANAGE_FIAT_BANK_TRANSFERS) && (
								<li
									className={`deposit-history__item ${
										typeWithdrawals === TYPE_TRANSACTIONS_WITHDRAWALS_FIAT ? 'active' : ''
									}`}
								>
									<a
										className="deposit-history__link"
										onClick={() => {
											setTypeWithdrawals(TYPE_TRANSACTIONS_WITHDRAWALS_FIAT);
											setTypeTransactions(TYPE_TRANSACTIONS_WITHDRAWALS_FIAT);
										}}
									>
										Fiat
									</a>
								</li>
							)}
						</ul>
					</div>
				</div>
				<div className="content-block__inside content-block__inside--login-his">
					{typeWithdrawals === TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO
						? checkReadable(permissions.MANAGE_CRYPTO_TRANSFERS) && <Crypto />
						: checkReadable(permissions.MANAGE_FIAT_BANK_TRANSFERS) && <Fiat />}
				</div>
			</div>
		</div>
	);
};
