import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ITokenManagementStore } from './types';

// ==========================================:
const getTokensManagementState = (state: IStoreState): ITokenManagementStore =>
	state.tokenManagement;
export const getManagementStore = createSelector(
	[getTokensManagementState],
	(tokenManagement: ITokenManagementStore) => tokenManagement.tokenManagement,
);
export const getManagementStoreLoading = createSelector(
	[getTokensManagementState],
	(tokenManagement: ITokenManagementStore) => tokenManagement.tokenLoader,
);
export const getManagementStoreExportLoading = createSelector(
	[getTokensManagementState],
	(tokenManagement: ITokenManagementStore) => tokenManagement.tokenExportLoader,
);
export const getManagementStoreUpdate = createSelector(
	[getTokensManagementState],
	(tokenManagement: ITokenManagementStore) => tokenManagement.tokenUpdate,
);
export const getManagementHistory = createSelector(
	[getTokensManagementState],
	(tokenManagement: ITokenManagementStore) => tokenManagement.tokenManagementHistory,
);
