import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EAccountLevel } from 'redux/reducers/fees/types';
import { getVolumeDependsFeeSettingsRequest } from 'redux/reducers/fees/reducer';
import { getFees, getVolumeDependsFeeSettingsList } from 'redux/reducers/fees/selectors';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import Loader from 'ui/Loader';
import VolumeDependsFeeSettingsItem from './VolumeDependsFeeSettingsItem';
import VipVolumeDependsFeeSettingsItem from './VipVolumeDependsFeeSettingsItem';

interface IVolumeDependsFeeSettings {
	accountLevel: EAccountLevel;
}

const VolumeDependsFeeSettings: FC<IVolumeDependsFeeSettings> = ({ accountLevel }) => {
	const feesList = useSelector(getFees);
	const volumeDependsFeeSettingsList = useSelector(getVolumeDependsFeeSettingsList);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getVolumeDependsFeeSettingsRequest());
	}, [dispatch]);

	const ColumnNameByLevel = useMemo(() => {
		return (
			<>
				{accountLevel === EAccountLevel.VIP && EAccountLevel.VIP.toUpperCase()}
				{accountLevel === EAccountLevel.REGULAR && capitalizeFirstLetter(EAccountLevel.REGULAR)}
			</>
		);
	}, [accountLevel]);
	return (
		<div className="VolumeDependsFeeSettings">
			<div className="content-block content-block--user-management">
				<div className="content-block__inside content-block__inside--bt content-block__inside--login-his">
					<div className="table-block">
						<div className="table-wrapper">
							<div className="table table--fee-trade">
								<div className="table-header">
									<div className="tr">
										<div className="td">
											<div className="td-name">
												<p>Level</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>{ColumnNameByLevel} Min amount $</p>
											</div>
										</div>
										<div className="td td--left">
											<div className="td-name">
												<p>{ColumnNameByLevel} Max amount $</p>
											</div>
										</div>
										<div className="td td--left">
											<div className="td-name">
												<p>{ColumnNameByLevel} Taker Fee, %</p>
											</div>
										</div>
										<div className="td td--left">
											<div className="td-name">
												<p>{ColumnNameByLevel} Maker Fee, %</p>
											</div>
										</div>
										<div className="td td--left">
											<div className="td-name">
												<p>{ColumnNameByLevel} Total Taker Fee, %</p>
											</div>
										</div>
										<div className="td td--left">
											<div className="td-name">
												<p>{ColumnNameByLevel} Total Maker Fee, %</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name td-name--action">
												<p>Edit</p>
											</div>
										</div>
									</div>
								</div>
								<div className="table-body">
									{feesList?.changeVolumeDependsFeeSettingsLoader ? (
										<div className="list-loader">
											<Loader />
										</div>
									) : (
										<>
											{accountLevel === EAccountLevel.REGULAR &&
												volumeDependsFeeSettingsList?.map((item) => (
													<VolumeDependsFeeSettingsItem item={item} key={item.id} />
												))}
											{accountLevel === EAccountLevel.VIP &&
												volumeDependsFeeSettingsList?.map((item) => (
													<VipVolumeDependsFeeSettingsItem item={item} key={item.id} />
												))}
											<div />
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VolumeDependsFeeSettings;
