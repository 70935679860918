import {
	IAssetsResponsePayload,
	ITradingPairsResponsePayload,
} from 'redux/reducers/tradingPairs/types';
import { endpoint } from 'services/endpoint';

import { http } from 'services/http';
import { ITradingPairsApi } from './types';

export const tradingPairs: ITradingPairsApi = {
	getTradingPairs: (params) =>
		http
			.get<ITradingPairsResponsePayload>(endpoint.tradingPairs.GET_ASSETS_PAIRS, { params })
			.then((response) => response.data),
	editTradingPairs: (payload) =>
		http.put(endpoint.tradingPairs.UPDATE_ASSETS_PAIRS, payload).then((response) => response.data),

	getMarginIsolated: () =>
		http.get(endpoint.tradingPairs.GET_MARGIN_ISOLATED).then(({ data }) => data),
	toggleActiveIsolate: ({ pairId }) =>
		http.post(endpoint.tradingPairs.TOGGLE_ACTIVE_ISOLATE(pairId)).then(({ data }) => data),
	toggleActiveCross: ({ pairId }) =>
		http.post(endpoint.tradingPairs.TOGGLE_ACTIVE_CROSS(pairId)).then(({ data }) => data),
	changeShoulderIsolate: (params) =>
		http.post(endpoint.tradingPairs.CHANGE_SHOULDER_ISOLATE, params).then(({ data }) => data),
	changeShoulderCross: (params) =>
		http.post(endpoint.tradingPairs.CHANGE_SHOULDER_CROSS, params).then(({ data }) => data),

	getAssets: () =>
		http
			.get<IAssetsResponsePayload>(endpoint.tradingPairs.GET_ASSETS)
			.then((response) => response.data),
	addAssetsPair: (payload) =>
		http.post(endpoint.tradingPairs.ADD_ASSETS_PAIR, payload).then((response) => response.data),
};
