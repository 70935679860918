import { FC, useState } from 'react';
import { noExponent, capitalizeFirstLetter } from 'utils/numberFormat';
import { allStatus } from 'redux/reducers/transactions/constants';
import { Link } from 'react-router-dom';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { ISpotOrdersProps } from './types';

const SpotOrdersTableItem: FC<ISpotOrdersProps> = ({ item }) => {
	const [created] = useState(item?.created_at);
	const [updatedAt] = useState(item?.updated_at);
	const [pair] = useState(item?.pair);
	const [userId] = useState(item?.user_id);
	const [type] = useState(item?.type);
	const [filling] = useState(item?.filling);
	const [price] = useState(item?.price);
	const [quantity] = useState(item?.quantity);
	const [total] = useState(item?.total);
	const [id] = useState(item?.id);

	const { checkReadable } = usePermission();

	const convertDate = (data: number | any) => {
		const date = new Date(data * 1000);
		const offset = (-date.getTimezoneOffset() * 60) / 1000;
		const dateOffset = new Date((+data + offset) * 1000);
		return dateOffset;
	};

	return (
		<div className="tr">
			<div className="td td--left">
				{checkReadable(permissions.MANAGE_USERS) ? (
					<Link to={`/user-management/${String(userId)}`}>
						<p className="td-hidden-name">User ID</p>
						<p className="grey-text">{userId}</p>
					</Link>
				) : (
					<div className="td">
						<p className="td-hidden-name">User ID</p>
						<p>{userId}</p>
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Order ID</p>
				<p>{id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<p style={{ textTransform: 'uppercase' }}>{pair.replace('_', '/')}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p
					style={{
						color:
							capitalizeFirstLetter(type).toLowerCase().indexOf('buy') === -1
								? '#F44336'
								: '#03BC70',
					}}
				>
					{capitalizeFirstLetter(type)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(Number(quantity).toString())}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Filled</p>
				<p>{noExponent(Number(filling).toString())}%</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total</p>
				<p>{noExponent(Number(parseFloat(total).toFixed(10)).toString())}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Price</p>
				<p>{noExponent(Number(price).toString())}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={`status ${allStatus?.[item.status]?.statusClass ?? ''}`}>
						{allStatus?.[item.status]?.statusText}
					</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Created</p>
				<p>
					{convertDate(created).toLocaleDateString('en-GB')},{' '}
					{convertDate(created).toLocaleTimeString().slice(0, -3)}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Updated</p>
				<p>
					{convertDate(updatedAt).toLocaleDateString('en-GB')},{' '}
					{convertDate(updatedAt).toLocaleTimeString().slice(0, -3)}
				</p>
			</div>
		</div>
	);
};
export default SpotOrdersTableItem;
