import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IBankAccountStore, IBankAccountResponse } from './types';

// ====================================================:
const getBankAccountState = (state: IStoreState): IBankAccountStore => state.bankAccount;

// ====================================================:
export const getBankAccounts = createSelector(
	[getBankAccountState],
	(store: IBankAccountStore): IBankAccountResponse | null => store.bankAccounts,
);
