/* eslint-disable @typescript-eslint/naming-convention */
import * as yup from 'yup';

// ==========================================:
export const email = yup
	.string()
	.trim()
	.required('Please, enter your email!')
	.email('Invalid email format');

// ==========================================:
export const password = yup
	.string()
	.required('Please, enter your password!')
	.matches(
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
		'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
	);

// ==========================================:
export const confirmPassword = yup
	.string()
	.required('Please, enter your confirm password!')
	.matches(
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
		'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
	)
	.oneOf([yup.ref('password'), null], 'Passwords must match');

// ==========================================:
export const acceptTerms = yup
	.bool()
	.oneOf([true], 'You must agree to the terms & conditions and privacy policy!');

// ==========================================:
export const temporaryCode = yup.string().required('Please, enter your temporary code!');

// ==========================================:
export const cookiePolicy = yup.bool().oneOf([true], 'You must agree to  cookie policy!');

// ==========================================:
export const recaptcha = yup.string().required('Please verify that you are a human!');

// ==========================================:
export const swap = yup.string().required('Insufficient funds on the wallet');

// ==========================================:
export const fullName = yup
	.string()
	.trim()
	.matches(
		/^[a-zA-Z]+( [a-zA-Z]+)+$/,
		'Full name must be at least two words, do not contain numbers and special symbol!',
	)
	.max(60, 'Full name max length must be not more 60 characters!')
	.required('Please, enter your full name!');

// ==========================================:
export const beneficiary_name = yup.string().required('Beneficiary name is required');

// ==========================================:
export const beneficiary_bank = yup.string().required('Beneficiary bank name is required');

// ==========================================:
export const asset_id = yup.string().required('Currency is required');

// ==========================================:
export const iban = yup.string().required('IBAN field is required');

// ==========================================:
export const swift = yup.string().required('Swift field is required');

// ==========================================:
export const beneficiary_bank_address = yup.string().required('Beneficiary bank address required');

// ==========================================:
export const correspondent_bank = yup.string().required('Correspondent bank name required');

// ==========================================:
export const provider_name = yup.string().required('Liquidity Provider required');

// ==========================================:
export const network = yup.string().required('Chain required');

// ==========================================:
export const address = yup.string().required('Wallet Address required');

// ===========================================:
export const currency_pair_first_id = yup.string().required('First pair required');

// ===========================================:
export const currency_pair_second_id = yup.string().required('Second pair required');

// ===========================================:
export const order_type = yup.string().required('Order type required');

// ===========================================:
export const timing_frequency = yup
	.string()
	.matches(/^[0-9]+$/, 'This field should only contain numbers')
	.test({
		name: 'greaterThanZero',
		exclusive: true,
		message: 'Value must be greater than 0',
		test: (value) => {
			if (typeof value === 'string') {
				return parseInt(value, 10) > 0;
			}
			return false;
		},
	})
	.required('Timing frequency required');

// ===========================================:
export const sizing_type = yup.string().required('Sizing type required');

// ===========================================:
export const sizing_from = yup
	.string()
	.matches(/^[0-9]*\.?[0-9]+$/, 'This field should only contain numbers')
	.test({
		name: 'greaterThanZero',
		exclusive: true,
		message: 'Value must be greater than 0',
		test: (value) => {
			if (typeof value === 'string') {
				return parseFloat(value) > 0;
			}
			return false;
		},
	})
	.required('Sizing from required');

// ===========================================:
export const sizing_to = yup
	.string()
	.matches(/^[0-9]*\.?[0-9]+$/, 'This field should only contain numbers')
	.test({
		name: 'greaterThanZero',
		exclusive: true,
		message: 'Value must be greater than 0',
		test: (value) => {
			if (typeof value === 'string') {
				return parseFloat(value) > 0;
			}
			return false;
		},
	})
	.test({
		name: 'greaterThanSizingFrom',
		exclusive: true,
		message: 'Sizing to must be greater than Sizing from',
		test(value) {
			const sizingFrom = this.resolve(yup.ref('sizing_from'));
			if (typeof value === 'string' && typeof sizingFrom === 'string' && sizingFrom !== undefined) {
				return parseFloat(value) > parseFloat(sizingFrom);
			}
			return false;
		},
	})
	.required('Sizing to required');

// ===========================================:
export const pricing_type = yup.string().required('Pricing type required');

// ===========================================:
export const price = yup
	.string()
	.matches(/^[0-9]*\.?[0-9]+$/, 'This field should only contain numbers')
	.test({
		name: 'greaterThanZero',
		exclusive: true,
		message: 'Value must be greater than 0',
		test: (value) => {
			if (typeof value === 'string') {
				return parseInt(value, 10) > 0;
			}
			return false;
		},
	})
	.required('Price required');

// ===========================================:
export const is_active = yup.string().required('Status required');

export const getFiatFeeSchema = (min?: number, max?: number) => {
	const minValue = min || 0;
	const maxValue = max || 999999999;
	return yup.object().shape({
		min_amount: yup
			.number()
			.required('Required')
			.typeError('Must be a number')
			.min(minValue, `Min ${minValue}`),
		max_amount: yup
			.number()
			.required('Required')
			.typeError('Must be a number')
			.max(maxValue, `Max ${maxValue}`),
		deposit_fee: yup.number().typeError('Must be a number'),
		withdraw_fee: yup.number().typeError('Must be a number'),
		vip_deposit_fee: yup.number().typeError('Must be a number'),
		vip_withdraw_fee: yup.number().typeError('Must be a number'),
		min_deposit_fee: yup.number().typeError('Must be a number'),
		min_withdraw_fee: yup.number().typeError('Must be a number'),
		min_vip_deposit_fee: yup.number().typeError('Must be a number'),
		min_vip_withdraw_fee: yup.number().typeError('Must be a number'),
	});
};
