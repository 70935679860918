import { FC, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import { ExportIcon, ClockIcon } from 'assets/img/icons';
import { useDateTimePicker } from 'services/hooks/useDateTimePicker';
import { ITradeFilters } from './types';

export const TradeFilter: FC<ITradeFilters> = ({ exportTitle, onDateSelect, onExport }) => {
	const { afterToday } = DateRangePicker;
	const { dateRangeValue, timeRangeValue, changeDateRangeHandler, changeTimeRangeHandler } =
		useDateTimePicker({ onDateSelect });

	const now = new Date();
	const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

	return (
		<>
			<DateRangePicker
				format="dd/MM/yyyy"
				size="lg"
				block
				placement="bottomEnd"
				style={{ width: '308px' }}
				disabledDate={afterToday?.()}
				value={dateRangeValue}
				onChange={changeDateRangeHandler}
				defaultCalendarValue={[prevMonth, now]}
				ranges={[]}
			/>
			<DateRangePicker
				className="time-datepicker"
				format="HH:mm"
				size="lg"
				block
				placement="bottomEnd"
				style={{ width: '200px' }}
				value={timeRangeValue}
				onChange={changeTimeRangeHandler}
				defaultCalendarValue={[prevMonth, now]}
				ranges={[]}
				caretAs={ClockIcon}
			/>
			<button type="button" className="transaction-export-btn" onClick={onExport}>
				{exportTitle}
				<span className="transaction-export-btn__icon">
					<ExportIcon />
				</span>
			</button>
		</>
		// <div className="count-label count-label--user-management count-label--left transactions-data-picker">
		// 	<DateRangePicker
		// 		format="dd/MM/yyyy"
		// 		size="lg"
		// 		block
		// 		placement="bottomEnd"
		// 		style={{ width: '308px' }}
		// 		disabledDate={afterToday?.()}
		// 		value={dateRangeValue}
		// 		onChange={changeDateRangeHandler}
		// 		defaultCalendarValue={[prevMonth, now]}
		// 		ranges={[]}
		// 	/>

		// 	<button type="button" className="transaction-export-btn" onClick={onExport}>
		// 		{exportTitle}
		// 		<span className="transaction-export-btn__icon">
		// 			<ExportIcon />
		// 		</span>
		// 	</button>
		// </div>
	);
};
