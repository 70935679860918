import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getReferralLoading } from 'redux/reducers/referral/selectors';
import Loader from 'ui/Loader';
import ReferralItem from '../ReferralItem';
import { IAdminManagementListProps } from './types';

// ==========================================:
const ReferralTable: FC<IAdminManagementListProps> = ({ users }) => {
	const listLoader = useSelector(getReferralLoading);

	return (
		<div className="table-block table-block--nomt">
			<div className="table-wrapper">
				<div className="table table--referrals">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>User ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Full name</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Email</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p> Referral fee %</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Referral quantity</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p> Total referrals (USDb)</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name">
									<p> Total Fee in USDb</p>
								</div>
							</div>
						</div>
					</div>

					{!users?.length && !listLoader && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">No referral found...</p>
						</div>
					)}
					{listLoader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{users?.map((item, index) => (
								<ReferralItem
									key={item.id}
									data={item}
									index={index + 1}
									isAdminManagementPermissionUpdateRules
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ReferralTable;
