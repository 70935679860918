import { FC, useState } from 'react';
import { noExponent } from 'utils/numberFormat';
import { CopyIcon } from 'assets/img/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'utils/notificationContainer';

import { ISpotBalancesProps } from './types';

const SpotBalancesItem: FC<ISpotBalancesProps> = ({ item }) => {
	const [cryptocurrency] = useState(item.asset.code);
	const [totalBalance] = useState(item.total);
	const [availableBalance] = useState(item.balance);
	const { networks } = item;

	const handleAddressCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
	};

	return (
		<>
			<div className="tr">
				<div className="td td--left">
					<p className="td-hidden-name">Cryptocurrency</p>
					<p style={{ textTransform: 'uppercase' }}>{cryptocurrency}</p>
				</div>
				<div className="td td--hide-mob ">
					<p className="td-hidden-name">Public key</p>
					<p />
				</div>
				<div className="td td--center">
					<p className="td-hidden-name">Total balance</p>
					<p>{noExponent(Number(totalBalance).toString())}</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Available balance</p>
					<p>{noExponent(Number(availableBalance).toString())}</p>
				</div>
			</div>
			{networks.map((ntw) => {
				const {
					network_id: networkId,
					network_name: networkName,
					top_up_address: topUpAddress,
					contract_address_url: contractAddressUrl,
				} = ntw;
				return (
					<div className="tr" key={networkId}>
						<div className="td">
							<p className="td-hidden-name">Network</p>
							<p className="balance-network">{networkName}</p>
						</div>
						<div className="td ">
							<p className="td-hidden-name">Public key</p>
							{topUpAddress && (
								<div className="copy-button">
									<span className="copy-button__text">{topUpAddress}</span>

									<CopyToClipboard text={topUpAddress} onCopy={handleAddressCopy}>
										<button type="button" className="copy-button__item">
											<CopyIcon />
										</button>
									</CopyToClipboard>
								</div>
							)}
						</div>
					</div>
				);
			})}
		</>
	);
};

export default SpotBalancesItem;
