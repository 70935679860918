import { FC } from 'react';
import Nav from 'layouts-elements/Nav';
import { useDispatch, useSelector } from 'react-redux';
import { getMobileMenuStatus } from 'redux/reducers/app/selectors';
import { mobileMenu } from 'redux/reducers/app/reducer';
// ==========================================:
const Sidebar: FC = () => {
	const mobileMenuStatus = useSelector(getMobileMenuStatus);
	const dispatch = useDispatch();

	const handleMobileMenu = (): void => {
		dispatch(mobileMenu({ open: !mobileMenuStatus }));
	};

	return (
		<div className="sidebar">
			<button className="sidebar__action" onClick={handleMobileMenu} type="button">
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4 14L10 14L10 20"
						stroke="#777E91"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M10 14L3 21"
						stroke="#777E91"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M20 10L14 10L14 4"
						stroke="#777E91"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M21 3L14 10"
						stroke="#777E91"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>

			<Nav />
		</div>
	);
};

export default Sidebar;
