import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { setFilterParams, getTradesSpotRequest } from 'redux/reducers/trade/reducer';
import { getFilterParams } from 'redux/reducers/trade/selectors';
import { IMarketMakerPageRequestPayload } from 'redux/reducers/trade/types';
import { TradeFilter } from '../Filter';

export const SpotTradesFilter = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const filterParams = useSelector(getFilterParams);
	const { start_date: startDate, end_date: endDate } = filterParams || {};
	const handleDataSelect = (start_date: string | null, end_date: string | null) => {
		dispatch(setFilterParams({ start_date, end_date }));
	};

	const handleExport = () => {
		const params: IMarketMakerPageRequestPayload = {
			current_page: page || 1,
			export: 1,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};
		dispatch(getTradesSpotRequest(params));
	};
	return (
		<TradeFilter
			exportTitle="Export spot trades"
			onDateSelect={handleDataSelect}
			onExport={handleExport}
		/>
	);
};
