import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IStakingApi } from './types';
/* eslint-disable */
// ==========================================:
export const staking: IStakingApi = {
	getStakingInfo: async () => {
		return http.get(endpoint.staking.STAKING_INFO).then((response) => response.data);
	},
	getStakeTransactions: async (params) => {
		return http.get(endpoint.staking.STAKE_TRANSACTIONS, { params }).then((response) => response.data);
	},
	stakeTron: async (payload) => {
		return http.post(endpoint.staking.STAKE_TRON, payload).then((response) => response.data);
	},
	unstakeTron: async (payload) => {
		return http.post(endpoint.staking.UNSTAKE_TRON, payload).then((response) => response.data);
	},
};
