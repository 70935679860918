/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useSelect } from 'services/hooks/useSelect';
import { getExternalMarkerAssets } from 'redux/reducers/externalMaker/selectors';
import { ISelectCurrency } from './types';

const SelectCyrrencyPair: FC<ISelectCurrency> = (props) => {
	const {
		field: { name, value },
		form: { setFieldValue },
		className = '',
	} = props;
	const assets = useSelector(getExternalMarkerAssets);

	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const selectCurrencyAsset = assets?.find((item) => item.id === value);

	const currencyAssetText = `${
		selectCurrencyAsset?.name
	} ${selectCurrencyAsset?.code.toUpperCase()}`;

	const handleSelect = (id: number) => {
		setFieldValue(name, id);
		setOpen(false);
	};

	const handleSelectClick = () => {
		toggleOpen();
	};

	return (
		<div
			className={`select select--regular2 select--height-48 ${open ? 'active' : ''} ${className}`}
		>
			<button
				type="button"
				className={`select__current ${selectCurrencyAsset ? '' : 'select__current--unselect'}`}
				ref={triggerRef}
				onClick={handleSelectClick}
			>
				{selectCurrencyAsset && (
					<span className="select-coin">
						<img src={selectCurrencyAsset?.img_path || ''} alt="Currency Asset" />
					</span>
				)}
				<span className="select__current-text">
					{selectCurrencyAsset ? currencyAssetText : 'Select'}
				</span>
				<span className="select__current-arrow">
					<svg
						className="stroke"
						width="8"
						height="5"
						viewBox="0 0 8 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
					</svg>
				</span>
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select-drop__item">
						<ul>
							{assets?.map((item, index) => (
								<li key={index}>
									<button type="button" onClick={() => handleSelect(item.id)}>
										{item.code.toUpperCase()}
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectCyrrencyPair;
