/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IFiatFeesStore,
	IGetPercentFiatFeesParams,
	IAddPercentFiatFeesParams,
	IAddPercentFiatFeesResponse,
	IUpdatePercentFiatFeesPayload,
	IUpdateRangesPercentFiatFeesPayload,
	TRangePercent,
	IRangePercentItem,
	IDeletePercentFiatFeesPayload,
} from './types';

// ==========================================:
export const initialState: IFiatFeesStore = {
	rangePercentLoading: false,
	rangePercent: null,
	fiatList: null,
	channelList: null,
};

// ==========================================:
const fees = createSlice({
	name: '@@fiatFees',
	initialState,
	reducers: {
		getPercentFiatFeeRequest: (state, action: PayloadAction<IGetPercentFiatFeesParams>) => {
			const rangePercentState = state;
			rangePercentState.rangePercentLoading = true;
		},
		getPercentFiatFeeSuccess: (state, { payload }: PayloadAction<TRangePercent>) => {
			const rangePercentState = state;
			rangePercentState.rangePercentLoading = false;
			rangePercentState.rangePercent = payload;
		},
		getPercentFiatFeeFailed: (state) => {
			const rangePercentState = state;
			rangePercentState.rangePercentLoading = false;
		},
		getFiatListRequest: (state) => {},
		getFiatListSuccess: (state, { payload }: PayloadAction<string[]>) => {
			const rangePercentState = state;
			rangePercentState.fiatList = payload;
		},
		getChannelListRequest: (state) => {},
		getChannelListSuccess: (state, { payload }: PayloadAction<string[]>) => {
			const rangePercentState = state;
			rangePercentState.channelList = payload;
		},
		addPercentFiatFeeRequest: (state, action: PayloadAction<IAddPercentFiatFeesParams>) => {},
		addPercentFiatFeeSuccess: (state, { payload }: PayloadAction<IAddPercentFiatFeesResponse>) => {
			const rangePercentState = state;
			rangePercentState.rangePercent = payload.data;
		},
		updatePercentFiatFeeRequest: (
			state,
			action: PayloadAction<IUpdatePercentFiatFeesPayload>,
		) => {},
		updatePercentFiatFeeSuccess: (state, { payload }: PayloadAction<IRangePercentItem>) => {
			// const rangePercentState = state;
			// if (!rangePercentState.rangePercent) return;
			// rangePercentState.rangePercent = rangePercentState.rangePercent?.map((el) =>
			// 	el.id === payload.id ? { ...el, ...payload } : el,
			// );
		},
		updateRangePercentRequest: (
			state,
			action: PayloadAction<IUpdateRangesPercentFiatFeesPayload>,
		) => {},
		updateRangePercentSuccess: (state, { payload }: PayloadAction<IRangePercentItem>) => {
			// const rangePercentState = state;
			// if (!rangePercentState.rangePercent) return;
			// rangePercentState.rangePercent = rangePercentState.rangePercent?.map((el) =>
			// 	el.id === payload.id ? { ...el, ...payload } : el,
			// );
		},
		deletePercentFiatFeeRequest: (
			state,
			action: PayloadAction<IDeletePercentFiatFeesPayload>,
		) => {},
		deletePercentFiatFeeSuccess: (state, { payload: id }: PayloadAction<number>) => {
			const rangePercentState = state;
			if (!rangePercentState.rangePercent) return;
			rangePercentState.rangePercent = rangePercentState.rangePercent?.filter(
				(item) => item.id !== id,
			);
		},
		exportPercentFiatFeesRequest: (state) => {},
		feesInitState: () => initialState,
	},
});

export default fees.reducer;
export const {
	getPercentFiatFeeRequest,
	getPercentFiatFeeSuccess,
	getPercentFiatFeeFailed,
	addPercentFiatFeeRequest,
	addPercentFiatFeeSuccess,
	updatePercentFiatFeeRequest,
	updatePercentFiatFeeSuccess,
	updateRangePercentRequest,
	updateRangePercentSuccess,
	deletePercentFiatFeeRequest,
	deletePercentFiatFeeSuccess,
	exportPercentFiatFeesRequest,
	getFiatListRequest,
	getFiatListSuccess,
	getChannelListRequest,
	getChannelListSuccess,
	feesInitState,
} = fees.actions;
