/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';

export const MarginTradesTableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Date</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>User id</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Pair</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Side</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Price</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Executed</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Fee</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Total</p>
					</div>
				</div>
			</div>
		</div>
	);
};
