import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getBankAccountBalances,
	getBankAccountBalancesLoader,
} from 'redux/reducers/walletManagement/selectors';
import { getBankAccountBalancesRequest } from 'redux/reducers/walletManagement/reducer';
import Loader from 'ui/Loader';
import { BankBalancesItem } from './BankBalancesItem';

export const BankBalance: FC = () => {
	const loader = useSelector(getBankAccountBalancesLoader);
	const bankBalances = useSelector(getBankAccountBalances);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getBankAccountBalancesRequest());
	}, [dispatch]);

	return (
		<div className="content-block content-block--mt-0">
			<div className="content-block__inside content-block__inside--type2">
				<div className="table-block table-block--walletman">
					<div className="table-wrapper">
						<div className="table table--wallet-bank-balance">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Assets</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Balance</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Action</p>
										</div>
									</div>
								</div>
							</div>

							{!bankBalances?.length && !loader && (
								<div className="user-management-empty-data">
									<p className="user-management-empty__text">No balances found...</p>
								</div>
							)}

							{loader ? (
								<div className="list-loader">
									<Loader />
								</div>
							) : (
								<div className="table-body">
									{bankBalances?.map((item) => (
										<BankBalancesItem key={item.id} data={item} />
									))}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
