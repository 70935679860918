import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import { setFilterParams } from 'redux/reducers/externalMaker/reducer';
import { getFilterParams } from 'redux/reducers/externalMaker/selectors';
import { OrderHistorySearchBar as SearchBar } from './SearchBar';
import { IOrderHistoryFilter } from './types';

export const OrderHistoryFilter: FC<IOrderHistoryFilter> = ({ onDateSelect }) => {
	const { afterToday } = DateRangePicker;
	const [dateRangeValue, setDateRangeValue] = useState<DateRange | null>(null);
	const filters = useSelector(getFilterParams);
	const dispatch = useDispatch();

	const now = new Date();
	const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

	const changeDateRangeHandler = (d: DateRange | null) => {
		const startDate = d?.[0].toISOString().split('T')[0] || null;
		const endDate = d?.[1].toISOString().split('T')[0] || null;
		setDateRangeValue(d);
		onDateSelect(startDate, endDate);
		dispatch(
			setFilterParams({
				...filters,
				start_date: startDate,
				end_date: endDate,
			}),
		);
	};

	return (
		<>
			<SearchBar />
			<DateRangePicker
				format="dd/MM/yyyy"
				size="lg"
				block
				placement="bottomEnd"
				style={{ width: '308px' }}
				disabledDate={afterToday?.()}
				value={dateRangeValue}
				onChange={changeDateRangeHandler}
				defaultCalendarValue={[prevMonth, now]}
				ranges={[]}
			/>
		</>
	);
};
