/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IReferralStore,
	IGetReferralListRequest,
	IReferralFee,
	IReferralResponse,
	IReferralFeeRequest,
} from './types';

// ==========================================:
export const initialState: IReferralStore = {
	referralLoading: false,
	referral: null,
	referralFee: null,
	userReferralLoading: false,
	userReferral: null,
};

// ==========================================:
const referral = createSlice({
	name: '@@referral',
	initialState,
	reducers: {
		getReferralListRequest: (state, { payload }: PayloadAction<IGetReferralListRequest>) => {
			const referralState = state;

			if (payload.params.export !== 1) {
				referralState.referralLoading = true;
			}
		},
		getReferralListSuccess: (state, { payload }: PayloadAction<IReferralResponse>) => {
			const referralState = state;
			referralState.referralLoading = false;
			referralState.referral = payload;
		},
		getReferralFeeRequest: (state) => {
			const referralState = state;
		},
		getReferralFeeSuccess: (state, { payload }: PayloadAction<IReferralFee>) => {
			const referralState = state;
			referralState.referralFee = payload;
		},
		updateReferralFeeRequest: (state, { payload }: PayloadAction<IReferralFeeRequest>) => {
			const referralState = state;
		},
		getReferralRequest: (state, { payload }: PayloadAction<IGetReferralListRequest>) => {
			const referralState = state;
			referralState.userReferralLoading = false;
		},
		getReferralSuccess: (state, { payload }: PayloadAction<IReferralResponse>) => {
			const referralState = state;
			referralState.userReferralLoading = false;
			referralState.userReferral = payload;
		},

		referralInitState: () => initialState,
	},
});

export default referral.reducer;
export const {
	getReferralListRequest,
	getReferralListSuccess,
	getReferralFeeRequest,
	getReferralFeeSuccess,
	updateReferralFeeRequest,
	referralInitState,
	getReferralRequest,
	getReferralSuccess,
} = referral.actions;
