import { FC, useState, useMemo } from 'react';
import { ArrowDownIcon } from 'assets/img/icons';
import TranslatePanelItem from '../TranslatePanelItem';
import { ITranslatePanelGroup } from './types';

const TranslatePanelGroup: FC<ITranslatePanelGroup> = ({ groupName, path, values, search }) => {
	const [active, setActive] = useState(true);
	const isRootPath = path.split('.').length === 1;

	const keys = Object.keys(values);
	const filteredKeys = keys
		.filter((k) => (isRootPath ? typeof values[k] === 'string' : true))
		.filter((k) => {
			return values[k].toLowerCase().includes(search.toLowerCase());
		});

	return (
		<div
			className={`translate-group ${
				active || (search && filteredKeys.length !== 0) ? 'active' : ''
			}`}
			key={groupName}
		>
			<button type="button" className="translate-header" onClick={() => setActive((prev) => !prev)}>
				<h2 className="translate-header__title">{groupName}</h2>
				<div className="translate-header__btn">
					<ArrowDownIcon />
				</div>
			</button>
			<div className="translate-content">
				{filteredKeys.map((k) => (
					<TranslatePanelItem objKey={k} value={values[k]} path={`${path}.${groupName}`} />
				))}
			</div>
		</div>
	);
};

export default TranslatePanelGroup;
