/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */

import SocketConnectWrapper from 'components/SocketConnectWrapper';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	getChatRequest,
	postMessageBuyerRequest,
	postMessageSellerRequest,
} from 'redux/reducers/chat/reducer';
import { ChatsUsers } from './ChatsUsers/ChatsUsers';
import { ChatsSeller } from './ChatSeller/ChatsSeller';
import { ChatBuyer } from './ChatBuyer/ChatBuyer';

export const Chats = () => {
	const [seller, setSeller] = useState('');
	const [buyer, setBuyer] = useState('');
	const dispatch = useDispatch();
	const sendMessages = (status: string) => {
		const body = {
			message: '',
			trade_id: window.location.pathname.split('/dispute-info/')[1],
			'files.*.file': null,
		};
		console.log(status);
		console.log(buyer);
		console.log(seller);
		if (status === 'seller' && seller) {
			body.message = seller;
			console.log('message to seller');
			dispatch(postMessageSellerRequest({ ...body }));
		}
		if (status === 'buyer' && buyer) {
			body.message = buyer;
			console.log('message to buyer');
			dispatch(postMessageBuyerRequest({ ...body }));
		}
		setSeller('');
		setBuyer('');
	};

	useEffect(() => {
		dispatch(getChatRequest({ trade_id: window.location.pathname.split('/dispute-info/')[1] }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// sending messages by clicking on Enter key
		const send = (e: any) => {
			if (e.key === 'Enter') {
				e.preventDefault();
				const body = {
					message: '',
					trade_id: window.location.pathname.split('/dispute-info/')[1],
					'files.*.file': null,
				};
				if (seller) {
					body.message = seller;
					dispatch(postMessageSellerRequest({ ...body }));
					setSeller('');
				}
				if (buyer) {
					body.message = buyer;
					dispatch(postMessageBuyerRequest({ ...body }));
					setBuyer('');
				}
			}
		};
		document.addEventListener('keydown', send);
		return () => document.removeEventListener('keydown', send);
	}, [seller, buyer, dispatch]);

	return (
		<SocketConnectWrapper>
			<SocketWithPrivateTopicConnectWrapper topicName="chat">
				<div className="chat content-block__inside content-block__inside--dispute">
					<div className="dispute-chats">
						<form className="chats-form">
							<div className="chats-form__col">
								<p className="textarea-name">Buyer and Seller Chat History</p>
								<div className="chat-history-box">
									<ChatsUsers info="users" />
								</div>
							</div>
							<div className="chats-form__col">
								<p className="textarea-name">Admin with Buyer Chat History</p>
								<div className="chat-history-box">
									<div className="chat-history-box__scroll">
										<ChatBuyer info="buyer" />
									</div>
								</div>
								<div className="input input--flex">
									<label>
										<div className="input-wrapper">
											<input
												onChange={(e) => {
													setBuyer(e.target.value);
												}}
												className="input-item input-item--mid-width"
												type="text"
												value={buyer}
												placeholder="Enter your message"
											/>
										</div>
									</label>
									<button
										onClick={(e) => {
											e.preventDefault();
											sendMessages('buyer');
										}}
										type="button"
										className="button button--little button--medium-height"
									>
										Send
									</button>
								</div>
							</div>

							<div className="chats-form__col">
								<p className="textarea-name">Admin with Seller Chat History</p>
								<div className="chat-history-box">
									<div className="chat-history-box__scroll">
										<ChatsSeller info="seller" />
									</div>
								</div>
								<div className="input input--flex">
									<label>
										<div className="input-wrapper">
											<input
												onChange={(e) => {
													setSeller(e.target.value);
												}}
												className="input-item input-item--mid-width"
												type="text"
												value={seller}
												placeholder="Enter your message"
											/>
										</div>
									</label>
									<button
										onClick={(e) => {
											e.preventDefault();
											sendMessages('seller');
										}}
										type="button"
										className="button button--little button--medium-height"
									>
										Send
									</button>
								</div>
							</div>
							{/* <div className="chats-form__col"> */}
							{/*	<p className="textarea-name">Buyer and Seller Chat History</p> */}
							{/*	<div className="chat-history-box"> */}
							{/*		<div className="chat-history-box__scroll chat-history-box__scroll--smaller"> */}
							{/*			<div className="history-message history-message--admin"> */}
							{/*				<p className="history-message__name"> */}
							{/*					Admin */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*			<div className="history-message history-message--user"> */}
							{/*				<p className="history-message__name"> */}
							{/*					User */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*			<div className="history-message history-message--admin"> */}
							{/*				<p className="history-message__name"> */}
							{/*					Admin */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test!Some text message! Just test!Some text */}
							{/*						message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*			<div className="history-message history-message--user"> */}
							{/*				<p className="history-message__name"> */}
							{/*					User */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*			<div className="history-message history-message--admin"> */}
							{/*				<p className="history-message__name"> */}
							{/*					Admin */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*			<div className="history-message history-message--user"> */}
							{/*				<p className="history-message__name"> */}
							{/*					User */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*		</div> */}

							{/*	</div> */}
							{/*	<div className="input input--mt-16 input--flex"> */}
							{/*		<label> */}
							{/*			<div className="input-wrapper"> */}
							{/*				<input className="input-item " type="text" placeholder="Enter your message"> */}
							{/*			</div> */}
							{/*		</label> */}
							{/*		<button className="button button--little button--medium-height"> */}
							{/*			Send */}
							{/*		</button> */}
							{/*	</div> */}
							{/* </div> */}
							{/* <div className="chats-form__col"> */}
							{/*	<p className="textarea-name">Admin with Seller Chat History</p> */}
							{/*	<div className="chat-history-box"> */}
							{/*		<div className="chat-history-box__scroll chat-history-box__scroll--smaller"> */}
							{/*			<div className="history-message history-message--admin"> */}
							{/*				<p className="history-message__name"> */}
							{/*					Admin */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*			<div className="history-message history-message--user"> */}
							{/*				<p className="history-message__name"> */}
							{/*					User */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*			<div className="history-message history-message--admin"> */}
							{/*				<p className="history-message__name"> */}
							{/*					Admin */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test!Some text message! Just test!Some text */}
							{/*						message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*			<div className="history-message history-message--user"> */}
							{/*				<p className="history-message__name"> */}
							{/*					User */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*			<div className="history-message history-message--admin"> */}
							{/*				<p className="history-message__name"> */}
							{/*					Admin */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*			<div className="history-message history-message--user"> */}
							{/*				<p className="history-message__name"> */}
							{/*					User */}
							{/*				</p> */}
							{/*				<div className="history-message__text"> */}
							{/*					<p> */}
							{/*						Some text message! Just test! */}
							{/*					</p> */}
							{/*				</div> */}
							{/*			</div> */}
							{/*		</div> */}
							{/*	</div> */}
							{/*	<div className="input  input--mt-16 input--flex"> */}
							{/*		<label> */}
							{/*			<div className="input-wrapper"> */}
							{/*				<input className="input-item " type="text" placeholder="Enter your message"> */}
							{/*			</div> */}
							{/*		</label> */}
							{/*		<button className="button button--little button--medium-height"> */}
							{/*			Send */}
							{/*		</button> */}
							{/*	</div> */}
							{/* </div> */}
						</form>
					</div>
				</div>
			</SocketWithPrivateTopicConnectWrapper>
		</SocketConnectWrapper>
	);
};
