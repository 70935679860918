import { FC } from 'react';
import Popup from 'reactjs-popup';
import { IDeletePairProps } from './types';

// ================================================:
const DeletePairModal: FC<IDeletePairProps> = ({ isModalOpen, closeModal, onDeletePair }) => {
	return (
		<Popup
			open={isModalOpen}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window admin-managment-popup">
				<div className="popup-window__inside">
					<div className="popup popup">
						<div className="popup-header">
							<h3 className="popup-header__title">Delete Pair</h3>
						</div>

						<div className="popup-body">
							<div className="popup-text popup-text--center">
								<p>Are you sure you want to delete the pair?</p>
							</div>
							<div className="popup-submit popup-submit--sb popup-submit--column">
								<button className="button button--wider" type="button" onClick={closeModal}>
									Cancel
								</button>
								<button
									className="button button--del button--wider button--full"
									type="button"
									onClick={onDeletePair}
								>
									Yes, I&apos;m sure
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default DeletePairModal;
