/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IHotColdTransactionsRequestPayload,
	IHotColdTransactionsResponsePayload,
	IWalletMangmentRequestPayload,
	IHotWalletAddressRequestPayload,
	IHotWalletAddressResponsePayload,
	IWalletsRequestPayload,
	IWalletsResponsePayload,
	IWithdrawToColdWalletRequestPayload,
	IWithdrawToColdWalletResponsePayload,
	IBotLiquidityDataResponse,
	IResetColdBalancePayload,
	IBotLiquidityUpdateResponse,
	IBotLiqudityUpdatePayload,
	IExternalBalanceRequestPayload,
	IExternalBalanceDataResponse,
	IExternalBalanceDataUpdatePayload,
	IExternalBalanceDataUpdateResponse,
	ILiquiditySettingsRequestPayload,
	ILiquiditySettingsResponse,
	ILiquiditySetitngsUppdatePayload,
	ILiquiditySetitngsUppdateResponse,
	IWalletsListData,
	IDeleteExternalWalletPayload,
	IProviders,
	ICoinListWithChains,
	IAddExternalWalletPayload,
	IExternalWaletsItem,
	IUpdateExternalWalletPayload,
	IExternalWalletHistory,
	IExternalWalletRequestPayload,
	IExternalWaletsHistoryRequestPayload,
	IPutHotWalletsRequest,
	IBankAccountBalanceItem,
	IBankAccountBalanceUpdatePayload,
} from './types';
import {
	geHotColdTransactionsRequest,
	geHotWalletAddressRequest,
	getHotColdTransactionsSuccess,
	getHotWalletAddressSuccess,
	getWalletsRequest,
	getWalletsSuccess,
	exportWalletsFinally,
	walletManagementInitState,
	withdrawToColdWalletRequest,
	withdrawToColdWalletSuccess,
	getLiquidityRequest,
	getLiquiditySuccess,
	resetColdBalance,
	disableResetColdBalanceLoader,
	liquidityDataUpdateRequest,
	liquidityDataUpdateSuccess,
	getExternalBalancesRequest,
	getExternalBalancesSuccess,
	exportExternalBalancesFinally,
	externalBalancesDataUppdate,
	externalBalancesDataUppdateSuccess,
	getLiquiditySettingsRequestSuccess,
	getLiquiditySettingsRequest,
	liquiditySettingsUppdate,
	liquiditySettingsUppdateSuccess,
	getWalletsListRequest,
	getWalletsListRequestSuccess,
	deleteExternalWalletSuccess,
	deleteExternalWalletRequest,
	getProvidersRequestSuccess,
	getProvidersRequest,
	getCoinsListWithChainsRequest,
	getCoinsListWithChainsRequestSuccess,
	addExternalWalletRequest,
	externalWalletStatusUppdate,
	externalWalletStatusUppdateSuccess,
	getExternalWalletHistoryRequest,
	getExternalWalletHistoryRequestSuccess,
	putHotWalletsRequest,
	putHotWalletsSuccess,
	getBankAccountBalancesRequest,
	getBankAccountBalancesSuccess,
	bankAccountBalanceUpdateRequest,
	bankAccountBalanceUpdateSuccess,
} from './reducer';

// ===================================================:
function* walletsRequestWorker(action: PayloadAction<IWalletsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWalletsResponsePayload = yield call(api.walletManagement.getWallets, payload);
		if (payload.export === 1) {
			notificationContainer('Wallets successfully exported, check your mailbox!', 'info');
		} else {
			yield put(getWalletsSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
		if (payload.export === 1) {
			yield put(exportWalletsFinally());
		}
	}
}

// ===================================================:
function* hotWalletAddressRequestWorker(action: PayloadAction<IHotWalletAddressRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IHotWalletAddressResponsePayload = yield call(
			api.walletManagement.getHotWalletAddress,
			payload,
		);
		yield put(getHotWalletAddressSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* withdrawToColdWalletRequestWorker(
	action: PayloadAction<IWithdrawToColdWalletRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWithdrawToColdWalletResponsePayload = yield call(
			api.walletManagement.withdrawToColdWallet,
			payload,
		);
		yield put(withdrawToColdWalletSuccess(response));
		notificationContainer('Withdrawal to cold wallet will be processed', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
		geHotColdTransactionsRequest({});
	}
}

// ===================================================:
function* geHotColdTransactionsWorker(action: PayloadAction<IHotColdTransactionsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IHotColdTransactionsResponsePayload = yield call(
			api.walletManagement.getHotColdTransactions,
			payload,
		);
		if (payload.export === 1) {
			notificationContainer('Transactions successfully exported, check your mailbox!', 'info');
		} else yield put(getHotColdTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* getLiquidityWorker(action: PayloadAction<IWalletMangmentRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IBotLiquidityDataResponse = yield call(
			api.walletManagement.getLiquidityData,
			payload,
		);
		yield put(getLiquiditySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}
// ==================================================:
function* resetColdBalanceWorker({ payload }: PayloadAction<IResetColdBalancePayload>) {
	try {
		yield put(showLoading());
		yield call(api.walletManagement.resetColdBalance, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(getWalletsRequest({}));
		yield put(disableResetColdBalanceLoader());
		yield put(hideLoading());
	}
}
// ==================================================:
function* postLiquidityDataWorker({ payload }: PayloadAction<IBotLiqudityUpdatePayload>) {
	try {
		yield put(showLoading());
		const response: IBotLiquidityUpdateResponse = yield call(
			api.walletManagement.putLiquidityData,
			payload,
		);
		yield put(liquidityDataUpdateSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// ==================================================:
function* krakenBalanceWorker({ payload }: PayloadAction<IExternalBalanceRequestPayload>) {
	try {
		yield put(showLoading());
		const response: IExternalBalanceDataResponse = yield call(
			api.walletManagement.getExternalBalances,
			payload,
		);
		if (payload.export === 1) {
			notificationContainer('Extarnal balances successfully exported, check your mailbox!', 'info');
		} else yield put(getExternalBalancesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
		if (payload.export === 1) {
			yield put(exportExternalBalancesFinally());
		}
	}
}

function* putKrakenBalanceDataWorker({
	payload,
}: PayloadAction<IExternalBalanceDataUpdatePayload>) {
	try {
		yield put(showLoading());
		const response: IExternalBalanceDataUpdateResponse = yield call(
			api.walletManagement.putExternalBalances,
			payload,
		);
		yield put(externalBalancesDataUppdateSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* liquiditySettingsWorker({ payload }: PayloadAction<ILiquiditySettingsRequestPayload>) {
	try {
		yield put(showLoading());
		const response: ILiquiditySettingsResponse = yield call(
			api.walletManagement.getLiquiditySettings,
			payload,
		);
		yield put(getLiquiditySettingsRequestSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* putLiquiditySettingsWorker({ payload }: PayloadAction<ILiquiditySetitngsUppdatePayload>) {
	try {
		yield put(showLoading());
		const response: ILiquiditySetitngsUppdateResponse = yield call(
			api.walletManagement.putLiquiditySettings,
			payload,
		);
		yield put(liquiditySettingsUppdateSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* walletsListWorker({ payload }: PayloadAction<IExternalWalletRequestPayload>) {
	try {
		yield put(showLoading());
		const response: IWalletsListData = yield call(api.walletManagement.getWalletsList, payload);
		yield put(getWalletsListRequestSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* deleteExternalWalletWorker({ payload }: PayloadAction<IDeleteExternalWalletPayload>) {
	const { id, onSuccess } = payload;
	try {
		yield put(showLoading());
		yield call(api.walletManagement.deleteExternalWallet, id);

		onSuccess();
		yield put(deleteExternalWalletSuccess(id));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* providerstWorker() {
	try {
		yield put(showLoading());
		const response: IProviders = yield call(api.walletManagement.getProviders);
		yield put(getProvidersRequestSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* coinsListWithChainsWorker() {
	try {
		yield put(showLoading());
		const response: ICoinListWithChains = yield call(api.walletManagement.getCoinsListWithChains);
		yield put(getCoinsListWithChainsRequestSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* addExternalWalletWorker({ payload }: PayloadAction<IAddExternalWalletPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		yield call(api.walletManagement.addExternalWallet, apiParams);

		onSuccess();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* putExternalWalletStatusWorker({ payload }: PayloadAction<IUpdateExternalWalletPayload>) {
	try {
		yield put(showLoading());
		const response: IExternalWaletsItem = yield call(
			api.walletManagement.putExternalWallet,
			payload,
		);
		yield put(externalWalletStatusUppdateSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* externalWalletHistoryWorker({
	payload,
}: PayloadAction<IExternalWaletsHistoryRequestPayload>) {
	try {
		yield put(showLoading());
		const response: IExternalWalletHistory = yield call(
			api.walletManagement.getExternalWalletHistory,
			payload,
		);
		if (payload.export === 1) {
			notificationContainer(
				'External wallet history successfully exported, check your mailbox!',
				'info',
			);
		} else yield put(getExternalWalletHistoryRequestSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* putHotWalletsWorker({ payload }: PayloadAction<IPutHotWalletsRequest>) {
	try {
		yield put(showLoading());
		const response: string = yield call(api.walletManagement.putHotWallets, payload);
		yield put(putHotWalletsSuccess());
		yield put(getWalletsRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ==================================================:
function* getBankAccountBalancesWorker() {
	try {
		yield put(showLoading());
		const response: IBankAccountBalanceItem[] = yield call(
			api.walletManagement.getBankAccountBalances,
		);
		yield put(getBankAccountBalancesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ==================================================:
function* bankAccountBalanceUpdateWorker({
	payload,
}: PayloadAction<IBankAccountBalanceUpdatePayload>) {
	try {
		yield put(showLoading());
		const response: IBankAccountBalanceItem = yield call(
			api.walletManagement.udpateBankAccountBalance,
			payload,
		);
		yield put(bankAccountBalanceUpdateSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

export function* walletManagementSaga() {
	yield takeEvery(getWalletsRequest.type, walletsRequestWorker);
	yield takeEvery(geHotWalletAddressRequest.type, hotWalletAddressRequestWorker);
	yield takeEvery(withdrawToColdWalletRequest.type, withdrawToColdWalletRequestWorker);
	yield takeEvery(geHotColdTransactionsRequest.type, geHotColdTransactionsWorker);
	yield takeEvery(getLiquidityRequest.type, getLiquidityWorker);
	yield takeEvery(resetColdBalance.type, resetColdBalanceWorker);
	yield takeEvery(liquidityDataUpdateRequest.type, postLiquidityDataWorker);
	yield takeEvery(getExternalBalancesRequest.type, krakenBalanceWorker);
	yield takeEvery(externalBalancesDataUppdate.type, putKrakenBalanceDataWorker);
	yield takeEvery(getLiquiditySettingsRequest.type, liquiditySettingsWorker);
	yield takeEvery(liquiditySettingsUppdate.type, putLiquiditySettingsWorker);
	yield takeEvery(getWalletsListRequest.type, walletsListWorker);
	yield takeEvery(deleteExternalWalletRequest.type, deleteExternalWalletWorker);
	yield takeEvery(getProvidersRequest.type, providerstWorker);
	yield takeEvery(getCoinsListWithChainsRequest.type, coinsListWithChainsWorker);
	yield takeEvery(addExternalWalletRequest.type, addExternalWalletWorker);
	yield takeEvery(externalWalletStatusUppdate.type, putExternalWalletStatusWorker);
	yield takeEvery(getExternalWalletHistoryRequest.type, externalWalletHistoryWorker);
	yield takeEvery(putHotWalletsRequest.type, putHotWalletsWorker);
	yield takeEvery(getBankAccountBalancesRequest.type, getBankAccountBalancesWorker);
	yield takeEvery(bankAccountBalanceUpdateRequest.type, bankAccountBalanceUpdateWorker);
}
