type NestedObject = { [key: string]: any };

export function getValueByPath(obj: NestedObject, path: string): any {
	const keys = path.split('.');

	return keys.reduce((acc, key) => {
		// Проверяем, что значение существует перед доступом к ключу
		return acc && acc[key] !== undefined ? acc[key] : undefined;
	}, obj);
}

export function setNestedValue<T>(obj: T, path: string, value: any): T {
	const keys = path.split('.');
	let current: any = obj;

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < keys.length - 1; i++) {
		const key = keys[i];
		if (!(key in current)) {
			current[key] = {};
		}
		current = current[key];
	}

	current[keys[keys.length - 1]] = value;

	return obj;
}
