export const searchOptions = [
	{
		name: 'user_id',
		label: 'User ID',
	},
	{
		name: 'reference_code',
		label: 'Reference code',
	},
];

export const getInputPlaceholder = (searchField: string) => {
	switch (searchField) {
		case 'user_id':
			return 'Enter user ID';
		case 'reference_code':
			return 'Enter reference code';
		default:
			return '';
	}
};
