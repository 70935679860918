import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IMarketBotStore } from './types';

// ==========================================:
const getMarketBotState = (state: IStoreState): IMarketBotStore => state.marketBot;

export const getMarketBotList = createSelector(
	[getMarketBotState],
	(store: IMarketBotStore) => store.marketBotList,
);
// ====================================================:
export const getMarketBotListLoader = createSelector(
	[getMarketBotState],
	(store: IMarketBotStore): boolean => store.marketBotListLoader,
);

// ====================================================:
export const getUpdateMarketBotLoader = createSelector(
	[getMarketBotState],
	(store: IMarketBotStore): boolean => store.updateMarketBotLoader,
);
