import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Sms2FAHistory from 'components/Sms2FAHistory';

// ================================================:
const Sms2FAPage: FC = () => {
	return (
		<Dashboard title="Sms 2FA history">
			<Sms2FAHistory />
		</Dashboard>
	);
};

export default Sms2FAPage;
