/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMarketMakerPageRequestPayload, TGeneralCurrencyChangeRequest } from './types';
// ==========================================:

export const initialState: any = {
	general: null,
	generalLoading: false,
	p2p: null,
	p2pLoading: false,
};

// ==========================================:

const currencies = createSlice({
	name: '@@currencies',
	initialState,
	reducers: {
		getGeneralRequest: (state) => {
			const generalState = state;
			generalState.generalLoading = true;
		},
		getGeneralSuccess: (state, action: any) => {
			const { payload } = action;
			const generalState = state;
			generalState.general = payload;
			generalState.generalLoading = false;
		},
		changeGeneralRequest: (state, action: PayloadAction<TGeneralCurrencyChangeRequest>) => {
			const generalState = state;
			// generalState.generalLoading = true;
		},
		getP2PRequest: (state) => {
			const p2pState = state;
			p2pState.p2pLoading = true;
		},
		getP2PSuccess: (state, action: any) => {
			const { payload } = action;
			const p2pState = state;
			p2pState.p2p = payload;
			p2pState.p2pLoading = false;
		},
		setP2PCurrencyStatus: (state, action) => {},
		generalInitState: () => initialState,
	},
});

export default currencies.reducer;
export const {
	getGeneralRequest,
	getGeneralSuccess,
	changeGeneralRequest,
	getP2PRequest,
	getP2PSuccess,
	setP2PCurrencyStatus,
} = currencies.actions;
