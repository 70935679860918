/* eslint-disable react/no-array-index-key */

import { FC } from 'react';
import Loader from 'ui/Loader';
import { useSelector } from 'react-redux';
import { getConvertSettingsloader } from 'redux/reducers/settings/selectors';
import { IConvertSettingsData } from 'redux/reducers/settings/types';
import { ConvertSettingsItem } from './ConvertSettingsItem';
import { IWithdrawalListProps } from './types';

// ==========================================:
const ConvertSettingsList: FC<IWithdrawalListProps> = ({ data }) => {
	const convertSettingsloader = useSelector(getConvertSettingsloader);

	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--convert-settings">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>Currency</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Spread, %</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>
					{!data && !convertSettingsloader && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">No withdrawals found...</p>
						</div>
					)}
					{convertSettingsloader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{data?.map((item: IConvertSettingsData, index: number) => (
								<ConvertSettingsItem key={index} data={item} />
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ConvertSettingsList;
