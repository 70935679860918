import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import FeeManagementFiat from 'components/FeeManagementFiat';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const FeeManagementFiatPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.SET_FEES}>
			<Dashboard title="Fee management fiat">
				<FeeManagementFiat />
			</Dashboard>
		</PermissionGate>
	);
};

export default FeeManagementFiatPage;
