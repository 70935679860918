/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { setNestedValue } from 'utils/object';
import {
	ITranslatesStore,
	ITranslates,
	IGetTranslateParams,
	IUpdateTranslatePayload,
} from './types';
// ==========================================:
export const initialState: ITranslatesStore = {
	language: 'en',
	translates: null,
	loading: false,
	updateLoading: false,
	currentIndex: 0,
};

// ==========================================:
const translations = createSlice({
	name: '@@Translates',
	initialState,
	reducers: {
		setLanguage: (state, { payload }: PayloadAction<string>) => {
			const translatesState = state;
			translatesState.language = payload;
		},
		getTranslatesRequest: (state, action: PayloadAction<IGetTranslateParams>) => {
			const translatesState = state;
			translatesState.loading = true;
		},
		getTranslatesSuccess: (state, { payload }: PayloadAction<ITranslates[]>) => {
			const translatesState = state;
			translatesState.loading = false;
			translatesState.translates = payload;
		},
		getTranslatesError: (state) => {
			const translatesState = state;
			translatesState.loading = false;
		},
		updateTranslatesRequest: (state, action: PayloadAction<IUpdateTranslatePayload>) => {
			const translatesState = state;
			translatesState.updateLoading = true;
		},
		updateTranslatesSuccess: (
			state,
			{ payload }: PayloadAction<{ path: string; response: ITranslates; index: number }>,
		) => {
			const translatesState = state;
			const { index } = payload;
			const targetKey = Object.keys(payload.response)[0];
			if (targetKey && translatesState.translates?.[0]) {
				translatesState.translates[index][targetKey] = payload.response[targetKey];
			}
			translatesState.updateLoading = false;
		},
		updateTranslatesError: (state) => {
			const translatesState = state;
			translatesState.updateLoading = false;
		},
		setCurrentIndex: (state, { payload }: PayloadAction<number>) => {
			const translatesState = state;
			translatesState.currentIndex = payload;
		},
	},
});

export default translations.reducer;
export const {
	getTranslatesRequest,
	getTranslatesSuccess,
	updateTranslatesRequest,
	updateTranslatesSuccess,
	getTranslatesError,
	updateTranslatesError,
	setLanguage,
	setCurrentIndex,
} = translations.actions;
