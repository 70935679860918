import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getChatListBuyer } from 'redux/reducers/chat/selectors';
import Popup from 'reactjs-popup';
import ChatBuyerMessagesItem from './ChatBuyerMessagesItem';
import { TProps } from '../types';

export const ChatBuyer: FC<TProps> = ({ info }) => {
	const [modalImage, setModalImage] = useState<string | null>(null);
	const wrapperRef = useRef<null | any>(info);

	const chatListBuyer = useSelector(getChatListBuyer);

	useEffect(() => {
		wrapperRef?.current?.scroll({
			top: wrapperRef.current.scrollHeight,
			left: 0,
		});
	}, [chatListBuyer]);

	return (
		<>
			<div id={info} ref={wrapperRef} className="chat-history-box__scroll">
				{chatListBuyer?.map((item: any) => {
					return (
						<ChatBuyerMessagesItem
							showImage={setModalImage}
							key={item.id}
							item={item}
							status={chatListBuyer[0].from_username}
						/>
					);
				})}
			</div>
			<Popup
				open={!!modalImage}
				onClose={() => setModalImage(null)}
				overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
			>
				<div className="image-modal" onClick={() => setModalImage(null)}>
					<img src={modalImage as string} alt="document" />
				</div>
			</Popup>
		</>
	);
};
