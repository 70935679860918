import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ITranslatesStore, ITranslates } from './types';

// ==========================================:
const getTranslatesState = (state: IStoreState): ITranslatesStore => state.translates;
export const getTranslates = createSelector(
	[getTranslatesState],
	(state: ITranslatesStore) => state.translates,
);

// ==========================================:
export const getTranslatesLoading = createSelector(
	[getTranslatesState],
	(state: ITranslatesStore) => state.loading,
);

// ==========================================:
export const getTranslatesUpdating = createSelector(
	[getTranslatesState],
	(state: ITranslatesStore) => state.updateLoading,
);

// ==========================================:
export const getCurrentLanguage = createSelector(
	[getTranslatesState],
	(state: ITranslatesStore) => state.language,
);

// ==========================================:
export const getCurrentIndex = createSelector(
	[getTranslatesState],
	(state: ITranslatesStore) => state.currentIndex,
);
