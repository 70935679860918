import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ITokenManagementApi } from './types';
import {
	ITokenManagementEditParams,
	ITokenManagementParams,
	ITokenManagementResponse,
} from '../../../redux/reducers/tokenManagement/types';
// ==========================================:

export const tokenManagement: ITokenManagementApi = {
	getTokenManagement: (params: ITokenManagementParams) =>
		http
			.get<ITokenManagementResponse>(endpoint.tokenManagement.TOKEN_MANAGEMENT, {
				params,
			})
			.then((response) => response.data),
	postTokenManagement: (body: ITokenManagementEditParams) =>
		http
			.post(endpoint.tokenManagement.TOKEN_MANAGEMENT_EDIT(body.url), {
				asset_id: body.asset_id,
				network: body.network,
				[body.key]: body.value,
			})
			.then((response) => response.data),
	getTokenManagementHistory: (params) =>
		http
			.get<ITokenManagementResponse>(endpoint.tokenManagement.TOKEN_MANAGEMENT_HISTORY, {
				params,
			})
			.then((response) => response.data),
	checkAndMint: (params) => http.post(endpoint.tokenManagement.CHECK_AND_MINT, params),
	checkAndBurn: (params) => http.post(endpoint.tokenManagement.CHECK_AND_BURN, params),
	check: (params) => http.get(endpoint.tokenManagement.CHECK, { params }),
};
