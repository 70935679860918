/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMarketMakerPageRequestPayload, IFilterParams } from './types';
// ==========================================:

export const initialState: any = {
	tradesSpot: null,
	tradesSpotLoading: false,
	tradesMargin: null,
	tradesMarginLoading: false,
	tradesP2PTrades: null,
	tradesP2PTradesLoading: false,
	tradesP2PTradesExporting: false,
	filterParams: null,
};

// ==========================================:

const trades = createSlice({
	name: '@@trades',
	initialState,
	reducers: {
		getTradesSpotRequest: (state, action: PayloadAction<IMarketMakerPageRequestPayload>) => {
			const { payload } = action;
			const tradesMarginRequestState = state;
			if (payload.export !== 1) {
				tradesMarginRequestState.tradesSpotLoading = true;
			}
		},
		getTradesSpotSuccess: (state, action: any) => {
			const { payload } = action;
			const tradesMarginSuccessState = state;
			tradesMarginSuccessState.tradesSpot = payload;
			tradesMarginSuccessState.tradesSpotLoading = false;
		},
		getTradesMarginRequest: (state, action: PayloadAction<IMarketMakerPageRequestPayload>) => {
			const { payload } = action;
			const tradesSpotRequestState = state;
			if (payload.export !== 1) {
				tradesSpotRequestState.tradesMarginLoading = true;
			}
		},
		getTradesMarginSuccess: (state, action: any) => {
			const { payload } = action;
			const tradesSpotSuccessState = state;
			tradesSpotSuccessState.tradesMargin = payload;
			tradesSpotSuccessState.tradesMarginLoading = false;
		},
		getTradesP2PTradesRequest: (state, action: PayloadAction<IMarketMakerPageRequestPayload>) => {
			const { payload } = action;
			const tradesP2PTradesRequestState = state;
			if (payload.export === 1) {
				tradesP2PTradesRequestState.tradesP2PTradesExporting = true;
			} else {
				tradesP2PTradesRequestState.tradesP2PTradesLoading = true;
			}
		},
		getMTradesP2PTradesSuccess: (state, action: any) => {
			const { payload } = action;
			const tradesP2PTradesSuccessState = state;
			tradesP2PTradesSuccessState.tradesP2PTrades = payload;
			tradesP2PTradesSuccessState.tradesP2PTradesLoading = false;
		},
		exportP2PTradesFinally: (state) => {
			const tradesP2PTradesState = state;
			tradesP2PTradesState.tradesP2PTradesExporting = false;
		},
		setFilterParams: (state, action: PayloadAction<IFilterParams>) => {
			const { payload } = action;
			const tradeState = state;
			tradeState.filterParams = payload;
		},
		marketMakerBotInitState: () => initialState,
	},
});
export default trades.reducer;
export const {
	getTradesSpotRequest,
	getTradesSpotSuccess,
	getTradesMarginRequest,
	getTradesMarginSuccess,
	getTradesP2PTradesRequest,
	getMTradesP2PTradesSuccess,
	exportP2PTradesFinally,
	setFilterParams,
} = trades.actions;
