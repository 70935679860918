/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { desktopOrLaptop, laptop, macLaptop } from 'utils/mediaBreakpoints';
import CustomTooltip from './CustomTooltip';
import RenderActiveShape from './ActiveShape';
import { IBalanceChartProps, IChartData } from './types';

// ================================================:
const Chart: FC<IBalanceChartProps> = ({ data, currentBalanceItemName, type }) => {
	const [activeBalanceItemIndex, setActiveBalanceItemIndex] = useState(0);

	useEffect(() => {
		const currentItemIndex = data?.findIndex((el) => el.asset_code === currentBalanceItemName);

		setActiveBalanceItemIndex(currentItemIndex);
	}, [data, currentBalanceItemName]);

	const isDesktopOrLaptop = useMediaQuery({
		query: `(min-device-width: ${String(desktopOrLaptop)}px)`,
	});

	const isLaptop = useMediaQuery({
		query: `(min-device-width: ${String(laptop)}px)`,
	});

	const isMacLaptop = useMediaQuery({
		query: `(max-device-width: ${String(macLaptop)}px)`,
	});

	const pieChartRadius =
		(isLaptop && isMacLaptop && 100) || (!isDesktopOrLaptop && 100) || (!isLaptop && 100) || 150;

	const pieChartSize = (!isDesktopOrLaptop && 300) || 400;

	const COLORS = ['#ba82ec', '#9cc5f2', '#ffeaa0', '#f36161', '#2fe762', '#ff9999', '#ff9999'];

	// const pieChartData: IChartData[] = [];

	// data?.forEach((item) => {
	// 	pieChartData.push({
	// 		name: item.asset_code,
	// 		value: Number(
	// 			item.balances.reduce((sum: number, elem: any) => {
	// 				return sum + Number(elem.balance_usdt);
	// 			}, 0),
	// 		),
	// 	});
	// });

	const [pieChartData, setPieChartData] = useState<IChartData[]>([]);

	useEffect(() => {
		const pieData: IChartData[] = [];
		if (!type) {
			data?.map((item) => {
				return pieData.push({
					name: item.asset_code,
					value: Number(
						item.balances.reduce((sum: number, elem: any) => {
							return sum + Number(elem.balance);
						}, 0),
					),
				});
			});
		} else {
			data?.map((item) => {
				return pieData.push({
					name: item.asset_code,
					value: Number(item.profits_by_type[type]),
				});
			});
		}
		setPieChartData(pieData);
	}, [data, type]);

	return (
		<div className="chart chart-custom">
			<div className="chart__wrapper">
				<ResponsiveContainer width="100%" height={pieChartSize}>
					<PieChart>
						<Pie
							data={pieChartData}
							cx="50%"
							cy="50%"
							activeIndex={activeBalanceItemIndex}
							activeShape={(props) => <RenderActiveShape data={data} type={type} {...props} />}
							outerRadius={pieChartRadius}
							fill="#8884d8"
							dataKey="value"
						>
							{pieChartData?.map((entry: IChartData, index: number) => (
								<Cell key={`cell-${entry.name}`} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
						<Tooltip>
							<CustomTooltip data={data} />
						</Tooltip>
					</PieChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
};

export default Chart;
