import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { getExternalBalancesRequest } from 'redux/reducers/walletManagement/reducer';
import { getExternalBalanceExporting } from 'redux/reducers/walletManagement/selectors';
import { IExternalBalanceRequestPayload } from 'redux/reducers/walletManagement/types';
import { ExportIcon } from 'assets/img/icons';

export const BybitBalanceFilter = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const isExporting = useSelector(getExternalBalanceExporting);

	const handleExport = () => {
		const params: IExternalBalanceRequestPayload = {
			platform_name: 'bybit',
			current_page: page || 1,
			export: 1,
		};
		dispatch(getExternalBalancesRequest(params));
	};

	return (
		<button
			type="button"
			className="transaction-export-btn"
			onClick={handleExport}
			disabled={isExporting}
		>
			Export Bybit balances
			<span className="transaction-export-btn__icon">
				<ExportIcon />
			</span>
		</button>
	);
};
