import { MouseEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { numberFormat8Fraction } from 'utils/numberFormat';
import { liquidityDataUpdateRequest } from 'redux/reducers/walletManagement/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IItemParams, IItemInputParams } from './types';
import { SubbButton } from '../SubbButton/SubbButton';

export const LiquidityItem: FC<IItemParams> = ({ data }) => {
	const [baseTotal, setBaseTotal] = useState(String(data.base_asset_total));
	const [quoteTotal, setQuoteTotal] = useState(String(data.quote_asset_total));

	const [isClosed, setIsClosed] = useState(false);
	const dispatch = useDispatch();
	const [concat, setConcat] = useState({ base: '+', qute: '+' });

	const { checkEditable } = usePermission();

	const handleChange = (e: MouseEvent<HTMLButtonElement>) => {
		const { value, name } = e.currentTarget;
		setConcat({ ...concat, [name]: value });
		name === 'base'
			? setBaseTotal(String(-1 * Number(baseTotal)))
			: setQuoteTotal(String(-1 * Number(quoteTotal)));
	};

	const handleChangeExchangeFeeSubmit = (value: IItemInputParams) => {
		if (baseTotal === '' || quoteTotal === '') return;
		setIsClosed(!isClosed);
		dispatch(liquidityDataUpdateRequest({ ...value }));
	};
	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Currency</p>
					<p>{data.pair_code.toUpperCase()}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">First coin</p>
					{isClosed ? <div /> : <p>{data.base_asset_total}</p>}
					<div className={`table-enter-coin ${isClosed ? '' : 'table-input--disable'}`}>
						<SubbButton concat={concat.base} name="base" setConcat={handleChange} />
						<div className="table-input table-input--start">
							<input
								type="text"
								value={baseTotal}
								onChange={(e) => setBaseTotal(e.target.value.replace(/[^\d\\.]/g, ''))}
							/>
						</div>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Second coin</p>
					{isClosed ? <div /> : <p>{data.quote_asset_total}</p>}
					<div className={`table-enter-coin ${isClosed ? '' : 'table-input--disable'}`}>
						<SubbButton concat={concat.qute} name="qute" setConcat={handleChange} />
						<div className="table-input table-input--start">
							<input
								type="text"
								value={quoteTotal}
								onChange={(e) => setQuoteTotal(e.target.value.replace(/[^\d\\.]/g, ''))}
							/>
						</div>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Edit</p>
					<div className="table-buttons">
						<button
							type="submit"
							className={`${isClosed ? '' : 'disable'}`}
							onClick={() => {
								// setBaseTotal(numberFormat8Fraction(data.base_asset_total, 'en-En'));
								// setQuoteTotal(numberFormat8Fraction(data.base_asset_total, 'en-En'));
								setIsClosed(!isClosed);
							}}
						>
							<span className="edit-icon icon-cancel-icon" />
						</button>
						{isClosed ? (
							<button
								type="button"
								onClick={() => {
									handleChangeExchangeFeeSubmit({
										id: data.id,
										base_asset_total: Number(baseTotal),
										quote_asset_total: Number(quoteTotal),
									});
								}}
								className={baseTotal === '' || quoteTotal === '' ? 'button-not-active' : ''}
							>
								<svg
									width="18"
									height="19"
									viewBox="0 0 18 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
										stroke="#00DA83"
										strokeLinecap="square"
									/>
								</svg>
							</button>
						) : (
							<button
								type="button"
								disabled={!checkEditable(permissions.MANAGE_WALLETS)}
								onClick={() => setIsClosed(!isClosed)}
							>
								<span className="edit-icon icon-edit-icon" />
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
