import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { provider_name, asset_id, network, address } from 'utils/formValidation';
import { getCoinsListWithChains, getProviders } from 'redux/reducers/walletManagement/selectors';
import {
	addExternalWalletRequest,
	getCoinsListWithChainsRequest,
	getProvidersRequest,
	getWalletsListRequest,
} from 'redux/reducers/walletManagement/reducer';
import {
	IAddExternalWalletPayload,
	IAddExternalWalletRequest,
} from 'redux/reducers/walletManagement/types';
import { notificationContainer } from 'utils/notificationContainer';
import { IAddExternalWalletPopup, TValues } from './types';
import SelectLiquidityProvider from './SelectLiquidityProvider';
import SelectCoin from './SelectCoin';
import SelectChain from './SelectChain';

const initialValues: TValues = {
	provider_name: '',
	asset_id: '',
	network: '',
	address: '',
};

const AddExternalWalletPopup: FC<IAddExternalWalletPopup> = ({ onClose }) => {
	const dispatch = useDispatch();
	const providers = useSelector(getProviders);
	const coinsListWithChains = useSelector(getCoinsListWithChains);

	const validationSchema = yup.object().shape({
		provider_name,
		asset_id,
		network,
		address,
	});

	useEffect(() => {
		if (providers) {
			return;
		}
		dispatch(getProvidersRequest());
	}, [providers, dispatch]);

	useEffect(() => {
		if (coinsListWithChains) {
			return;
		}
		dispatch(getCoinsListWithChainsRequest());
	}, [coinsListWithChains, dispatch]);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<button type="button" className="popup__close" onClick={onClose}>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15 3L3 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3 3L15 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">Add New Wallet</p>
					</div>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values: TValues) => {
							const payload: IAddExternalWalletPayload = {
								apiParams: values as IAddExternalWalletRequest,
								onSuccess: () => {
									notificationContainer('External wallet successfully added', 'success');
									dispatch(getWalletsListRequest({}));
									onClose();
								},
							};
							dispatch(addExternalWalletRequest(payload));
						}}
						validateOnBlur
						enableReinitialize
					>
						{({ errors, touched }) => {
							const showError = (field: keyof TValues) => errors[field] && touched[field];
							return (
								<Form>
									<div className="popup-body popup-body--type2">
										<div className="input ">
											<p className="input__name">Select Liquidity Provider</p>
											<Field
												type="text"
												placeholder="Select"
												name="provider_name"
												className={showError('provider_name') ? 'select--error' : ''}
												component={SelectLiquidityProvider}
											/>
											{showError('provider_name') && (
												<p className="error-text">{errors.provider_name}</p>
											)}
										</div>
										<div className="input ">
											<p className="input__name">List of Coins</p>
											<Field
												type="text"
												placeholder="List of Coins"
												name="asset_id"
												className={showError('asset_id') ? 'select--error' : ''}
												component={SelectCoin}
											/>
											{showError('asset_id') && <p className="error-text">{errors.asset_id}</p>}
										</div>
										<div className="input ">
											<p className="input__name">Chain</p>
											<Field
												type="text"
												placeholder="Chain"
												name="network"
												className={showError('network') ? 'select--error' : ''}
												component={SelectChain}
											/>
											{showError('network') && <p className="error-text">{errors.network}</p>}
										</div>
										<div className="input">
											<p className="input__name">Wallet Address</p>
											<div
												className={`input-wrapper ${showError('address') ? 'input--error' : ''}`}
											>
												<Field
													type="text"
													placeholder="Enter wallet address"
													name="address"
													className="input-item"
												/>
												<ErrorMessage name="address" className="error-text" component="p" />
											</div>
										</div>
									</div>
									<div className="popup-submit popup-submit--type2">
										<button
											className="button button--cancel button--full-width"
											type="button"
											onClick={onClose}
										>
											Cancel
										</button>
										<button className="button button--check button--full-width" type="submit">
											Add
										</button>
									</div>
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default AddExternalWalletPopup;
