import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IMarketMakerApi } from './types';

// ==========================================:
export const marketMakerBot: IMarketMakerApi = {
	getMarketMakerSettings: (params) =>
		http
			.get(endpoint.marketMakerBot.MARKET_MAKER_SETTINGS, { params })
			.then((response) => response.data),
	putMarketMakerToggleEnabled: () =>
		http.put(endpoint.marketMakerBot.MARKET_MAKER_TOGGLE_ENABLE).then((response) => response.data),
	putMarketMakerSettings: (body) =>
		http
			.put(endpoint.marketMakerBot.MARKET_MAKER_SETTINGS_EDIT, body)
			.then((response) => response.data),
	deletePairBot: (payload) =>
		http.post(endpoint.marketMakerBot.DELETE_PAIR_BOT, payload).then((response) => response.data),
};
