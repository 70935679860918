import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAuthStore, IUserData } from 'redux/reducers/auth/types';

// ==========================================:
const getAuthState = (state: IStoreState): IAuthStore => state.auth;
export const getAuth = createSelector([getAuthState], (auth: IAuthStore) => auth);

// ====================================================:
export const getIsAuth = createSelector([getAuth], (auth: IAuthStore): boolean => {
	return !!(auth.isAuthenticated && auth.accessToken);
});

// ====================================================:
export const getAuthIsAuthenticated = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => !!(auth.isAuthenticated && auth.accessToken),
);

// ====================================================:
export const getAuthIsNotAuthenticated = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => !auth.isAuthenticated && !auth.accessToken,
);
export const getSocketToken = createSelector(
	[getAuth],
	(auth: IAuthStore): string | null => auth.socketToken,
);
// ====================================================:
export const getAdminEmail = createSelector(
	[getAuth],
	(auth: IAuthStore): null | string => auth.email,
);

// ====================================================:
export const getIsLoad = createSelector([getAuth], (auth: IAuthStore): boolean => auth.loginLoader);

export const getIsToken = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => !!auth.accessToken,
);

export const getUserData = createSelector(
	[getAuth],
	(auth: IAuthStore): null | IUserData => auth.userData,
);

export const getTokenTime = createSelector([getAuth], (auth: IAuthStore) => auth.tokenExpires);
