import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ICurrencies } from './types';

// ==========================================:
export const currencies: ICurrencies = {
	getGeneral: () => http.get(endpoint.currencies.general).then((response) => response.data),
	getP2P: () => http.get(endpoint.currencies.p2p).then((response) => response.data),
	changeGeneral: (payload) => http.put(endpoint.currencies.generalChange, payload),
	setCurrencyStatus: ({ id, active }) =>
		http
			.put(endpoint.currencies.CHANGE_CURRENCY_STATUS, { id, active })
			.then((response) => response.data),
};
