import { FC } from 'react';
import { whitelistingIPStatus } from 'redux/reducers/userManagement/constants';
import { IWhitelistingIPItemProps } from './types';
import { WhitelistingIPActions } from './WhitelistingIPActions';

export const WhitelistingIPItem: FC<IWhitelistingIPItemProps> = ({ data }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);

	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Ip</p>
					<p>{data.ip}</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Date</p>
					<p>
						{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
						{dateOffset.toLocaleTimeString()}
					</p>
				</div>
			</div>
		</div>
	);
};
