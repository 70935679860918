import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	getMTradesP2PTradesSuccess,
	getTradesMarginRequest,
	getTradesMarginSuccess,
	getTradesP2PTradesRequest,
	getTradesSpotRequest,
	getTradesSpotSuccess,
	exportP2PTradesFinally,
} from './reducer';
import { IMarketMakerPageRequestPayload, ITradesResponsePayload } from './types';
// =============================================================:
// =============================================================:

function* spotTradesRequestWorker(action: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ITradesResponsePayload = yield call(api.trades.getTradesSpot, payload);
		if (payload.export === 1) {
			notificationContainer('Spot trades successfully exported, check your mailbox!', 'info');
		} else yield put(getTradesSpotSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
function* marginTradesRequestWorker(action: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ITradesResponsePayload = yield call(api.trades.getTradesMargin, payload);
		if (payload.export === 1) {
			notificationContainer('Margin trades successfully exported, check your mailbox!', 'info');
		} else yield put(getTradesMarginSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* tradesP2PTradesRequestWorker(action: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ITradesResponsePayload = yield call(api.trades.getTradesP2P, payload);
		if (payload.export === 1) {
			notificationContainer('P2P trades successfully exported, check your mailbox!', 'info');
		} else yield put(getMTradesP2PTradesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		if (payload.export === 1) {
			yield put(exportP2PTradesFinally());
		}
	}
}
export function* tradesSaga() {
	yield takeEvery(getTradesSpotRequest.type, spotTradesRequestWorker);
	yield takeEvery(getTradesMarginRequest.type, marginTradesRequestWorker);
	yield takeEvery(getTradesP2PTradesRequest.type, tradesP2PTradesRequestWorker);
}
