import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { INotificationsStore, INotificationsData } from './types';

const getNotificationsState = (state: IStoreState): INotificationsStore => state.notifications;
export const getNotifications = createSelector(
	[getNotificationsState],
	(notif: INotificationsStore) => notif,
);

export const getNotificationsList = createSelector(
	[getNotifications],
	(notif: INotificationsStore): INotificationsData => notif.all_notifications,
);
