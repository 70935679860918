import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTabMenuUserIndex } from 'redux/reducers/userManagement/reducer';
import { noExponent } from 'utils/numberFormat';
import { ICryptoDepositProps } from './types';

const ConvertsItem: FC<ICryptoDepositProps> = ({ item }) => {
	const dispatch = useDispatch();
	const [id] = useState(item.id);
	const [fromAssetCode] = useState(item.from_asset_code);
	const [toAssetCode] = useState(item.to_asset_code);
	const [rate] = useState(item.rate);
	const [fee] = useState(item.fee);
	const [amount] = useState(item.amount);
	const [userId] = useState(item.user_id);
	const [coin] = useState(item?.asset?.code);
	const [currency] = useState(item?.asset?.name);
	const [created] = useState(item.created_at);
	const date = new Date(created * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+created + offset) * 1000);

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>
			{/* <div className="td td--left">
				<Link
					onClick={() => {
						dispatch(setTabMenuUserIndex(0));
					}}
					to={`/user-management/${String(userId)}`}
				>
					<p className="td-hidden-name">User ID</p>
					<p>{userId}</p>
				</Link>
			</div> */}
			<div className="td td--left">
				<p className="td-hidden-name">From</p>
				<p style={{ textTransform: 'uppercase' }}>
					{fromAssetCode === undefined ? '-' : fromAssetCode}
				</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">To</p>
				<p style={{ textTransform: 'uppercase' }}>
					{toAssetCode === undefined ? '-' : toAssetCode}
				</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Rate</p>
				<p>{noExponent(Number(rate).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Value</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				<p>{noExponent(Number(fee).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Created</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
		</div>
	);
};
export default ConvertsItem;
