import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ITrades } from './types';

// ==========================================:
export const trades: ITrades = {
	getTradesSpot: (params) =>
		http.get<any>(endpoint.trades.tradesSpot, { params }).then((response) => response.data),
	getTradesMargin: (params) =>
		http.get<any>(endpoint.trades.tradesSpot, { params }).then((response) => response.data),
	getTradesP2P: (params) =>
		http.get<any>(endpoint.trades.tradesP2P, { params }).then((response) => response.data),
};
