import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getAdminManagementIsLoad } from 'redux/reducers/adminManagement/selectors';
import Loader from 'ui/Loader';
import AdminManagementItem from '../AdminManagementItem';
import { IAdminManagementListProps } from './types';

// ==========================================:
const AdminManagementList: FC<IAdminManagementListProps> = ({ users }) => {
	const adminManagementListLoader = useSelector(getAdminManagementIsLoad);

	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--admin-managment">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>&#8470;</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Admin ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Full name</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>E-mail</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Permissions</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Registration date </p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name">
									<p>Edit</p>
								</div>
							</div>
						</div>
					</div>

					{!users?.length && !adminManagementListLoader && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">No admins found...</p>
						</div>
					)}
					{adminManagementListLoader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{users?.map((item, index) => (
								<AdminManagementItem
									key={item.id}
									data={item}
									index={index + 1}
									isAdminManagementPermissionUpdateRules
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default AdminManagementList;
