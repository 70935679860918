import {
	IHotColdTransactionsResponsePayload,
	IHotWalletAddressResponsePayload,
	IWalletsResponsePayload,
	IWithdrawToColdWalletRequestPayload,
	IBotLiquidityDataResponse,
	IBotLiquidityUpdateResponse,
	IExternalBalanceDataResponse,
	IExternalBalanceDataUpdateResponse,
	ILiquiditySettingsResponse,
	ILiquiditySetitngsUppdateResponse,
	IWalletsListData,
	IExternalWaletsItem,
	IProviders,
	ICoinListWithChains,
	IExternalWalletHistory,
	IBankAccountBalanceUpdatePayload,
} from 'redux/reducers/walletManagement/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IWalletManagementApi } from './types';
// ==========================================:
export const walletManagement: IWalletManagementApi = {
	getWallets: (params) =>
		http
			.get<IWalletsResponsePayload>(endpoint.walletManagement.GET_HOT_WALLETS_DATA, {
				params,
			})
			.then((response) => response.data),
	putHotWallets: (payload) =>
		http
			.put<IWalletsResponsePayload>(endpoint.walletManagement.PUT_HOT_WALLETS_DATA, payload)
			.then((response) => response.data),
	getHotWalletAddress: (params) =>
		http
			.get<IHotWalletAddressResponsePayload>(endpoint.walletManagement.GET_HOT_WALLET_ADDRESS, {
				params,
			})
			.then((response) => response.data),

	withdrawToColdWallet: (payload: IWithdrawToColdWalletRequestPayload) =>
		http
			.post(endpoint.walletManagement.WITHDRAW_TO_COLD_WALLET, payload)
			.then((response) => response.data),
	getHotColdTransactions: (params) =>
		http
			.get<IHotColdTransactionsResponsePayload>(endpoint.walletManagement.HOT_COLD_TRANSACTIONS, {
				params,
			})
			.then((response) => response.data),
	getLiquidityData: (params) =>
		http
			.get<IBotLiquidityDataResponse>(endpoint.walletManagement.GET_LIQUIDITY_DATA, {
				params,
			})
			.then((response) => response.data),
	putLiquidityData: (params) =>
		http
			.put<IBotLiquidityUpdateResponse>(endpoint.walletManagement.GET_LIQUIDITY_DATA, params)
			.then((response) => response.data),
	resetColdBalance: (payload) =>
		http
			.put(endpoint.walletManagement.RESET_COLD_BALANCE, payload)
			.then((response) => response.data),
	getExternalBalances: (params) =>
		http
			.get<IExternalBalanceDataResponse>(endpoint.walletManagement.GET_EXTERNAL_BALANCES_DATA, {
				params,
			})
			.then((response) => response.data),
	putExternalBalances: (params) =>
		http
			.put<IExternalBalanceDataUpdateResponse>(
				endpoint.walletManagement.PUT_EXTERNAL_BALANCES_DATA,
				params,
			)
			.then((response) => response.data),
	getLiquiditySettings: (params) =>
		http
			.get<ILiquiditySettingsResponse>(endpoint.walletManagement.GET_LIQUIDITY_SETTINGS, { params })
			.then((response) => response.data),
	putLiquiditySettings: (params) =>
		http
			.put<ILiquiditySetitngsUppdateResponse>(
				endpoint.walletManagement.PUT_LIQUIDITY_SETTINGS,
				params,
			)
			.then((response) => response.data),
	getWalletsList: (params) =>
		http
			.get<IWalletsListData>(endpoint.walletManagement.GET_WALLETS_LIST, { params })
			.then((response) => response.data),
	deleteExternalWallet: (id) =>
		http
			.delete<IExternalWaletsItem>(endpoint.walletManagement.DELETE_EXTERNAL_WALLET(id))
			.then((response) => response.data),
	getProviders: () =>
		http.get<IProviders>(endpoint.walletManagement.GET_PROVIDERS).then((response) => response.data),
	getCoinsListWithChains: () =>
		http
			.get<ICoinListWithChains>(endpoint.walletManagement.GET_COIN_LIST_WITH_CHAINS)
			.then((response) => response.data),
	addExternalWallet: (params) =>
		http
			.post<IExternalWaletsItem>(endpoint.walletManagement.ADD_EXTERNAL_WALLET, params)
			.then((response) => response.data),
	putExternalWallet: (params) =>
		http
			.put<IExternalWaletsItem>(endpoint.walletManagement.UPDATE_WALLET_STATUS, params)
			.then((response) => response.data),
	getExternalWalletHistory: (params) =>
		http
			.get<IExternalWalletHistory>(endpoint.walletManagement.GET_EXTERNAL_WALLET_HISTORY, {
				params,
			})
			.then((response) => response.data),
	getBankAccountBalances: () =>
		http.get(endpoint.walletManagement.BANK_ACCOUNT_BALANCE).then((response) => response.data),
	udpateBankAccountBalance: (payload: IBankAccountBalanceUpdatePayload) =>
		http
			.put(endpoint.walletManagement.BANK_ACCOUNT_BALANCE, payload)
			.then((response) => response.data),
};
