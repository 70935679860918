import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	feesInitState,
	getPercentFiatFeeRequest,
	getPercentFiatFeeSuccess,
	getPercentFiatFeeFailed,
	addPercentFiatFeeRequest,
	addPercentFiatFeeSuccess,
	updatePercentFiatFeeRequest,
	updatePercentFiatFeeSuccess,
	updateRangePercentRequest,
	updateRangePercentSuccess,
	deletePercentFiatFeeRequest,
	deletePercentFiatFeeSuccess,
	exportPercentFiatFeesRequest,
	getFiatListRequest,
	getFiatListSuccess,
	getChannelListRequest,
	getChannelListSuccess,
} from './reducer';
import {
	IGetPercentFiatFeesParams,
	IAddPercentFiatFeesParams,
	IAddPercentFiatFeesResponse,
	IUpdatePercentFiatFeesPayload,
	IUpdateRangesPercentFiatFeesPayload,
	TRangePercent,
	IRangePercentItem,
	IDeletePercentFiatFeesPayload,
} from './types';

function* getPercentFiatFeeWorker({ payload }: PayloadAction<IGetPercentFiatFeesParams>) {
	try {
		yield put(showLoading());
		const response: TRangePercent = yield call(api.fiatFees.getPercentFiatFees, payload);
		yield put(getPercentFiatFeeSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}

		yield put(getPercentFiatFeeFailed());
		yield put(feesInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* addPercentFiatFeeWorker({ payload }: PayloadAction<IAddPercentFiatFeesParams>) {
	try {
		yield put(showLoading());
		const response: IAddPercentFiatFeesResponse = yield call(
			api.fiatFees.addPercentFiatFee,
			payload,
		);
		yield put(addPercentFiatFeeSuccess(response));
		notificationContainer('Fee was successfullt added', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}

		yield put(feesInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* updatePercentFiatFeeWorker({ payload }: PayloadAction<IUpdatePercentFiatFeesPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		const response: IRangePercentItem = yield call(api.fiatFees.updatePercentFiatFee, apiParams);
		yield put(updatePercentFiatFeeSuccess(response));
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}

		yield put(feesInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* updateRangePercentWorker({
	payload,
}: PayloadAction<IUpdateRangesPercentFiatFeesPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		const response: IRangePercentItem = yield call(
			api.fiatFees.updateRangesPercentFiatFees,
			apiParams,
		);
		yield put(updateRangePercentSuccess(response));
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}

		yield put(feesInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* deletePercentFiatFeeWorker({ payload }: PayloadAction<IDeletePercentFiatFeesPayload>) {
	try {
		const { id, onSuccess } = payload;
		yield put(showLoading());
		yield call(api.fiatFees.deletePercentFiatFee, id);
		yield put(deletePercentFiatFeeSuccess(id));
		notificationContainer('Fee was successfullt deleted', 'success');
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(feesInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* exportPercentFiatFeesWorker() {
	try {
		yield call(api.fiatFees.exportPercentFiatFees);
		notificationContainer(
			'Transaction history successfully exported, check your mailbox',
			'success',
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getFiatListWorker() {
	try {
		const response: string[] = yield call(api.fiatFees.getFiatList);
		yield put(getFiatListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getChannelListWorker() {
	try {
		const response: string[] = yield call(api.fiatFees.getChannelList);
		yield put(getChannelListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* fiatFeesSaga() {
	yield takeEvery(getPercentFiatFeeRequest.type, getPercentFiatFeeWorker);
	yield takeEvery(addPercentFiatFeeRequest.type, addPercentFiatFeeWorker);
	yield takeEvery(updatePercentFiatFeeRequest.type, updatePercentFiatFeeWorker);
	yield takeEvery(updateRangePercentRequest.type, updateRangePercentWorker);
	yield takeEvery(deletePercentFiatFeeRequest.type, deletePercentFiatFeeWorker);
	yield takeEvery(exportPercentFiatFeesRequest.type, exportPercentFiatFeesWorker);
	yield takeEvery(getFiatListRequest.type, getFiatListWorker);
	yield takeEvery(getChannelListRequest.type, getChannelListWorker);
}
