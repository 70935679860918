/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getCreateTransactionLoading } from 'redux/reducers/transactions/selectors';
import { IPropsPopup } from './type';

// ================================================:
export const ConfirmPopup: FC<IPropsPopup> = ({ user, amount, currency, onBack, onSubmit }) => {
	const createLoading = useSelector(getCreateTransactionLoading);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--medium">
					<div className="popup-header">
						<p className="popup-header__title">Confirm creating transaction</p>
						<p className="popup-header__sub--title">Are you sure you want to create transaction</p>
					</div>
					<div className="popup-body popup-body--type2">
						<div className="withdrawal-options ">
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">User ID</span>
								<span className="withdrawal-option__desc">{user?.id}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">User name</span>
								<span className="withdrawal-option__desc">{user?.username}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Amount</span>
								<span className="withdrawal-option__desc">{amount}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Currency</span>
								<span className="withdrawal-option__desc">{currency?.code?.toUpperCase()}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Reference code</span>
								<span className="withdrawal-option__desc">{user?.payment_reference}</span>
							</div>
						</div>
					</div>
					<div className="popup-submit popup-submit--sb">
						<button
							onClick={onBack}
							type="button"
							className="button button--second-grey button--full-width"
						>
							Back
						</button>
						<button
							onClick={onSubmit}
							disabled={createLoading}
							type="button"
							className="button button--full-width"
						>
							Confrim
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
