import { FC } from 'react';
import { ICryptoDepositProps } from './types';

const CardsTableItem: FC<ICryptoDepositProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Name</p>
				<p>{item?.holder_name || '---'}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Bank account number</p>
				<p>**** **** **** {item?.card_number_last4}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{item?.card_name || '---'}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Bank name</p>
				<p>{item?.bank_name || '---'}</p>
			</div>
		</div>
	);
};
export default CardsTableItem;
