import { FC, useState } from 'react';
import { getDateOffset } from 'utils/dateAndTimeHelpers';
import { capitalizeFirstLetter } from 'utils/numberFormat';
import { getStatusClass } from './utils';
import { ITableRow } from './types';

const TableRow: FC<ITableRow> = ({ item }) => {
	const createdAtOffset = getDateOffset(item.created_at);
	const confirmedAtOffset = item.confirmed_at && getDateOffset(item.confirmed_at);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>{item.user_id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<p>{item.phone}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Request Creation Time</p>
				<p>
					{createdAtOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{createdAtOffset.toLocaleTimeString()}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Confirmation Time</p>
				<p>
					{confirmedAtOffset && (
						<>
							{confirmedAtOffset.toLocaleDateString('en-GB')}{' '}
							<span className="custom-dot"> • </span>
							{confirmedAtOffset.toLocaleTimeString()}
						</>
					)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Code</p>
				<p>{item.code}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Event type</p>
				<p>{item.event && capitalizeFirstLetter(item.event)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<span className={`status ${getStatusClass(item.status)}`}>
					{capitalizeFirstLetter(item.status)}
				</span>
			</div>
		</div>
	);
};

export default TableRow;
