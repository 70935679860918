import { FC } from 'react';

import { IBalancesP2PProps } from './types';

const BalancesTableItem: FC<IBalancesP2PProps> = ({ item }) => {
	const { asset_code: assetCode, balance, total } = item;

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Cryptocurrency</p>
				<p>{assetCode?.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total balance</p>
				<p>{balance}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Available balance</p>
				<p>{total}</p>
			</div>
		</div>
	);
};

export default BalancesTableItem;
