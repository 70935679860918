import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Trades from 'components/Trades/index';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const TradesPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.VIEW_TRADES}>
			<Dashboard title="Trades">
				<Trades />
			</Dashboard>
		</PermissionGate>
	);
};
export default TradesPage;
