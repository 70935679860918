import { TStatus } from 'redux/reducers/unidentifiedDeposits/types';

export const getStatusClass = (status: TStatus) => {
	switch (status) {
		case 'identified': {
			return 'status--success';
		}
		case 'unidentified':
			return 'status--grey';
		case 'rejected':
			return 'status--rejected';
		default:
			return '';
	}
};
