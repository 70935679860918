/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMarketMakerPageRequestPayload, IFilterParams } from './types';
// ==========================================:

export const initialState: any = {
	ordersSpot: null,
	ordersSpotLoading: false,
	ordersMargin: null,
	ordersMarginLoading: false,
	ordersP2P: null,
	ordersP2PLoading: false,
	filterParams: null,
};

// ==========================================:

const orders = createSlice({
	name: '@@orders',
	initialState,
	reducers: {
		getOrdersSpotRequest: (state, { payload }: PayloadAction<IMarketMakerPageRequestPayload>) => {
			const ordersMarginRequestState = state;
			if (payload.export !== 1) {
				ordersMarginRequestState.ordersSpotLoading = true;
			}
		},
		getOrdersSpotSuccess: (state, action: any) => {
			const { payload } = action;
			const ordersMarginSuccessState = state;
			ordersMarginSuccessState.ordersSpot = payload;
			ordersMarginSuccessState.ordersSpotLoading = false;
		},
		getOrdersMarginRequest: (state, { payload }: PayloadAction<IMarketMakerPageRequestPayload>) => {
			const ordersSpotRequestState = state;
			if (payload.export !== 1) {
				ordersSpotRequestState.ordersMarginLoading = true;
			}
		},
		getOrdersMarginSuccess: (state, action: any) => {
			const { payload } = action;
			const ordersSpotSuccessState = state;
			ordersSpotSuccessState.ordersMargin = payload;
			ordersSpotSuccessState.ordersMarginLoading = false;
		},
		getOrdersP2PRequest: (state, { payload }: PayloadAction<IMarketMakerPageRequestPayload>) => {
			const ordersP2PordersRequestState = state;
			if (payload.export !== 1) {
				ordersP2PordersRequestState.ordersP2PLoading = true;
			}
		},
		getOrdersP2PSuccess: (state, action: any) => {
			const { payload } = action;
			const ordersP2POrdersSuccessState = state;
			ordersP2POrdersSuccessState.ordersP2P = payload;
			ordersP2POrdersSuccessState.ordersP2PLoading = false;
		},
		setFilterParams: (state, action: PayloadAction<IFilterParams>) => {
			const { payload } = action;
			const ordersMarginSuccessState = state;
			ordersMarginSuccessState.filterParams = payload;
		},
		marketMakerBotInitState: () => initialState,
	},
});
export default orders.reducer;
export const {
	getOrdersSpotRequest,
	getOrdersSpotSuccess,
	getOrdersMarginRequest,
	getOrdersMarginSuccess,
	getOrdersP2PRequest,
	getOrdersP2PSuccess,
	setFilterParams,
} = orders.actions;
