import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { enable2faRequest } from 'redux/reducers/settings/reducer';
import Google2faForm from 'components/Forms/Google2faForm';
import { IGoogle2faFormData } from 'components/Forms/Google2faForm/types';
import AppStroreImg from 'assets/img/app-strore.jpg';
import AppGooglePlayImg from 'assets/img/google-play.jpg';
import { IGoogle2faSteps } from './types';

// ================================================:
const Google2faSteps: FC<IGoogle2faSteps> = ({ data }) => {
	const dispatch = useDispatch();

	const handleGoogle2faSubmit = (value: IGoogle2faFormData) => {
		dispatch(enable2faRequest(value));
	};

	return (
		<div className="forms-block tfa-block">
			<div className="form form--settings">
				<div className="tfa-block__info">
					<h3 className="tfa-block__title">Enable Two-Factor Authentication</h3>
					<p className="tfa-block__text">
						For your security, we strongly recommend enabling 2FA on your account. After that, you
						will need to enter a one-time 6-digit code each time you are logging into your account,
						withdrawing funds or changing the password.
					</p>

					<div className="tfa-block__content">
						<div className="tfa-block__item">
							<h4 className="tfa-block__item-title">
								<span>1</span>
								Download 2FA App
							</h4>
							<p className="tfa-block__item-text">
								You should download and install an authenticator application, such as Google
								Authenticator or Authy, on your mobile device. This app will generate one-time
								6-digit access codes, which are required for logging into your account.
							</p>
							<div className="tfa-block__item-apps">
								<div className="tfa-block__item-appstore">
									<img src={AppStroreImg} alt="app strore img" />
								</div>
								<div className="tfa-block__item-google">
									<img src={AppGooglePlayImg} alt="app google play img" />
								</div>
							</div>
						</div>
						<div className="tfa-block__item">
							<h4 className="tfa-block__item-title">
								<span>2</span>
								Save 2FA Backup Key and Scan QR Code
							</h4>
							<p className="tfa-block__item-text">
								Please, backup your 2FA recovery key to some offline storage, write down on a paper.
								If your mobile device gets lost, stolen or erased, you will need this key to recover
								your 2FA access.
							</p>
							<div className="tfa-block__qr">
								<div className="tfa-block__qr-img">
									<img src={data?.QR_Image} alt="QR" />
								</div>
								<div className="tfa-block__qr-info">
									<h5 className="tfa-block__qr-title">Backup / Secret Key</h5>
									<div className="tfa-block__qr-code">{data?.secret}</div>
									<div className="tfa-block__qr-error">
										Please, write down or print a copy of the key above and store it safely.
									</div>
								</div>
							</div>
						</div>
						<div className="tfa-block__item">
							<h4 className="tfa-block__item-title">
								<span>3</span>
								Confirm 2FA Activation
							</h4>
							<Google2faForm google2faSubmit={handleGoogle2faSubmit} btnText="Enable" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Google2faSteps;
