/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { deletePairBotRequest } from 'redux/reducers/marketMakerBot/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import DeleteAdminModal from '../../DeleteAdminModal';
import { IMarketMakerListItemProps } from './types';

// ==========================================:
const MarketMakerBotItem: FC<IMarketMakerListItemProps> = ({ item, editSettings }) => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const [inputEdit, inputEditChange] = useState({
		toggleInputActions: false,
		asset_pair_id: item.asset_pair_id,
		limit_orders_enabled: item.limit_orders_enabled,
		market_orders_enabled: item.market_orders_enabled,
		spread: item.spread,
		matching_spread: item.matching_spread,
		frequency: item.frequency,
		enabled_skip_users_from_spread: item.enabled_skip_users_from_spread === 1,
	});

	const { checkEditable } = usePermission();

	const editValue = (e: any, boolean: boolean) => {
		if (!boolean) {
			inputEditChange({
				...inputEdit,
				[e.target.name]: e.target.value,
			});
		} else {
			inputEditChange({
				...inputEdit,
				[e.target.name]: e.target.checked,
			});
		}
	};
	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);
	const [disabled, setDisabled] = useState(false);

	const handleDeleteAdmin = (): void => {
		const pairId: number = item.asset_pair_id;
		dispatch(
			deletePairBotRequest({
				pair_id: pairId,
				onSuccess: () => {
					closeModal();
					setDisabled(false);
				},
				onError: () => {
					closeModal();
					setDisabled(false);
				},
			}),
		);
		setDisabled(true);
		history.push({
			pathname: location.pathname,
			search: `?page=1`,
		});
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<p style={{ textTransform: 'uppercase' }}>{item.pair_code.replace('_', '/')}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Spread % Bot</p>
				<div
					style={{
						display: inputEdit.toggleInputActions ? 'flex' : 'none',
						justifyContent: 'space-benween',
						alignItems: 'center',
					}}
				>
					<p>
						<input
							type="number"
							name="spread"
							value={inputEdit.spread}
							onChange={(e) => {
								editValue(e, false);
							}}
						/>
					</p>
					<div className="title-block_checkbox__box">
						<input
							type="checkbox"
							className="switch"
							name="enabled_skip_users_from_spread"
							checked={inputEdit.enabled_skip_users_from_spread}
							onChange={(e) => editValue(e, true)}
						/>
					</div>
				</div>
				<p style={inputEdit.toggleInputActions ? { display: 'none' } : { display: 'flex' }}>
					{item.spread}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Matching spread</p>
				<p style={inputEdit.toggleInputActions ? { display: 'flex' } : { display: 'none' }}>
					<input
						type="number"
						name="matching_spread"
						value={inputEdit.matching_spread}
						onChange={(e) => {
							editValue(e, false);
						}}
					/>
				</p>
				<p style={inputEdit.toggleInputActions ? { display: 'none' } : { display: 'flex' }}>
					{item.matching_spread}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">External price</p>
				<p style={{ display: 'flex' }}>{item.external_price}</p>
			</div>
			<div className="td">
				<div className="td-hidden-name">Count Orders</div>
				<p>{item?.orders_count?.bot_open_orders_count}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Frequency / Timing (sec)</p>
				<p style={inputEdit.toggleInputActions ? { display: 'flex' } : { display: 'none' }}>
					<input
						type="number"
						name="frequency"
						value={inputEdit.frequency}
						onChange={(e) => {
							editValue(e, false);
						}}
					/>
				</p>
				<p style={inputEdit.toggleInputActions ? { display: 'none' } : { display: 'flex' }}>
					{item.frequency}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Limit Orders</p>
				<p style={inputEdit.toggleInputActions ? { display: 'none' } : { display: 'flex' }}>
					{item.limit_orders_enabled ? 'on' : 'off'}
				</p>
				<div
					style={inputEdit.toggleInputActions ? { display: 'flex' } : { display: 'none' }}
					className="title-block_checkbox__box"
				>
					<input
						onChange={(e) => {
							editValue(e, true);
						}}
						name="limit_orders_enabled"
						id="s1"
						type="checkbox"
						className="switch"
						checked={inputEdit.limit_orders_enabled}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Market Orders</p>
				<p style={inputEdit.toggleInputActions ? { display: 'none' } : { display: 'flex' }}>
					{item.market_orders_enabled ? 'on' : 'off'}
				</p>
				<div
					style={inputEdit.toggleInputActions ? { display: 'flex' } : { display: 'none' }}
					className="title-block_checkbox__box"
				>
					<input
						onChange={(e) => {
							editValue(e, true);
						}}
						name="market_orders_enabled"
						id="s1"
						type="checkbox"
						className="switch"
						checked={inputEdit.market_orders_enabled}
					/>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					<button
						type="button"
						disabled={!checkEditable(permissions.SET_LIMITS)}
						onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
					>
						<span className="trash-icon trash-icon--action icon-trash-icon" />
					</button>

					<button
						style={inputEdit.toggleInputActions ? { display: 'none' } : { display: 'flex' }}
						disabled={!checkEditable(permissions.SET_LIMITS)}
						onClick={() => {
							inputEditChange({
								...inputEdit,
								limit_orders_enabled: item.limit_orders_enabled,
								market_orders_enabled: item.market_orders_enabled,
								spread: item.spread,
								frequency: item.frequency,
								toggleInputActions: !inputEdit.toggleInputActions,
							});
						}}
						type="submit"
					>
						<span className="edit-icon icon-edit-icon" />
					</button>
					<div
						style={inputEdit.toggleInputActions ? { display: 'flex' } : { display: 'none' }}
						className="table-buttons--block"
					>
						<button
							onClick={() => {
								inputEditChange({
									asset_pair_id: item.asset_pair_id,
									limit_orders_enabled: false,
									market_orders_enabled: false,
									spread: 0,
									matching_spread: 0,
									frequency: 0,
									toggleInputActions: !inputEdit.toggleInputActions,
									enabled_skip_users_from_spread: item.enabled_skip_users_from_spread === 1,
								});
							}}
							type="submit"
						>
							<span className="edit-icon icon-cancel-icon" />
						</button>
						<button
							onClick={() => {
								inputEditChange({
									...inputEdit,
									toggleInputActions: !inputEdit.toggleInputActions,
								});
								editSettings({
									...inputEdit,
									enabled_skip_users_from_spread: inputEdit.enabled_skip_users_from_spread ? 1 : 0,
								});
							}}
							type="button"
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<DeleteAdminModal
				openModal={openModal}
				closeModal={closeModal}
				handleDeleteAdmin={handleDeleteAdmin}
				pair={item.pair_code.replace('_', '/')}
				disabled={disabled}
			/>
		</div>
	);
};

export default MarketMakerBotItem;
