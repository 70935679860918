/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersList } from 'redux/reducers/userManagement/selectors';
import { getMarketMaker } from 'redux/reducers/marketMakerBot/selectors';
import { getUsersRequest } from 'redux/reducers/userManagement/reducer';
import {
	getMarketMakerRequest,
	putMarketMakerBotToggleRequest,
	putMarketMakerBotEditSettingsRequest,
} from 'redux/reducers/marketMakerBot/reducer';
import { IUsersListRequestPayload } from 'redux/reducers/userManagement/types';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import MarketMakerBotList from './MarketMakerBotList';
// ==========================================:
const MarketMakerRobot: FC = () => {
	const marketMaker = useSelector(getMarketMaker);
	const dispatch = useDispatch();
	const editSettings = (newObg: any) => {
		dispatch(putMarketMakerBotEditSettingsRequest(newObg));
	};
	const { checkEditable } = usePermission();
	// ==========================================:
	const usersList = useSelector(getUsersList);
	const history = useHistory();
	const location = useLocation();
	const searchFieldArray = ['user_id', 'email'];
	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(field ? String(field) : searchFieldArray[0]);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [spotMarketBoolean, setSpotMarketBoolean] = useState<boolean>(false);
	useEffect(() => {
		dispatch(getMarketMakerRequest({ current_page: 1 }));
	}, []);
	useEffect(() => {
		dispatch(getMarketMakerRequest({ current_page: currentPage }));
	}, [marketMaker.render]);
	useEffect(() => {
		setSpotMarketBoolean(marketMaker.global_enabled === null ? false : marketMaker.global_enabled);
	}, [marketMaker]);
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';

		if (text) {
			verifyText = String(text).trim();
		}
		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: `?page=${String(pageNumber)}`;

		const params: IUsersListRequestPayload =
			verifyText.length > 0
				? {
						current_page: pageNumber || 1,
						search_field: searchField,
						search_value: verifyText,
				  }
				: {
						current_page: pageNumber || 1,
				  };
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getMarketMakerRequest(params));
	};

	const handleSearch = (txt: string) => {
		const params = {
			current_page: 1,
			search_value: txt,
			search_field: searchField,
		};
		if (txt.length >= 1) {
			dispatch(getUsersRequest(params));
		} else {
			dispatch(getUsersRequest({ current_page: 1 }));
		}
		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1
					? `?page=${String(page || 1)}&text=${String(txt)}&field=${searchField}`
					: `?page=${String(1)}`,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const searchFieldHandler = (txt: string) => {
		setSearchField(txt);
	};
	return (
		<>
			<div className="title-block">
				<p className="title">Internal</p>
			</div>

			<div className="count-label--user-management">
				<div className="title-block_checkbox">
					<p>Spot Market Disable/Enable</p>
					<div className="title-block_checkbox__box">
						<input
							onChange={(e) => {
								dispatch(putMarketMakerBotToggleRequest());
								setSpotMarketBoolean(!spotMarketBoolean);
							}}
							disabled={!checkEditable(permissions.MANAGE_MARKET_MAKER)}
							id="s1"
							type="checkbox"
							className="switch"
							checked={spotMarketBoolean}
						/>
					</div>
				</div>
			</div>

			<MarketMakerBotList
				key={marketMaker.pairs_settings?.asset_pair_id}
				pairsSettings={marketMaker}
				handleClearSearch={handleClearSearch}
				editSettings={editSettings}
			/>
			{(usersList?.total ?? 0) > 1 && (
				<Pagination
					pageCount={marketMaker?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default MarketMakerRobot;
