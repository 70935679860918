import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IPairsSettingsStore } from './types';

const getMarketMakerState = (state: IStoreState): IPairsSettingsStore => state.marketMakerBot;

export const getMarketMaker = createSelector(
	[getMarketMakerState],
	(marketMaker: IPairsSettingsStore) => marketMaker,
);
export const getMarketMakerList = createSelector(
	[getMarketMakerState],
	(marketMaker: IPairsSettingsStore) => marketMaker,
);
