/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { CryptoDeposit } from './TransactionHistoryBox/CryptoDeposit/CryptoDeposit';
import { CryptoWithdrawal } from './TransactionHistoryBox/CryptoWithdrawal/CryptoWithdrawal';
import { FiatDeposit } from './TransactionHistoryBox/FiatDeposit/FiatDeposit';
import { FiatWithdrawal } from './TransactionHistoryBox/FiatWithdrawal/FiatWithdrawal';
import { Converts } from './TransactionHistoryBox/Converts/Converts';
import { Transfers } from './TransactionHistoryBox/Transfers/Transfers';
import { Redemption } from './TransactionHistoryBox/Redemption/Redemption';

export const TransactionHistory: FC = () => {
	const [activeMenu, setActiveMenu] = useState('Crypto deposit');
	const [radioCheck, setRadioCheck] = useState('Cross');
	let className = '';
	if (activeMenu === 'Crypto deposit') {
		className = 'table--transactionHistory-crypto_deposit';
	} else if (activeMenu === 'Crypto withdraw') {
		className = 'table--transactionHistory-crypto_withdraw';
	} else if (activeMenu === 'Fiat deposit') {
		className = 'table--history-deposit-fiat table--with-scroll';
	} else if (activeMenu === 'Fiat withdrawal') {
		className = 'table--transactionHistory-fiat_withdrawal';
	} else if (activeMenu === 'Converts') {
		className = 'table--transactionHistory_converts';
	} else if (activeMenu === 'Transfers') {
		className = 'table--transactionHistory_transfers';
	} else if (activeMenu === 'Redemption') {
		className = 'table--transaction-history-redemption';
	}
	const arrayMenu: { id: number; name: string; permission?: string }[] = [
		{
			id: 1,
			name: 'Crypto deposit',
		},
		{
			id: 2,
			name: 'Crypto withdraw',
		},
		{
			id: 3,
			name: 'Fiat deposit',
		},
		{
			id: 4,
			name: 'Fiat withdrawal',
		},
		{
			id: 5,
			name: 'Converts',
		},
		{
			id: 6,
			name: 'Transfers',
			permission: permissions.MANAGE_CRYPTO_TRANSFERS,
		},
		{
			id: 7,
			name: 'Redemption',
			permission: permissions.MANAGE_REDEMPTIONS,
		},
	];

	const { checkReadable } = usePermission();

	const menuTop = () => {
		return arrayMenu
			.map((e) => {
				const el = (
					<li
						key={e.id}
						className={`table-panel-tab__item ${activeMenu === e.name ? 'active' : ''}`}
						onClick={() => setActiveMenu(e.name)}
					>
						<span className="table-panel-tab__link">{e.name}</span>
					</li>
				);
				if (e.permission) {
					if (checkReadable(e.permission)) {
						return el;
					}
					return null;
				}
				return el;
			})
			.filter((item) => item);
	};

	const menuContent = () => {
		switch (activeMenu) {
			case 'Crypto deposit':
				return <CryptoDeposit />;
			case 'Crypto withdraw':
				return <CryptoWithdrawal />;
			case 'Fiat deposit':
				return <FiatDeposit />;
			case 'Fiat withdrawal':
				return <FiatWithdrawal />;
			case 'Converts':
				return <Converts />;
			case 'Transfers':
				return permissions.MANAGE_CRYPTO_TRANSFERS ? <Transfers /> : null;
			case 'Redemption':
				return permissions.MANAGE_REDEMPTIONS ? <Redemption /> : null;
			default:
				null;
		}
		return null;
	};
	return (
		<div className="margin">
			<div className="content-block content-block--user-management">
				<div>
					<div className="content-block__inside content-block__inside--panel-only">
						<div className="table-panel">
							<ul className="table-panel-tab">{menuTop()}</ul>
						</div>
					</div>
				</div>
				<div className="content-block__inside content-block__inside--login-his">
					<div className="table-block table-block--ip">
						<div className="table-wrapper">
							<div className={`table ${className}`}>{menuContent()}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
