import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IUnidentifiedDepositsStore, IUserItem } from './types';

// ==========================================:
const getUnidentDepositsState = (state: IStoreState): IUnidentifiedDepositsStore =>
	state.unidentifiedDeposits;

export const getUnidentDepositsList = createSelector(
	[getUnidentDepositsState],
	(store: IUnidentifiedDepositsStore) => store.depositsList,
);
// ====================================================:
export const getUnidentDepositsLoader = createSelector(
	[getUnidentDepositsState],
	(store: IUnidentifiedDepositsStore): boolean => store.depositsListLoader,
);

// ====================================================:
export const getSearchUsers = createSelector(
	[getUnidentDepositsState],
	(store: IUnidentifiedDepositsStore): IUserItem[] | null => store.users,
);

// ====================================================:
export const getSearchUsersLoader = createSelector(
	[getUnidentDepositsState],
	(store: IUnidentifiedDepositsStore): boolean => store.usersLoading,
);

// ====================================================:
export const getFilterParams = createSelector(
	[getUnidentDepositsState],
	(store: IUnidentifiedDepositsStore): any => store.filterParams,
);
