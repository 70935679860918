import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	getPendingDepositsRequest,
	getPendingDepositsSuccess,
	getPendingDepositsExportSuccess,
	getPendingDepositsError,
	approveDepositRequest,
	approveDepositSuccess,
	approveAllDepositsRequest,
	approveAllDepositsSuccess,
	rejectDepositRequest,
	rejectDepositSuccess,
	rejectAllDepositsRequest,
	rejectAllDepositsSuccess,
} from './reducer';
import {
	IGetPendingDepositsRequest,
	IPendingDepositsItem,
	IApproveRequestPayload,
	IRejectRequestPayload,
	IApproveAllRequestPayload,
	IRejectAllRequestPayload,
} from './types';
// =============================================================:

function* getPendingDepositsRequestWorker(action: PayloadAction<IGetPendingDepositsRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IPendingDepositsItem[] = yield call(
			api.pendingDeposits.getPendingDeposits,
			payload,
		);
		if (payload.export === 1) {
			yield put(getPendingDepositsExportSuccess());
			notificationContainer('Pening deposits successfully exported, check your mailbox!', 'info');
		} else yield put(getPendingDepositsSuccess(response));
	} catch (error) {
		yield put(getPendingDepositsError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* approveDepositRequestWorker({ payload }: PayloadAction<IApproveRequestPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		yield call(api.pendingDeposits.approve, apiParams);
		yield put(approveDepositSuccess({ id: apiParams.id }));
		onSuccess?.();
		notificationContainer('Deposit successfully approved', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* rejectDepositRequestWorker({ payload }: PayloadAction<IRejectRequestPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		yield call(api.pendingDeposits.reject, apiParams);
		yield put(rejectDepositSuccess({ id: apiParams.id }));
		onSuccess?.();
		notificationContainer('Deposit successfully rejected', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* approveAllDepositsRequestWorker({ payload }: PayloadAction<IApproveAllRequestPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		yield call(api.pendingDeposits.approveAll, apiParams);
		yield put(approveAllDepositsSuccess({ ids: apiParams.ids }));
		onSuccess?.();
		notificationContainer('Deposits successfully approved', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* rejectAllDepositsRequestWorker({ payload }: PayloadAction<IRejectAllRequestPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		yield call(api.pendingDeposits.rejectAll, apiParams);
		yield put(rejectAllDepositsSuccess({ ids: apiParams.ids }));
		onSuccess?.();
		notificationContainer('Deposits successfully rejected', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

export function* pendingDepositsSaga() {
	yield takeEvery(getPendingDepositsRequest.type, getPendingDepositsRequestWorker);
	yield takeEvery(approveDepositRequest.type, approveDepositRequestWorker);
	yield takeEvery(rejectDepositRequest.type, rejectDepositRequestWorker);
	yield takeEvery(approveAllDepositsRequest.type, approveAllDepositsRequestWorker);
	yield takeEvery(rejectAllDepositsRequest.type, rejectAllDepositsRequestWorker);
}
