/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { getWalletsListRequest } from 'redux/reducers/walletManagement/reducer';
import SearchBar from 'components/SearchBar';

const ExternalWalletSearch: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const { page, field } = queryString.parse(search);

	const searchExternalWalletFieldArray = ['coin', 'address'];
	const [searchExternalWalletField, setSearchExternalWalletField] = useState(
		field ? String(field) : searchExternalWalletFieldArray.join('/'),
	);

	const handleSearchExternalWallet = (txt: string) => {
		const params = {
			current_page: 1,
			search_value: txt,
		};

		if (txt.length >= 1) {
			dispatch(getWalletsListRequest(params));
		} else {
			dispatch(getWalletsListRequest({}));
		}
		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1
					? `?page=${String(page || 1)}&text=${String(txt)}&field=${searchExternalWalletField}`
					: `?page=${String(1)}`,
		});
	};

	const [externalWalletTerm, setExternalWalletTerm] = useState('');
	const [externalWalletTermDebouncedTerm, setExternalWalletTermDebouncedTerm] =
		useState(externalWalletTerm);

	useEffect(() => {
		const timer = setTimeout(() => setExternalWalletTerm(externalWalletTermDebouncedTerm), 600);
		return () => clearTimeout(timer);
	}, [externalWalletTermDebouncedTerm]);

	useEffect(() => {
		handleSearchExternalWallet(externalWalletTerm);
	}, [externalWalletTerm, searchExternalWalletField]);

	const handleClearExternalWalletSearch = () => {
		setExternalWalletTerm('');
		setExternalWalletTermDebouncedTerm('');
	};

	const searchExternalWalletFieldHandler = (txt: string) => {
		setSearchExternalWalletField(txt);
	};

	return (
		<SearchBar
			setDebouncedTerm={setExternalWalletTermDebouncedTerm}
			debouncedTerm={externalWalletTermDebouncedTerm}
			searchFieldArray={[]}
			searchField="coin and address"
			setSearchField={searchExternalWalletFieldHandler}
			handleClearSearch={handleClearExternalWalletSearch}
		/>
	);
};

export default ExternalWalletSearch;
