import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import L from 'i18n-react';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { notificationContainer } from 'utils/notificationContainer';
import { api, responseErrors } from 'services';
import { authInitState } from 'redux/reducers/auth/reducer';
import {
	changeUserPassRequest,
	changeUserPassSuccess,
	check2faEnablesRequest,
	check2faEnablesSuccess,
	disable2faRequest,
	disable2faSuccess,
	enable2faRequest,
	enable2faSuccess,
	generate2faKeyRequest,
	generate2faKeySuccess,
	settingsInitState,
	getConvertSettingsRequest,
	getConvertSettingsSuccess,
	putConvertLiquidityRequest,
	getConvertSettingsFailure,
} from './reducer';
import {
	IChangePassPayload,
	ICheck2faEnables,
	IDisable2faPayload,
	IEnable2faPayload,
	IGenerate2faKeyResponse,
	IConvertSettingsData,
} from './types';
import { getConvertSettings } from './selectors';

// =============================================================:
function* changeUserPassWorker(action: PayloadAction<IChangePassPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.changePassword, payload);
		yield put(changeUserPassSuccess());
		yield put(authInitState());

		notificationContainer('Your password has been successfully changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(settingsInitState());
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* generate2faKeyWorker() {
	try {
		yield put(showLoading());
		const response: IGenerate2faKeyResponse = yield call(api.settings.generate2faKey);
		yield put(generate2faKeySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(settingsInitState());
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* check2faEnablesWorker() {
	try {
		yield put(showLoading());
		const response: ICheck2faEnables = yield call(api.settings.check2faEnables);
		yield put(check2faEnablesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(settingsInitState());
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* disable2faWorker(action: PayloadAction<IDisable2faPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.disable2fa, payload);
		yield put(disable2faSuccess());
		yield put(check2faEnablesRequest());
		notificationContainer(String(L.translate(`Errors.google_2fa_turned_off`)), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* enable2faWorker(action: PayloadAction<IEnable2faPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.enable2fa, payload);
		yield put(enable2faSuccess());
		yield put(check2faEnablesRequest());
		notificationContainer(String(L.translate(`Errors.google_2fa_activated`)), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* covertSettinsWorker() {
	try {
		yield put(showLoading());
		const response: Array<IConvertSettingsData> = yield call(api.settings.getConvertSettings);
		yield put(getConvertSettingsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* putCovertSettinsWorker(action: PayloadAction<IConvertSettingsData>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IConvertSettingsData = yield call(api.settings.putConvertSettings, payload);
		const settings: Array<IConvertSettingsData> = yield select(getConvertSettings);

		const settingToChange = settings.find((item) => item.asset_id === response.asset_id);
		const otherSettings = settings.filter((item) => item.asset_id !== response.asset_id);
		const newSettings = [{ ...settingToChange, spread: response.spread }, ...otherSettings];

		yield put(getConvertSettingsSuccess(newSettings));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors?.spread[0] === 'the_spread_must_not_be_greater_than_100') {
				notificationContainer('The spread must not be greater than 100', 'error');
				yield put(getConvertSettingsFailure());
				return;
			}
			if (error?.response?.data?.errors?.spread[0] === 'the_spread_must_be_at_least_0') {
				notificationContainer('The spread must be at least 0', 'error');
				yield put(getConvertSettingsFailure());
				return;
			}
			responseErrors(error);
			yield put(getConvertSettingsFailure());
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* settingsSaga() {
	yield takeEvery(changeUserPassRequest.type, changeUserPassWorker);
	yield takeEvery(generate2faKeyRequest.type, generate2faKeyWorker);
	yield takeEvery(enable2faRequest.type, enable2faWorker);
	yield takeEvery(check2faEnablesRequest.type, check2faEnablesWorker);
	yield takeEvery(disable2faRequest.type, disable2faWorker);
	yield takeEvery(getConvertSettingsRequest.type, covertSettinsWorker);
	yield takeEvery(putConvertLiquidityRequest.type, putCovertSettinsWorker);
}
