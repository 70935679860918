import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IStakingStore, IStakingInfo, IGetStakeTransactionsResponse } from './types';

const getStakingState = (state: IStoreState): IStakingStore => state.staking;
export const getReferral = createSelector([getStakingState], (staking: IStakingStore) => staking);

export const getStakeInfoLoading = createSelector(
	[getReferral],
	(staking: IStakingStore): boolean => staking.infoLoading,
);

export const getStakingInfo = createSelector(
	[getReferral],
	(staking: IStakingStore): IStakingInfo | null => staking.info,
);

export const getStakingTransactionLoading = createSelector(
	[getReferral],
	(staking: IStakingStore): boolean => staking.transactionLoading,
);

export const getStakingTransactions = createSelector(
	[getReferral],
	(staking: IStakingStore): IGetStakeTransactionsResponse | null => staking.transactions,
);

export const getStakeLoading = createSelector(
	[getReferral],
	(staking: IStakingStore): boolean => staking.stakeLoading,
);
