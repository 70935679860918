import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ITranslatesApi } from './types';

// ==========================================:
export const translates: ITranslatesApi = {
	getTranslates: (params) =>
		http.get(endpoint.translates.PAGE_CONTENT, { params }).then((response) => response.data),
	updateTranslates: (params) =>
		http.put(endpoint.translates.PAGE_CONTENT, params).then((response) => response.data),
};
