/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
	getFiatTransactionsIsLoad,
	getFiatTransactionsList,
	getWithdrawalsFiatTransactionsIsLoad,
	getWithdrawalsFiatTransactionsList,
} from 'redux/reducers/transactions/selectors';
import Loader from 'ui/Loader';
import { Item } from './Item';

export const Fiat: FC = () => {
	const deposit = useSelector(getWithdrawalsFiatTransactionsList);
	const depositLoader = useSelector(getWithdrawalsFiatTransactionsIsLoad);

	return (
		<>
			{(deposit?.data?.length ?? 0) < 1 && !depositLoader && (
				<div className="user-management-empty-data">
					<p className="user-management-empty__text">Deposit not found...</p>
				</div>
			)}
			{depositLoader && (
				<div className="list-loader">
					<Loader />
				</div>
			)}

			{(deposit?.data?.length ?? 0) > 0 && !depositLoader && (
				<div className="table-block">
					<div className="table-wrapper">
						<div className="table table--transaction-withdrawal-fiat">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>ID</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>User ID</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p className="status-arrow status-arrow--flex-start">
												<span className="status-arrow__text">Date</span>
												{/* <button type="button" className="status-arrow__button" /> */}
											</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p className="status-arrow status-arrow--flex-start">
												<span className="status-arrow__text">Currency</span>
												{/* <button type="button" className="status-arrow__button" /> */}
											</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p className="status-arrow status-arrow--flex-start">
												<span className="status-arrow__text">Payment Method</span>
												{/* <button type="button" className="status-arrow__button" /> */}
											</p>
										</div>
									</div>

									<div className="td">
										<div className="td-name">
											<p className="status-arrow status-arrow--flex-start">
												<span className="status-arrow__text">Transaction type</span>
												{/* <button type="button" className="status-arrow__button" /> */}
											</p>
										</div>
									</div>

									<div className="td">
										<div className="td-name">
											<p className="status-arrow status-arrow--flex-start">
												<span className="status-arrow__text">IBAN</span>
												{/* <button type="button" className="status-arrow__button" /> */}
											</p>
										</div>
									</div>

									<div className="td td--right">
										<div className="td-name">
											<p>Indicated Amount</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Fee</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Amount</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p className="status-arrow">
												<span className="status-arrow__text">Status</span>
												{/* <button type="button" className="status-arrow__button" /> */}
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="table-body">
								{deposit?.data.map((item: any) => (
									<Item data={item} key={item.id} />
								))}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
