import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import TwoFA from 'components/TwoFA';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const TwoFAPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.MANAGE_2FA_RESETS}>
			<Dashboard title="2FA">
				<TwoFA />
			</Dashboard>
		</PermissionGate>
	);
};

export default TwoFAPage;
