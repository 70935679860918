/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	I2FAListRequestPayload,
	I2FARejectPayload,
	I2FAResetPayload,
	I2FAResponsePayload,
	IDepositAndWithdrawHistoryRequestData,
	IDepositAndWithdrawHistoryResponsePayload,
	ILoginHistoryRequestData,
	ILoginHistoryResponsePayload,
	ISelectUserResponsePayload,
	IUserBalancesRequestData,
	IUserBalancesResponsePayload,
	IUserBlockUnblockRequestData,
	IUserBlockUnblockResponsePayload,
	IUserItem,
	IUserOrdersHistoryMarginRequestData,
	IUserOrdersHistoryRequestData,
	IUserRequestPayload,
	IUsersListRequestPayload,
	IUsersResponsePayload,
	IUsersStore,
	IUserUpdateVipStatusRequestData,
	IWhitelistIpListResponsePayload,
	IWhitelistIpRemoveRequestData,
	IWhitelistIpRemoveResponsePayload,
	IWhitelistIpRequestData,
	IWhitelistIpVerifyRequestData,
	IWhitelistIpVerifyResponsePayload,
	TOneUserDateRange,
	IUserCardsRequest,
	IUserBankAccountRequest,
	IUserCardsResponse,
	IUserBankAccountResponse,
	IFilterParams,
	TBalancesP2PResponse,
	IReferralUpdateParams,
	IUpdateBankAccountStatusPayload,
	IUserBankAccountItem,
	IEmail2FARequest,
	IEmail2Fa,
	ISms2Fa,
} from './types';
import { InterestTableHeader } from '../../../components/UserManagement/SelectUser/Margin/MarginBox/Interest/InterestTableHeader';
import Transfers from '../../../components/UserManagement/SelectUser/TransactionHistory/TransactionHistoryBox/Transfers/TransfersTableItem';

// ==========================================:
export const initialState: IUsersStore = {
	tabMenuUserIndex: null,
	users: null,
	usersLoader: false,
	user: null,
	userLoader: false,
	selectUser: null,
	oneUserDateRange: null,

	userBalances: null,
	userBalancesLoader: false,
	userOrdersHistory: null,
	userOrdersHistoryLoader: false,
	userOrdersHistoryMargin: null,
	userOrdersHistoryMarginLoader: false,
	whitelistIp: null,
	whitelistIpLoader: false,
	whitelistIpRemove: null,
	whitelistIpRemoveLoader: false,
	whitelistIpVerify: null,
	whitelistIpVerifyLoader: false,
	id: 0,
	blocked: '0',
	userBlockUnblockLoader: false,
	twoFA: null,
	twoFALoader: false,
	twoFAResetLoader: false,
	twoFARejectLoader: false,
	loginHistory: null,
	loginHistoryLoader: false,
	depositAndWithdrawHistory: null,
	depositAndWithdrawHistoryLoader: false,
	userBorrowingLoader: false,
	userBorrowingMargin: null,
	userRepaymentLoader: false,
	userRepaymentMargin: null,
	userInterestLoader: false,
	userInterestMargin: null,
	userLiquidationLoader: false,
	userLiquidationMargin: null,
	userBalancesMarginLoader: false,
	userBalancesMargin: null,
	userMarginCallMarginLoader: false,
	userMarginCallMargin: null,
	userAdvertisementsP2PLoader: false,
	userAdvertisementsP2P: null,
	userDisputeP2PLoader: false,
	userDisputeP2P: null,
	userTradesP2PLoader: false,
	userTradesP2P: null,
	userBalancesP2P: null,
	userBalancesP2PLoader: false,
	userCryptoDepositLoader: false,
	userCryptoDeposit: null,
	userFiatDepositLoader: false,
	userFiatDeposit: null,
	userConvertsLoader: false,
	userConverts: null,
	userTransfersLoader: false,
	userTransfers: null,
	userRedemptionsLoader: false,
	userRedemptions: null,
	userCardsLoader: false,
	userCardsPayment: null,
	userBankAccountPayment: null,
	userBankAccountLoader: false,
	updateBankAccountStateLoader: false,
	email2FA: null,
	email2FALoading: false,
	sms2FA: null,
	sms2FALoading: false,
	filterParams: null,
};
// ==========================================:
const users = createSlice({
	name: '@@users',
	initialState,
	reducers: {
		setTabMenuUserIndex: (state, action) => {
			const usersState = state;
			const { payload } = action;
			usersState.tabMenuUserIndex = payload;
		},
		getUsersRequest: (state, { payload }: PayloadAction<IUsersListRequestPayload>) => {
			const usersRequestState = state;
			if (payload.export !== 1) {
				usersRequestState.usersLoader = true;
			}
		},
		getUsersSuccess: (state, action: PayloadAction<IUsersResponsePayload>) => {
			const { payload } = action;
			const usersSuccessState = state;
			usersSuccessState.users = payload;
			usersSuccessState.usersLoader = false;
		},
		setUsers: (state, action: PayloadAction<IUsersResponsePayload | null>) => {
			const { payload } = action;
			const usersSuccessState = state;
			usersSuccessState.users = payload;
		},
		updateUsersSuccess: (state, action: PayloadAction<IUsersResponsePayload>) => {
			const { payload } = action;
			const usersUpdateState = state;
			usersUpdateState.users = payload;
			usersUpdateState.usersLoader = false;
		},

		getUserRequest: (state, action: PayloadAction<IUserRequestPayload>) => {
			const userRequestState = state;
			userRequestState.userLoader = true;
		},
		getUserSuccess: (state, action: PayloadAction<ISelectUserResponsePayload>) => {
			const { payload } = action;
			const userSuccessState = state;
			userSuccessState.user = payload;
			userSuccessState.selectUser = payload;
			userSuccessState.userLoader = false;
		},

		setOneUserDateRange: (state, { payload }: PayloadAction<TOneUserDateRange>) => {
			state.oneUserDateRange = payload;
		},
		unsetOneUserDateRange: (state) => {
			state.oneUserDateRange = null;
		},

		getUserBalancesRequest: (state, action: PayloadAction<IUserBalancesRequestData>) => {
			const userBalancesRequestState = state;
			userBalancesRequestState.userBalancesLoader = true;
			const userSelect = state;
		},

		getUserBalancesSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userBalancesSuccessState = state;

			userBalancesSuccessState.userBalances = payload;
			userBalancesSuccessState.userBalancesLoader = false;
		},

		getWhitelistIpRequest: (state, action: PayloadAction<IWhitelistIpRequestData>) => {
			const whitelistIpRequestState = state;
			whitelistIpRequestState.whitelistIpLoader = true;
			const whitelistIp = state;
		},

		getWhitelistIpSuccess: (state, action: PayloadAction<IWhitelistIpListResponsePayload>) => {
			const { payload } = action;
			const whitelistIpSuccessState = state;

			whitelistIpSuccessState.whitelistIp = payload;
			whitelistIpSuccessState.whitelistIpLoader = false;
		},

		removeWhitelistIpRequest: (state, action: PayloadAction<IWhitelistIpRemoveRequestData>) => {
			const whitelistIpRequestRemoveState = state;
			whitelistIpRequestRemoveState.whitelistIpRemoveLoader = true;
		},

		removeWhitelistIpSuccess: (state, action: PayloadAction<IWhitelistIpRemoveResponsePayload>) => {
			const { payload } = action;
			const whitelistIpRemoveSuccessState = state;

			whitelistIpRemoveSuccessState.whitelistIpRemove = payload;
			whitelistIpRemoveSuccessState.whitelistIpRemoveLoader = false;
		},

		verifyWhitelistIpRequest: (state, action: PayloadAction<IWhitelistIpVerifyRequestData>) => {
			const whitelistIpRequestRemoveState = state;
			whitelistIpRequestRemoveState.whitelistIpRemoveLoader = true;
		},

		verifyWhitelistIpSuccess: (state, action: PayloadAction<IWhitelistIpVerifyResponsePayload>) => {
			const { payload } = action;
			const whitelistIpVerifySuccessState = state;

			whitelistIpVerifySuccessState.whitelistIpVerify = payload;
			whitelistIpVerifySuccessState.whitelistIpVerifyLoader = false;
		},

		setUserBlockUnblockRequest: (state, action: PayloadAction<IUserBlockUnblockRequestData>) => {
			const { payload } = action;
			const userBlockUnblockRequestState = state;
			userBlockUnblockRequestState.userBlockUnblockLoader = true;
		},

		setUserBlockUnblockSuccess: (
			state,
			action: PayloadAction<IUserBlockUnblockResponsePayload>,
		) => {
			const { payload } = action;
			const userBlockUnblockSuccessState = state;
			userBlockUnblockSuccessState.userBlockUnblockLoader = false;
		},

		setUserUpdateVipStatusRequest: (
			state,
			action: PayloadAction<IUserUpdateVipStatusRequestData>,
		) => {
			const userUpdateVipStatusSuccessState = state;
			userUpdateVipStatusSuccessState.userLoader = true;
		},

		setUserUpdateVipStatusSuccess: (state, { payload }: PayloadAction<IUserItem>) => {
			const userUpdateVipStatusSuccessState = state;

			userUpdateVipStatusSuccessState.selectUser = payload;
			userUpdateVipStatusSuccessState.userLoader = false;
		},

		get2FARequest: (state, action: PayloadAction<I2FAListRequestPayload>) => {
			const twoFARequestState = state;
			twoFARequestState.twoFALoader = true;
		},
		get2FASuccess: (state, action: PayloadAction<I2FAResponsePayload>) => {
			const { payload } = action;
			const twoFASuccessState = state;
			twoFASuccessState.twoFA = payload;
			twoFASuccessState.twoFALoader = false;
		},
		reset2FA: (state, action: PayloadAction<I2FAResetPayload>) => {
			const { payload } = action;
			const twoFAResetState = state;
			twoFAResetState.twoFAResetLoader = true;
		},
		reset2FASuccess: (state) => {
			const twoFAResetSuccessState = state;
			twoFAResetSuccessState.twoFAResetLoader = false;
		},
		reject2FA: (state, action: PayloadAction<I2FARejectPayload>) => {
			const { payload } = action;
			const twoFARejectState = state;
			twoFARejectState.twoFARejectLoader = true;
		},
		reject2FASuccess: (state) => {
			const twoFAResetSuccessState = state;
			twoFAResetSuccessState.twoFARejectLoader = false;
		},

		getLoginHistoryRequest: (state, action: PayloadAction<ILoginHistoryRequestData>) => {
			const loginHistoryRequestState = state;
			loginHistoryRequestState.loginHistoryLoader = true;
			const loginHistory = state;
		},

		getLoginHistorySuccess: (state, action: PayloadAction<ILoginHistoryResponsePayload>) => {
			const { payload } = action;
			const loginHistorySuccessState = state;

			loginHistorySuccessState.loginHistory = payload;
			loginHistorySuccessState.loginHistoryLoader = false;
		},

		getDepositAndWithdrawHistoryRequest: (
			state,
			action: PayloadAction<IDepositAndWithdrawHistoryRequestData>,
		) => {
			const depositAndWithdrawHistoryRequestState = state;
			depositAndWithdrawHistoryRequestState.depositAndWithdrawHistoryLoader = true;
		},

		getDepositAndWithdrawHistorySuccess: (
			state,
			action: PayloadAction<IDepositAndWithdrawHistoryResponsePayload>,
		) => {
			const { payload } = action;
			const depositAndWithdrawHistorySuccessState = state;

			depositAndWithdrawHistorySuccessState.depositAndWithdrawHistory = payload;
			depositAndWithdrawHistorySuccessState.depositAndWithdrawHistoryLoader = false;
		},
		getUserOrdersHistoryRequest: (state, action: PayloadAction<IUserOrdersHistoryRequestData>) => {
			const userBalancesRequestState = state;
			userBalancesRequestState.userOrdersHistoryLoader = true;
		},

		getUserOrdersHistorySuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userOrdersHistoryState = state;

			userOrdersHistoryState.userOrdersHistory = payload;
			userOrdersHistoryState.userOrdersHistoryLoader = false;
		},
		getUserMarginOrdersHistoryRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userOrdersMarginRequestState = state;
			userOrdersMarginRequestState.userOrdersHistoryMarginLoader = true;
		},

		getUserMarginOrdersHistorySuccess: (
			state,
			action: PayloadAction<IUserBalancesResponsePayload>,
		) => {
			const { payload } = action;
			const userOrdersHistoryMarginState = state;

			userOrdersHistoryMarginState.userOrdersHistoryMargin = payload;
			userOrdersHistoryMarginState.userOrdersHistoryMarginLoader = false;
		},
		getUserMarginBorrowingRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userMarginBorrowingRequestState = state;
			userMarginBorrowingRequestState.userBorrowingLoader = true;
		},
		getUserMarginBorrowingSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userMarginBorrowingRequestState = state;
			userMarginBorrowingRequestState.userBorrowingMargin = payload;
			userMarginBorrowingRequestState.userBorrowingLoader = false;
		},
		getUserMarginRepaymentRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userMarginRepaymentRequestState = state;
			userMarginRepaymentRequestState.userRepaymentLoader = true;
		},
		getUserMarginRepaymentSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userMarginRepaymentRequestState = state;
			userMarginRepaymentRequestState.userRepaymentMargin = payload;
			userMarginRepaymentRequestState.userRepaymentLoader = false;
		},
		getUserMarginInterestRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userMarginInterestRequestState = state;
			userMarginInterestRequestState.userInterestLoader = true;
		},
		getUserMarginInterestSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userMarginInterestRequestState = state;
			userMarginInterestRequestState.userInterestMargin = payload;
			userMarginInterestRequestState.userInterestLoader = false;
		},
		getUserMarginLiquidationRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userMarginLiquidationRequestState = state;
			userMarginLiquidationRequestState.userLiquidationLoader = true;
		},
		getUserMarginLiquidationSuccess: (
			state,
			action: PayloadAction<IUserBalancesResponsePayload>,
		) => {
			const { payload } = action;
			const userMarginLiquidationRequestState = state;
			userMarginLiquidationRequestState.userLiquidationMargin = payload;
			userMarginLiquidationRequestState.userLiquidationLoader = false;
		},
		getUserMarginBalancesRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userMarginBalancesRequestState = state;
			userMarginBalancesRequestState.userBalancesMarginLoader = true;
		},
		getUserMarginBalancesSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userMarginBalancesRequestState = state;
			userMarginBalancesRequestState.userBalancesMargin = payload;
			userMarginBalancesRequestState.userBalancesMarginLoader = false;
		},
		getUserMarginMarginCallRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userMarginMarginCallRequestState = state;
			userMarginMarginCallRequestState.userMarginCallMarginLoader = true;
		},
		getUserMarginMarginCallSuccess: (
			state,
			action: PayloadAction<IUserBalancesResponsePayload>,
		) => {
			const { payload } = action;
			const userMarginMarginCallRequestState = state;
			userMarginMarginCallRequestState.userMarginCallMargin = payload;
			userMarginMarginCallRequestState.userMarginCallMarginLoader = false;
		},
		getUserAdvertisementsP2PRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userAdvertisementsRequestState = state;
			userAdvertisementsRequestState.userAdvertisementsP2PLoader = true;
		},
		getUserAdvertisementsP2PSuccess: (
			state,
			action: PayloadAction<IUserBalancesResponsePayload>,
		) => {
			const { payload } = action;
			const userAdvertisementsRequestState = state;
			userAdvertisementsRequestState.userAdvertisementsP2P = payload;
			userAdvertisementsRequestState.userAdvertisementsP2PLoader = false;
		},
		getUserDisputeP2PRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userDisputeRequestState = state;
			userDisputeRequestState.userDisputeP2PLoader = true;
		},
		getUserDisputeP2PSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userDisputeRequestState = state;
			userDisputeRequestState.userDisputeP2P = payload;
			userDisputeRequestState.userDisputeP2PLoader = false;
		},
		getUserTradesP2PRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userTradeRequestState = state;
			userTradeRequestState.userTradesP2PLoader = true;
		},
		getUserTradesP2PSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userTradesRequestState = state;
			userTradesRequestState.userTradesP2P = payload;
			userTradesRequestState.userTradesP2PLoader = false;
		},
		getUserBalancesP2PRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userBalancesRequestState = state;
			userBalancesRequestState.userBalancesP2PLoader = true;
		},
		getUserBalancesP2PSuccess: (state, action: PayloadAction<TBalancesP2PResponse>) => {
			const { payload } = action;
			const userTradesRequestState = state;
			userTradesRequestState.userBalancesP2P = payload;
			userTradesRequestState.userBalancesP2PLoader = false;
		},
		getUserCryptoDepositRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userCryptoDepositState = state;
			userCryptoDepositState.userCryptoDepositLoader = true;
		},
		getUserCryptoDepositSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userCryptoDepositRequestState = state;
			userCryptoDepositRequestState.userCryptoDeposit = payload;
			userCryptoDepositRequestState.userCryptoDepositLoader = false;
		},
		getUserFiatDepositRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userFiatDepositState = state;
			userFiatDepositState.userFiatDepositLoader = true;
		},
		getUserFiatDepositSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userFiatDepositRequestState = state;
			userFiatDepositRequestState.userFiatDeposit = payload;
			userFiatDepositRequestState.userFiatDepositLoader = false;
		},
		getUserConvertsRequest: (state, action: PayloadAction<IUserOrdersHistoryMarginRequestData>) => {
			const userСonvertsState = state;
			userСonvertsState.userConvertsLoader = true;
		},

		getUserConvertsSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userConvertsDepositRequestState = state;
			userConvertsDepositRequestState.userConverts = payload;
			userConvertsDepositRequestState.userConvertsLoader = false;
		},
		getUserTransfersRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userTransfersState = state;
			userTransfersState.userTransfersLoader = true;
		},

		getUserTransfersSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userTransfersDepositRequestState = state;
			userTransfersDepositRequestState.userTransfers = payload;
			userTransfersDepositRequestState.userTransfersLoader = false;
		},
		getUserRedemptionsRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userRedemptionsState = state;
			userRedemptionsState.userRedemptionsLoader = true;
		},

		getUserRedemptionsSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userRedemptionsDepositRequestState = state;
			userRedemptionsDepositRequestState.userRedemptions = payload;
			userRedemptionsDepositRequestState.userRedemptionsLoader = false;
		},
		getUserCardsRequest: (state, action: PayloadAction<IUserCardsRequest>) => {
			const userPaymentState = state;
			userPaymentState.userCardsLoader = true;
		},
		getUserCardsSuccess: (state, action: PayloadAction<IUserCardsResponse>) => {
			const userPaymentState = state;
			userPaymentState.userCardsLoader = false;
			userPaymentState.userCardsPayment = action.payload;
		},
		getUserBankAccountRequest: (state, action: PayloadAction<IUserBankAccountRequest>) => {
			const userPaymentState = state;
			userPaymentState.userBankAccountLoader = true;
		},
		getUserBankAccountSuccess: (state, action: PayloadAction<IUserBankAccountResponse>) => {
			const userPaymentState = state;
			userPaymentState.userBankAccountLoader = false;
			userPaymentState.userBankAccountPayment = action.payload;
		},
		setFilterParams: (state, action: PayloadAction<IFilterParams>) => {
			const { payload } = action;
			const userState = state;
			userState.filterParams = payload;
		},
		updateReferralFeeRequest: (state, action: PayloadAction<IReferralUpdateParams>) => {
			const userState = state;
			userState.usersLoader = true;
		},

		updateReferralFeeSuccess: (state, { payload }: PayloadAction<IReferralUpdateParams>) => {
			const userState = state;
			userState.usersLoader = false;
			if (!userState.users) {
				return;
			}

			userState.users = {
				...userState.users,
				users:
					userState.users.users &&
					userState.users.users?.map((item) => {
						if (String(item.id) === String(payload.id)) {
							return { ...item, data: { ...item.data, referral_fee: payload.fee } };
						}
						return item;
					}),
			};
		},
		updateBankAccountStatusRequest: (
			state,
			action: PayloadAction<IUpdateBankAccountStatusPayload>,
		) => {
			const userState = state;
			userState.updateBankAccountStateLoader = true;
		},
		updateBankAccountStatusSuccess: (state, { payload }: PayloadAction<IUserBankAccountItem>) => {
			const userState = state;
			if (userState.userBankAccountPayment) {
				userState.userBankAccountPayment = userState.userBankAccountPayment?.map((item) => {
					return item.id === payload.id ? payload : item;
				});
			}
			userState.updateBankAccountStateLoader = false;
		},
		updateBankAccountStatusError: (state) => {
			const userState = state;
			userState.updateBankAccountStateLoader = false;
		},
		getEmail2FARequest: (state, { payload }: PayloadAction<IEmail2FARequest>) => {
			const userState = state;
			if (payload.export === 1) {
				return;
			}
			userState.email2FALoading = true;
		},
		getEmail2FASuccess: (state, { payload }: PayloadAction<IEmail2Fa>) => {
			const userState = state;
			userState.email2FALoading = false;
			userState.email2FA = payload;
		},
		getEmail2FAError: (state) => {
			const userState = state;
			userState.email2FALoading = true;
		},
		getSms2FARequest: (state, { payload }: PayloadAction<IEmail2FARequest>) => {
			const userState = state;
			if (payload.export === 1) {
				return;
			}
			userState.sms2FALoading = true;
		},
		getSms2FASuccess: (state, { payload }: PayloadAction<ISms2Fa>) => {
			const userState = state;
			userState.sms2FALoading = false;
			userState.sms2FA = payload;
		},
		getSms2FAError: (state) => {
			const userState = state;
			userState.sms2FALoading = true;
		},
		usersInitState: () => initialState,
	},
});

export default users.reducer;
export const {
	getUsersRequest,
	getUsersSuccess,
	setUsers,
	updateUsersSuccess,
	getUserRequest,
	getUserSuccess,
	setOneUserDateRange,
	unsetOneUserDateRange,
	getUserBalancesRequest,
	getUserBalancesSuccess,
	getWhitelistIpRequest,
	getWhitelistIpSuccess,
	removeWhitelistIpRequest,
	removeWhitelistIpSuccess,
	verifyWhitelistIpRequest,
	verifyWhitelistIpSuccess,
	setUserBlockUnblockRequest,
	setUserBlockUnblockSuccess,
	usersInitState,
	get2FARequest,
	get2FASuccess,
	reset2FA,
	reset2FASuccess,
	reject2FA,
	reject2FASuccess,
	getLoginHistoryRequest,
	getLoginHistorySuccess,
	getDepositAndWithdrawHistoryRequest,
	getDepositAndWithdrawHistorySuccess,
	getUserOrdersHistoryRequest,
	getUserOrdersHistorySuccess,
	getUserMarginOrdersHistoryRequest,
	getUserMarginOrdersHistorySuccess,
	getUserMarginBorrowingRequest,
	getUserMarginBorrowingSuccess,
	getUserMarginRepaymentRequest,
	getUserMarginRepaymentSuccess,
	getUserMarginInterestRequest,
	getUserMarginInterestSuccess,
	getUserMarginLiquidationRequest,
	getUserMarginLiquidationSuccess,
	getUserMarginBalancesRequest,
	getUserMarginBalancesSuccess,
	getUserMarginMarginCallRequest,
	getUserMarginMarginCallSuccess,
	getUserAdvertisementsP2PRequest,
	getUserAdvertisementsP2PSuccess,
	getUserDisputeP2PRequest,
	getUserDisputeP2PSuccess,
	getUserTradesP2PRequest,
	getUserTradesP2PSuccess,
	getUserCryptoDepositRequest,
	getUserCryptoDepositSuccess,
	getUserFiatDepositRequest,
	getUserFiatDepositSuccess,
	getUserConvertsRequest,
	getUserConvertsSuccess,
	getUserTransfersRequest,
	getUserTransfersSuccess,
	setTabMenuUserIndex,
	setUserUpdateVipStatusRequest,
	setUserUpdateVipStatusSuccess,
	getUserCardsRequest,
	getUserCardsSuccess,
	getUserBankAccountRequest,
	getUserBankAccountSuccess,
	setFilterParams,
	getUserBalancesP2PRequest,
	getUserBalancesP2PSuccess,
	updateReferralFeeRequest,
	updateReferralFeeSuccess,
	updateBankAccountStatusRequest,
	updateBankAccountStatusSuccess,
	updateBankAccountStatusError,
	getEmail2FARequest,
	getEmail2FASuccess,
	getEmail2FAError,
	getSms2FARequest,
	getSms2FASuccess,
	getSms2FAError,
	getUserRedemptionsRequest,
	getUserRedemptionsSuccess,
} = users.actions;
