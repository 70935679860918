import {
	IApproveWithdrawalsResponsePayload,
	IRejectWithdrawalsResponsePayload,
	IWithdrawalsResponsePayload,
	IGetBankTransferResponse,
	IGetPendingRedemptionsResponse,
	TAvailableGramList,
} from 'redux/reducers/withdrawals/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IWithdrawalsApi } from './types';

// ==========================================:
export const withdrawals: IWithdrawalsApi = {
	getWithdrawals: (params) =>
		http
			.get<IWithdrawalsResponsePayload>(endpoint.pendingWithdrawals.PENDING_WITHDRAWALS, { params })
			.then((response) => response.data),

	approveWithdrawals: (params) =>
		http
			.put<IApproveWithdrawalsResponsePayload>(endpoint.pendingWithdrawals.APPROVE_WITHDRAWALS, {
				status: 1,
				id: params.id,
			})
			.then((response) => response.data),

	rejectWithdrawals: (params) =>
		http
			.put<IRejectWithdrawalsResponsePayload>(endpoint.pendingWithdrawals.REJECT_WITHDRAWALS, {
				status: 0,
				id: params.id,
				comment: params.comment,
			})
			.then((response) => response.data),
	getFiatWithdrawals: (params) =>
		http
			.get<IWithdrawalsResponsePayload>(endpoint.pendingWithdrawals.FIAT_WITHDRAWALS, { params })
			.then((response) => response.data),
	approveFiatWithdrawals: (params) =>
		http
			.post<IApproveWithdrawalsResponsePayload>(
				endpoint.pendingWithdrawals.APPROVE_FIAT_WITHDRAWAL,
				{
					id: params.id,
					payment_id: params.payment_id,
				},
			)
			.then((response) => response.data),

	rejectFiatWithdrawals: (params) =>
		http
			.post<IRejectWithdrawalsResponsePayload>(endpoint.pendingWithdrawals.REJECT_FIAT_WITHDRAWAL, {
				id: params.id,
				comment: params.comment,
				payment_id: params.payment_id,
			})
			.then((response) => response.data),
	getBankTransfers: (params) =>
		http
			.get<IGetBankTransferResponse>(endpoint.pendingWithdrawals.GET_BANK_ACCOUNT, { params })
			.then((response) => response.data),
	getPendingRedemptions: (params) =>
		http
			.get<IGetPendingRedemptionsResponse>(endpoint.pendingWithdrawals.GET_PENDING_REDEMPTIONS, {
				params,
			})
			.then((response) => response.data),
	getAvailableGramList: () =>
		http
			.get<TAvailableGramList>(endpoint.pendingWithdrawals.AVAILABLE_GRAM_LIST)
			.then((response) => response.data),
	approveRedemption: (payload) =>
		http
			.post(endpoint.pendingWithdrawals.APPROVE_REDEMPTION, payload)
			.then((response) => response.data),
	rejectRedemption: (payload) =>
		http
			.post(endpoint.pendingWithdrawals.REJECT_REDEMPTION, payload)
			.then((response) => response.data),
};
