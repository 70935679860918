/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { noExponent, capitalizeFirstLetter } from 'utils/numberFormat';
import { IReferralItemProps } from './types';

export const ReferralItem: FC<IReferralItemProps> = ({ data }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);

	return (
		<div className="tr">
			<Link className="td" to={`/user-management/${String(data.to_user_id)}`}>
				<p className="td-hidden-name">Referral ID</p>
				<p>{data.to_user_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Referral Fee</p>
				<p>{noExponent(String(data.fee_amount))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Referral fee in USD</p>
				<p>{noExponent(String(data.fee_amount_usd))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Data</p>
				{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
				{dateOffset.toLocaleTimeString()}
			</div>
			<Link className="td" to={`/user-management/${String(data.order_transaction_user_first_id)}`}>
				<p className="td-hidden-name">User ID 1</p>
				<p>{data.order_transaction_user_first_id}</p>
			</Link>
			<Link className="td" to={`/user-management/${String(data.order_transaction_user_second_id)}`}>
				<p className="td-hidden-name">User ID 2</p>
				<p>{data.order_transaction_user_second_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<p style={{ textTransform: 'uppercase' }}>
					{data.order_transaction_pair.replace('_', '/')}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Side</p>
				<p
					style={{
						color:
							capitalizeFirstLetter(data.order_transaction_type).toLowerCase().indexOf('buy') === -1
								? '#F44336'
								: '#03BC70',
					}}
				>
					{capitalizeFirstLetter(data.order_transaction_type)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Matching order ID</p>
				<p>{data.order_transaction_matching_order_id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Order ID</p>
				<p>{data.order_transaction_order_id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Executed</p>
				<p>{noExponent(Number(data.order_transaction_matching_price_filled))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{noExponent(Number(data.total_fee_amount))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Bitanica Fee</p>
				<p>{noExponent(Number(data.platform_fee_amount))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Bitanica Fee USD</p>
				<p>{noExponent(Number(data.platform_fee_amount_usd))}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(Number(data.order_transaction_amount))}</p>
			</div>
		</div>
	);
};
