import { FC } from 'react';
import { FieldProps } from 'formik';
import { useSelector } from 'react-redux';
import { getAssetsList } from 'redux/reducers/tradingPairs/selectors';
import { useSelect } from 'services/hooks/useSelect';
import { ISelectCurrency } from './types';

const SelectCurrency: FC<ISelectCurrency> = (props) => {
	const {
		field: { name, value },
		form: { setFieldValue, setFieldTouched },
		className = '',
	} = props;
	const assets = useSelector(getAssetsList)?.filter((item) => item.type === 'fiat');

	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const selectedAsset = assets?.find((item) => item.id === value);

	const handleSelect = (id: number) => {
		setFieldValue(name, id);
		setOpen(false);
	};

	const handleSelectClick = () => {
		setFieldTouched(name, true);
		toggleOpen();
	};

	return (
		<div
			className={`select select--regular2 select--height-48 ${open ? 'active' : ''} ${className}`}
		>
			<button
				type="button"
				className="select__current"
				ref={triggerRef}
				onClick={handleSelectClick}
			>
				<span className="select__current-text">
					{selectedAsset?.code?.toUpperCase() || 'Select currency'}
				</span>
				<span className="select__current-arrow">
					<svg
						className="stroke"
						width="8"
						height="5"
						viewBox="0 0 8 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
					</svg>
				</span>
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select-drop__item">
						<ul>
							{assets?.map((item) => (
								<li key={item.id}>
									<button type="button" onClick={() => handleSelect(item.id)}>
										{item.code.toUpperCase()}
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectCurrency;
