import { ChangeEvent } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { IRowField } from './types';

const RowField = <T extends Record<string, string>>({ name }: IRowField<T>) => {
	const { errors, setFieldValue, touched } = useFormikContext<T>();

	return (
		<div className={`table-input-wrapper${errors[name] && touched[name] ? '--error' : ''}`}>
			<div className="table-input">
				<Field
					name={name}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						const { value } = e.target;
						if (value && !/^\d+(\.+\d*)?$/.test(value)) {
							return;
						}
						setFieldValue(String(name), value);
					}}
				/>
			</div>
			<ErrorMessage name={String(name)} className="error-text" component="p" />
		</div>
	);
};

export default RowField;
