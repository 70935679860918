import { FC, useState, useEffect, useRef } from 'react';
import { TProps } from './type';

export const SubbButton: FC<TProps> = ({ concat, setConcat, name }) => {
	const [open, setOpen] = useState(false);
	const node = useRef<HTMLDivElement | null>(null);

	const handleSelectToggel = (): void => {
		setOpen(!open);
	};
	const handleClick = (event: MouseEvent): boolean | undefined => {
		if (node?.current?.contains(event.target as Node)) {
			return false;
		}

		setOpen(false);
		return true;
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	return (
		<div
			ref={node}
			className={`select select--table-select select--sign-type ${open ? 'active' : ''}`}
		>
			<button onClick={handleSelectToggel} type="button" className="select__current">
				<span className="select__current-box">
					<span className="select__current-text">{concat}</span>
				</span>
				<span className="select__current-arrow">
					<svg
						className="stroke"
						width="8"
						height="5"
						viewBox="0 0 8 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
					</svg>
				</span>
			</button>
			<div className="select__drop">
				<div className="select__drop-scroll">
					<div className="select-drop__item">
						<ul>
							<li>
								<button
									name={name}
									onClick={(e) => {
										setConcat(e);
										setOpen(false);
									}}
									type="button"
									value="+"
								>
									+
								</button>
							</li>
							<li>
								<button
									name={name}
									onClick={(e) => {
										setConcat(e);
										setOpen(false);
									}}
									type="button"
									value="-"
								>
									-
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
