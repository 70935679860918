import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	tokenManagementSuccess,
	tokenManagementInitState,
	tokenManagementRequest,
	tokenManagementEditSuccess,
	tokenManagementEditRequest,
	tokenManagementHistorySuccess,
	tokenManagementExportHistorySuccess,
	tokenManagementHistoryRequest,
	tokenManagementMintRequest,
	tokenManagementBurnRequest,
	tokenManagementCheckRequest,
} from './reducer';
import {
	ITokenManagementEditParams,
	ITokenManagementEditResponse,
	ITokenManagementParams,
	ITokenManagementResponse,
	ITokenManagementMintPayload,
	ITokenManagementMintResponse,
	ITokenManagementBurnResponse,
	ITokenManagementCheckPayload,
	ITokenManagementCheckResponse,
} from './types';
import { notificationContainer } from '../../../utils/notificationContainer';

// =============================================================:
function* tokenManagementWorker(action: PayloadAction<ITokenManagementParams>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		const response: ITokenManagementResponse = yield call(
			api.tokenManagement.getTokenManagement,
			payload,
		);

		yield put(tokenManagementSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(tokenManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* tokenManagementEditWorker(action: PayloadAction<ITokenManagementEditParams>) {
	const { payload } = action;
	console.log(payload.value);
	try {
		yield put(showLoading());
		const response: ITokenManagementEditResponse = yield call(
			api.tokenManagement.postTokenManagement,
			payload,
		);
		yield put(tokenManagementEditSuccess(response));
		notificationContainer('Ok!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* tokenManagementHistoryWorker(action: any) {
	const { payload } = action;

	try {
		yield put(showLoading());

		const response: ITokenManagementResponse = yield call(
			api.tokenManagement.getTokenManagementHistory,
			payload,
		);

		if (payload.export === 1) {
			yield put(tokenManagementExportHistorySuccess());
			notificationContainer(
				'Token management history successfully exported, check your mailbox!',
				'info',
			);
		} else yield put(tokenManagementHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(tokenManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* tokenManagementMintWorker(action: PayloadAction<ITokenManagementMintPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		const response: ITokenManagementMintResponse = yield call(
			api.tokenManagement.checkAndMint,
			payload,
		);
		notificationContainer(`Minted: ${String(response.data.minted)}`, 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* tokenManagementBurnWorker(action: PayloadAction<ITokenManagementMintPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		const response: ITokenManagementBurnResponse = yield call(
			api.tokenManagement.checkAndBurn,
			payload,
		);
		notificationContainer(`Burned: ${String(response.data.burned)}`, 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* tokenManagementCheckWorker({ payload }: PayloadAction<ITokenManagementCheckPayload>) {
	const { params, onSuccess } = payload;

	try {
		yield put(showLoading());

		const response: ITokenManagementCheckResponse = yield call(api.tokenManagement.check, params);
		onSuccess?.(response);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* tokenManagementSaga() {
	yield takeEvery(tokenManagementRequest.type, tokenManagementWorker);
	yield takeEvery(tokenManagementEditRequest.type, tokenManagementEditWorker);
	yield takeEvery(tokenManagementHistoryRequest.type, tokenManagementHistoryWorker);
	yield takeEvery(tokenManagementMintRequest.type, tokenManagementMintWorker);
	yield takeEvery(tokenManagementBurnRequest.type, tokenManagementBurnWorker);
	yield takeEvery(tokenManagementCheckRequest.type, tokenManagementCheckWorker);
}
