import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IHotColdTransactionsData,
	IHotWalletAddressData,
	IWalletManagementStore,
	IWalletsData,
	IBotLiquidityDataResponse,
	IExternalBalanceDataResponse,
	ILiquiditySettings,
	IWalletsListData,
	IProviders,
	ICoinListWithChains,
	IChainItem,
	IExternalWalletHistory,
	IBankAccountBalanceItem,
} from './types';

// ==========================================:
const getWalletsState = (state: IStoreState): IWalletManagementStore => state.walletManagement;
export const getWithdrawals = createSelector(
	[getWalletsState],
	(walletManagement: IWalletManagementStore) => walletManagement,
);

// ====================================================:
export const getWalletsIsLoad = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.walletsLoader,
);

// ====================================================:
export const getWalletsIsExporting = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.walletsExporting,
);

// ====================================================:
export const getWalletsList = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IWalletsData => walletManagement.wallets,
);

// ====================================================:
export const getHotWalletAddressIsLoad = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.hotWalletAddressLoader,
);
// ====================================================:
export const getHotWalletAddress = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IHotWalletAddressData =>
		walletManagement.hotWalletAddress,
);

// ====================================================:
export const getHotColdTransactionsIsLoad = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.hotColdTransactionsLoader,
);

// ====================================================:
export const getHotColdTransactionsList = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IHotColdTransactionsData =>
		walletManagement.hotColdTransactions,
);

// ====================================================:
export const getBotLiquidityList = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IBotLiquidityDataResponse | null =>
		walletManagement.botLiquidity,
);

export const getBotLiquidityLoader = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.botLiquidityLoader,
);

export const getResetColdBalanceLoader = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.resetColdBalanceLoader,
);

export const getExternalBalances = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IExternalBalanceDataResponse | null =>
		walletManagement.externalBalances,
);

export const getExternalBalancesLoader = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.externalBalancesLoader,
);

export const getExternalBalanceExporting = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.externalBalancesExporting,
);

export const getLiquiditySettings = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): ILiquiditySettings | null =>
		walletManagement.liquiditySetting,
);

export const getWalletsListData = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IWalletsListData | null =>
		walletManagement.walletsListData,
);

export const getWalletsListDataLoader = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.walletsListDataLoader,
);

export const getProviders = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IProviders | null => walletManagement.providers,
);

export const getCoinsListWithChains = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): ICoinListWithChains | null =>
		walletManagement.coinListWithChains,
);

export const getChains = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IChainItem[] | null =>
		walletManagement.chainsFromCoin,
);

export const getExternalWalletHistory = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IExternalWalletHistory | null =>
		walletManagement.externalWalletHistory,
);

export const getExternalWalletHistoryLoader = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean =>
		walletManagement.externalWalletHistoryLoader,
);

export const getBankAccountBalances = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IBankAccountBalanceItem[] | null =>
		walletManagement.bankAccountBalances,
);

export const getBankAccountBalancesLoader = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.bankAccountBalancesLoader,
);

export const getFilterParams = createSelector(
	[getWithdrawals],
	(trades: IWalletManagementStore) => trades.filterParams,
);
