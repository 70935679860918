import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter, noExponent } from 'utils/numberFormat';
import { useDispatch } from 'react-redux';
import { setTabMenuUserIndex } from 'redux/reducers/userManagement/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';

import { IAdvertisementsP2PProps } from './types';

const P2POrdersTableItem: FC<IAdvertisementsP2PProps> = ({ item }) => {
	const dispatch = useDispatch();
	const [id] = useState(item.id);
	const [coin] = useState(item?.asset?.code);
	const [amount] = useState(item.amount);
	const [amountLeft] = useState(item.amount_left);
	const [type] = useState(item.type);
	const [userId] = useState(item.user_id);
	const [buyerFee] = useState(item.buyer_fee);
	const [sellFee] = useState(item.seller_fee);
	const [created] = useState(item.created_at);
	const [updated] = useState(item.updated_at);
	const [fiat] = useState(item.current_fiat_code);

	const { checkReadable } = usePermission();

	const convertDate = (data: number | any) => {
		const dateX = new Date(data);
		const secondsX = dateX.getTime() / 1000;
		const date = new Date(secondsX * 1000);
		const offset = (-date.getTimezoneOffset() * 60) / 1000;
		const dateOffset = new Date((+secondsX + offset) * 1000);
		return dateOffset;
	};
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Order ID</p>
				<p>{id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Coin</p>
				<p style={{ textTransform: 'uppercase' }}>{coin}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fiat</p>
				<p>{fiat}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p
					style={{
						color:
							capitalizeFirstLetter(type).toLowerCase().indexOf('buy') === -1
								? '#F44336'
								: '#03BC70',
					}}
				>
					{capitalizeFirstLetter(type)}
				</p>
			</div>
			<div className="td">
				{checkReadable(permissions.MANAGE_USERS) ? (
					<Link
						onClick={() => {
							dispatch(setTabMenuUserIndex(0));
						}}
						to={`/user-management/${String(userId)}`}
					>
						<p className="td-hidden-name">User ID</p>
						<p>{userId}</p>
					</Link>
				) : (
					<div className="td">
						<p className="td-hidden-name">User ID</p>
						<p>{userId}</p>
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Quantity</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Quantity left</p>
				<p>{noExponent(Number(amountLeft).toString())}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee buyer</p>
				<p>{noExponent(Number(buyerFee).toString())}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee seller</p>
				<p>{noExponent(Number(sellFee).toString())}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Created</p>
				<p>
					{convertDate(created).toLocaleDateString('en-GB')},{' '}
					{convertDate(created).toLocaleTimeString().slice(0, -3)}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Updated</p>
				<p>
					{convertDate(updated).toLocaleDateString('en-GB')},{' '}
					{convertDate(updated).toLocaleTimeString().slice(0, -3)}
				</p>
			</div>
		</div>
	);
};
export default P2POrdersTableItem;
