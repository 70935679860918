import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Dispute from 'components/Dispute';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const DisputePage: FC = () => {
	return (
		<PermissionGate permissions={permissions.MANAGE_DISPUTES}>
			<Dashboard title="Dispute">
				<Dispute />
			</Dashboard>
		</PermissionGate>
	);
};
export default DisputePage;
