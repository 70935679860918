import { FC } from 'react';
import { Sector } from 'recharts';
import { numberFormat } from 'utils/numberFormat';
import { IRenderActiveShapeProps } from './types';

// ==========================================:
const RenderActiveShape: FC<IRenderActiveShapeProps> = ({
	data,
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	type,
	startAngle,
	endAngle,
	fill,
	payload,
}) => {
	const balanceList = data;

	const charactersAfterZero = process.env.REACT_APP_NAME_CHARACTERS_AFTER_ZERO;

	const RADIAN = Math.PI / 180;

	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	const activeItem = balanceList?.find((el) => el.asset_code === payload.name);

	if (outerRadius < innerRadius || startAngle === endAngle) {
		return null;
	}

	return (
		<g>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={innerRadius + 20}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
				{numberFormat(
					type && activeItem.profits_by_type
						? Number(activeItem.profits_by_type[type])
						: Number(
								activeItem.balances.reduce((sum: number, elem: any) => {
									return sum + Number(elem.balance);
								}, 0),
						  ),
					'en-EN',
				)}{' '}
				{activeItem?.asset_code.toUpperCase()}
			</text>
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
				${' '}
				{numberFormat(
					type && activeItem.profits_by_type_usdt
						? Number(activeItem.profits_by_type_usdt[type])
						: Number(
								activeItem.balances.reduce((sum: number, elem: any) => {
									return sum + Number(elem.balance_usdt);
								}, 0),
						  ),
					'en-EN',
				)}
			</text>
		</g>
	);
};

export default RenderActiveShape;
