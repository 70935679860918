import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { noExponent } from 'utils/numberFormat';
import { setTabMenuUserIndex } from 'redux/reducers/userManagement/reducer';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { useDispatch } from 'react-redux';

import { ICryptoDepositProps } from './types';

const Transfers: FC<ICryptoDepositProps> = ({ item }) => {
	const getStatusClass = () => {
		switch (item.status) {
			case 'approved':
				return 'status status--success';
			case 'rejected':
				return 'status status--rejected';
			default:
				return '';
		}
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Referral Fee</p>
				<p>{noExponent(String(item.id))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Phone number</p>
				<p>{item.phone}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Initiated amount (gram)</p>
				{item.init_gold_grams}
				<p>-</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Available GOLDB amount</p>
				<p>{item.available_amount != null ? noExponent(String(item.available_amount)) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Released Amount</p>
				<p>{item.released_amount != null ? noExponent(String(item.released_amount)) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{item.fee != null ? noExponent(String(item.fee)) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Gold (gram)</p>
				<p>
					{item.released_gold_grams != null ? noExponent(String(item.released_gold_grams)) : '-'}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Reason of reject</p>
				<div className="hint-block">
					<div className="hint-block__text">
						<p>{item.reject_reason}</p>
					</div>
					<div className="hint-item">
						<p>{item.reject_reason}</p>
					</div>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={getStatusClass()}>{item.status}</span>
				</div>
			</div>
		</div>
	);
};

export default Transfers;
