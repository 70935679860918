import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetColdBalance, putHotWalletsRequest } from 'redux/reducers/walletManagement/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { ActionsButtons } from './ActionButtons';
import { IWalletItemProps } from './types';
import { RessetButton } from './ActionButtons/RessetButton';

export const WalletItem: FC<IWalletItemProps> = ({ data, asset_code, asset_id }) => {
	const dispatch = useDispatch();
	const [minHot, setMinHot] = useState(String(data.min_on_hot));
	const [maxHot, setMaxHot] = useState(String(data.max_on_hot));
	const [isRedact, setIsRedact] = useState(false);
	const [notCorrect, setNotCorrect] = useState(false);

	const { checkEditable } = usePermission();

	const handleResetColdBalance = () => {
		dispatch(
			resetColdBalance({
				asset_id,
				network: data.network_id,
			}),
		);
	};

	const handleTradingPairsChange = () => {
		if (notCorrect) return;
		setIsRedact(!isRedact);
		dispatch(
			putHotWalletsRequest({
				asset_id,
				network: String(data.network_id),
				max_on_hot: Number(maxHot),
				min_on_hot: Number(minHot),
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>
					{`${asset_code.toUpperCase()}
					${asset_code !== data.network_name ? ` (${data.network_name.toUpperCase()})` : ''}`}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Balance</p>
				<p>{data.balance}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Cold Balance</p>
				<p>{data.cold_balance}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Min alert</p>
				{!isRedact ? (
					<p>{minHot}</p>
				) : (
					<div className="table-input table-input--start table-input--width-100">
						<input
							type="text"
							value={minHot}
							onChange={(e) => {
								setMinHot(e.target.value.replace(/[^\d\\.]/g, ''));
								setNotCorrect(e.target.value.replace(/[^\d\\.]/g, '') === '');
							}}
						/>
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Max alert</p>
				{!isRedact ? (
					<p>{maxHot}</p>
				) : (
					<div className="table-input table-input--start table-input--width-100">
						<input
							type="text"
							value={maxHot}
							onChange={(e) => {
								setMaxHot(e.target.value.replace(/[^\d\\.]/g, ''));
								setNotCorrect(e.target.value.replace(/[^\d\\.]/g, '') === '');
							}}
						/>
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Reset cold balance</p>
				<p>
					{!!resetColdBalance && (
						<RessetButton
							resetColdBalance={handleResetColdBalance}
							coldBalance={data.cold_balance}
						/>
					)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Deposit / Withdrawal</p>
				<ActionsButtons
					addressHot={data.hot_wallet_address}
					asset_code={asset_code}
					asset_id={asset_id}
					addressCold={data.cold_wallet_address}
					network={data.network_id}
					resetColdBalance={handleResetColdBalance}
					coldBalance={data.cold_balance}
				/>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					{isRedact ? (
						<>
							<button
								type="submit"
								onClick={() => {
									setMinHot(String(data.min_on_hot));
									setMaxHot(String(data.max_on_hot));
									setIsRedact(!isRedact);
								}}
							>
								<span className="edit-icon icon-cancel-icon" />
							</button>

							<button
								type="button"
								onClick={handleTradingPairsChange}
								className={notCorrect ? 'button-not-active' : ''}
							>
								<svg
									width="18"
									height="19"
									viewBox="0 0 18 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
										stroke="#00DA83"
										strokeLinecap="square"
									/>
								</svg>
							</button>
						</>
					) : (
						<button
							type="button"
							disabled={!checkEditable(permissions.MANAGE_WALLETS)}
							onClick={() => setIsRedact(!isRedact)}
						>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
