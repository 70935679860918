/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import {
	getLoadingP2POrders,
	getOrdersP2P,
	getFilterParams,
} from 'redux/reducers/orders/selectors';
import { getOrdersP2PRequest, setFilterParams } from 'redux/reducers/orders/reducer';
import { P2POrdersTableHeader } from './P2POrdersTableHeader';
import { IMarginProps, IAdvertisementsP2PItem } from './types';
import P2POrdersTableItem from './P2POrdersTableItem';

export const P2POrders: FC<IMarginProps> = ({ radioCheck }) => {
	const ordersP2P = useSelector(getOrdersP2P);
	const ordersP2PLoad = useSelector(getLoadingP2POrders);
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const filterParams = useSelector(getFilterParams);

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			per_page: 15,
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getOrdersP2PRequest(params));
	};

	useEffect(() => {
		return () => {
			dispatch(setFilterParams({ start_date: null, end_date: null }));
		};
	}, [dispatch]);

	useEffect(() => {
		const searchParams =
			text && text.length >= 1
				? `?page=${String(page || 1)}&text=${String(text)}&field=${field}`
				: `?page=${String(1)}`;
		const params = {
			per_page: 15,
			current_page: 1,
			search_value: text ? String(text) : null,
			search_field: field ? String(field) : null,
			start_date: startDate || null,
			end_date: endDate || null,
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getOrdersP2PRequest(params));
	}, [startDate, endDate]);
	return (
		<>
			{ordersP2PLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--P2P-orders">
							<P2POrdersTableHeader />
							<div className="table-body">
								{ordersP2P?.data.map((item: IAdvertisementsP2PItem) => (
									<P2POrdersTableItem item={item} key={item.id} />
								))}
								{ordersP2P?.data.length === 0 ? (
									<p style={{ padding: '20px' }}>Borrowing is empty</p>
								) : null}
							</div>
						</div>
					</div>
					<div style={{ padding: '14px' }}>
						{(ordersP2P?.last_page ?? 0) > 1 && (
							<Pagination
								pageCount={ordersP2P?.last_page ?? 0}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
