/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { noExponent } from 'utils/numberFormat';
import { gameTransactionsStatus } from 'redux/reducers/transactions/constants';
import { IGameItemProps } from './types';

export const GameItem: FC<IGameItemProps> = ({ data }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			<Link className="td" to={`/user-management/${String(data.user_id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{data.user_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Game User ID</p>
				<p>{data.game_user_id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Time</p>
				{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
				{dateOffset.toLocaleTimeString()}
			</div>
			<div className="td">
				<p className="td-hidden-name">Asset</p>
				<p>{data.asset_code?.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(data.amount)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount in USD</p>
				<p>{noExponent(data.amount_usd)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Reason of reject</p>
				<p>{data.reject_reason}</p>
			</div>
			<div className="td td--right">
				<div>
					<span className={`status ${String(gameTransactionsStatus?.[data?.status]?.statusClass)}`}>
						{gameTransactionsStatus?.[data?.status]?.statusText}
					</span>
				</div>
			</div>
		</div>
	);
};
