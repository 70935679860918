import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IFiatFeesApi } from './types';

// ==========================================:
export const fiatFees: IFiatFeesApi = {
	getPercentFiatFees: (params) =>
		http.get(endpoint.fiatFees.RANGES_PERCENT, { params }).then((response) => response.data),
	updatePercentFiatFee: (params) =>
		http.put(endpoint.fiatFees.UPDATE_FIAT_FEE, params).then((response) => response.data),
	updateRangesPercentFiatFees: (params) => http.put(endpoint.fiatFees.RANGES_PERCENT, params),
	addPercentFiatFee: (params) => http.post(endpoint.fiatFees.RANGES_PERCENT, params),
	deletePercentFiatFee: (id: number) => http.delete(`${endpoint.fiatFees.RANGES_PERCENT}/${id}`),
	exportPercentFiatFees: () => http.get(endpoint.fiatFees.EXPORT_PERCENT_FIAT_FEE),
	getFiatList: () => http.get(endpoint.fiatFees.FIAT_LIST).then((response) => response.data),
	getChannelList: () => http.get(endpoint.fiatFees.CHANNEL_LIST).then((response) => response.data),
};
