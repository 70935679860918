/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAssetsPairRequest } from 'redux/reducers/tradingPairs/reducer';
import { getAssetsList } from 'redux/reducers/tradingPairs/selectors';
import { IAddNewPairModalProps } from './types';

export const AddNewPairModal: FC<IAddNewPairModalProps> = ({ openModal, setCloseModal }) => {
	const SELECT_CURRENCY = 'Select currency';
	const assetsList = useSelector(getAssetsList)?.filter((item) => item.type !== 'fiat');
	const dispatch = useDispatch();

	const [open1, setOpen1] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [startPosition, setStartPosition] = useState(true);
	const [asset1, setAsset1] = useState(SELECT_CURRENCY);
	const [asset2, setAsset2] = useState(SELECT_CURRENCY);

	const startHandler = () => {
		setAsset1(SELECT_CURRENCY);
		setAsset2(SELECT_CURRENCY);
		setStartPosition(true);
	};

	const selectAsset1Handler = (asset: string) => {
		setAsset1(asset);
		setOpen1(false);
		if (asset2 === SELECT_CURRENCY || asset === asset2) setStartPosition(true);
		else setStartPosition(false);
	};
	const selectAsset2Handler = (asset: string) => {
		setAsset2(asset);
		setOpen2(false);
		if (asset1 === SELECT_CURRENCY || asset1 === asset) setStartPosition(true);
		else setStartPosition(false);
	};

	const addAssetsPairHandler = () => {
		if (startPosition) return;
		setCloseModal();
		dispatch(addAssetsPairRequest({ base_asset_code: asset1, quote_asset_code: asset2 }));
		startHandler();
	};

	return (
		<div className={`${openModal ? 'popup-open' : 'hidden'}`}>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small-medium">
						<button
							type="button"
							className="popup__close"
							onClick={() => {
								setCloseModal();
								startHandler();
							}}
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1.80005 2.30005L16.2 16.7M1.80005 16.7L16.2 2.30005" stroke="#F44336" />
							</svg>
						</button>
						<div className="popup-header">
							<p className="popup-header__title">Add New Pair</p>
						</div>
						<div className="popup-body">
							<div className="select-block select-block--mb select-block--text-color">
								<p className="select-block__name select-block__name--pair">Currency 1</p>
								<div className={`select select--medium-height ${open1 ? 'active' : ''}`}>
									<button
										type="button"
										className="select__current"
										onClick={() => setOpen1(!open1)}
									>
										{asset1.toUpperCase()}
										<span className="select__current-arrow">
											<svg
												className="stroke"
												width="8"
												height="5"
												viewBox="0 0 8 5"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
											</svg>
										</span>
									</button>
									<div className="select__drop">
										<div className="select__drop-scroll">
											<div className="select-drop__item">
												<ul>
													{assetsList?.map((item) => (
														<li key={`asset1${String(item.code)}`}>
															<button
																type="button"
																onClick={() => {
																	selectAsset1Handler(item.code);
																}}
															>
																{item.code.toUpperCase()}
															</button>
														</li>
													))}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="select-block select-block--mb select-block--text-color">
								<p className="select-block__name select-block__name--pair">Currency 2</p>
								<div className={`select select--medium-height ${open2 ? 'active' : ''}`}>
									<button
										type="button"
										className="select__current"
										onClick={() => setOpen2(!open2)}
									>
										{asset2.toUpperCase()}
										<span className="select__current-arrow">
											<svg
												className="stroke"
												width="8"
												height="5"
												viewBox="0 0 8 5"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
											</svg>
										</span>
									</button>
									<div className="select__drop">
										<div className="select__drop-scroll">
											<div className="select-drop__item">
												<ul>
													{assetsList?.map((item) => (
														<li key={`asset2${String(item.code)}`}>
															<button
																type="button"
																onClick={() => {
																	selectAsset2Handler(item.code);
																}}
															>
																{item.code.toUpperCase()}
															</button>
														</li>
													))}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="input">
								<label>
									<p className="input__name">Currency pair</p>
									<div className="input-wrapper">
										<input
											className="input-item input-item--medium"
											value={
												asset1 === SELECT_CURRENCY || asset2 === SELECT_CURRENCY
													? 'Nothing selected'
													: startPosition
													? 'Not correct!'
													: `${asset1.toUpperCase()}/${asset2.toUpperCase()}`
											}
											type="text"
											disabled
										/>
									</div>
								</label>
							</div>
							<div className="popup-submit">
								<button
									type="button"
									className="button button--cancel button--medium-height"
									onClick={() => {
										setCloseModal();
										startHandler();
									}}
								>
									Cancel
								</button>
								<button
									type="button"
									className={`button button--medium-height button--check ${
										startPosition ? 'button-block' : ''
									}`}
									onClick={addAssetsPairHandler}
								>
									Create
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
