/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';

export const P2POrdersTableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Oreder ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Coin</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fiat</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Type</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>User ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Quantity</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Quantity left</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fee buyer</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fee seller</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Created</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Updated</p>
					</div>
				</div>
			</div>
		</div>
	);
};
