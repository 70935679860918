/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useRef } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import PDFFileDownload from '../PDFFileDownload';
import { IInvoicePopup } from './types';

export const InvoicePopup: FC<IInvoicePopup> = ({ onClose, data }) => {
	const { total_amount, asset, bank_account } = data;
	const { beneficiary_name, bank_name, swift, iban, reference, address } = bank_account || {};

	const pdfExportComponent = useRef<PDFExport>(null);

	const handleDownload = () => {
		if (pdfExportComponent.current) {
			pdfExportComponent.current.save();
		}
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-560">
					<button type="button" className="popup__close" onClick={onClose}>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15 3L3 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3 3L15 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">Invoice</p>
					</div>
					<div className="popup-body popup-body--type2">
						<div className="withdrawal-options ">
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Amount</span>
								<span className="withdrawal-option__desc">
									{total_amount} {asset?.code?.toUpperCase()}
								</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Beneficiary name</span>
								<span className="withdrawal-option__desc">{beneficiary_name}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Bank Name</span>
								<span className="withdrawal-option__desc">{bank_name}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Swift</span>
								<span className="withdrawal-option__desc">{swift}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">IBAN</span>
								<span className="withdrawal-option__desc">{iban}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Reference</span>
								<span className="withdrawal-option__desc">{reference}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Address</span>
								<span className="withdrawal-option__desc">{address}</span>
							</div>
						</div>
					</div>
					<div className="popup-submit popup-submit--type2">
						<button
							type="button"
							className="button button--check button--br-12 button--size-60 button--full-width "
							onClick={handleDownload}
						>
							Download Invoice
						</button>
					</div>
				</div>
			</div>
			<div style={{ position: 'fixed', left: '-10000' }}>
				<PDFExport ref={pdfExportComponent} paperSize="A4">
					<PDFFileDownload data={data} />
				</PDFExport>
			</div>
		</div>
	);
};
