/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { notificationContainer } from 'utils/notificationContainer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCoinsListWithChains } from 'redux/reducers/walletManagement/selectors';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IItemParams } from './types';

export const ExternalWalletHistoryItem: FC<IItemParams> = ({ data }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);
	const { status } = data;
	const statusStyle = `status status--${status}`;
	const coinList = useSelector(getCoinsListWithChains);
	const coin = coinList?.find((item) => item.asset_id === data.asset_id)?.asset_code;

	const { checkReadable } = usePermission();

	const handleAddressToCopy = () => {
		notificationContainer('Address to copied successfully!', 'info');
	};

	const handleHashCopy = () => {
		notificationContainer('Hash copied successfully!', 'info');
	};

	const handleAddressFromCopy = () => {
		notificationContainer('Address from copied successfully!', 'info');
	};

	return (
		<div className="tr">
			{checkReadable(permissions.MANAGE_USERS) ? (
				<Link className="td" to={`/user-management/${String(data.user_id)}`}>
					<p className="td-hidden-name">Id</p>
					<p>{data.user_id}</p>
				</Link>
			) : (
				<div className="td">
					<p className="td-hidden-name">Id</p>
					<p>{data.user_id}</p>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Liquidity Provider Name</p>
				<p>{data.provider_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<p style={{ whiteSpace: 'nowrap' }}>{dateOffset.toLocaleDateString('en-GB')}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Coin</p>
				<p>{String(coin).toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p>{parseFloat(data.amount.toFixed(5))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Address to</p>
				<div className="copy-button">
					<p className="copy-button__text">{data.address_to}</p>
					<CopyToClipboard text={data?.address_to} onCopy={handleAddressToCopy}>
						<button type="button" className="copy-button__item">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3.33337 10.0007H2.66671C2.31309 10.0007 1.97395 9.86018 1.7239 9.61013C1.47385 9.36008 1.33337 9.02094 1.33337 8.66732V2.66732C1.33337 2.3137 1.47385 1.97456 1.7239 1.72451C1.97395 1.47446 2.31309 1.33398 2.66671 1.33398H8.66671C9.02033 1.33398 9.35947 1.47446 9.60952 1.72451C9.85956 1.97456 10 2.3137 10 2.66732V3.33398"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</CopyToClipboard>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Hash</p>
				<div className="copy-button">
					<p className="copy-button__text">{data.transaction_hash}</p>
					<CopyToClipboard text={data?.transaction_hash} onCopy={handleHashCopy}>
						<button type="button" className="copy-button__item">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3.33337 10.0007H2.66671C2.31309 10.0007 1.97395 9.86018 1.7239 9.61013C1.47385 9.36008 1.33337 9.02094 1.33337 8.66732V2.66732C1.33337 2.3137 1.47385 1.97456 1.7239 1.72451C1.97395 1.47446 2.31309 1.33398 2.66671 1.33398H8.66671C9.02033 1.33398 9.35947 1.47446 9.60952 1.72451C9.85956 1.97456 10 2.3137 10 2.66732V3.33398"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</CopyToClipboard>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Address from</p>
				<div className="copy-button">
					<p className="copy-button__text">{data.address_from}</p>
					<CopyToClipboard text={data?.address_from} onCopy={handleAddressFromCopy}>
						<button type="button" className="copy-button__item">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3.33337 10.0007H2.66671C2.31309 10.0007 1.97395 9.86018 1.7239 9.61013C1.47385 9.36008 1.33337 9.02094 1.33337 8.66732V2.66732C1.33337 2.3137 1.47385 1.97456 1.7239 1.72451C1.97395 1.47446 2.31309 1.33398 2.66671 1.33398H8.66671C9.02033 1.33398 9.35947 1.47446 9.60952 1.72451C9.85956 1.97456 10 2.3137 10 2.66732V3.33398"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</CopyToClipboard>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Chain</p>
				<p>{String(data.network).toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<span className={statusStyle}>{status}</span>
			</div>
		</div>
	);
};
