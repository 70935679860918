import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Notifications from 'components/Notifications';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const NotificationPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.MANAGE_NOTIFICATIONS}>
			<Dashboard title="Notification">
				<Notifications />
			</Dashboard>
		</PermissionGate>
	);
};

export default NotificationPage;
