/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { getWhitelistIpIsLoad, getWhitelistIpList } from 'redux/reducers/userManagement/selectors';
import queryString from 'query-string';
import { getWhitelistIpRequest } from 'redux/reducers/userManagement/reducer';
import Pagination from 'ui/Pagination';
import { IWhitelistIpListRequestPayload } from 'redux/reducers/userManagement/types';
import Loader from 'ui/Loader';
import { WhitelistingIPItem } from './WhitelistingIPItem';

export const WhitelistingIP: FC = () => {
	const { id } = useParams<{ id: string }>();

	const whitelistIp = useSelector(getWhitelistIpList);
	const whitelistIpLoader = useSelector(getWhitelistIpIsLoad);

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);

		const searchParams = `?page=${String(pageNumber)}`;

		const params: IWhitelistIpListRequestPayload = {
			id,
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getWhitelistIpRequest(params));
	};
	useEffect(() => {
		history.push({
			pathname: location.pathname,
			search: ``,
		});
		setCurrentPage(1);

		dispatch(getWhitelistIpRequest({ id }));
	}, [dispatch, id]);

	return (
		<>
			<div className="content-block content-block--user-management">
				{!whitelistIp && !whitelistIpLoader && (
					<div className="user-management-empty-data">
						<p className="user-management-empty__text">No user found...</p>
					</div>
				)}
				{whitelistIpLoader ? (
					<div className="list-loader">
						<Loader />
					</div>
				) : (
					<>
						<div className="content-block__inside">
							<div className="user-transaction">
								<div className="user-transaction__header">
									<div className="user-info user-info--ip">
										<div className="user-info__block personal-info">
											<span className="personal-info__name">User ID</span>
											<div className="personal-info__desc">
												<span className="personal-info__text">{id}</span>
											</div>
										</div>
										<div className="user-info__block personal-info">
											<span className="personal-info__name">Email</span>
											<div className="personal-info__desc">
												<span className="personal-info__text">{whitelistIp?.email}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="content-block__inside content-block__inside--bt content-block__inside--ip">
							<div className="table-block table-block--ip">
								<div className="table-wrapper">
									<div className="table table--ip">
										<div className="table-header">
											<div className="tr">
												<div className="td">
													<div className="td-name">
														<p>IP</p>
													</div>
												</div>
												<div className="td td--right">
													<div className="td-name">
														<p>Date</p>
													</div>
												</div>
											</div>
										</div>
										{whitelistIp?.data?.length && whitelistIp?.data?.length > 0 ? (
											whitelistIp?.data?.map((item) => <WhitelistingIPItem data={item} />)
										) : (
											<p style={{ padding: '20px' }}>IP & Whitelist is empty</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			{(whitelistIp?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={whitelistIp?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
			{/* {openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)} */}
		</>
	);
};
