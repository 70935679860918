import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import Loader from 'ui/Loader';
import 'react-tabs/style/react-tabs.css';
// import { USER_LEVEL_ARRAY } from 'redux/reducers/referral/constants';
import { getUserReferralList, getUserReferralLoading } from 'redux/reducers/referral/selectors';
import { IUserParamsPayload } from 'redux/reducers/referral/types';
import { getReferralRequest } from 'redux/reducers/referral/reducer';
import ReferralUserTable from '../ReferralUserTable';
/* eslint-disable */
// const LOGIN_HISTORY_PAGE_INDEX = 2;

export const SelectReferral: FC = () => {
	const dispatch = useDispatch();
	const params = useParams<IUserParamsPayload>();
	const { id } = params;
	const selectUser = useSelector(getUserReferralList);
	const userReferralLoading = useSelector(getUserReferralLoading);
	const history = useHistory();
	const { state } = useLocation();

	const handleBack = () => {
		history.push({
			pathname: '/referral',
		});
	};

	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(field ? String(field) : 'user_id');
	// const [dropdownArray, setDropdownArray] = useState<{
	// 	dropdownArray: string[];
	// 	dropdownTextArray: string[];
	// }>({ dropdownArray: ['all'], dropdownTextArray: ['all'] });
	// const [typeSearchDropdown, setTypeSearchDropdown] = useState(false);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: `?page=${String(pageNumber)}`;

		const payload = {
			id: id,
			params:
				verifyText.length > 0
					? {
							current_page: pageNumber || 1,
							// search_field: searchField,
							// search_value: verifyText,
					  }
					: {
							current_page: pageNumber || 1,
					  },
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
			// state: state,
		});

		dispatch(getReferralRequest(payload));
	};

	// const handleSearch = (txt: string) => {
	// 	const payload = {
	// 		id: id,
	// 		params: {
	// 			current_page: 1,
	// 			search_value: txt ? txt : null,
	// 			search_field: txt ? searchField : null,
	// 		},
	// 	};

	// 	if (txt.length >= 1) {
	// 		dispatch(getReferralRequest(payload));
	// 	} else {
	// 		dispatch(getReferralRequest(payload));
	// 	}

	// 	history.push({
	// 		pathname: location.pathname,
	// 		search:
	// 			txt.length >= 1
	// 				? `?page=${String(page || 1)}&text=${String(txt)}&field=${searchField}`
	// 				: `?page=${String(1)}`,
	// 		state: state,
	// 	});
	// 	setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	// };
	// const [term, setTerm] = useState('');
	// const [debouncedTerm, setDebouncedTerm] = useState(term);

	// useEffect(() => {
	// 	const timer = setTimeout(() => setTerm(debouncedTerm), 800);
	// 	return () => clearTimeout(timer);
	// }, [debouncedTerm]);

	// useEffect(() => {
	// 	handleSearch(term);
	// }, [term, searchField]);

	// const handleClearSearch = () => {
	// 	setDebouncedTerm('');
	// 	setTerm('');
	// };

	// const searchFieldHandler = (txt: string) => {
	// 	if (txt === 'level') {
	// 		setDropdownArray({
	// 			dropdownArray: USER_LEVEL_ARRAY,
	// 			dropdownTextArray: USER_LEVEL_ARRAY,
	// 		});
	// 		setSearchField(txt);
	// 		setTypeSearchDropdown(true);
	// 		return;
	// 	}

	// 	setSearchField(txt);
	// 	setTypeSearchDropdown(false);
	// };

	useEffect(() => {
		const payload = {
			id: id,
			params: {
				current_page: 1,
			},
		};

		dispatch(getReferralRequest(payload));
	}, [dispatch, id]);

	return (
		<>
			{!selectUser && !userReferralLoading && (
				<div className="user-management-empty-data">
					<p className="user-management-empty__text">No user found...</p>
				</div>
			)}
			{userReferralLoading ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<>
					<div className="user-block">
						<button type="button" onClick={handleBack} className="back-btn">
							<svg
								className="fill"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.9534 7.5536L14.3069 7.20004L13.5998 6.49294L13.2463 6.84649L13.9534 7.5536ZM8.79981 12L8.44625 11.6465L8.0927 12L8.44625 12.3536L8.79981 12ZM13.2463 17.1536L13.5998 17.5071L14.3069 16.8L13.9534 16.4465L13.2463 17.1536ZM13.2463 6.84649L8.44625 11.6465L9.15336 12.3536L13.9534 7.5536L13.2463 6.84649ZM8.44625 12.3536L13.2463 17.1536L13.9534 16.4465L9.15336 11.6465L8.44625 12.3536ZM11.9998 22.7C6.09035 22.7 1.2998 17.9095 1.2998 12H0.299805C0.299804 18.4618 5.53807 23.7 11.9998 23.7V22.7ZM22.6998 12C22.6998 17.9095 17.9092 22.7 11.9998 22.7V23.7C18.4615 23.7 23.6998 18.4618 23.6998 12H22.6998ZM11.9998 1.30005C17.9093 1.30005 22.6998 6.0906 22.6998 12H23.6998C23.6998 5.53832 18.4615 0.30005 11.9998 0.300049V1.30005ZM11.9998 0.300049C5.53807 0.300048 0.299806 5.53831 0.299805 12H1.2998C1.29981 6.0906 6.09036 1.30005 11.9998 1.30005V0.300049Z"
									fill="#25262D"
								/>
							</svg>
						</button>
						<p className="user-block__name">{String(state)}</p>
						<p className="user-block__id">User ID: {id}</p>
					</div>
					{/* <div className="count-label count-label--filter-type">
						<div className="filter-group">
							<SearchBar
								setDebouncedTerm={setDebouncedTerm}
								debouncedTerm={debouncedTerm}
								searchFieldArray={USER_SEARCH_ARRAY}
								searchField={searchField}
								setSearchField={searchFieldHandler}
								handleClearSearch={handleClearSearch}
								searchFieldTextArray={USER_SEARCH_TEXT_ARRAY}
								typeSearch={typeSearchDropdown ? 'dropdown' : undefined}
								dropdownArray={dropdownArray.dropdownArray}
								dropdownTextArray={dropdownArray.dropdownTextArray}
								inputWidth="120px"
							/>
							<ReferalFilter />
						</div>
					</div> */}
					<div className="content-block">
						<div className="content-block__inside content-block__inside--type2">
							<ReferralUserTable users={selectUser?.data} />
						</div>
					</div>

					{(selectUser?.total ?? 1) > 1 && (
						<Pagination
							pageCount={selectUser?.last_page ?? 1}
							forcePage={currentPage - 1}
							onPageChange={handlePageChange}
						/>
					)}
				</>
			)}
		</>
	);
};
