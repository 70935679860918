import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getBotLiquidityLoader,
	getBotLiquidityList,
} from 'redux/reducers/walletManagement/selectors';
import { getLiquidityRequest } from 'redux/reducers/walletManagement/reducer';
import Loader from 'ui/Loader';
import { IBotLiquidityItem } from 'redux/reducers/walletManagement/types';
import { LiquidityItem } from './LiquidityItem';

export const BotLiquidity: FC = () => {
	const dispatch = useDispatch();
	const geLiquidityList = useSelector(getBotLiquidityList);
	const getLiquidityLoader = useSelector(getBotLiquidityLoader);

	useEffect(() => {
		dispatch(getLiquidityRequest({ current_page: 1, per_page: 10 }));
	}, [dispatch]);

	return (
		<div className="content-block content-block--walletman">
			<div>
				<div className="table-block table-block--walletman">
					<div className="table-wrapper">
						<div className="table table--wallett-liquiduty">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Currency</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>First coin</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Second coin</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name td-name--action">
											<p>Action</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body">
								{!geLiquidityList?.data?.length && !getLiquidityLoader && (
									<div className="user-management-empty-data">
										<p className="user-management-empty__text">No liquidity found...</p>
									</div>
								)}

								{getLiquidityLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<div className="table-body">
										{geLiquidityList?.data?.length &&
											geLiquidityList?.data?.map((item: IBotLiquidityItem) => (
												<LiquidityItem key={item.id} data={item} />
											))}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
