/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMarginOrdersHistoryRequest } from 'redux/reducers/userManagement/reducer';
import {
	getOrdersHistoryMarginIsLoad,
	getOrdersHistoryMarginList,
} from 'redux/reducers/userManagement/selectors';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { OrdersHistoryTableHeader } from './OrdersHistoryTableHeader';
import { IMarginProps } from './types';
import { IOrdersHistoryItem } from '../../../Spot/SpotOrdersHistory/types';
import OrdersHistoryTableItem from './OrdersHistoryTableItem';

export const OrdersHistory: FC<IMarginProps> = ({ radioCheck }) => {
	const ordersHistoryMarginList = useSelector(getOrdersHistoryMarginList);
	const ordersHistoryMarginListLoader = useSelector(getOrdersHistoryMarginIsLoad);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const paramsMargin = radioCheck === 'Cross' ? 'is_margin' : 'is_isolated';
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			id,
			per_page: 15,
			[paramsMargin]: 1,
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getUserMarginOrdersHistoryRequest(params));
	};
	useEffect(() => {
		const params = {
			id,
			per_page: 15,
			[paramsMargin]: 1,
			current_page: 1,
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getUserMarginOrdersHistoryRequest(params));
	}, [radioCheck]);

	return (
		<div>
			{ordersHistoryMarginListLoader ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<>
					<OrdersHistoryTableHeader />
					<div className="table-body">
						{ordersHistoryMarginList?.data.map((item: IOrdersHistoryItem) => (
							<OrdersHistoryTableItem item={item} key={item.id} />
						))}
						{ordersHistoryMarginList?.data.length === 0 ? (
							<p style={{ padding: '20px' }}>Orders history is empty</p>
						) : null}
					</div>
				</>
			)}
			<div style={{ padding: '14px' }}>
				{(ordersHistoryMarginList?.last_page ?? 0) > 1 && (
					<Pagination
						pageCount={ordersHistoryMarginList?.last_page ?? 0}
						forcePage={currentPage - 1}
						onPageChange={handlePageChange}
					/>
				)}
			</div>
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</div>
	);
};
