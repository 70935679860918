import { SyntheticEvent } from 'react';
import { ArrowDownIcon } from 'assets/img/icons';
import { useSelect } from 'services/hooks/useSelect';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from 'redux/reducers/translates/reducer';
import { getCurrentLanguage } from 'redux/reducers/translates/selectors';
import { languages, getLanguageLabel } from './data';

const LanguageSelect = () => {
	const dispatch = useDispatch();
	const language = useSelector(getCurrentLanguage);
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (e: SyntheticEvent<HTMLButtonElement>) => {
		const { name } = e.target as HTMLButtonElement;
		dispatch(setLanguage(name));
		setOpen(false);
	};

	return (
		<div className={`select ${open ? 'active' : ''}`}>
			<button className="select__current " type="button" onClick={toggleOpen} ref={triggerRef}>
				<span className="select__current-text">{getLanguageLabel(language)}</span>
				<span className="select__current-arrow">
					<ArrowDownIcon />
				</span>
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select-drop__item">
						<ul>
							{languages.map(({ name, label }) => (
								<li>
									<button type="button" name={name} onClick={handleSelect}>
										{label}
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LanguageSelect;
