import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { IGetTranslateParams, IUpdateTranslatePayload, ITranslates } from './types';
import {
	getTranslatesRequest,
	getTranslatesSuccess,
	updateTranslatesRequest,
	updateTranslatesSuccess,
	getTranslatesError,
	updateTranslatesError,
} from './reducer';

// ===================================================:
function* getTranslatesWorker(action: PayloadAction<IGetTranslateParams>) {
	const { payload } = action;

	try {
		const response: ITranslates[] = yield call(api.translates.getTranslates, payload);

		// *****************

		// const response: ITranslates[] = [
		// 	{
		// 		JustPage: {
		// 			// second_0: 'second_0',
		// 			Header: {
		// 				title: 'Title',
		// 				subtitle: 'Subtitle',
		// 				sign_in: 'Sign in',
		// 			},
		// 			Body: {
		// 				OrderBookTable: {
		// 					id: 'id',
		// 					name: 'name',
		// 					amount: 'amount',
		// 					total: 'total',
		// 				},
		// 				high: 'high',
		// 				big: 'big',
		// 				small: 'small',
		// 				medium: 'medium',
		// 			},
		// 		},
		// 	},
		// ];
		yield put(getTranslatesSuccess(response));
	} catch (error) {
		yield put(getTranslatesError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

// ===================================================:
function* updateTranslationsWorker({ payload }: PayloadAction<IUpdateTranslatePayload>) {
	const { index, apiParams, onSuccess } = payload;

	try {
		const response: ITranslates = yield call(api.translates.updateTranslates, apiParams);
		yield put(
			updateTranslatesSuccess({
				path: apiParams.path,
				index,
				response,
			}),
		);
		onSuccess?.();
	} catch (error) {
		yield put(updateTranslatesError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* translationsSaga() {
	yield takeEvery(getTranslatesRequest.type, getTranslatesWorker);
	yield takeEvery(updateTranslatesRequest.type, updateTranslationsWorker);
}
