import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSelect } from 'services/hooks/useSelect';
import { getCoinsListWithChains } from 'redux/reducers/walletManagement/selectors';
import { getChainsFromCoin } from 'redux/reducers/walletManagement/reducer';
import { ISelectCurrency } from './types';

const SelectCoin: FC<ISelectCurrency> = (props) => {
	const {
		field: { name, value },
		form: { setFieldValue },
		className = '',
	} = props;
	const coinsListWithChains = useSelector(getCoinsListWithChains);
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const selectedCoinWithChain = coinsListWithChains?.find((item) => item.asset_id === value);

	const dispatch = useDispatch();

	const handleSelect = (id: number) => {
		setFieldValue(name, id);
		dispatch(getChainsFromCoin(id));
		setOpen(false);
	};

	const handleSelectClick = () => {
		toggleOpen();
	};

	return (
		<div
			className={`select select--regular2 select--height-48 ${open ? 'active' : ''} ${className}`}
		>
			<button
				type="button"
				className={`select__current ${selectedCoinWithChain ? '' : 'select__current--unselect'}`}
				ref={triggerRef}
				onClick={handleSelectClick}
			>
				<span className="select__current-text">
					{selectedCoinWithChain?.asset_code.toUpperCase() || 'Select a Coin'}
				</span>
				<span className="select__current-arrow">
					<svg
						className="stroke"
						width="8"
						height="5"
						viewBox="0 0 8 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
					</svg>
				</span>
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select-drop__item">
						<ul>
							{coinsListWithChains?.map((item) => (
								<li key={item.asset_id}>
									<button type="button" onClick={() => handleSelect(item.asset_id)}>
										{item.asset_code.toUpperCase()}
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectCoin;
