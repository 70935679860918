import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { ExternalMakerBot } from 'components/ExternalMakerBot';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const ExternalMakerRobotPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.MANAGE_EXTERNAL_SERVICES}>
			<Dashboard title="External">
				<ExternalMakerBot />
			</Dashboard>
		</PermissionGate>
	);
};
export default ExternalMakerRobotPage;
