/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { noExponent } from 'utils/numberFormat';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { ITransfersItemProps } from './types';

export const TransfersItem: FC<ITransfersItemProps> = ({ data }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);

	const checkType = (value: string) => {
		return value === 'earn' ? 'Referral' : capitalizeFirstLetter(value);
	};

	return (
		<div className="tr">
			<Link className="td" to={`/user-management/${String(data.user_id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{data.user_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Asset</p>
				<p>{data?.asset?.code?.toUpperCase() ?? ''}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">From wallet</p>
				<p>{checkType(data.from)}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">To wallet</p>
				<p>{checkType(data.to)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(data.amount)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount in USD</p>
				<p>{noExponent(data.amount_usd)}</p>
			</div>
		</div>
	);
};
