import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	referralInitState,
	getReferralListRequest,
	getReferralListSuccess,
	getReferralFeeRequest,
	getReferralFeeSuccess,
	updateReferralFeeRequest,
	getReferralRequest,
	getReferralSuccess,
} from './reducer';
import {
	IGetReferralListRequest,
	IReferralFee,
	IReferralResponse,
	IReferralFeeRequest,
} from './types';

function* getReferralListWorker({ payload }: PayloadAction<IGetReferralListRequest>) {
	try {
		const { params, id } = payload;
		yield put(showLoading());
		const response: IReferralResponse = yield call(
			api.referral.getReferralList,
			params,
			String(id),
		);

		if (params.export === 1) {
			notificationContainer('referral exported', 'success');
		} else yield put(getReferralListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}

		yield put(referralInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getReferralFeeWorker() {
	try {
		yield put(showLoading());
		const response: IReferralFee = yield call(api.referral.getReferralFee);
		yield put(getReferralFeeSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}

		yield put(referralInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* updateReferralFeeWorker({ payload }: PayloadAction<IReferralFeeRequest>) {
	const { fee, onSuccess } = payload;
	try {
		yield put(showLoading());
		const response: IReferralFee = yield call(api.referral.updateReferralFee, String(fee));
		yield put(getReferralFeeSuccess(response));
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}

		yield put(referralInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* getReferralWorker({ payload }: PayloadAction<IGetReferralListRequest>) {
	try {
		const { params, id } = payload;
		yield put(showLoading());
		const response: IReferralResponse = yield call(
			api.referral.getReferralList,
			params,
			String(id),
		);
		if (params.export === 1) {
			notificationContainer('referral exported', 'success');
		} else yield put(getReferralSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}

		yield put(referralInitState());
	} finally {
		yield put(hideLoading());
	}
}

export function* referralSaga() {
	yield takeEvery(getReferralListRequest.type, getReferralListWorker);
	yield takeEvery(getReferralFeeRequest.type, getReferralFeeWorker);
	yield takeEvery(updateReferralFeeRequest.type, updateReferralFeeWorker);
	yield takeEvery(getReferralRequest.type, getReferralWorker);
}
