import { createSelector } from '@reduxjs/toolkit';
import { ITradesStore, IFilterParams } from './types';

const getTradesState = (state: any): ITradesStore => state.trades;

export const getTradesSpotList = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.tradesSpot,
);
export const getLoadingTradesSpot = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.tradesSpotLoading,
);
export const getTradesMarginList = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.tradesMargin,
);
export const getLoadingTradesMargin = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.tradesMarginLoading,
);
export const getTradesP2P = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.tradesP2PTrades,
);
export const getLoadingTrades = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.tradesP2PTradesLoading,
);

export const getFilterParams = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.filterParams,
);

export const getP2PTradesExporting = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.tradesP2PTradesExporting,
);
