import { FC } from 'react';
import { IExternalWalletDeletePopup } from './types';

const ExternalWalletDeletePopup: FC<IExternalWalletDeletePopup> = ({ onAccept, onCancel }) => {
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--medium">
					<div className="popup-body">
						<div className="popup-text popup-text--center popup-text--bigger">
							<p>Are you sure you want to delete the wallet?</p>
						</div>
					</div>
					<div className="popup-submit popup-submit--column">
						<button
							type="button"
							className="button button--wider button--del button--full"
							onClick={onCancel}
						>
							Cancel
						</button>
						<button type="button" className="button button--wider button--text" onClick={onAccept}>
							Yes, I&apos;m sure!
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExternalWalletDeletePopup;
