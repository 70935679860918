/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBankAccountLoader, getBankAccountList } from 'redux/reducers/userManagement/selectors';
import { useHistory, useLocation, useParams } from 'react-router';
import { IUserBankAccountItem } from 'redux/reducers/userManagement/types';
import { getUserBankAccountRequest } from 'redux/reducers/userManagement/reducer';
import Loader from 'ui/Loader';
import { BankAccountTableHeader } from './BankAccountTableHeader';
import BankAccountTableItem from './BankAccountTableItem';

export const BankAccount = () => {
	const bankAccountList = useSelector(getBankAccountList);
	const bankAccountLoader = useSelector(getBankAccountLoader);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const [editId, setEditId] = useState<number | null>(null);
	const history = useHistory();
	const location = useLocation();

	console.log('---id', id);

	console.log('editId', editId);

	const toggleEditId = (_id: number) => {
		setEditId((prev) => {
			return prev === _id ? null : _id;
		});
	};

	useEffect(() => {
		const params = {
			id,
		};
		// history.push({
		// 	pathname: location.pathname,
		// });
		dispatch(getUserBankAccountRequest(params));
	}, []);

	return (
		<>
			{bankAccountLoader ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<>
					<BankAccountTableHeader />
					<div className="table-body">
						{bankAccountList?.map((item: IUserBankAccountItem) => (
							<BankAccountTableItem
								item={item}
								key={item.id}
								active={item.id === editId}
								toggleActive={toggleEditId}
							/>
						))}
						{bankAccountList?.length === 0 ? (
							<p style={{ padding: '20px' }}>Bank account list is empty</p>
						) : null}
					</div>
				</>
			)}
		</>
	);
};
