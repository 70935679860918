/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCryptoDailyLimitationsRequest } from 'redux/reducers/limitations/reducer';
import { getLimitationsList, getLimitationsIsLoad } from 'redux/reducers/limitations/selectors';
import Loader from 'ui/Loader';
import { LimitationsItem } from './LimitationsItem';
// import { LimitationsWithdrawal } from './Withdrawal';
// import { LimitationsDeposit } from './Deposit';

export const DailyCrypto = () => {
	const dispatch = useDispatch();
	const limitations = useSelector(getLimitationsList);
	const limitationsLoader = useSelector(getLimitationsIsLoad);

	useEffect(() => {
		dispatch(getCryptoDailyLimitationsRequest());
	}, [dispatch]);

	return (
		<div className="content-block content-block--user-management">
			<div className="content-block__inside content-block__inside--type3">
				<div className="table-block table-block--big-mt">
					<div className="table-wrapper">
						<div className="table table--limitations-daily-crypto">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Currency</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Max Withdrawal</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>No limit</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>VIP Max Withdrawal</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>VIP No limit</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name td-name--action">
											<p>Action</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body">
								{!limitations?.length && !limitationsLoader && (
									<div className="user-management-empty-data">
										<p className="user-management-empty__text">No users found...</p>
									</div>
								)}
								{limitationsLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<div className="table-body">
										{limitations?.map((item) => (
											<LimitationsItem key={item.id} data={item} />
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
