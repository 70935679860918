/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { DateRangePicker } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { useDateTimePicker } from 'services/hooks/useDateTimePicker';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import {
	getPendingDepositsRequest,
	setFilterParams,
	approveAllDepositsRequest,
	rejectAllDepositsRequest,
} from 'redux/reducers/pendingDeposits/reducer';
import { IGetPendingDepositsRequest } from 'redux/reducers/pendingDeposits/types';
import {
	getPendingDeposits,
	getFilterParams,
	getPeningDepositsExportLoad,
	getChangeStatusAllLoder,
} from 'redux/reducers/pendingDeposits/selectors';
import {
	PENDING_DEPOSITS_SEARCH_ARRAY,
	PENDING_DEPOSITS_SEARCH_TEXT_ARRAY,
} from 'redux/reducers/pendingDeposits/constants';
import SearchBar from 'components/SearchBar';
import { ExportIcon, ClockIcon } from 'assets/img/icons';
import { AcceptWithdrawalsModal } from './DepositsList/DepositItem/ActionButtons/AcceptWithdrawalsModal';
import { RejectWithdrawalsModal } from './DepositsList/DepositItem/ActionButtons/RejectWithdrawalsModal';
import DepositsList from './DepositsList';

const PendingDeposits = () => {
	const deposits = useSelector(getPendingDeposits);
	const exportLoading = useSelector(getPeningDepositsExportLoad);
	const changeStatusAll = useSelector(getChangeStatusAllLoder);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const { checkEditable } = usePermission();

	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(
		field ? String(field) : PENDING_DEPOSITS_SEARCH_ARRAY[0],
	);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const filterParams = useSelector(getFilterParams);

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const handleDataSelect = (start_date: string | null, end_date: string | null) => {
		dispatch(setFilterParams({ start_date, end_date }));
		const params: IGetPendingDepositsRequest = {
			search_field: text ? searchField : undefined,
			search_value: text ? String(text) : undefined,
			start_date,
			end_date,
		};

		const searchParams = text
			? `?${String(text) && `&text=${String(text)}&field=${searchField}`}`
			: '';

		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getPendingDepositsRequest(params));
	};

	const { afterToday } = DateRangePicker;

	const { dateRangeValue, timeRangeValue, changeDateRangeHandler, changeTimeRangeHandler } =
		useDateTimePicker({
			onDateSelect: handleDataSelect,
		});

	const now = new Date();
	const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

	const handleSearch = (txt: string) => {
		const params = {
			search_value: txt || undefined,
			search_field: txt ? searchField : undefined,
			start_date: startDate || null,
			end_date: endDate || null,
		};

		dispatch(getPendingDepositsRequest(params));

		history.push({
			pathname: location.pathname,
			search: txt.length >= 1 ? `?text=${String(txt)}&field=${searchField}` : ``,
		});
		setCurrentPage(1);
	};

	const handleExport = () => {
		const params = {
			search_field: text ? searchField : undefined,
			search_value: text ? String(text) : undefined,
			start_date: startDate || null,
			end_date: endDate || null,
			export: 1,
		};
		dispatch(getPendingDepositsRequest(params));
	};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	const [selectedItems, setSelectedItems] = useState<Record<number, boolean>>({});

	const [acceptOpen, setAcceptOpen] = useState(false);

	const [rejectOpen, setRejectOpen] = useState(false);

	const selectedCount = deposits?.filter((item) => selectedItems[item.id])?.length || 0;

	const handleSelectItem = (id: number, checked: boolean) => {
		setSelectedItems((prev) => ({ ...prev, [id]: checked }));
	};

	const handleSelectAll = (checked: boolean) => {
		const result = (deposits || [])?.reduce((acc, curr) => {
			return {
				...acc,
				[curr.id]: checked,
			};
		}, {});
		setSelectedItems(result);
	};

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 400);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};
	const searchFieldHandler = (txt: string) => {
		setSearchField(txt);
	};

	const searchHandler = (txt: string) => {
		setDebouncedTerm(txt);
	};

	useEffect(() => {
		return () => {
			dispatch(setFilterParams({ start_date: null, end_date: null }));
		};
	}, [dispatch]);

	const handleApproveAll = () => {
		if (!checkEditable(permissions.MANAGE_PENDING_DEPOSITS)) return;
		dispatch(
			approveAllDepositsRequest({
				apiParams: {
					ids: Object.keys(selectedItems)
						.filter((key) => selectedItems[+key])
						.map((item) => +item),
				},
			}),
		);
		handleClearSearch();
	};

	const handleRejectAll = (comment: string) => {
		if (!checkEditable(permissions.MANAGE_PENDING_DEPOSITS)) return;
		dispatch(
			rejectAllDepositsRequest({
				apiParams: {
					ids: Object.keys(selectedItems)
						.filter((key) => selectedItems[+key])
						.map((item) => +item),
					reject_reason: comment,
				},
			}),
		);
		handleClearSearch();
	};

	return (
		<>
			<div className="title-block">
				<p className="title">Pending deposits</p>
			</div>
			<div className="count-label count-label--filter-type">
				<SearchBar
					setDebouncedTerm={searchHandler}
					debouncedTerm={debouncedTerm}
					searchFieldArray={PENDING_DEPOSITS_SEARCH_ARRAY}
					searchField={searchField}
					setSearchField={searchFieldHandler}
					handleClearSearch={handleClearSearch}
					searchFieldTextArray={PENDING_DEPOSITS_SEARCH_TEXT_ARRAY}
				/>
				<DateRangePicker
					format="dd/MM/yyyy"
					size="lg"
					block
					placement="bottomEnd"
					style={{ width: '308px' }}
					disabledDate={afterToday?.()}
					value={dateRangeValue}
					onChange={changeDateRangeHandler}
					defaultCalendarValue={[prevMonth, now]}
					ranges={[]}
				/>

				<DateRangePicker
					className="time-datepicker"
					format="HH:mm"
					size="lg"
					block
					placement="bottomEnd"
					style={{ width: '200px' }}
					value={timeRangeValue}
					onChange={changeTimeRangeHandler}
					defaultCalendarValue={[prevMonth, now]}
					ranges={[]}
					caretAs={ClockIcon}
				/>

				<button
					type="button"
					className="transaction-export-btn"
					onClick={handleExport}
					disabled={exportLoading}
				>
					Export
					<span className="transaction-export-btn__icon">
						<ExportIcon />
					</span>
				</button>
			</div>

			<div className="count-label count-label--mt-24 count-label--filter-between">
				<p className="count-label__text">
					Selected: <span>{selectedCount}</span>
				</p>
				<div className="filter-group">
					<button
						className="button button--green-type"
						disabled={
							selectedCount === 0 ||
							!checkEditable(permissions.MANAGE_PENDING_DEPOSITS) ||
							changeStatusAll
						}
						onClick={() => setAcceptOpen(true)}
					>
						Approve Selected
					</button>
					<button
						className="button button--red-type"
						disabled={
							selectedCount === 0 ||
							!checkEditable(permissions.MANAGE_PENDING_DEPOSITS) ||
							changeStatusAll
						}
						onClick={() => setRejectOpen(true)}
					>
						Reject Selected
					</button>
				</div>
			</div>

			<DepositsList
				deposits={deposits || []}
				handleClearSearch={handleClearSearch}
				onSelectItem={handleSelectItem}
				selectedItems={selectedItems}
				onSelectAll={handleSelectAll}
			/>

			<AcceptWithdrawalsModal
				openModal={acceptOpen}
				closeModal={() => setAcceptOpen(false)}
				handleAcceptDeposit={handleApproveAll}
			/>
			<RejectWithdrawalsModal
				openModal={rejectOpen}
				closeModal={() => setRejectOpen(false)}
				handleRejectDeposit={handleRejectAll}
			/>
		</>
	);
};

export default PendingDeposits;
