import { getDetailsRequest, getDetailsSuccess } from 'redux/reducers/dispute/reducer';
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { take, fork, call, put, race, select } from 'redux-saga/effects';
import { eventChannel, EventChannel } from 'redux-saga';
import L from 'i18n-react';
import { WEB_SOCKETS_URL } from 'services/constants/env';
import { getChatRequest, postChatMessageSendUpdate } from 'redux/reducers/chat/reducer';
import { IApiDisputeDetails } from 'services/api/dispute/types';
import {
	setSocketsConnect,
	setSocketsDisconnect,
	socketClosedConnection,
	socketOpenConnection,
	socketSendMessage,
} from './reducer';
import { notificationContainer } from '../../../utils/notificationContainer';
import { IEvidencesResponse, IPrivateNotificationsData, SocketsResponseData } from './types';
import { getDetailsItem } from '../dispute/selectors';

export const socketConnection = (socketToken: string | null) => {
	return new Promise((resolve, reject) => {
		let socket: WebSocket;

		if (socketToken) {
			socket = new WebSocket(`${String(WEB_SOCKETS_URL)}/?${socketToken}`, ['wamp']);
		} else {
			socket = new WebSocket(`${String(WEB_SOCKETS_URL)}`, ['wamp']);
		}

		socket.onopen = () => {
			resolve(socket);
			// console.log('Connection open...');
		};
		socket.onerror = (event) => {
			reject(event);
		};
		socket.onclose = (event) => {
			if (event.wasClean) {
				// console.log('Connection closed...');
			} else {
				// console.log('Lost connection...');
			}
		};
	});
};

export const socketChannel = (socketValue: WebSocket) => {
	const socket = socketValue;
	return eventChannel((emiter) => {
		socket.onmessage = ({ data }) => {
			emiter(JSON.parse(data));
		};
		return () => {
			socket.close();
		};
	});
};

function* socketSend(socket: WebSocket) {
	const isOpenSocket = socket.readyState === socket.OPEN;
	if (isOpenSocket) {
		while (true) {
			const { payload }: { payload: PayloadAction } = yield take(socketSendMessage.type);
			socket.send(JSON.stringify(payload));
		}
	}
}

function* socketClose(socket: WebSocket) {
	while (true) {
		yield take(socketClosedConnection.type);
		yield put(setSocketsDisconnect());

		socket.close();
	}
}
function* socketOnmessage(channel: EventChannel<SocketsResponseData>) {
	while (true) {
		const data: SocketsResponseData = yield take(channel);
		if (+data[0] === 8 && data[1] === 'admin_notification') {
			const notification: IPrivateNotificationsData = data[2].data;
			notificationContainer(String(notification.message), String(notification.type));
		}
		if (+data[0] === 8) {
			switch (data[1].split(':')[0]) {
				case 'chat':
					const chatP2P = data[2].data;
					yield put(postChatMessageSendUpdate(chatP2P));
					// yield put(
					// 	getChatRequest({ trade_id: window.location.pathname.split('/dispute-info/')[1] }),
					// );
					break;
				case 'dispute_evidence_updated':
					const details: IApiDisputeDetails | null = yield select(getDetailsItem);
					const evidences = data[2].data;

					if (details?.trade.id === evidences.trade_id && evidences.evidences && details) {
						const allEvidences = details.evidences;
						const newEvidences = evidences.evidences;

						if (details.evidences.length < evidences.evidences.length) {
							const difference = newEvidences.find((ev) =>
								allEvidences.every((item) => item.id !== ev.id),
							);

							yield put(
								getDetailsSuccess({
									...details,
									evidences: [...details.evidences, { ...difference, user_id: evidences.user_id }],
								}),
							);
						}
						if (details.evidences.length > evidences.evidences.length) {
							const difference = allEvidences.find((ev) =>
								newEvidences.every((item) => item.id !== ev.id),
							);
							const filtered = allEvidences.filter((ev) => ev.id !== difference?.id);

							yield put(
								getDetailsSuccess({
									...details,
									evidences: [...filtered],
								}),
							);
						}
					}
					break;
				default:
					break;
			}
		}
	}
}
export function* socketsSaga() {
	try {
		while (true) {
			const { payload }: { payload: PayloadAction } = yield take(socketOpenConnection.type);

			const socketToken: any = payload; // TODO: add types

			const socket: WebSocket = yield call(socketConnection, socketToken);
			const channel: EventChannel<any> = yield call(socketChannel, socket);

			if (socket.onopen) {
				yield put(setSocketsConnect());
			}

			yield fork(socketSend, socket);
			yield fork(socketClose, socket);

			const { cancel } = yield race({
				task: call(socketOnmessage, channel),
				cancel: take(socketClosedConnection.type),
			});

			if (cancel) {
				channel.close();
			}
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('websockets_error', error);
		// eslint-disable-next-line no-console
		console.dir('websockets_error', error);
	}
}
