import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getManagementHistory } from 'redux/reducers/tokenManagement/selectors';
import { ITokenHistoryDataItem } from 'redux/reducers/tokenManagement/types';
import HistoryItem from './HistoryItem';

const HistoryTab = () => {
	const [timeSort, setTimeSort] = useState<boolean>(false);
	const [currencySort, setCurrencySort] = useState<boolean>(false);
	const tokenHistory = useSelector(getManagementHistory);

	const renderItems = () => {
		let data: ITokenHistoryDataItem[] = tokenHistory?.data || [];
		if (timeSort) {
			data = [...data]?.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
		}
		if (currencySort) {
			data = [...data]?.sort((a, b) => (a.asset_id > b.asset_id ? 1 : -1));
		}
		return data?.map((item: ITokenHistoryDataItem) => <HistoryItem data={item} key={item.id} />);
	};
	return (
		<div className="table-block table-block--big-mt">
			<div className="table-wrapper">
				<div className="table table--token-management-history">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>ID</p>
								</div>
							</div>
							<div className="td td--status">
								<div className="td-name td-name--status">
									<p>Time</p>
									<span className="arrow-icon" onClick={() => setTimeSort(!timeSort)}>
										<svg
											style={{ transform: `${timeSort ? 'rotate(180deg)' : ''}` }}
											width="8"
											height="5"
											viewBox="0 0 8 5"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M1 1L4 4L7 1" stroke="#011E26" strokeLinecap="square" />
										</svg>
									</span>
								</div>
							</div>
							<div className="td td--status">
								<div className="td-name td-name--status">
									<p>Asset</p>
									<span className="arrow-icon" onClick={() => setCurrencySort(!currencySort)}>
										<svg
											style={{ transform: `${currencySort ? 'rotate(180deg)' : ''}` }}
											width="8"
											height="5"
											viewBox="0 0 8 5"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M1 1L4 4L7 1" stroke="#011E26" strokeLinecap="square" />
										</svg>
									</span>
									<div className="status-drop ">
										<ul className="status-drop__list ">
											<li className="status-drop__item">VIP</li>
											<li className="status-drop__item">Regular</li>
											<li className="status-drop__item">All</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Network</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Type</p>
								</div>
							</div>

							<div className="td">
								<div className="td-name">
									<p>Value</p>
								</div>
							</div>

							<div className="td">
								<div className="td-name">
									<p>Status</p>
								</div>
							</div>

							<div className="td">
								<div className="td-name">
									<p>Message</p>
								</div>
							</div>

							<div className="td td--right">
								<div className="td-name">
									<p>TxID</p>
								</div>
							</div>
						</div>
					</div>
					<div className="table-body">{renderItems()}</div>
				</div>
			</div>
		</div>
	);
};

export default HistoryTab;
