import { FC, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';

import { IDisputeItem } from 'redux/reducers/dispute/types';
import { getDisputeList, getDisputeLoading } from 'redux/reducers/dispute/selectors';
import { getDisputeRequest } from 'redux/reducers/dispute/reducer';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import DisputeTableHeader from './DisputeTableHeader';
import DisputeTableItem from './DisputeTableItem';

const Dispute: FC = () => {
	const disputeList = useSelector(getDisputeList);
	const isDisputeLoading = useSelector(getDisputeLoading);
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const parsedQueryStr = queryString.parse(location.search);
	const lastPage = disputeList?.last_page;

	const getCurrentPage = () => {
		const pageNum = Number(parsedQueryStr.page);
		if (Number.isInteger(pageNum) && pageNum >= 1) {
			return pageNum;
		}
		return null;
	};
	const currentPage = getCurrentPage();

	const getIsShowPagination = () => {
		if (!currentPage || !lastPage) {
			return false;
		}
		return lastPage > 1 && currentPage <= lastPage;
	};

	const navigateToPage = (page: number) => {
		const search = queryString.stringify({ ...parsedQueryStr, page });
		history.push({ search });
	};

	useLayoutEffect(() => {
		if (!currentPage) {
			navigateToPage(1);
			return;
		}
		const params = {
			per_page: 15,
			current_page: currentPage,
		};
		dispatch(getDisputeRequest(params));
	}, [currentPage]); //eslint-disable-line

	return (
		<div className="dispute">
			<div className="title-block">
				<p className="title">Dispute</p>
			</div>

			{isDisputeLoading && (
				<div className="list-loader">
					<Loader />
				</div>
			)}

			{!isDisputeLoading && (
				<div className="table-block">
					<div className="table-wrapper">
						<div className="table">
							<DisputeTableHeader />

							<div className="table-body">
								{disputeList?.data.map((item: IDisputeItem) => (
									<DisputeTableItem item={item} key={item.id} />
								))}

								{disputeList?.data.length === 0 && <p style={{ padding: 20 }}>List is empty</p>}
							</div>
						</div>
					</div>

					{getIsShowPagination() && (
						<Pagination
							pageCount={lastPage as number}
							forcePage={(currentPage as number) - 1}
							onPageChange={navigateToPage}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default Dispute;
