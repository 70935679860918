/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IChangeLimitationsP2PLimitsResponse,
	IChangeLimitationsP2PPayload,
	IChangeLimitationsPayload,
	IDailyLimitationsItem,
	ILimitationsP2PData,
	ILimitationsP2PLimits,
	ILimitationsRequestPayload,
	ILimitationsResponsePayload,
	ILimitationsStore,
	ILimitationsItem,
	IUpdateFiatSingleRequest,
} from './types';
// ==========================================:
export const initialState: ILimitationsStore = {
	limitations: null,
	limitationsLoader: false,
	limitationsFiat: null,
	limitationsFiatLoader: false,
	dailyLimitations: null,
	dailyLimitationsLoader: false,
	limitationsP2P: null,
	limitationsP2PLoader: false,
	changeLimitationsLoader: false,
};

// ==========================================:
const limitations = createSlice({
	name: '@@Limitations',
	initialState,
	reducers: {
		getLimitationsRequest: (state, action: PayloadAction<ILimitationsRequestPayload>) => {
			const limitationsRequestState = state;
			limitationsRequestState.limitationsLoader = true;
		},
		getLimitationsSuccess: (state, action: PayloadAction<ILimitationsResponsePayload>) => {
			const { payload } = action;
			const limitationsSuccessState = state;
			limitationsSuccessState.limitations = payload;
			limitationsSuccessState.limitationsLoader = false;
		},
		changeLimitationsRequest: (state, action: PayloadAction<IChangeLimitationsPayload>) => {
			const changeLimitationsState = state;
			changeLimitationsState.changeLimitationsLoader = true;
		},
		changeLimitationsSuccess: (state) => {
			const changeLimitationsState = state;
			changeLimitationsState.changeLimitationsLoader = false;
		},
		// fiat
		getLimitationsFiatRequest: (state, action: PayloadAction<ILimitationsRequestPayload>) => {
			const limitationsRequestState = state;
			limitationsRequestState.limitationsFiatLoader = true;
		},
		getLimitationsFiatSuccess: (state, action: PayloadAction<ILimitationsResponsePayload>) => {
			const { payload } = action;
			const limitationsSuccessState = state;
			limitationsSuccessState.limitationsFiat = payload;
			limitationsSuccessState.limitationsFiatLoader = false;
		},
		changeLimitationsFiatRequest: (state, action: PayloadAction<any>) => {
			const changeLimitationsState = state;
			changeLimitationsState.limitationsFiatLoader = true;
		},
		changeLimitationsFiatSuccess: (state) => {
			const changeLimitationsState = state;
			changeLimitationsState.limitationsFiatLoader = false;
		},
		//
		getDailyLimitationsRequest: (state, action: PayloadAction<ILimitationsRequestPayload>) => {
			const dailyLimitationsState = state;
			dailyLimitationsState.dailyLimitationsLoader = true;
		},
		getDailyLimitationsSuccess: (state, { payload }: PayloadAction<IDailyLimitationsItem>) => {
			const dailyLimitationsState = state;
			dailyLimitationsState.dailyLimitations = payload;
			dailyLimitationsState.dailyLimitationsLoader = false;
		},
		changeDailyLimitationsRequest: (state, action: PayloadAction<IDailyLimitationsItem>) => {
			const dailyLimitationsState = state;
			dailyLimitationsState.dailyLimitationsLoader = true;
		},
		changeDailyLimitationsSuccess: (state) => {
			const dailyLimitationsState = state;
			dailyLimitationsState.dailyLimitationsLoader = false;
		},
		disableDailyLimitationsLoader: (state) => {
			const dailyLimitationsState = state;
			dailyLimitationsState.dailyLimitationsLoader = false;
		},

		getLimitationsP2PRequest: (state) => {
			const limitationsP2PRequestState = state;
			limitationsP2PRequestState.limitationsP2PLoader = true;
		},
		getLimitationsP2PSuccess: (state, { payload }: PayloadAction<ILimitationsP2PData>) => {
			const limitationsP2PSuccessState = state;

			limitationsP2PSuccessState.limitationsP2P = payload;
			limitationsP2PSuccessState.limitationsP2PLoader = false;
		},
		changeLimitationsP2PRequest: (state, action: PayloadAction<IChangeLimitationsP2PPayload>) => {
			const limitationsP2PRequestState = state;
			limitationsP2PRequestState.limitationsP2PLoader = true;
		},
		changeLimitationsP2PSuccess: (
			state,
			{ payload }: PayloadAction<IChangeLimitationsP2PLimitsResponse>,
		) => {
			const limitationsP2PSuccessState = state;
			limitationsP2PSuccessState.limitationsP2P = payload.data;
			limitationsP2PSuccessState.limitationsP2PLoader = false;
		},
		disableLimitationsP2PLoader: (state) => {
			const limitationsP2PState = state;
			limitationsP2PState.limitationsP2PLoader = false;
		},
		// **********************************************************
		getCryptoSingleLimitationsRequest: (state) => {
			const limitationsRequestState = state;
			limitationsRequestState.limitationsLoader = true;
		},
		getCryptoDailyLimitationsRequest: (state) => {
			const limitationsRequestState = state;
			limitationsRequestState.limitationsLoader = true;
		},
		getCryptoMonthlyLimitationsRequest: (state) => {
			const limitationsRequestState = state;
			limitationsRequestState.limitationsLoader = true;
		},
		getSingleFiatLimitationsRequest: (state) => {
			const limitationsRequestState = state;
			limitationsRequestState.limitationsLoader = true;
		},
		getDailyFiatLimitationsRequest: (state) => {
			const limitationsRequestState = state;
			limitationsRequestState.limitationsLoader = true;
		},
		getMonthlyFiatLimitationsRequest: (state) => {
			const limitationsRequestState = state;
			limitationsRequestState.limitationsLoader = true;
		},
		updateSingleFiatWithdrawRequest: (state, action: PayloadAction<IUpdateFiatSingleRequest>) => {
			const limitationsRequestState = state;
			limitationsRequestState.changeLimitationsLoader = true;
		},
		updateSingleCryptoWithdrawRequest: (state, action: PayloadAction<IUpdateFiatSingleRequest>) => {
			const limitationsRequestState = state;
			limitationsRequestState.changeLimitationsLoader = true;
		},
		updateDailyFiatLimitationRequest: (state, action: PayloadAction<IUpdateFiatSingleRequest>) => {
			const limitationsRequestState = state;
			limitationsRequestState.changeLimitationsLoader = true;
		},
		updateCryptoDailyWithdrawRequest: (state, action: PayloadAction<IUpdateFiatSingleRequest>) => {
			const limitationsRequestState = state;
			limitationsRequestState.changeLimitationsLoader = true;
		},
		updateCryptoMonthlyLimitationRequest: (
			state,
			action: PayloadAction<IUpdateFiatSingleRequest>,
		) => {
			const limitationsRequestState = state;
			limitationsRequestState.changeLimitationsLoader = true;
		},
		updateFiatMonthlyLimitationRequest: (
			state,
			action: PayloadAction<IUpdateFiatSingleRequest>,
		) => {
			const limitationsRequestState = state;
			limitationsRequestState.changeLimitationsLoader = true;
		},
		updateSingleFiatWithdrawSuccess: (state, { payload }: PayloadAction<ILimitationsItem>) => {
			const limitationsRequestState = state;
			limitationsRequestState.changeLimitationsLoader = false;
			if (!limitationsRequestState.limitations) {
				return;
			}
			limitationsRequestState.limitations = limitationsRequestState.limitations?.map((item) => {
				if (item.id === payload.id) {
					return payload;
				}
				return item;
			});
		},
		limitationsInitState: () => initialState,
	},
});

export default limitations.reducer;
export const {
	getLimitationsRequest,
	getLimitationsSuccess,
	getLimitationsFiatRequest,
	getLimitationsFiatSuccess,
	changeLimitationsFiatRequest,
	changeLimitationsFiatSuccess,
	changeLimitationsRequest,
	changeLimitationsSuccess,
	getDailyLimitationsRequest,
	getDailyLimitationsSuccess,
	changeDailyLimitationsRequest,
	changeDailyLimitationsSuccess,
	disableDailyLimitationsLoader,
	getLimitationsP2PRequest,
	getLimitationsP2PSuccess,
	changeLimitationsP2PSuccess,
	changeLimitationsP2PRequest,
	disableLimitationsP2PLoader,
	getSingleFiatLimitationsRequest,
	updateSingleFiatWithdrawRequest,
	updateSingleFiatWithdrawSuccess,
	getCryptoSingleLimitationsRequest,
	updateSingleCryptoWithdrawRequest,
	getDailyFiatLimitationsRequest,
	updateDailyFiatLimitationRequest,
	getCryptoDailyLimitationsRequest,
	updateCryptoDailyWithdrawRequest,
	getCryptoMonthlyLimitationsRequest,
	updateCryptoMonthlyLimitationRequest,
	getMonthlyFiatLimitationsRequest,
	updateFiatMonthlyLimitationRequest,
	limitationsInitState,
} = limitations.actions;
