/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { noExponent } from 'utils/numberFormat';
import { IConvertsItemProps } from './types';

export const ConvertsItem: FC<IConvertsItemProps> = ({ data }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);

	return (
		<div className="tr">
			<Link className="td" to={`/user-management/${String(data.user_id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{data.user_id}</p>
			</Link>
			{data.referral_id != null ? (
				<Link className="td" to={`/user-management/${String(data.referral_id)}`}>
					<p className="td-hidden-name">Referral ID</p>
					<p>{data.referral_id}</p>
				</Link>
			) : (
				<div className="td">
					<p className="td-hidden-name">Referral ID</p>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Date</p>
				{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
				{dateOffset.toLocaleTimeString()}
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{noExponent(String(data.fee))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">From</p>
				<p>{data.from_asset_code.toUpperCase()}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">To</p>
				<p>{data.to_asset_code.toUpperCase()}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount (From)</p>
				<p>{noExponent(data.quantity)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount (From) in USD</p>
				<p>{noExponent(Number(data.amount_from_usd))}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount (To)</p>
				<p>{noExponent(data.amount)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount (To) in USD</p>
				<p>{noExponent(Number(data.amount_to_usd))}</p>
			</div>
		</div>
	);
};
