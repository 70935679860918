/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { getTradesMarginRequest } from 'redux/reducers/trade/reducer';
import { getP2PRequest } from 'redux/reducers/сurrencies/reducer';
import { getP2PList, getP2PLoading } from 'redux/reducers/сurrencies/selectors';
import { CurrenciesP2PTableHeader } from './CurrenciesP2PTableHeader';
import { IP2PItem } from './types';
import CurrenciesP2PTableItem from './CurrenciesP2PTableItem';

export const CurrenciesP2P: FC = () => {
	const generalList = useSelector(getP2PList);
	const generalLoading = useSelector(getP2PLoading);
	const [radioCheck] = useState('margin');
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			id,
			per_page: 15,
			[radioCheck]: radioCheck,
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getTradesMarginRequest(params));
	};
	useEffect(() => {
		dispatch(getP2PRequest());
	}, []);
	return (
		<div className="trades-marginTrades">
			{generalLoading ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table currencies-p2p">
							<CurrenciesP2PTableHeader />
							<div className="table-body">
								{generalList?.map((item: IP2PItem) => (
									<CurrenciesP2PTableItem item={item} key={item.id} />
								))}
								{generalList?.length === 0 ? (
									<p style={{ padding: '20px' }}>Orders history is empty</p>
								) : null}
							</div>
						</div>
					</div>
					<div style={{ padding: '14px' }}>
						{(generalList?.last_page ?? 0) > 1 && (
							<Pagination
								pageCount={generalList?.last_page ?? 0}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</div>
	);
};
