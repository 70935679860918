import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CreateAdmin from 'components/AdminManagement/CreateAdmin';
import { getPermissionsListRequest } from 'redux/reducers/adminManagement/reducer';
import Dashboard from 'layouts/Dashboard';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ==========================================:
const CreateAdminPage: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPermissionsListRequest());
	}, [dispatch]);

	return (
		<PermissionGate
			permissions={permissions.MANAGE_ADMINS}
			permissionsEdit={permissions.MANAGE_ADMINS}
		>
			<Dashboard title="Create Admin">
				<CreateAdmin />
			</Dashboard>
		</PermissionGate>
	);
};

export default CreateAdminPage;
