import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getWalletsListData,
	getWalletsListDataLoader,
} from 'redux/reducers/walletManagement/selectors';
import {
	deleteExternalWalletRequest,
	getWalletsListRequest,
} from 'redux/reducers/walletManagement/reducer';
import Loader from 'ui/Loader';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IDeleteExternalWalletPayload,
	IExternalWalletRequestPayload,
} from 'redux/reducers/walletManagement/types';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import ExternalWalletWarningPopup from './WarningPopup';
import { ExternalWalletItem } from './ExternalWalletItem';
import DeletePopup from './DeletePopup';

export const ExternalWallet: FC = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const [deleteId, setDeleteId] = useState<number | null>(null);
	const [isWarning, setIsWarning] = useState(false);

	const externalWalletListLoader = useSelector(getWalletsListDataLoader);
	const externalWalletsList = useSelector(getWalletsListData);

	useEffect(() => {
		dispatch(getWalletsListRequest({}));
	}, [dispatch]);

	const handleDeleteClick = (id: number) => {
		setDeleteId(id);
	};

	const handleDeleteItem = () => {
		if (deleteId == null) {
			return;
		}
		const payload: IDeleteExternalWalletPayload = {
			id: deleteId,
			onSuccess: () => {
				notificationContainer('External Wallet successfully deleted', 'success');
				setDeleteId(null);
			},
		};
		dispatch(deleteExternalWalletRequest(payload));
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params: IExternalWalletRequestPayload = {
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getWalletsListRequest(params));
	};
	const handleAcceptWarning = () => {
		setIsWarning(false);
	};

	return (
		<>
			<div className="content-block content-block--walletman">
				<div>
					<div className="table-block table-block--walletman">
						<div className="table-wrapper">
							<div className="table table--wallet-external">
								<div className="table-header">
									<div className="tr">
										<div className="td">
											<div className="td-name">
												<p>Liquidity Provider Name</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Created Date</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Coin</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Wallet Address</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Chain</p>
											</div>
										</div>
										<div className="td td--right">
											<div className="td-name">
												<p>On/Off</p>
											</div>
										</div>
										<div className="td td--right">
											<div className="td-name">
												<p>Action</p>
											</div>
										</div>
									</div>
								</div>

								{!externalWalletsList?.data.length && !externalWalletListLoader && (
									<div className="user-management-empty-data">
										<p className="user-management-empty__text">No wallets found...</p>
									</div>
								)}

								{externalWalletListLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<div className="table-body">
										{externalWalletsList?.data?.map((item) => (
											<ExternalWalletItem
												key={item.id}
												data={item}
												onDelete={handleDeleteClick}
												setIsWarning={setIsWarning}
											/>
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{(externalWalletsList?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={externalWalletsList?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
			{deleteId != null && (
				<DeletePopup onAccept={handleDeleteItem} onCancel={() => setDeleteId(null)} />
			)}
			{isWarning && <ExternalWalletWarningPopup onAccept={handleAcceptWarning} />}
		</>
	);
};
