import { FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ITokenHistoryDataItem } from 'redux/reducers/tokenManagement/types';
import { notificationContainer } from 'utils/notificationContainer';

type THistoryItemProps = {
	data: ITokenHistoryDataItem;
};

const HistoryItem: FC<THistoryItemProps> = ({ data }) => {
	const [copied, setCopied] = useState<boolean>(false);

	useEffect(() => {
		if (copied) {
			setCopied(!copied);
			notificationContainer('Hash copied to clipboard', 'info');
		}
	}, [copied]);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Time</p>
				<p>{new Date(data.created_at * 1000).toLocaleString()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Asset</p>
				<p>{data.asset.code.slice(0, -1).toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Network</p>
				<p>{data.network.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{data.type}</p>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Value</p>
				<p>{data.value}</p>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Status</p>
				<p>{data.status}</p>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Message</p>
				<p>{data.message}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">TxID</p>
				<div className="copy-buttun">
					<a
						href={data.tx_url}
						target="_blank"
						rel="noreferrer"
						className="copy-buttun__text"
						style={{ textDecoration: 'none', color: '#000' }}
					>
						{data.transaction_hash.slice(0, 9)}...
						{data.transaction_hash.slice(
							+data.transaction_hash.length - 7,
							data.transaction_hash.length,
						)}
					</a>
					<CopyToClipboard text={data.transaction_hash} onCopy={() => setCopied(true)}>
						<button className="copy-buttun__item" type="button">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3.33337 10.0007H2.66671C2.31309 10.0007 1.97395 9.86018 1.7239 9.61013C1.47385 9.36008 1.33337 9.02094 1.33337 8.66732V2.66732C1.33337 2.3137 1.47385 1.97456 1.7239 1.72451C1.97395 1.47446 2.31309 1.33398 2.66671 1.33398H8.66671C9.02033 1.33398 9.35947 1.47446 9.60952 1.72451C9.85956 1.97456 10 2.3137 10 2.66732V3.33398"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</CopyToClipboard>
				</div>
			</div>
		</div>
	);
};

export default HistoryItem;
