import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { setFilterParams, getUsersRequest } from 'redux/reducers/userManagement/reducer';
import { getFilterParams } from 'redux/reducers/userManagement/selectors';
import { ManageFilter } from '../Filter';

export const UserFilter = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const filterParams = useSelector(getFilterParams);
	const { start_date: startDate, end_date: endDate } = filterParams || {};
	const handleDataSelect = (start_date: string | null, end_date: string | null) => {
		dispatch(setFilterParams({ start_date, end_date }));
	};

	const handleExport = () => {
		const params = {
			current_page: page || 1,
			export: 1,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
			search_field: field ? String(field) : null,
			search_value: text ? String(text) : null,
		};
		dispatch(getUsersRequest(params));
	};
	return (
		<ManageFilter
			exportTitle="Export users"
			onDateSelect={handleDataSelect}
			onExport={handleExport}
		/>
	);
};
