import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { addBankAccountRequest, getBankAccountsRequest } from 'redux/reducers/bankAccount/reducer';
import { IAddBankAccountPayload, IAddBankAccountRequest } from 'redux/reducers/bankAccount/types';
import { getAssetsRequest } from 'redux/reducers/tradingPairs/reducer';
import { getAssetsList } from 'redux/reducers/tradingPairs/selectors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	beneficiary_name,
	beneficiary_bank,
	asset_id,
	iban,
	swift,
	beneficiary_bank_address,
	correspondent_bank,
} from 'utils/formValidation';
import SelectCurrency from './SelectCurrency';
import { IAddBankAccountPopup, TValues } from './types';

const initialValues: TValues = {
	beneficiary_name: '',
	beneficiary_bank: '',
	beneficiary_bank_address: '',
	swift: '',
	iban: '',
	correspondent_bank: '',
	is_partner: 1,
};

const AddBankAccountPopup: FC<IAddBankAccountPopup> = ({ onClose }) => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const assets = useSelector(getAssetsList);

	const validationSchema = yup.object().shape({
		beneficiary_name,
		beneficiary_bank,
		asset_id,
		iban,
		swift,
		beneficiary_bank_address,
		correspondent_bank,
	});

	useEffect(() => {
		if (assets) {
			return;
		}
		dispatch(getAssetsRequest());
	}, [dispatch, assets]);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-560">
					<button type="button" className="popup__close" onClick={onClose}>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15 3L3 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3 3L15 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">Add bank account</p>
					</div>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values: TValues) => {
							const payload: IAddBankAccountPayload = {
								apiParams: values as IAddBankAccountRequest,
								onSuccess: () => {
									notificationContainer('Bank account successfully added', 'success');
									dispatch(getBankAccountsRequest({ current_page: Number(page) || 1 }));
									onClose();
								},
							};
							dispatch(addBankAccountRequest(payload));
						}}
						validateOnBlur
						enableReinitialize
					>
						{({ errors, touched, values, setValues }) => {
							const showError = (field: keyof TValues) => touched[field] && errors[field];
							return (
								<Form>
									<div className="popup-body popup-body--type2">
										<div className="input ">
											<p className="input__name">Beneficiary name</p>
											<div
												className={`input-wrapper ${
													showError('beneficiary_name') ? 'input--error' : ''
												}`}
											>
												<div className="radio-row radio-row--mb-16">
													<div className="radio radio--no-mb radio--size2">
														<label className="radio__label">
															<input
																className="hidden"
																type="radio"
																name="one-group"
																checked={values.is_partner === 1}
																onClick={() => setValues({ ...values, is_partner: 1 })}
															/>
															<div className="radio__item" />
															<span className="radio__text">Partner Bank</span>
														</label>
													</div>
													<div className="radio radio--no-mb  radio--size2">
														<label className="radio__label">
															<input
																className="hidden"
																type="radio"
																name="one-group"
																checked={values.is_partner === 0}
																onClick={() => setValues({ ...values, is_partner: 0 })}
															/>
															<div className="radio__item" />
															<span className="radio__text">Other Banks</span>
														</label>
													</div>
												</div>
												<Field
													type="text"
													placeholder="Enter name"
													name="beneficiary_name"
													className="input-item"
												/>
												<ErrorMessage
													name="beneficiary_name"
													className="error-text"
													component="p"
												/>
											</div>
										</div>
										<div className="input ">
											<p className="input__name">Beneficiary Bank</p>
											<div
												className={`input-wrapper ${
													showError('beneficiary_bank') ? 'input--error' : ''
												}`}
											>
												<Field
													type="text"
													placeholder="Enter bank"
													name="beneficiary_bank"
													className="input-item"
												/>
												<ErrorMessage
													name="beneficiary_bank"
													className="error-text"
													component="p"
												/>
											</div>
										</div>
										<div className="input ">
											<p className="input__name">Currency</p>
											<Field
												type="text"
												placeholder="Enter bank"
												name="asset_id"
												className={showError('asset_id') ? 'select--error' : ''}
												component={SelectCurrency}
											/>
											{showError('asset_id') && <p className="error-text">{errors.asset_id}</p>}
										</div>
										<div className="input ">
											<p className="input__name">Beneficiary IBAN</p>
											<div className={`input-wrapper ${showError('iban') ? 'input--error' : ''}`}>
												<Field
													type="text"
													placeholder="Enter IBAN"
													name="iban"
													className="input-item"
												/>
												<ErrorMessage name="iban" className="error-text" component="p" />
											</div>
										</div>
										<div className="input ">
											<p className="input__name">Swift</p>
											<div className={`input-wrapper ${showError('swift') ? 'input--error' : ''}`}>
												<Field
													type="text"
													placeholder="Enter swift"
													name="swift"
													className="input-item"
												/>
												<ErrorMessage name="swift" className="error-text" component="p" />
											</div>
										</div>
										<div className="input ">
											<p className="input__name">Beneficiary Bank Address</p>
											<div
												className={`input-wrapper ${
													showError('beneficiary_bank_address') ? 'input--error' : ''
												}`}
											>
												<Field
													type="text"
													placeholder="Enter Address"
													name="beneficiary_bank_address"
													className="input-item"
												/>
												<ErrorMessage
													name="beneficiary_bank_address"
													className="error-text"
													component="p"
												/>
											</div>
										</div>
										<div className="input ">
											<p className="input__name">Correspondent Bank</p>
											<div
												className={`input-wrapper ${
													showError('correspondent_bank') ? 'input--error' : ''
												}`}
											>
												<Field
													type="text"
													placeholder="Enter Correspondent Bank"
													name="correspondent_bank"
													className="input-item"
												/>
												<ErrorMessage
													name="correspondent_bank"
													className="error-text"
													component="p"
												/>
											</div>
										</div>
									</div>
									<div className="popup-submit popup-submit--type2">
										<button
											className="button button--cancel  button--full-width"
											type="button"
											onClick={onClose}
										>
											Cancel
										</button>
										<button className="button button--check   button--full-width" type="submit">
											Add account
										</button>
									</div>
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default AddBankAccountPopup;
