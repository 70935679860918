import { FC, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SpotFilter } from './filters/SpotFilter';
import { MarginFilter } from './filters/MarginFilter';
import { P2PFilter } from './filters/P2PFilter';
import 'react-tabs/style/react-tabs.css';
import { P2POrders } from './OrdersBox/P2POrders/P2POrders';
import { MarginOrders } from './OrdersBox/MarginOrders/MarginOrders';
import { SpotOrders } from './OrdersBox/SpotOrders/SpotOrders';

const Orders: FC = () => {
	const [tabIndex, setTabIndex] = useState(0);
	const [radioCheck, setRadioCheck] = useState('margin');

	return (
		<div className="orders">
			<div className="title-block">
				<p className="title">Orders</p>
			</div>
			{tabIndex === 0 && <SpotFilter />}
			{tabIndex === 1 && <MarginFilter radioCheck={radioCheck} />}
			{tabIndex === 2 && <P2PFilter />}
			<Tabs selectedIndex={tabIndex} onSelect={setTabIndex} className="settings-tabs">
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link"> Spot orders</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link"> Margin orders(cross/isolated)</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link"> P2P Orders</span>
					</Tab>
				</TabList>
				<TabPanel>
					<SpotOrders radioCheck="cross" />
				</TabPanel>
				<TabPanel>
					<MarginOrders radioCheck={radioCheck} setRadioCheck={setRadioCheck} />
				</TabPanel>
				<TabPanel>
					<P2POrders radioCheck="cross" />
				</TabPanel>
			</Tabs>
		</div>
	);
};

export default Orders;
