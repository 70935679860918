import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { noExponent, capitalizeFirstLetter } from 'utils/numberFormat';
import { ISpotTradeProps } from './types';

const SpotTradesTableItem: FC<ISpotTradeProps> = ({ item }) => {
	const [created] = useState(item?.created_at);
	const [pair] = useState(item?.pair);
	const [type] = useState(item?.type);
	const [priceRequested] = useState(item?.price_requested);
	const [priceFilled] = useState(item?.price_filled);
	const [total] = useState(item?.asset_get_change);
	const [fee] = useState(item?.fee);
	const date = new Date(created * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+created + offset) * 1000);

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Date</p>
				<p className="grey-text">
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
			<Link className="td" to={`/user-management/${String(item.user_id)}`}>
				<p className="td-hidden-name">User ID 1</p>
				<p>{item.user_id}</p>
			</Link>
			<Link className="td" to={`/user-management/${String(item.matching_user_id)}`}>
				<p className="td-hidden-name">User ID 2</p>
				<p>{item.matching_user_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<p style={{ textTransform: 'uppercase' }}>{pair.replace('_', '/')}</p>
			</div>
			{item.referral_id != null ? (
				<Link className="td" to={`/user-management/${String(item.referral_id)}`}>
					<p className="td-hidden-name">Referral ID</p>
					<p>{item.referral_id}</p>
				</Link>
			) : (
				<div className="td">
					<p className="td-hidden-name">Referral ID</p>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Side</p>
				<p
					style={{
						color:
							capitalizeFirstLetter(type).toLowerCase().indexOf('buy') === -1
								? '#F44336'
								: '#03BC70',
					}}
				>
					{capitalizeFirstLetter(type)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Order ID</p>
				<p>{item.order_id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Matching Order ID</p>
				<p>{item.matching_order_id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Price</p>
				<p>{noExponent(Number(priceRequested).toString())}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Executed</p>
				<p>{noExponent(Number(priceFilled).toString())}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>
					{noExponent(Number(fee).toString())}{' '}
					{pair
						.split('_')
						[capitalizeFirstLetter(type).toLowerCase().indexOf('buy') === -1 ? 1 : 0].toUpperCase()}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total</p>
				<p>
					{noExponent(Number(total).toString())}{' '}
					{pair
						.split('_')
						[capitalizeFirstLetter(type).toLowerCase().indexOf('buy') === -1 ? 1 : 0].toUpperCase()}
				</p>
			</div>
		</div>
	);
};
export default SpotTradesTableItem;
