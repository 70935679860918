/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
	getRedemptionTransactionsList,
	getRedemptionTransactionsIsLoad,
} from 'redux/reducers/transactions/selectors';
import Loader from 'ui/Loader';
import { RedemptionItem } from './RedemptionItem';

export const Redemption: FC = () => {
	const redemption = useSelector(getRedemptionTransactionsList);
	const redemptionLoader = useSelector(getRedemptionTransactionsIsLoad);

	return (
		<div className="content-block content-block--transaction">
			<div className="content-block__inside content-block__inside--login-his">
				{(redemption?.data?.length ?? 0) < 1 && !redemptionLoader && (
					<div className="user-management-empty-data">
						<p className="user-management-empty__text">Referral not found...</p>
					</div>
				)}
				{redemptionLoader && (
					<div className="list-loader">
						<Loader />
					</div>
				)}
				{(redemption?.data?.length ?? 0) > 0 && !redemptionLoader ? (
					<div className="table-block">
						<div className="table-wrapper">
							<div className="table table--transaction-redemption">
								<div className="table-header">
									<div className="tr">
										<div className="td">
											<div className="td-name">
												<p>ID</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>User ID</p>
											</div>
										</div>

										<div className="td">
											<div className="td-name">
												<p>Phone number</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Initiated amount (gram)</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Available GOLDB amount</p>
											</div>
										</div>

										<div className="td">
											<div className="td-name">
												<p>Released Amount</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Fee</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Gold (gram)</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Reason of reject</p>
											</div>
										</div>
										<div className="td td--right">
											<div className="td-name">
												<p>Status</p>
											</div>
										</div>
									</div>
								</div>

								<div className="table-body">
									{redemption?.data.map((item) => (
										<RedemptionItem data={item} key={item.id} />
									))}
								</div>
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};
