import { TEmail2FAStatus } from 'redux/reducers/userManagement/types';

export const getStatusClass = (status: TEmail2FAStatus) => {
	switch (status) {
		case 'confirmed': {
			return 'status--success';
		}
		case 'unverified':
			return 'status--error';
		case 'expired':
			return 'status--expired';
		default:
			return '';
	}
};
