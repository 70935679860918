/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { getConvertSettings } from 'redux/reducers/settings/selectors';
import { IWithdrawalsListRequestPayload } from 'redux/reducers/withdrawals/types';
import { getWithdrawalsRequest } from 'redux/reducers/withdrawals/reducer';
import ConvertSettingsList from './ConvertSettingsList';

// ==========================================:
const ConvertSettings: FC = () => {
	const convertSettings = useSelector(getConvertSettings);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();

	const searchFieldArray = ['user_id'];
	const searchFieldTextArray = ['User ID'];

	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(field ? String(field) : searchFieldArray[0]);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: `?page=${String(pageNumber)}`;

		const params: IWithdrawalsListRequestPayload =
			verifyText.length > 0
				? {
						current_page: pageNumber || 1,
						search_field: searchField,
						search_value: verifyText,
				  }
				: {
						current_page: pageNumber || 1,
				  };
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getWithdrawalsRequest(params));
	};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 400);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	return (
		<>
			<div className="title-block">
				<p className="title">Convert Settings</p>
			</div>
			<ConvertSettingsList data={convertSettings} />

			{/* {(withdrawals?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={withdrawals?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)} */}
		</>
	);
};

export default ConvertSettings;
