import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IFeesStore,
	IFeesData,
	IPlatformFeesData,
	ICryptoFeesData,
	IFiatFeesData,
	IVolumeDependsFeeSettingsData,
} from './types';

const getFeesState = (state: IStoreState): IFeesStore => state.fees;
export const getFees = createSelector([getFeesState], (fees: IFeesStore) => fees);

export const getFeesList = createSelector([getFees], (fees: IFeesStore): IFeesData => fees.fees);

export const getPlatformFeesList = createSelector(
	[getFees],
	(fees: IFeesStore): IPlatformFeesData => fees.platformFees,
);

export const getCryptoFeesList = createSelector(
	[getFees],
	(fees: IFeesStore): ICryptoFeesData => fees.cryptoFees,
);

export const getFiatFeesList = createSelector(
	[getFees],
	(fees: IFeesStore): IFiatFeesData => fees.fiatFees,
);

export const getVolumeDependsFeeSettingsList = createSelector(
	[getFees],
	(fees: IFeesStore): IVolumeDependsFeeSettingsData => fees.volumeDependsFeeSettings,
);
export const getP2P = createSelector([getFees], (fees: IFeesStore): IPlatformFeesData => fees.p2p);

export const getP2PLoading = createSelector([getFees], (fees: IFeesStore): any => fees.P2PLoading);

export const getMarginBorrow = createSelector(
	[getFees],
	(fees: IFeesStore): IPlatformFeesData => fees.marginBorrow,
);

export const getMarginBorrowLoading = createSelector(
	[getFees],
	(fees: IFeesStore): any => fees.marginBorrowLoading,
);
