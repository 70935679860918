import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IWithdrawalsData,
	IWithdrawalsStore,
	IGetBankTransferResponse,
	IGetPendingRedemptionsResponse,
	TAvailableGramList,
} from './types';

// ==========================================:
const getWithdrawalsState = (state: IStoreState): IWithdrawalsStore => state.withdrawals;
export const getWithdrawals = createSelector(
	[getWithdrawalsState],
	(withdrawals: IWithdrawalsStore) => withdrawals,
);

// ====================================================:
export const getWithdrawalsIsLoad = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): boolean => withdrawals.withdrawalsLoader,
);

// ====================================================:
export const getWithdrawalsList = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): IWithdrawalsData => withdrawals.withdrawals,
);

// ====================================================:
export const getFiatWithdrawalsList = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore) => withdrawals.withdrawalsFiat,
);

// ====================================================:
export const getWithdrawalsFiatIsLoad = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): boolean => withdrawals.withdrawalsFiatLoader,
);

// ====================================================:
export const getBankTransferIsLoad = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): boolean => withdrawals.bankTransferLoader,
);

// ====================================================:
export const getBankTransfers = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): IGetBankTransferResponse | null => withdrawals.bankTransfer,
);

// ====================================================:
export const getRededmptionsIsLoad = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): boolean => withdrawals.pendingRedemptionsLoader,
);

// ====================================================:
export const getRededmptions = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): IGetPendingRedemptionsResponse | null =>
		withdrawals.pendingRedemptions,
);

// ====================================================:
export const getAvailableGramListIsLoad = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): boolean => withdrawals.availableGramListLoader,
);

// ====================================================:
export const getAvailableGramList = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): TAvailableGramList | null => withdrawals.availableGramList,
);

// ====================================================:
export const getAcceptRedemptionLoader = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): boolean => withdrawals.approveRedemptionLoader,
);

// ====================================================:
export const getRejectRedemptionLoader = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): boolean => withdrawals.rejectRedemptionLoader,
);
