import { transactions } from './transactions';
import { IApi } from './types';
import { admins } from './admins';
import { auth } from './auth';
import { settings } from './settings';
import { users } from './users';
import { balance } from './balance';
import { fees } from './fees';
import { fiatFees } from './fiatFees';
import { withdrawals } from './withdrawals';
import { notifications } from './notifications';
import { tradingPairs } from './tradingPairs';
import { walletManagement } from './walletManagement';
import { tokenManagement } from './tokenManagement';
import { marketMakerBot } from './marketMakerBot';
import { limitations } from './limitations';
import { trades } from './trades';
import { orders } from './orders';
import { dispute } from './dispute';
import { currencies } from './currencies';
import { chat } from './chat';
import { bankAccount } from './bankAccount';
import { externalMaker } from './externalMaker';
import { translates } from './translates';
import { referral } from './referral';
import { staking } from './staking';
import { marketBot } from './marketBot';
import { unidentifiedDeposits } from './unidentifiedDeposits';
import { pendingDeposits } from './pedningDeposits';

const api: IApi = {
	auth,
	settings,
	users,
	admins,
	balance,
	fees,
	fiatFees,
	withdrawals,
	notifications,
	tradingPairs,
	walletManagement,
	tokenManagement,
	marketMakerBot,
	limitations,
	transactions,
	trades,
	orders,
	dispute,
	currencies,
	chat,
	bankAccount,
	externalMaker,
	translates,
	referral,
	staking,
	marketBot,
	unidentifiedDeposits,
	pendingDeposits,
};
export { api };
