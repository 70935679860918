import { FC, useState, useEffect } from 'react';
import Loader from 'ui/Loader';
import { useSelector } from 'react-redux';
import { getBankTransferIsLoad } from 'redux/reducers/withdrawals/selectors';
import { WithdrawalItem } from './WithdrawalItem';
import { IWithdrawalListProps } from './types';

// ==========================================:
const WithdrawalList: FC<IWithdrawalListProps> = ({ redemptions, onRefetch }) => {
	const withdrawalsLoader = useSelector(getBankTransferIsLoad);

	return (
		<div className="table-block table-block--mt-0">
			<div className="table-wrapper">
				<div className="table table--pending-gold">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>User Id</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Phone number</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Initiated amount</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Available GOLDb Amount</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Released Amount</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Fee</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Gold in grams</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Reject reason</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Created</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name td-name--action">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>
					{!redemptions?.length && !withdrawalsLoader && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">No withdrawals found...</p>
						</div>
					)}
					{withdrawalsLoader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{redemptions?.map((item) => (
								<WithdrawalItem key={item.id} data={item} onRefetch={onRefetch} />
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default WithdrawalList;
