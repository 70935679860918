/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IUnidentifiedDepositsStore,
	IUnidentifiedDepositsResponse,
	IUnidentifiedDepositsRequest,
	IUserRequest,
	IUserItem,
	IFilterParams,
	IAcceptPayload,
	IRejectPayload,
	IUnidentifiedDepositItem,
} from './types';

const initFilterParams = {
	search_field: undefined,
	search_value: undefined,
	start_date: null,
	end_date: null,
};
// ==========================================:
export const initialState: IUnidentifiedDepositsStore = {
	depositsList: null,
	depositsListLoader: false,
	users: null,
	usersLoading: false,
	filterParams: initFilterParams,
};

// ==========================================:
const unidentifiedDeposits = createSlice({
	name: '@@unidentifiedDeposits',
	initialState,
	reducers: {
		getUnidentDepositsListRequest: (
			state,
			{ payload }: PayloadAction<IUnidentifiedDepositsRequest>,
		) => {
			const unidentDepositsState = state;
			if (payload.export !== 1) {
				unidentDepositsState.depositsListLoader = true;
			}
		},
		getUnidentDepositsListSuccess: (
			state,
			action: PayloadAction<IUnidentifiedDepositsResponse>,
		) => {
			const { payload } = action;
			const unidentDepositsState = state;
			unidentDepositsState.depositsList = payload;
			unidentDepositsState.depositsListLoader = false;
		},
		getUnidentDepositsListError: (state) => {
			const unidentDepositsState = state;
			unidentDepositsState.depositsListLoader = false;
		},
		findUsersRequest: (state, action: PayloadAction<IUserRequest>) => {
			const unidentDepositsState = state;
			unidentDepositsState.usersLoading = true;
		},
		findUsersSuccess: (state, { payload }: PayloadAction<IUserItem[]>) => {
			const unidentDepositsState = state;
			unidentDepositsState.usersLoading = false;
			unidentDepositsState.users = payload;
		},
		findUsersError: (state) => {
			const unidentDepositsState = state;
			unidentDepositsState.usersLoading = false;
		},
		resetUsers: (state) => {
			const unidentDepositsState = state;
			unidentDepositsState.users = null;
		},
		setFilterParams: (state, { payload }: PayloadAction<IFilterParams>) => {
			const unidentDepositsState = state;
			unidentDepositsState.filterParams = payload;
		},
		acceptDepositRequest: (state, action: PayloadAction<IAcceptPayload>) => {},
		acceptDepositSuccess: (state, { payload }: PayloadAction<IUnidentifiedDepositItem>) => {
			const unidentDepositsState = state;
			if (!unidentDepositsState.depositsList?.data) {
				return;
			}
			unidentDepositsState.depositsList.data = unidentDepositsState.depositsList.data.map(
				(item) => {
					if (item.id === payload.id) {
						return payload;
					}
					return item;
				},
			);
		},
		acceptDepositError: (state) => {},
		rejectDepositRequest: (state, action: PayloadAction<IRejectPayload>) => {},
		rejectDepositSuccess: (state, { payload }: PayloadAction<IUnidentifiedDepositItem>) => {
			const unidentDepositsState = state;
			if (!unidentDepositsState.depositsList?.data) {
				return;
			}
			unidentDepositsState.depositsList.data = unidentDepositsState.depositsList.data.map(
				(item) => {
					if (item.id === payload.id) {
						return payload;
					}
					return item;
				},
			);
		},
		rejectDepositError: (state) => {},
		unidentDepositsInitState: () => initialState,
	},
});

export default unidentifiedDeposits.reducer;
export const {
	getUnidentDepositsListRequest,
	getUnidentDepositsListSuccess,
	getUnidentDepositsListError,
	findUsersRequest,
	findUsersSuccess,
	findUsersError,
	resetUsers,
	setFilterParams,
	acceptDepositRequest,
	acceptDepositSuccess,
	acceptDepositError,
	rejectDepositRequest,
	rejectDepositSuccess,
	rejectDepositError,
	unidentDepositsInitState,
} = unidentifiedDeposits.actions;
