/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import { IStakingInfoProps } from './types';
// ==========================================:
const StakingInfo: FC<IStakingInfoProps> = ({ data }) => {
	const {
		balance_available: balanceAvailable,
		balance_frozen: balanceFrozen,
		energy_available: energyAvailable,
		energy_total: energyTotal,
		bandwidth_available: bandwidthAvailable,
		bandwidth_total: bandwidthTotal,
	} = data;

	return (
		<>
			<div className="content-block">
				<div className="content-block__inside content-block__inside--padding-20">
					<div className="staking-info-box staking-info-box--align-end">
						<div className="staking-info">
							<p className="block-subtitle">Balance</p>
							<div className="staking-stat">
								<p className="staking-stat__name">Available:</p>
								<p className="staking-stat__value">
									{balanceAvailable} <span>(TRX)</span>
								</p>
							</div>
						</div>
						<div className="staking-info">
							<div className="staking-stat">
								<p className="staking-stat__name">Frozen:</p>
								<p className="staking-stat__value">
									{balanceFrozen} <span>(TRX)</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="content-block">
				<div className="content-block__inside content-block__inside--padding-20">
					<div className="staking-info-box">
						<div className="staking-info">
							<p className="block-subtitle">Energy</p>
							<div className="staking-stat">
								<p className="staking-stat__name">Available:</p>
								<p className="staking-stat__value">{energyAvailable}</p>
							</div>
							<div className="staking-stat">
								<p className="staking-stat__name">Total:</p>
								<p className="staking-stat__value">{energyTotal}</p>
							</div>
						</div>
						<div className="staking-info">
							<p className="block-subtitle">Bandwidth</p>
							<div className="staking-stat">
								<p className="staking-stat__name">Available:</p>
								<p className="staking-stat__value">{bandwidthAvailable}</p>
							</div>
							<div className="staking-stat">
								<p className="staking-stat__name">Total:</p>
								<p className="staking-stat__value">{bandwidthTotal}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StakingInfo;
