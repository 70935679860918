/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { Advertisements } from './P2PBox/Advertisements/Advertisements';
import { Dispute } from './P2PBox/Dispute/Dispute';
import { Trades } from './P2PBox/Trades/Trades';
import { Balances } from './P2PBox/Balances/Balances';

export const P2P: FC = () => {
	const [activeMenu, setActiveMenu] = useState('Advertisements');
	let className = '';
	if (activeMenu === 'Advertisements') {
		className = 'table--spot-advertisements';
	} else if (activeMenu === 'Dispute') {
		className = 'table--spot-dispute';
	} else if (activeMenu === 'Trades') {
		className = 'table--spot-trades_p2p';
	} else if (activeMenu === 'Balances') {
		className = 'table--p2p-balances';
	}
	const arrayMenu = [
		{
			id: 1,
			name: 'Advertisements',
		},
		{
			id: 2,
			name: 'Dispute',
		},
		{
			id: 3,
			name: 'Trades',
		},
		{
			id: 4,
			name: 'Balances',
		},
	];
	const menuTop = () => {
		return arrayMenu.map((e) => {
			return (
				<li
					key={e.id}
					className={`table-panel-tab__item ${activeMenu === e.name ? 'active' : ''}`}
					onClick={() => setActiveMenu(e.name)}
				>
					<span className="table-panel-tab__link">{e.name}</span>
				</li>
			);
		});
	};

	const menuContent = () => {
		switch (activeMenu) {
			case 'Advertisements':
				return <Advertisements />;
				break;
			case 'Dispute':
				return <Dispute />;
				break;
			case 'Trades':
				return <Trades />;
				break;
			case 'Balances':
				return <Balances />;
				break;
			default:
				null;
		}
		return null;
	};

	return (
		<div className="p2p">
			<div className="content-block content-block--user-management">
				<div>
					<div className="content-block__inside content-block__inside--panel-only">
						<div className="table-panel">
							<ul className="table-panel-tab">{menuTop()}</ul>
						</div>
					</div>
				</div>
				<div className="content-block__inside content-block__inside--login-his">
					<div className="table-block table-block--ip">
						<div className="table-wrapper">
							<div className={`table ${className}`}>{menuContent()}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
