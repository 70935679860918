import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useSelect } from 'services/hooks/useSelect';
import { getChannelList } from 'redux/reducers/fiatFees/selectors';
import { getChannelName } from '../utils';
import { IChannelFilter } from './types';

const ChannelFilter: FC<IChannelFilter> = ({ value, onChange }) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const channelList = useSelector(getChannelList);

	const handleSelect = (item: string) => {
		onChange(item);
		setOpen(false);
	};

	return (
		<div className={`select select--height-42 ${open ? 'active' : ''}`}>
			<button type="button" className="select__current " ref={triggerRef} onClick={toggleOpen}>
				<span className="select__current-text">{getChannelName(value)}</span>
				<span className="select__current-arrow">
					<svg
						className="stroke"
						width="8"
						height="5"
						viewBox="0 0 8 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
					</svg>
				</span>
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select-drop__item">
						<ul>
							{channelList?.map((chl) => (
								<li key={chl}>
									<button type="button" onClick={() => handleSelect(chl)}>
										{getChannelName(chl)}
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChannelFilter;
