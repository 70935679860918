/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import {
	getOrdersSpotList,
	getLoadingOrdersSpot,
	getFilterParams,
} from 'redux/reducers/orders/selectors';
import { getOrdersSpotRequest, setFilterParams } from 'redux/reducers/orders/reducer';
import { SpotOrdersTableHeader } from './SpotOrdersTableHeader';
import { ISpotOrdersItem, IMarginProps } from './types';
import SpotOrdersTableItem from './SpotOrdersTableItem';

export const SpotOrders: FC<IMarginProps> = ({ radioCheck }) => {
	const ordersSpotList = useSelector(getOrdersSpotList);
	const ordersSpotIsLoad = useSelector(getLoadingOrdersSpot);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const filterParams = useSelector(getFilterParams);

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = text?.length
			? `?page=${String(pageNumber)}&text=${String(text)}&field=${field}`
			: `?page=${String(pageNumber)}`;
		let searchValue = text?.length ? String(text) : undefined;
		if (field === 'pair') {
			searchValue = searchValue?.toLowerCase().replace('/', '_');
		}
		const params = {
			per_page: 15,
			current_page: pageNumber || 1,
			start_date: startDate || null,
			end_date: endDate || null,
			search_value: searchValue,
			search_field: text?.length ? String(field) : undefined,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getOrdersSpotRequest(params));
	};

	useEffect(() => {
		return () => {
			dispatch(setFilterParams({ start_date: null, end_date: null }));
		};
	}, [dispatch]);

	useEffect(() => {
		setCurrentPage(Number(page) || 1);
	}, [page]);

	// useEffect(() => {
	// 	const params = {
	// 		per_page: 15,
	// 		current_page: 1,
	// 		start_date: startDate || null,
	// 		end_date: endDate || null,
	// 	};
	// 	setCurrentPage(1);
	// 	history.push({
	// 		pathname: location.pathname,
	// 	});
	// 	dispatch(getOrdersSpotRequest(params));
	// }, [startDate, endDate]);
	return (
		<>
			{ordersSpotIsLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--spot-orders">
							<SpotOrdersTableHeader />
							<div className="table-body">
								{ordersSpotList?.data.map((item: ISpotOrdersItem) => (
									<SpotOrdersTableItem item={item} key={item.id} />
								))}
								{ordersSpotList?.data.length === 0 ? (
									<p style={{ padding: '20px' }}>Borrowing is empty</p>
								) : null}
							</div>
						</div>
					</div>
					<div style={{ padding: '14px' }}>
						{(ordersSpotList?.last_page ?? 0) > 1 && (
							<Pagination
								pageCount={ordersSpotList?.last_page ?? 0}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
