/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMarketBotStore, IMarketBotItem, IUpdateMarketBotPayload } from './types';
// ==========================================:
export const initialState: IMarketBotStore = {
	marketBotList: null,
	marketBotListLoader: false,
	updateMarketBotLoader: false,
};

// ==========================================:
const limitations = createSlice({
	name: '@@marketBot',
	initialState,
	reducers: {
		getMarketBotListRequest: (state) => {
			const marketBotState = state;
			marketBotState.marketBotListLoader = true;
		},
		getMarketBotListSuccess: (state, action: PayloadAction<IMarketBotItem[]>) => {
			const { payload } = action;
			const marketBotState = state;
			marketBotState.marketBotList = payload;
			marketBotState.marketBotListLoader = false;
		},
		getMarketBotListError: (state) => {
			const marketBotState = state;
			marketBotState.marketBotListLoader = false;
		},
		updateMarketBotRequest: (state, action: PayloadAction<IUpdateMarketBotPayload>) => {},
		updateMarketBotSuccess: (state, action: PayloadAction<IMarketBotItem>) => {
			const marketBotState = state;
			const { payload } = action;
			if (!marketBotState.marketBotList) {
				return;
			}
			marketBotState.marketBotList = marketBotState.marketBotList.map((item) => {
				if (item.id === payload.id) {
					return payload;
				}
				return item;
			});
		},
		marketBotInitState: () => initialState,
	},
});

export default limitations.reducer;
export const {
	getMarketBotListRequest,
	getMarketBotListSuccess,
	getMarketBotListError,
	updateMarketBotRequest,
	updateMarketBotSuccess,
	marketBotInitState,
} = limitations.actions;
