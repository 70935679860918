import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeGeneralRequest } from 'redux/reducers/сurrencies/reducer';
import { INetworkProps, ItemKey } from './types';

const CurrencyNetworkItem: FC<INetworkProps> = ({ asset, network }) => {
	const dispatch = useDispatch();
	const [code] = useState(asset?.code);
	const { depositable: depositTable, withdrawable: withdrawAble, id } = network;
	const { exchangeable: exchangeAble } = asset;

	const formInit = {
		depositable: depositTable,
		withdrawable: withdrawAble,
		exchangeable: exchangeAble,
		network_id: code || undefined,
		id: id || 0,
	};
	const [form, setForm] = useState(formInit);

	const handleSwitcherChange = (sw: boolean, text: ItemKey) => {
		const networkId =
			network.network_id && text !== 'exchangeable' ? network.network_id : undefined;
		const newValue = sw ? 1 : 0;
		setForm({ ...form, [text]: newValue });
		const body = {
			id: asset.id,
			[text]: newValue,
			network_id: networkId,
		};

		dispatch(
			changeGeneralRequest({
				apiParams: body,
				onError: () => {
					setForm((prev) => ({
						...prev,
						[text]: asset[text],
						network_id: networkId,
					}));
				},
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Currency</p>
				<p style={{ textTransform: 'uppercase' }}>
					{code} ({network.network_name})
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Deposit</p>
				<div className="switch switch--type2">
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={!!form.depositable}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								handleSwitcherChange(e.target.checked, 'depositable');
							}}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Withdrawal</p>
				<div className="switch switch--type2">
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={!!form.withdrawable}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								handleSwitcherChange(e.target.checked, 'withdrawable');
							}}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Convert</p>
				{network.network_id === 'default' && (
					<div className="switch switch--type2">
						<label className="switch__label">
							<input
								type="checkbox"
								className="hidden"
								checked={!!form.exchangeable}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									handleSwitcherChange(e.target.checked, 'exchangeable');
								}}
							/>
							<div className="switch__toggler" />
						</label>
					</div>
				)}
			</div>
		</div>
	);
};

export default CurrencyNetworkItem;
