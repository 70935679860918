import { ILimitationsItem } from 'redux/reducers/limitations/types';
import { capitalizeFirstLetter } from 'utils/numberFormat';

type GroupedFiatLimitations = Record<string, ILimitationsItem[]>;

export const groupFiatLimitations = (limitations: ILimitationsItem[]) => {
	return limitations.reduce(
		(acc: GroupedFiatLimitations, curr: ILimitationsItem): GroupedFiatLimitations => {
			const assetCode = curr.asset_code;
			const result = { ...acc };
			if (Object.keys(acc).includes(assetCode)) {
				result[assetCode].push(curr);
				return result;
			}
			result[assetCode] = [curr];
			return result;
		},
		{},
	);
};

export const displayChannelName = (value: string) => {
	switch (value) {
		case 'paypal':
			return 'PayPal';
		case 'card_other':
			return 'Other card';
		case 'bank_transfer':
			return 'Partner bank';
		case 'bank_transfer_other':
			return 'Other bank';
		default:
			return capitalizeFirstLetter(value.replace('card_', '').replaceAll('_', ' '));
	}
};
