import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IApiChangeShoulderCrossParams,
	IApiChangeShoulderIsolateParams,
	IApiGetMarginIsolatedResponse,
	IApiToggleActiveIsolateParams,
} from 'services/api/tradingPairs/types';
import {
	IAddAssetsPairPayload,
	IAssetsResponsePayload,
	IEditTradingPairsPayload,
	ITradingPairsRequestPayload,
	ITradingPairsResponsePayload,
} from './types';
import {
	editTradingPairsRequest,
	editTradingPairsSuccess,
	getTradingPairsRequest,
	getTradingPairsSuccess,
	tradingPairsInitState,
	getAssetsSuccess,
	getAssetsRequest,
	addAssetsPairSuccess,
	addAssetsPairRequest,
	getMarginIsolatedRequest,
	getMarginIsolatedSuccess,
	toggleActiveIsolateRequest,
	toggleActiveIsolateSuccess,
	toggleActiveIsolateError,
	changeShoulderIsolateRequest,
	changeShoulderIsolateSuccess,
	changeShoulderIsolateError,
	changeShoulderCrossRequest,
	changeShoulderCrossSuccess,
	changeShoulderCrossError,
	toggleActiveCrossSuccess,
	toggleActiveCrossError,
	toggleActiveCrossRequest,
} from './reducer';

// =============================================================:
function* tradingPairsRequestWorker(action: PayloadAction<ITradingPairsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ITradingPairsResponsePayload = yield call(
			api.tradingPairs.getTradingPairs,
			payload,
		);
		yield put(getTradingPairsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(tradingPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* editTradingPairsWorker(action: PayloadAction<IEditTradingPairsPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.tradingPairs.editTradingPairs, payload);
		yield put(editTradingPairsSuccess());
		notificationContainer('Trading Pairs has been edited!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(tradingPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getMarginIsolatedWorker() {
	try {
		const data: IApiGetMarginIsolatedResponse = yield call(api.tradingPairs.getMarginIsolated);
		yield put(getMarginIsolatedSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* toggleActiveIsolateWorker({ payload }: PayloadAction<IApiToggleActiveIsolateParams>) {
	try {
		yield call(api.tradingPairs.toggleActiveIsolate, payload);
		yield put(toggleActiveIsolateSuccess());

		notificationContainer('Trading pair changed', 'success');
	} catch (error) {
		yield put(toggleActiveIsolateError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* toggleActiveCrossWorker({ payload }: PayloadAction<IApiToggleActiveIsolateParams>) {
	try {
		yield call(api.tradingPairs.toggleActiveCross, payload);
		yield put(toggleActiveCrossSuccess());

		notificationContainer('Trading pair changed', 'success');
		yield put(getMarginIsolatedRequest());
	} catch (error) {
		yield put(toggleActiveCrossError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* changeShoulderIsolateWorker({ payload }: PayloadAction<IApiChangeShoulderIsolateParams>) {
	try {
		yield call(api.tradingPairs.changeShoulderIsolate, payload);
		yield put(changeShoulderIsolateSuccess());

		notificationContainer('Trading pair changed', 'success');
	} catch (error) {
		yield put(changeShoulderIsolateError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* changeShoulderCrossWorker({ payload }: PayloadAction<IApiChangeShoulderCrossParams>) {
	try {
		yield call(api.tradingPairs.changeShoulderCross, payload);
		yield put(changeShoulderCrossSuccess());
		yield put(getMarginIsolatedRequest());

		notificationContainer('Trading pair changed', 'success');
	} catch (error) {
		yield put(changeShoulderCrossError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

// =============================================================:
function* assetsRequestWorker() {
	try {
		yield put(showLoading());
		const response: IAssetsResponsePayload = yield call(api.tradingPairs.getAssets);
		yield put(getAssetsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(tradingPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* addAssetsPairWorker(action: PayloadAction<IAddAssetsPairPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.tradingPairs.addAssetsPair, payload);
		yield put(addAssetsPairSuccess());
		notificationContainer('Assets pair has been added!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(tradingPairsInitState());
		yield put(getAssetsRequest());
	} finally {
		yield put(hideLoading());
	}
}

export function* tradingPairsSaga() {
	yield takeEvery(getTradingPairsRequest.type, tradingPairsRequestWorker);
	yield takeEvery(editTradingPairsRequest.type, editTradingPairsWorker);

	yield takeEvery(getMarginIsolatedRequest.type, getMarginIsolatedWorker);
	yield takeEvery(toggleActiveIsolateRequest.type, toggleActiveIsolateWorker);
	yield takeEvery(toggleActiveCrossRequest.type, toggleActiveCrossWorker);
	yield takeEvery(changeShoulderIsolateRequest.type, changeShoulderIsolateWorker);
	yield takeEvery(changeShoulderCrossRequest.type, changeShoulderCrossWorker);

	yield takeEvery(getAssetsRequest.type, assetsRequestWorker);
	yield takeEvery(addAssetsPairRequest.type, addAssetsPairWorker);
}
