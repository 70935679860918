import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getExternalBalances,
	getExternalBalancesLoader,
	getLiquiditySettings,
} from 'redux/reducers/walletManagement/selectors';
import queryString from 'query-string';
import {
	getExternalBalancesRequest,
	getLiquiditySettingsRequest,
	liquiditySettingsUppdate,
} from 'redux/reducers/walletManagement/reducer';
import Loader from 'ui/Loader';
import { useHistory, useLocation } from 'react-router';
import { IExternalBalanceRequestPayload } from 'redux/reducers/walletManagement/types';
import Pagination from 'ui/Pagination';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { BybitBalanceItem } from './BybitBalanceItem';

export const BybitBalance: FC = () => {
	const { search } = useLocation();
	const history = useHistory();
	const location = useLocation();
	const { page } = queryString.parse(search);
	const externalBalanceLoader = useSelector(getExternalBalancesLoader);
	const externalBalances = useSelector(getExternalBalances);
	const frequencyPerSecond = useSelector(getLiquiditySettings);

	const [newFrequencyValue, setNewFrequencyValue] = useState('');
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const dispatch = useDispatch();

	const { checkEditable } = usePermission();

	const handleChangeFrequencyPerSecondSubmit = () => {
		if (!frequencyPerSecond) return;
		dispatch(
			liquiditySettingsUppdate({
				id: frequencyPerSecond.id,
				balance_update_seconds: Number(newFrequencyValue),
			}),
		);
	};

	useEffect(() => {
		dispatch(getExternalBalancesRequest({ platform_name: 'bybit' }));
		dispatch(getLiquiditySettingsRequest({ platform_name: 'bybit' }));
	}, [dispatch]);

	useEffect(() => {
		setNewFrequencyValue(String(frequencyPerSecond?.balance_update_seconds));
	}, [frequencyPerSecond]);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;

		const params: IExternalBalanceRequestPayload = {
			platform_name: 'bybit',
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getExternalBalancesRequest(params));
	};

	return (
		<>
			<div className="content-block content-block--mt-0">
				<div className="content-block__inside content-block__inside--type2">
					<div className="value-form">
						<div className="input">
							<p className="input__name">Frequency per second</p>
							<div className="input-and-btn">
								<div className="input__wrapper">
									<input
										type="text"
										disabled={!checkEditable(permissions.MANAGE_WALLETS)}
										className="input-item input-item--medium"
										value={newFrequencyValue}
										onChange={(e) => setNewFrequencyValue(e.target.value)}
									/>
								</div>
								<button
									type="button"
									disabled={!checkEditable(permissions.MANAGE_WALLETS)}
									className="button button--check button--medium-height"
									onClick={handleChangeFrequencyPerSecondSubmit}
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="table-block table-block--walletman">
						<div className="table-wrapper">
							<div className="table table--wallet-kraken-balance">
								<div className="table-header">
									<div className="tr">
										<div className="td">
											<div className="td-name">
												<p>Wallet</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Balance</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Min alert</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Max alert</p>
											</div>
										</div>
										<div className="td td--right">
											<div className="td-name">
												<p>Action</p>
											</div>
										</div>
									</div>
								</div>

								{!externalBalances?.data?.length && !externalBalanceLoader && (
									<div className="user-management-empty-data">
										<p className="user-management-empty__text">No balances found...</p>
									</div>
								)}

								{externalBalanceLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<div className="table-body">
										{externalBalances?.data?.map((item) => (
											<BybitBalanceItem key={item.id} data={item} />
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{(externalBalances?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={externalBalances?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};
