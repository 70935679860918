const TableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Payment ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>External payment ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>User ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Full name</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Reference code</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Sender name</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Currency</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Amount</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Payment Method</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Bank name</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>IBAN</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Created</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Dated of confirm</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Admin ID</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Status</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Reason</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Reason of rejection</p>
					</div>
				</div>
				<div className="td td--right ">
					<div className="td-name ">
						<p>Actions</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
