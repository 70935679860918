import { createSelector } from '@reduxjs/toolkit';
import {
	IP2PStore,
	IP2PBalances,
	IP2POpneTradesData,
	IP2PClosedTradesData,
	IP2PAdevrtisementData,
	IAssetsData,
	IGetOrderQuick,
	ICurrenciesData,
	IGetCurrenciesItem,
	IPaymentsArray,
	IPaymentItem,
	IAllAdvertisementData,
	IPofileResponse,
	IGetProposalData,
	IAdvertisementData,
	ITradeUserMessageData,
	ITradeData,
} from './types';

// ==========================================:
const getP2PState = (state: any): any => state.chat;
export const getP2P = createSelector([getP2PState], (p2p: IP2PStore) => p2p);

// ====================================================:
export const getP2PBalancesList = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IP2PBalances | null => p2pWallets.p2pBalancesList,
);

// ====================================================:
export const getP2PBalancesLoad = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): boolean => p2pWallets.p2pBalancesListLoader,
);

// ====================================================:
export const getP2POpenTradesList = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IP2POpneTradesData | null => p2pWallets.p2pOpenTradesList,
);

// ====================================================:
export const getP2PClosedTradesList = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IP2PClosedTradesData | null => p2pWallets.p2pClosedTradesList,
);

// ====================================================:
export const getP2PAdvertisementList = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IP2PAdevrtisementData | null => p2pWallets.p2pAdvertisement,
);

// ====================================================:
export const getP2PAssets = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IAssetsData | null => p2pWallets.p2pAssets,
);

// ====================================================:
export const getP2POrderQuick = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IGetOrderQuick | null => p2pWallets.p2pOrderQuick,
);

// ====================================================:
export const getCurrentCoin = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): string => p2pWallets.currentCoin,
);

// ====================================================:
export const getCurrencyItem = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IGetCurrenciesItem | null => p2pWallets.currencyItem,
);

// ====================================================:
export const getCurrencies = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): ICurrenciesData | null => p2pWallets.currencies,
);

// ====================================================:
export const getCurrentType = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): string => p2pWallets.currentType,
);

// ====================================================:
export const getPayments = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IPaymentsArray | null => p2pWallets.paymentsTypes,
);

// ====================================================:
export const getCurrentPayment = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IPaymentItem | null => p2pWallets.paymentsTypeItem,
);

// ====================================================:
export const getAllAdvertisement = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IAllAdvertisementData | null => p2pWallets.allAdvertisement,
);

// ====================================================:
export const getAllAdvertisementSell = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IAllAdvertisementData | null => p2pWallets.allAdvertisementSell,
);

// ====================================================:
export const getProfile = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IPofileResponse | null => p2pWallets.userProfile,
);

// ====================================================:
export const getProposal = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IGetProposalData | null => p2pWallets.p2pProposal,
);

// ====================================================:
export const getUserAdvert = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IAdvertisementData | null => p2pWallets.userAdvrtisement,
);

// ====================================================:
export const getTradePaySell = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): ITradeData | undefined => p2pWallets?.tradePaySellData?.trade,
);

// ====================================================:
export const getTradeStatus = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): string | undefined => p2pWallets?.tradePaySellData?.trade?.status,
);

// ====================================================:
export const getChatListUser = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): Array<ITradeUserMessageData> | undefined => p2pWallets?.p2pChat?.common,
);

// ====================================================:
export const getChatListBuyer = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): Array<ITradeUserMessageData> | undefined => p2pWallets?.p2pChat?.buyer,
);
export const getChatListSeller = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): Array<ITradeUserMessageData> | undefined => p2pWallets?.p2pChat?.seller,
);
