import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { notificationContainer } from 'utils/notificationContainer';
import { responseErrors } from 'services/http/responseErrors';
import {
	getOrdersP2PSuccess,
	getOrdersMarginRequest,
	getOrdersMarginSuccess,
	getOrdersP2PRequest,
	getOrdersSpotRequest,
	getOrdersSpotSuccess,
} from './reducer';
import { IMarketMakerPageRequestPayload, IOrdersResponsePayload } from './types';
// =============================================================:
// =============================================================:

function* spotOrdersRequestWorker(action: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IOrdersResponsePayload = yield call(api.orders.getOrdersSpot, payload);
		if (payload.export === 1) {
			notificationContainer('Transactions successfully exported, check your mailbox!', 'info');
		} else yield put(getOrdersSpotSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* marginOrdersRequestWorker(action: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IOrdersResponsePayload = yield call(api.orders.getOrdersMargin, payload);
		if (payload.export === 1) {
			notificationContainer('Transactions successfully exported, check your mailbox!', 'info');
		} else yield put(getOrdersMarginSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* P2POrdersRequestWorker(action: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IOrdersResponsePayload = yield call(api.orders.getOrdersP2P, payload);
		if (payload.export === 1) {
			notificationContainer('Transactions successfully exported, check your mailbox!', 'info');
		} else yield put(getOrdersP2PSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
export function* ordersSaga() {
	yield takeEvery(getOrdersSpotRequest.type, spotOrdersRequestWorker);
	yield takeEvery(getOrdersMarginRequest.type, marginOrdersRequestWorker);
	yield takeEvery(getOrdersP2PRequest.type, P2POrdersRequestWorker);
}
