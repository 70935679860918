/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
	getConvertsTransactionsList,
	getCryptoTransactionsList,
	getFiatTransactionsList,
	getTransfersTransactionsList,
	getWithdrawalsTransactionsList,
} from 'redux/reducers/transactions/selectors';
import Pagination from 'ui/Pagination';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import {
	getConvertsTransactionsRequest,
	getCryptoTransactionsRequest,
	getFiatTransactionsRequest,
	getTransfersTransactionsRequest,
	getWithdrawalsTransactionsFiatRequest,
	getWithdrawalsTransactionsRequest,
} from 'redux/reducers/transactions/reducer';
import {
	ICryptoTransactionsRequestPayload,
	IFiatTransactionsRequestPayload,
	ITransactionsRequestPayload,
	TTypeTransactions,
} from 'redux/reducers/transactions/types';
import SearchBar from 'components/SearchBar';
import {
	CRYPTO_TRANSACTION_STATUS_ARRAY,
	FIAT_TRANSACTION_STATUS_ARRAY,
	TRANSFERS_FROM_TO_WALLET_ARRAY,
	TYPE_TRANSACTIONS_CONVERTS,
	TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_CONVERTS_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_CRYPTO,
	TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_CRYPTO_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_FIAT,
	TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_FIAT_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_TRANSFER,
	TYPE_TRANSACTIONS_TRANSFER_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_TRANSFER_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_WITHDRAWALS,
	TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO,
	TYPE_TRANSACTIONS_WITHDRAWALS_FIAT,
	TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_TEXT_ARRAY,
	WITHDRAWALS_TRANSACTION_STATUS_ARRAY,
} from 'redux/reducers/transactions/constants';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import 'rsuite/dist/rsuite.min.css';
import { WithdrawalsCryptoFiat } from './WithdrawalsCryptoFiat';
import { LimitationsDailyWithdrawal } from '../LimitationsBox/LimitationsDailyWithdrawal';
import { P2PLimitations } from '../LimitationsBox/LimitationsP2P/P2PLimitations';
import { SingleFiat } from './new/SingleFiat';
import { SingleCrypto } from './new/SingleCrypto';
import { DailyFiat } from './new/DailyFiat';
import { DailyCrypto } from './new/DailyCrypto';
import { MonthlyFiat } from './new/MonthlyFiat';
import { MonthlyCrypto } from './new/MonthlyCrypto';

export const Limitations: FC = () => {
	const crypto = useSelector(getCryptoTransactionsList);
	const fiat = useSelector(getFiatTransactionsList);
	const withdrawals = useSelector(getWithdrawalsTransactionsList);
	const transfers = useSelector(getTransfersTransactionsList);
	const converts = useSelector(getConvertsTransactionsList);

	// const [lastPage, setLastPage] = useState(0);
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const [tabIndex, setTabIndex] = useState(0);
	const dispatch = useDispatch();

	const { afterToday } = DateRangePicker;
	const [dateRangeValue, setDateRangeValue] = useState<DateRange | null>(null);

	const now = new Date();
	const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

	const [typeTransactions, setTypeTransactions] =
		useState<TTypeTransactions>(TYPE_TRANSACTIONS_CRYPTO);

	const [dropdownArray, setDropdownArray] = useState(CRYPTO_TRANSACTION_STATUS_ARRAY);

	const [searchFieldArray, setSearchFieldArray] = useState(TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY);
	const [searchFieldTextArray, setSearchFieldTextArray] = useState(
		TYPE_TRANSACTIONS_CRYPTO_SEARCH_TEXT_ARRAY,
	);

	const { page, text, field, tab } = queryString.parse(search);
	const [searchField, setSearchField] = useState(searchFieldArray[0]);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	/* eslint-disable */
	const getRequest = (
		params: ICryptoTransactionsRequestPayload | IFiatTransactionsRequestPayload,
		typeTransactionsParam?: TTypeTransactions,
	) => {
		const type = typeTransactionsParam || typeTransactions;
		switch (type) {
			case TYPE_TRANSACTIONS_CRYPTO:
				dispatch(getCryptoTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_FIAT:
				dispatch(getFiatTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO:
				dispatch(getWithdrawalsTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_WITHDRAWALS_FIAT:
				dispatch(getWithdrawalsTransactionsFiatRequest(params));
				break;
			case TYPE_TRANSACTIONS_TRANSFER:
				dispatch(getTransfersTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_CONVERTS:
				dispatch(getConvertsTransactionsRequest(params));
				break;
			default:
				dispatch(getCryptoTransactionsRequest(params));
		}
	};

	const handleSearch = (txt: string) => {
		const params: ITransactionsRequestPayload = {};
		if (txt.length >= 1) {
			params.search_value = txt;
			params.search_field = String(searchField);
		}
		if (dateRangeValue) {
			params.start_date = dateRangeValue[0].toISOString().split('T')[0];
			params.end_date = dateRangeValue[1].toISOString().split('T')[0];
		}

		history.push({
			pathname: location.pathname,
			search: txt.length >= 1 ? `?text=${txt}&field=${searchField}` : ``,
		});
		setCurrentPage(1);

		if ((searchField === 'currency' || searchField === 'code') && txt !== '' && txt.length < 3)
			return;
		getRequest(params);
	};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 600);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		if (term) {
			handleSearch(term);
		}
	}, [term, searchField]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};
	const setSearchFieldArrayHandler = (type: TTypeTransactions) => {
		let searchParams = '';
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		setDateRangeValue(null);
		switch (type) {
			case TYPE_TRANSACTIONS_CRYPTO:
				setSearchFieldArray(TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_CRYPTO_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY[0]);
				setDropdownArray(CRYPTO_TRANSACTION_STATUS_ARRAY);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_CRYPTO}`
				}`;
				break;
			case TYPE_TRANSACTIONS_FIAT:
				setSearchFieldArray(TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_FIAT_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY[0]);
				setDropdownArray(FIAT_TRANSACTION_STATUS_ARRAY);

				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_FIAT}`
				}`;
				break;
			case TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO:
				setSearchFieldArray(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY[0]);
				setDropdownArray(WITHDRAWALS_TRANSACTION_STATUS_ARRAY);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_WITHDRAWALS}`
				}`;
				break;
			default:
				setSearchFieldArray(TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY[0]);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_CONVERTS}`
				}`;
		}
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
	};

	const setTypeTransactionsHandler = (type: TTypeTransactions) => {
		setTypeTransactions(type);
		history.push({
			pathname: location.pathname,
			search: `?tab=${type}`,
		});
		setSearchFieldArrayHandler(type);
		handleClearSearch();
		setCurrentPage(1);
		getRequest({}, type);
	};

	return (
		<div className="limitations">
			<div className="title-block">
				<p className="title">Limitations</p>
			</div>
			<Tabs
				className="settings-tabs"
				selectedIndex={tabIndex}
				onSelect={(index) => {
					setTabIndex(index);
					if (index === 0) setTypeTransactionsHandler(TYPE_TRANSACTIONS_CRYPTO);
					else if (index === 1) setTypeTransactionsHandler(TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO);
					else if (index === 2) setTypeTransactionsHandler(TYPE_TRANSACTIONS_TRANSFER);
				}}
			>
				<TabList className="user-settings user-settings--settings">
					{/* <Tab className="user-settings__item">
						<span className="user-settings__link">Withdrawal</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Daily withdrawal</span>
					</Tab> */}
					<Tab className="user-settings__item">
						<span className="user-settings__link">Single fiat</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Single crypto</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Daily fiat</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Daily crypto</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Monthly fiat</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Monthly crypto</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">P2P</span>
					</Tab>
				</TabList>
				{/* <TabPanel>
					<WithdrawalsCryptoFiat setTypeTransactions={setTypeTransactionsHandler} />
				</TabPanel>
				<TabPanel>
					<LimitationsDailyWithdrawal />
				</TabPanel> */}
				<TabPanel>
					<SingleFiat />
				</TabPanel>
				<TabPanel>
					<SingleCrypto />
				</TabPanel>
				<TabPanel>
					<DailyFiat />
				</TabPanel>
				<TabPanel>
					<DailyCrypto />
				</TabPanel>
				<TabPanel>
					<MonthlyFiat />
				</TabPanel>
				<TabPanel>
					<MonthlyCrypto />
				</TabPanel>
				<TabPanel>
					<P2PLimitations />
				</TabPanel>
			</Tabs>
		</div>
	);
};
