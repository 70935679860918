import { FC } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import { CurrenciesGeneral } from './CurrenciesBox/CurrenciesGeneral/CurrenciesGeneral';
import { CurrenciesP2P } from './CurrenciesBox/CurrenciesP2P/CurrenciesP2P';
import { CurrenciesMarginCross } from './CurrenciesBox/CurrenciesMarginCross/CurrenciesMarginCross';

const Currencies: FC = () => {
	return (
		<div className="currencies">
			<div className="title-block">
				<p className="title">Currencies</p>
			</div>
			<Tabs className="settings-tabs">
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link">General</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">P2P</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Margin Cross</span>
					</Tab>
				</TabList>
				<TabPanel>
					<CurrenciesGeneral />
				</TabPanel>
				<TabPanel>
					<CurrenciesP2P />
				</TabPanel>
				<TabPanel>
					<CurrenciesMarginCross />
				</TabPanel>
			</Tabs>
		</div>
	);
};

export default Currencies;
