import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { noExponent } from 'utils/numberFormat';
import { p2pStatus } from 'redux/reducers/transactions/constants';
import { useDispatch } from 'react-redux';
import { setTabMenuUserIndex } from 'redux/reducers/userManagement/reducer';
import { ITradesP2PProps } from './types';

const TradesTableItem: FC<ITradesP2PProps> = ({ item }) => {
	const dispatch = useDispatch();
	const [id] = useState(item.trade_ai);
	const [coin] = useState(item.asset_code);
	const [type] = useState(item.advertisement.type);
	const [buyerId] = useState(item.buyer_id);
	const [sellerId] = useState(item.seller_id);
	const [buyerFee] = useState(item.buyer_fee);
	const [sellerFee] = useState(item.seller_fee);
	const [amount] = useState(item.amount);
	const [created] = useState(item.created_at);
	const dateX = new Date(created);
	const secondsX = dateX.getTime() / 1000;
	const date = new Date(secondsX * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+secondsX + offset) * 1000);
	return (
		<div className="tr">
			<div
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				}}
				className="td td--left"
			>
				<p className="td-hidden-name">ID</p>
				<p
					style={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
				>
					{id}
				</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Coin</p>
				<p style={{ textTransform: 'uppercase' }}>{coin}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Type</p>
				<p>{type}</p>
			</div>
			<div className="td td--right">
				<Link
					onClick={() => {
						dispatch(setTabMenuUserIndex(0));
					}}
					to={`/user-management/${String(buyerId)}`}
				>
					<p className="td-hidden-name">Buyer ID</p>
					<p>{buyerId}</p>
				</Link>
			</div>
			<div className="td td--right">
				<Link
					onClick={() => {
						dispatch(setTabMenuUserIndex(0));
					}}
					to={`/user-management/${String(sellerId)}`}
				>
					<p className="td-hidden-name">Seller ID</p>
					<p>{sellerId}</p>
				</Link>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee buyer</p>
				<p>{noExponent(Number(buyerFee).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee seller</p>
				<p>{noExponent(Number(sellerFee).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Created</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={`status ${p2pStatus?.[item.status]?.statusClass ?? ''}`}>
						{p2pStatus?.[item.status]?.statusText}
					</span>
				</div>
			</div>
		</div>
	);
};

export default TradesTableItem;
