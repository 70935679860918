import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminManagementList } from 'redux/reducers/adminManagement/selectors';
import { IAdminsListRequestPayload } from 'redux/reducers/adminManagement/types';
import { getAdminsListRequest } from 'redux/reducers/adminManagement/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import AdminManagementList from './AdminManagementList';

// ==========================================:
const AdminManagement: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const { checkEditable } = usePermission();
	useEffect(() => {
		setCurrentPage(Number(page) || 1);
	}, [page]);
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const params: IAdminsListRequestPayload = {
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: `?page=${String(pageNumber)}`,
		});
		dispatch(getAdminsListRequest(params));
	};

	const adminList = useSelector(getAdminManagementList);

	return (
		<>
			<div className="panel panel--margin-none">
				<div className="title-block">
					<p className="title">Admin Management</p>
				</div>
				{checkEditable(permissions.MANAGE_ADMINS) && (
					<Link to="/admin-management/new-admin" className="admin-managment-btn">
						<span className="admin-managment-btn__text">Add New Admin</span>
						<span className="plus-icon plus-icon--action plus-icon-custom">
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M9.9016 9.90061L14.9588 9.90614C15.1805 9.90613 15.3931 9.81803 15.5499 9.66124C15.7067 9.50445 15.7948 9.29181 15.7948 9.07009C15.7948 8.84837 15.7068 8.63574 15.55 8.47897C15.3932 8.3222 15.1806 8.23414 14.9589 8.23415L9.90171 8.2404L9.90796 3.18322C9.90798 2.9615 9.81991 2.74887 9.66315 2.5921C9.50638 2.43533 9.29374 2.34727 9.07202 2.34729C8.85031 2.3473 8.63766 2.43539 8.48087 2.59218C8.32408 2.74897 8.23599 2.96162 8.23597 3.18334L8.2415 8.24052L3.18432 8.23499C3.07441 8.23455 2.9655 8.25587 2.86386 8.29773C2.76223 8.33959 2.66989 8.40116 2.59216 8.47888C2.51444 8.55661 2.45287 8.64895 2.41101 8.75058C2.36915 8.85222 2.34782 8.96113 2.34827 9.07104C2.34781 9.18095 2.36912 9.28986 2.41096 9.39149C2.45281 9.49312 2.51436 9.58545 2.59208 9.66317C2.66979 9.74088 2.76212 9.80244 2.86375 9.84428C2.96538 9.88613 3.07429 9.90744 3.1842 9.90698L8.24138 9.90073L8.23514 14.9579C8.23468 15.0678 8.25598 15.1767 8.29783 15.2784C8.33968 15.38 8.40123 15.4723 8.47895 15.55C8.55666 15.6277 8.64899 15.6893 8.75062 15.7312C8.85225 15.773 8.96116 15.7943 9.07107 15.7938C9.18098 15.7943 9.28989 15.773 9.39153 15.7311C9.49316 15.6892 9.5855 15.6277 9.66323 15.55C9.74095 15.4722 9.80252 15.3799 9.84438 15.2782C9.88624 15.1766 9.90757 15.0677 9.90712 14.9578L9.9016 9.90061Z"
									fill="#00DA83"
								/>
							</svg>
						</span>
					</Link>
				)}
			</div>
			<AdminManagementList users={adminList?.data.users} />

			{(adminList?.data.total ?? 1) > 1 && (
				<Pagination
					pageCount={adminList?.data.last_page ?? 1}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default AdminManagement;
