import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import SearchBar from 'components/SearchBar';
import { IMarketMakerPageRequestPayload } from 'redux/reducers/orders/types';
import { getFilterParams } from 'redux/reducers/externalMaker/selectors';
import { getOrderHistoryRequest, setFilterParams } from 'redux/reducers/externalMaker/reducer';
import {
	SEARCH_ARRAY,
	SEARCH_TEXT_ARRAY,
	USER_STATUS_ARRAY,
	USER_STATUS_TEXT_ARRAY,
	USER_LEVEL_ARRAY,
} from './constants';

/* eslint-disable react-hooks/exhaustive-deps */

export const OrderHistorySearchBar = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const filterParams = useSelector(getFilterParams);
	const location = useLocation();

	const [dropdownArray, setDropdownArray] = useState<{
		dropdownArray: string[];
		dropdownTextArray: string[];
	}>({ dropdownArray: USER_STATUS_ARRAY, dropdownTextArray: USER_STATUS_TEXT_ARRAY });
	const [typeSearchDropdown, setTypeSearchDropdown] = useState(true);

	const [searchField, setSearchField] = useState(field ? String(field) : 'status');

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};
	/* eslint-disable */
	const handleSearch = (txt: string) => {
		let searchValue = txt.length >= 1 ? txt : undefined;
		const _searchField = txt.length >= 1 ? searchField : undefined;
		const params = {
			current_page: 1,
			search_value: searchValue,
			search_field: _searchField,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};

		dispatch(setFilterParams({
			...filterParams,
			search_value: searchValue,
			search_field: _searchField,
		}));

		// dispatch(getOrderHistoryRequest(params));
		history.push({
			pathname: location.pathname,
			search: '?page=1',
		});
		// setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField, startDate, endDate]);

	const searchFieldHandler = (txt: string) => {
		if (txt === 'status') {
			setDropdownArray({
				dropdownArray: USER_STATUS_ARRAY,
				dropdownTextArray: USER_STATUS_TEXT_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		if (txt === 'level') {
			setDropdownArray({
				dropdownArray: USER_LEVEL_ARRAY,
				dropdownTextArray: USER_LEVEL_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		setSearchField(txt);
		setTypeSearchDropdown(false);
	};

	return (
		<SearchBar
			setDebouncedTerm={setDebouncedTerm}
			debouncedTerm={debouncedTerm}
			searchFieldArray={SEARCH_ARRAY}
			searchField={searchField}
			setSearchField={searchFieldHandler}
			handleClearSearch={handleClearSearch}
			searchFieldTextArray={SEARCH_TEXT_ARRAY}
			typeSearch={typeSearchDropdown ? 'dropdown' : undefined}
			dropdownArray={dropdownArray.dropdownArray}
			dropdownTextArray={dropdownArray.dropdownTextArray}
		/>
	);
};
