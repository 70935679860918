import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { noExponent } from 'utils/numberFormat';
import { useDispatch } from 'react-redux';
import { setTabMenuUserIndex } from 'redux/reducers/userManagement/reducer';

import { IAdvertisementsP2PProps } from './types';

const AdvertisementsTableItem: FC<IAdvertisementsP2PProps> = ({ item }) => {
	const dispatch = useDispatch();
	const [id] = useState(item.id);
	const [coin] = useState(item.asset.code);
	const [amount] = useState(item.amount);
	const [amountLeft] = useState(item.amount_left);
	const [type] = useState(item.type);
	const [userId] = useState(item.user_id);
	const [buyerFee] = useState(item.buyer_fee);
	const [sellFee] = useState(item.seller_fee);
	const [created] = useState(item.created_at);
	const dateX = new Date(created);
	const secondsX = dateX.getTime() / 1000;
	const date = new Date(secondsX * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+secondsX + offset) * 1000);

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Coin</p>
				<p style={{ textTransform: 'uppercase' }}>{coin}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Type</p>
				<p>{type}</p>
			</div>
			<div className="td td--right">
				<Link
					onClick={() => {
						dispatch(setTabMenuUserIndex(0));
					}}
					to={`/user-management/${String(userId)}`}
				>
					<p className="td-hidden-name">User ID</p>
					<p>{userId}</p>
				</Link>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">QTY</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">QTY left</p>
				<p>{noExponent(Number(amountLeft).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee buyer</p>
				<p>{noExponent(Number(buyerFee).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee seller</p>
				<p>{noExponent(Number(sellFee).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Created</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
		</div>
	);
};
export default AdvertisementsTableItem;
