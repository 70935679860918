/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConvertsIsLoad, getConvertsList } from 'redux/reducers/userManagement/selectors';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import { getUserConvertsRequest } from 'redux/reducers/userManagement/reducer';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { ConvertsTableHeader } from './ConvertsTableHeader';
import { ICryptoDepositItem } from './types';
import ConvertsItem from './ConvertsItem';

export const Converts = () => {
	const convertsList = useSelector(getConvertsList);
	const convertsIsLoad = useSelector(getConvertsIsLoad);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			id,
			type: 'deposit',
			per_page: 15,
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getUserConvertsRequest(params));
	};
	useEffect(() => {
		const params = {
			id,
			type: 'deposit',
			per_page: 15,
			current_page: 1,
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getUserConvertsRequest(params));
	}, []);
	return (
		<>
			{convertsIsLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<>
					<ConvertsTableHeader />
					<div className="table-body">
						{convertsList?.exchanges.map((item: ICryptoDepositItem) => (
							<ConvertsItem item={item} key={item.id} />
						))}
						{convertsList?.exchanges.length === 0 ? (
							<p style={{ padding: '20px' }}>Converts is empty</p>
						) : null}
					</div>
				</>
			)}

			<div style={{ padding: '14px' }}>
				{(convertsList?.last_page ?? 0) > 1 && (
					<Pagination
						pageCount={convertsList?.last_page ?? 0}
						forcePage={currentPage - 1}
						onPageChange={handlePageChange}
					/>
				)}
			</div>
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
