/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IChangeLimitationsP2PLimitsResponse,
	IChangeLimitationsP2PPayload,
	IChangeLimitationsPayload,
	IDailyLimitationsItem,
	ILimitationsP2PData,
	ILimitationsRequestPayload,
	ILimitationsResponsePayload,
	ILimitationsItem,
	IUpdateFiatSingleRequest,
	IUpdateCryptoSingleRequest,
	IUpdateCryptoDailyRequest,
} from './types';
import {
	changeDailyLimitationsRequest,
	changeDailyLimitationsSuccess,
	changeLimitationsFiatRequest,
	changeLimitationsFiatSuccess,
	changeLimitationsP2PRequest,
	changeLimitationsP2PSuccess,
	changeLimitationsRequest,
	changeLimitationsSuccess,
	disableDailyLimitationsLoader,
	disableLimitationsP2PLoader,
	getDailyLimitationsRequest,
	getDailyLimitationsSuccess,
	getLimitationsFiatRequest,
	getLimitationsFiatSuccess,
	getLimitationsP2PRequest,
	getLimitationsP2PSuccess,
	getLimitationsRequest,
	getLimitationsSuccess,
	getSingleFiatLimitationsRequest,
	updateSingleFiatWithdrawRequest,
	updateSingleFiatWithdrawSuccess,
	getCryptoSingleLimitationsRequest,
	updateSingleCryptoWithdrawRequest,
	getDailyFiatLimitationsRequest,
	updateDailyFiatLimitationRequest,
	getCryptoDailyLimitationsRequest,
	updateCryptoDailyWithdrawRequest,
	getCryptoMonthlyLimitationsRequest,
	updateCryptoMonthlyLimitationRequest,
	getMonthlyFiatLimitationsRequest,
	updateFiatMonthlyLimitationRequest,
	limitationsInitState,
} from './reducer';

// ===================================================:
function* limitationsRequestWorker(action: PayloadAction<ILimitationsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ILimitationsResponsePayload = yield call(
			api.limitations.getLimitations,
			payload,
		);
		yield put(getLimitationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* changeLimitationsWorker(action: PayloadAction<IChangeLimitationsPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.limitations.changeLimitations, payload);
		yield put(changeLimitationsSuccess());

		notificationContainer('Your withdraw limit changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(hideLoading());
		yield put(getLimitationsRequest({}));
	}
}
// Fiat
function* limitationsRequestFiatWorker(action: PayloadAction<any>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ILimitationsResponsePayload = yield call(
			api.limitations.getLimitationsFiat,
			payload,
		);
		yield put(getLimitationsFiatSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* changeLimitationsFiatWorker(action: PayloadAction<any>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.limitations.changeLimitationsFiat, payload);
		yield put(changeLimitationsFiatSuccess());
		notificationContainer('Your withdraw limit changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(hideLoading());
		yield put(getLimitationsFiatRequest({}));
	}
}
// ===================================================:
function* getDailyLimitationsRequestWorker(action: PayloadAction<ILimitationsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IDailyLimitationsItem = yield call(
			api.limitations.getDailyLimitations,
			payload,
		);
		yield put(getDailyLimitationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(disableDailyLimitationsLoader());
	}
}

function* changeDailyLimitationsWorker(action: PayloadAction<IDailyLimitationsItem>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.limitations.changeDailyLimitations, payload);
		yield put(changeDailyLimitationsSuccess());

		notificationContainer('Your withdraw limit changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(disableDailyLimitationsLoader());
	} finally {
		yield put(hideLoading());
		yield put(getDailyLimitationsRequest({}));
	}
}
// =============================================================:
function* limitationsP2PRequestWorker() {
	try {
		yield put(showLoading());
		const response: ILimitationsP2PData = yield call(api.limitations.getLimitationsP2P);
		yield put(getLimitationsP2PSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(disableLimitationsP2PLoader());
		yield put(hideLoading());
	}
}
function* changeLimitationsP2PRequestWorker({
	payload,
}: PayloadAction<IChangeLimitationsP2PPayload>) {
	try {
		yield put(showLoading());
		const response: IChangeLimitationsP2PLimitsResponse = yield call(
			api.limitations.changeLimitationsP2P,
			payload,
		);
		notificationContainer('Successfully updated!', 'success');
		yield put(changeLimitationsP2PSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableLimitationsP2PLoader());
		yield put(hideLoading());
	}
}

function* getSingleFiatLimitationsWorker() {
	try {
		yield put(showLoading());
		const response: ILimitationsItem[] = yield call(api.limitations.getFiatSingle);
		yield put(getLimitationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* updateSingleFiatWithdrawWorker({ payload }: PayloadAction<IUpdateFiatSingleRequest>) {
	try {
		yield put(showLoading());
		const response: ILimitationsItem = yield call(api.limitations.updateFiatSingle, payload);
		yield put(updateSingleFiatWithdrawSuccess(response));

		notificationContainer('Your limit changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getSingleCryptoLimitationsWorker() {
	try {
		yield put(showLoading());
		const response: ILimitationsItem[] = yield call(api.limitations.getCryptoSingle);
		yield put(getLimitationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* updateSingleCryptoWithdrawWorker({ payload }: PayloadAction<IUpdateCryptoSingleRequest>) {
	try {
		yield put(showLoading());
		const response: ILimitationsItem = yield call(api.limitations.updateCryptoSingle, payload);
		yield put(updateSingleFiatWithdrawSuccess(response));

		notificationContainer('Your limit changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getFiatDailyLimitationsWorker() {
	try {
		yield put(showLoading());
		const response: ILimitationsItem[] = yield call(api.limitations.getFiatDaily);
		yield put(getLimitationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* updateDailyFiatLimitationWorker({ payload }: PayloadAction<IUpdateFiatSingleRequest>) {
	try {
		yield put(showLoading());
		const response: ILimitationsItem = yield call(api.limitations.updateFiatDaily, payload);
		yield put(updateSingleFiatWithdrawSuccess(response));

		notificationContainer('Your limit changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getCryptoDailyLimitationsWorker() {
	try {
		yield put(showLoading());
		const response: ILimitationsItem[] = yield call(api.limitations.getCryptoDaily);
		yield put(getLimitationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* updateCryptoDailyLimitationWorker({ payload }: PayloadAction<IUpdateCryptoDailyRequest>) {
	try {
		yield put(showLoading());
		const response: ILimitationsItem = yield call(api.limitations.updateCryptoDaily, payload);
		yield put(updateSingleFiatWithdrawSuccess(response));

		notificationContainer('Your limit changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getCryptoMonthlyLimitationsWorker() {
	try {
		yield put(showLoading());
		const response: ILimitationsItem[] = yield call(api.limitations.getCryptoMonthly);
		yield put(getLimitationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* updateCryptoMonthlyLimitationWorker({
	payload,
}: PayloadAction<IUpdateCryptoDailyRequest>) {
	try {
		yield put(showLoading());
		const response: ILimitationsItem = yield call(api.limitations.updateCryptoMonthly, payload);
		yield put(updateSingleFiatWithdrawSuccess(response));

		notificationContainer('Your limit changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getFiatMonthlyLimitationsWorker() {
	try {
		yield put(showLoading());
		const response: ILimitationsItem[] = yield call(api.limitations.getFiatMonthly);
		yield put(getLimitationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* updateFiatMonthlyLimitationWorker({ payload }: PayloadAction<IUpdateCryptoDailyRequest>) {
	try {
		yield put(showLoading());
		const response: ILimitationsItem = yield call(api.limitations.updateFiatMonthly, payload);
		yield put(updateSingleFiatWithdrawSuccess(response));

		notificationContainer('Your limit changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ==================================================:
export function* limitationsSaga() {
	yield takeEvery(getLimitationsRequest.type, limitationsRequestWorker);
	// crypto
	yield takeEvery(getLimitationsFiatRequest.type, limitationsRequestFiatWorker);
	yield takeEvery(changeLimitationsFiatRequest.type, changeLimitationsFiatWorker);
	//
	yield takeEvery(getDailyLimitationsRequest.type, getDailyLimitationsRequestWorker);
	yield takeEvery(getLimitationsP2PRequest.type, limitationsP2PRequestWorker);
	yield takeEvery(changeLimitationsRequest.type, changeLimitationsWorker);
	yield takeEvery(changeDailyLimitationsRequest.type, changeDailyLimitationsWorker);
	yield takeEvery(changeLimitationsP2PRequest.type, changeLimitationsP2PRequestWorker);
	yield takeEvery(getSingleFiatLimitationsRequest.type, getSingleFiatLimitationsWorker);
	yield takeEvery(updateSingleFiatWithdrawRequest.type, updateSingleFiatWithdrawWorker);
	yield takeEvery(getCryptoSingleLimitationsRequest.type, getSingleCryptoLimitationsWorker);
	yield takeEvery(updateSingleCryptoWithdrawRequest.type, updateSingleCryptoWithdrawWorker);
	yield takeEvery(getDailyFiatLimitationsRequest.type, getFiatDailyLimitationsWorker);
	yield takeEvery(updateDailyFiatLimitationRequest.type, updateDailyFiatLimitationWorker);
	yield takeEvery(getCryptoDailyLimitationsRequest.type, getCryptoDailyLimitationsWorker);
	yield takeEvery(updateCryptoDailyWithdrawRequest.type, updateCryptoDailyLimitationWorker);
	yield takeEvery(getCryptoMonthlyLimitationsRequest.type, getCryptoMonthlyLimitationsWorker);
	yield takeEvery(updateCryptoMonthlyLimitationRequest.type, updateCryptoMonthlyLimitationWorker);
	yield takeEvery(getMonthlyFiatLimitationsRequest.type, getFiatMonthlyLimitationsWorker);
	yield takeEvery(updateFiatMonthlyLimitationRequest.type, updateFiatMonthlyLimitationWorker);
}
