/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLimitationsRequest } from 'redux/reducers/limitations/reducer';
import { getLimitationsIsLoad, getLimitationsList } from 'redux/reducers/limitations/selectors';
import Loader from 'ui/Loader';
import { groupFiatLimitations } from 'components/Limitations/Limitations/new/utils';
import { LimitationsItem } from './LimitationsItem';

export const LimitationsDeposit: FC = () => {
	const limitations = useSelector(getLimitationsList);
	const limitationsLoader = useSelector(getLimitationsIsLoad);

	const groupedLimitations = groupFiatLimitations(limitations || []);

	return (
		<div className="table-wrapper">
			<div className="table table--limitations-fiat-withdraw">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<div className="td-name">
								<p>Currency</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Channel name</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Min Deposit</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Max Deposit</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>No limit</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>VIP Min Deposit</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>VIP Max Deposit</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>VIP No limit</p>
							</div>
						</div>
						<div className="td td--right">
							<div className="td-name td-name--action">
								<p>Action</p>
							</div>
						</div>
					</div>
				</div>
				<div className="table-body">
					{!limitations?.length && !limitationsLoader && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">No users found...</p>
						</div>
					)}
					{limitationsLoader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{Object.values(groupedLimitations).map((group) => (
								<div className="tr-currency-group">
									{group.map((item) => (
										<LimitationsItem key={item.id} data={item} />
									))}
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
