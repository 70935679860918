import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IReferralApi } from './types';
/* eslint-disable */
// ==========================================:
export const referral: IReferralApi = {
	getReferralList: async (params, id) => {
		return http
			.get(endpoint.referral.REFERRALS(Number(id)), { params })
			.then((response) => response.data);
	},
	getReferralFee: () => http.get(endpoint.referral.REFERRAL_FEE).then((response) => response.data),
	updateReferralFee: (payload) =>
		http.put(endpoint.referral.REFERRAL_FEE, { fee: payload }).then((response) => response.data),
};
