import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { TradingPairs } from 'components/TradingPairs';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const TradingPairsPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.MANAGE_ASSET_PAIRS}>
			<Dashboard title="Trading Pairs">
				<TradingPairs />
			</Dashboard>
		</PermissionGate>
	);
};

export default TradingPairsPage;
