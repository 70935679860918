import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { api, responseErrors } from 'services';
import axios from 'axios';
import {
	getBankAccountsRequest,
	getBankAccountsSuccess,
	addBankAccountRequest,
	addBankAccountSuccess,
	editBankAccountRequest,
	editBankAccountSuccess,
	deleteBankAccountRequest,
	deleteBankAccountSuccess,
	bankAccountInitState,
} from './reducer';
import {
	IBankAccountRequest,
	IBankAccountResponse,
	IAddBankAccountPayload,
	IEditBankAccountPayload,
	IDeleteBankAccountPayload,
	IBankAccountItem,
} from './types';

// =============================================================:
function* getBankAccountsWorker({ payload }: PayloadAction<IBankAccountRequest>) {
	try {
		yield put(showLoading());
		const response: IBankAccountResponse = yield call(api.bankAccount.getBankAccount, payload);

		yield put(getBankAccountsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(bankAccountInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* addBankAccountWorker({ payload }: PayloadAction<IAddBankAccountPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		const response: IBankAccountItem = yield call(api.bankAccount.addBankAccount, apiParams);

		onSuccess();
		yield put(editBankAccountSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* editBankAccountWorker({ payload }: PayloadAction<IEditBankAccountPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		const response: IBankAccountItem = yield call(api.bankAccount.editBankAccount, apiParams);

		onSuccess();
		yield put(editBankAccountSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* deleteBankAccountWorker({ payload }: PayloadAction<IDeleteBankAccountPayload>) {
	const { id, onSuccess } = payload;
	try {
		yield put(showLoading());
		yield call(api.bankAccount.deleteBankAccount, id);

		onSuccess();
		yield put(deleteBankAccountSuccess(id));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* bankAccountSaga() {
	yield takeEvery(getBankAccountsRequest.type, getBankAccountsWorker);
	yield takeEvery(addBankAccountRequest.type, addBankAccountWorker);
	yield takeEvery(editBankAccountRequest.type, editBankAccountWorker);
	yield takeEvery(deleteBankAccountRequest.type, deleteBankAccountWorker);
}
