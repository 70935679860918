/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateFiatMonthlyLimitationRequest } from 'redux/reducers/limitations/reducer';
import { noExponent } from 'utils/numberFormat';
import { displayChannelName } from 'components/Limitations/Limitations/new/utils';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { ILimitationsItemProps } from './types';

// ==========================================:
export const LimitationsItem: FC<ILimitationsItemProps> = ({ data }) => {
	const dispatch = useDispatch();

	const [isRedact, setIsRedact] = useState(false);
	const [regularWithdrawMax, setRegularWithdrawMax] = useState(String(data.withdraw_max ?? ''));
	const [vipWithdrawMax, setVipWithdrawMax] = useState(String(data.vip_withdraw_max ?? ''));
	const [notCorrect, setNotCorrect] = useState(false);
	const [noLimit, setNoLimit] = useState(data.withdraw_no_limit === 1);
	const [vipNoLimit, setVipNoLimit] = useState(data.vip_withdraw_no_limit === 1);

	const { checkEditable } = usePermission();

	const handleChangeLimitations = () => {
		if (notCorrect) return;
		setIsRedact(false);
		dispatch(
			updateFiatMonthlyLimitationRequest({
				id: data.id,
				withdraw_max: Number(regularWithdrawMax),
				vip_withdraw_max: Number(vipWithdrawMax),
				withdraw_no_limit: noLimit ? 1 : 0,
				vip_withdraw_no_limit: vipNoLimit ? 1 : 0,
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{data.asset_code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Channel name</p>
				<p>{data.channel_name && displayChannelName(data.channel_name)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Withdrawal</p>
				{!isRedact ? (
					<p>{noExponent(data.withdraw_max)}</p>
				) : (
					<div>
						<input
							type="text"
							value={noExponent(regularWithdrawMax)}
							onChange={(e) => {
								setRegularWithdrawMax(e.target.value.replace(/[^\d\\.]/g, ''));
								setNotCorrect(e.target.value.replace(/[^\d\\.]/g, '') === '');
							}}
						/>
					</div>
				)}
			</div>

			<div className="td">
				<p className="td-hidden-name">No limit</p>
				<div className="checkbox checkbox--no-mb checkbox--like-radio">
					<label className="checkbox__label">
						<input
							className="hidden"
							type="checkbox"
							checked={isRedact ? noLimit : data.withdraw_no_limit === 1}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								if (!isRedact) return;
								setNoLimit(e.target.checked);
							}}
						/>
						<div className="checkbox__item" />
						<span className="checkbox__text">No limit</span>
					</label>
				</div>
			</div>

			<div className="td">
				<p className="td-hidden-name">VIP Withdrawal</p>
				{!isRedact ? (
					<p>{noExponent(data.vip_withdraw_max)}</p>
				) : (
					<div>
						<input
							type="text"
							value={noExponent(vipWithdrawMax)}
							onChange={(e) => {
								setVipWithdrawMax(e.target.value.replace(/[^\d\\.]/g, ''));
								setNotCorrect(e.target.value.replace(/[^\d\\.]/g, '') === '');
							}}
						/>
					</div>
				)}
			</div>

			<div className="td">
				<p className="td-hidden-name">Vip No limit</p>
				<div className="checkbox checkbox--no-mb checkbox--like-radio">
					<label className="checkbox__label">
						<input
							className="hidden"
							type="checkbox"
							checked={isRedact ? vipNoLimit : data.vip_withdraw_no_limit === 1}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								if (!isRedact) return;
								setVipNoLimit(e.target.checked);
							}}
						/>
						<div className="checkbox__item" />
						<span className="checkbox__text">No limit</span>
					</label>
				</div>
			</div>

			<div className="td">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					<div className="table-buttons">
						{isRedact ? (
							<>
								<button
									type="button"
									onClick={() => {
										setRegularWithdrawMax(String(data.withdraw_max));
										setVipWithdrawMax(String(data.vip_withdraw_max));
										setNoLimit(data.withdraw_no_limit === 1);
										setVipNoLimit(data.vip_withdraw_no_limit === 1);

										setIsRedact(!isRedact);
									}}
								>
									<span className="edit-icon icon-cancel-icon" />
								</button>

								<button
									type="button"
									onClick={handleChangeLimitations}
									className={notCorrect ? 'button-not-active' : ''}
								>
									<svg
										width="18"
										height="19"
										viewBox="0 0 18 19"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
											stroke="#00DA83"
											strokeLinecap="square"
										/>
									</svg>
								</button>
							</>
						) : (
							<button
								type="button"
								disabled={!checkEditable(permissions.SET_LIMITS)}
								onClick={() => setIsRedact(!isRedact)}
							>
								<span className="edit-icon icon-edit-icon" />
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
