import { FC } from 'react';
import { Link } from 'react-router-dom';

import { p2pStatus } from 'redux/reducers/transactions/constants';
import { noExponent, capitalizeFirstLetter } from 'utils/numberFormat';
import { useDispatch } from 'react-redux';
import { getActiveChatId } from 'redux/reducers/chat/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IDisputeTableItemProps } from './types';

const DisputeTableItem: FC<IDisputeTableItemProps> = ({ item }) => {
	const dispatch = useDispatch();
	const dateCreated = new Date(item.created_at).toLocaleString('en-GB', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: 'numeric',
		minute: '2-digit',
	});

	const { checkReadable } = usePermission();

	const getStatusClassName = () => {
		const classNames = ['status'];
		const newClassName = p2pStatus[item.status]?.statusClass;

		if (newClassName) {
			classNames.push(newClassName);
		}
		return classNames.join(' ');
	};

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{item.id}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Coin</p>
				<p style={{ textTransform: 'uppercase' }}>{item.asset_code}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Type</p>
				<p>{capitalizeFirstLetter(item.advertisement.type)}</p>
			</div>

			<div className="td td--right">
				{checkReadable(permissions.MANAGE_USERS) ? (
					<Link className="td" to={`/user-management/${String(item.buyer_id)}`}>
						<p className="td-hidden-name">Buyer ID</p>
						<p>{item.buyer_id}</p>
					</Link>
				) : (
					<div className="td td--right">
						<p className="td-hidden-name">Buyer ID</p>
						<p>{item.buyer_id}</p>
					</div>
				)}
			</div>

			<div className="td td--right">
				{checkReadable(permissions.MANAGE_USERS) ? (
					<Link className="td" to={`/user-management/${String(item.seller_id)}`}>
						<p className="td-hidden-name">Seller ID</p>
						<p>{item.seller_id}</p>
					</Link>
				) : (
					<div className="td td--right">
						<p className="td-hidden-name">Seller ID</p>
						<p>{item.seller_id}</p>
					</div>
				)}
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(+item.amount)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Created</p>
				<p>{dateCreated}</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div
					className={getStatusClassName()}
					style={{ backgroundColor: '#71A2B0' }} // temp
				>
					{p2pStatus[item.status]?.statusText}
				</div>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<span className="status status--verified">
					<Link
						onClick={() => {
							dispatch(getActiveChatId(item.id));
						}}
						className="td"
						to={`/dispute-info/${item.id}`}
					>
						<p>Details</p>
					</Link>
				</span>
			</div>
		</div>
	);
};

export default DisputeTableItem;
