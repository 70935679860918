/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IDepositItemProps } from './types';
import { ActionsButtons } from './ActionButtons';

// ==========================================:
export const DepositItem: FC<IDepositItemProps> = ({
	data,
	handleClearSearch,
	selectedItems,
	onSelectItem,
}) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);
	const { checkReadable } = usePermission();

	return (
		<div className="tr">
			<div className="td">
				<div className="checkbox checkbox--no-mb">
					<label className="checkbox__label">
						<input
							type="checkbox"
							className="hidden"
							checked={selectedItems[data.id]}
							onChange={() => onSelectItem(data.id, !selectedItems[data.id])}
						/>
						<div className=" checkbox__item">
							<span className="checkbox__item-icon">
								<svg
									className="fill"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 26 26"
									width="26"
									height="26"
									fill="#1a1a1a"
								>
									<path d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z" />
								</svg>
							</span>
						</div>
					</label>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">User Id</p>
				{checkReadable(permissions.MANAGE_USERS) ? (
					<Link className="td" to={`/user-management/${String(data.user_id)}`}>
						<p className="td-hidden-name">Id</p>
						<p>{data.user_id}</p>
					</Link>
				) : (
					<div className="td">
						<p className="td-hidden-name">Id</p>
						<p>{data.user_id}</p>
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Game User ID</p>
				<p>{data.game_user_id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Time</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> &bull; </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Asset</p>
				<p>{data.asset_code?.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p>{data.amount}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount in USD</p>
				<p>{Number(data.amount_usd)}</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<ActionsButtons id={data.id} handleClearSearch={handleClearSearch} />
			</div>
		</div>
	);
};
