import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setP2PCurrencyStatus } from 'redux/reducers/сurrencies/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IP2PProps } from './types';

const CurrenciesP2PTableItem: FC<IP2PProps> = ({ item }) => {
	const { code, active, id } = item;
	const dispatch = useDispatch();
	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.MANAGE_CURRENCIES);

	const handleCurrencyChange = () => {
		if (!canEdit) return;
		dispatch(setP2PCurrencyStatus({ id, active: Number(!active) }));
	};

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Currency</p>
				<p style={{ textTransform: 'uppercase' }}>{code}</p>
			</div>
			{/* <div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<p>{active ? 'active' : 'not active'}</p>
			</div> */}
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div className={`switch switch--type2 ${!canEdit ? 'switch--disabled' : ''}`}>
					<label className="switch__label">
						<input
							onChange={handleCurrencyChange}
							type="checkbox"
							className="hidden"
							checked={!!active}
							disabled={!canEdit}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
		</div>
	);
};
export default CurrenciesP2PTableItem;
