import {
	IChangeLimitationsP2PPayload,
	IChangeLimitationsPayload,
	ILimitationsResponsePayload,
} from 'redux/reducers/limitations/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ILimitationsApi } from './types';

// ==========================================:
export const limitations: ILimitationsApi = {
	getLimitations: () =>
		http
			.get<ILimitationsResponsePayload>(endpoint.limitations.CRYPTO_FEES)
			.then((response) => response.data),
	// crypto
	getLimitationsFiat: () =>
		http
			.get<ILimitationsResponsePayload>(endpoint.limitations.CRYPTO_FEES_FIAT)
			.then((response) => response.data),
	changeLimitationsFiat: (payload) =>
		http.put<IChangeLimitationsP2PPayload>(endpoint.limitations.CRYPTO_FEES_FIAT, payload),
	//
	getDailyLimitations: () =>
		http.get(endpoint.limitations.DAILY_WITHDRAWAL).then((response) => response.data),
	changeLimitations: (payload) =>
		http.put<IChangeLimitationsPayload>(endpoint.limitations.UPDATE_CRYPTO_FEES, payload),
	changeDailyLimitations: (payload) => http.put(endpoint.limitations.DAILY_WITHDRAWAL, payload),
	getLimitationsP2P: () =>
		http.get(endpoint.limitations.LIMITATIONS_P2P).then((response) => response.data),
	changeLimitationsP2P: (payload) =>
		http.put<IChangeLimitationsP2PPayload>(endpoint.limitations.LIMITATIONS_P2P, payload),
	// ******************************************************************
	getCryptoSingle: () =>
		http.get(endpoint.limitations.CRYPTO_SINGLE).then((response) => response.data),
	getCryptoDaily: () =>
		http.get(endpoint.limitations.CRYPTO_DAILY).then((response) => response.data),
	getCryptoMonthly: () =>
		http.get(endpoint.limitations.CRYPTO_MONTHLY).then((response) => response.data),
	updateCryptoSingle: (payload) =>
		http.put(endpoint.limitations.CRYPTO_SINGLE, payload).then((response) => response.data),
	updateCryptoDaily: (payload) =>
		http.put(endpoint.limitations.CRYPTO_DAILY, payload).then((response) => response.data),
	updateCryptoMonthly: (payload) =>
		http.put(endpoint.limitations.CRYPTO_MONTHLY, payload).then((response) => response.data),
	getFiatSingle: () => http.get(endpoint.limitations.FIAT_SINGLE).then((response) => response.data),
	getFiatDaily: () => http.get(endpoint.limitations.FIAT_DAILY).then((response) => response.data),
	getFiatMonthly: () =>
		http.get(endpoint.limitations.FIAT_MONTHLY).then((response) => response.data),
	updateFiatSingle: (payload) =>
		http.put(endpoint.limitations.FIAT_SINGLE, payload).then((response) => response.data),
	updateFiatDaily: (payload) =>
		http.put(endpoint.limitations.FIAT_DAILY, payload).then((response) => response.data),
	updateFiatMonthly: (payload) =>
		http.put(endpoint.limitations.FIAT_MONTHLY, payload).then((response) => response.data),
};
