import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayDisableForm } from 'redux/reducers/settings/reducer';
import { get2faIsLoad } from 'redux/reducers/settings/selectors';
import Loader from 'ui/Loader';

// ==========================================:
const Disable2faInfo: FC = () => {
	const dispatch = useDispatch();
	const account2faIsLoad = useSelector(get2faIsLoad);

	const handleDisable2fa = () => {
		dispatch(displayDisableForm(true));
	};

	return (
		<div className="forms-block tfa-block">
			<div className="form form--settings">
				<div className="tfa-block__info">
					<h3 className="tfa-block__title">Disable Two-Factor Authentication</h3>
					<p className="tfa-block__text">
						For your security, we strongly recommend enabling 2FA on your account. After that, you
						will need to enter a one-time 6-digit code each time you are logging into your account,
						withdrawing funds or changing the password.
					</p>

					<div className="form-submit form-submit--settings">
						<button
							onClick={handleDisable2fa}
							type="button"
							disabled={account2faIsLoad}
							className="button button--full-width btn-2fa btn-2fa--disable button--rounded"
						>
							{account2faIsLoad ? (
								<>
									<Loader small />
									<span className="btn-2fa-loading-text">Loading...</span>
								</>
							) : (
								'Disable 2FA'
							)}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Disable2faInfo;
