import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Email2FAHistory from 'components/Email2FAHistory';

// ================================================:
const Email2FAPage: FC = () => {
	return (
		<Dashboard title="Email 2FA history">
			<Email2FAHistory />
		</Dashboard>
	);
};

export default Email2FAPage;
