import { FC, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import { SpotTrades } from './TradexBox/SpotTrades/SpotTrades';
import { P2PTrades } from './TradexBox/P2PTrades/P2PTrades';
import { MarginTrades } from './TradexBox/MarginTrades/MarginTrades';
import { SpotTradesFilter } from './filters/SpotTradesFilter';
import { MarginTradesFilter } from './filters/MarginTradesFilter';
import { P2PTradesFilter } from './filters/P2PTradesFilter';

const Trades: FC = () => {
	const [tabIndex, setTabIndex] = useState(0);
	const [radioCheck, setRadioCheck] = useState('cross');

	return (
		<div className="trades">
			<div className="title-block">
				<p className="title">Trades</p>
			</div>
			{tabIndex === 0 && <SpotTradesFilter />}
			{tabIndex === 1 && <MarginTradesFilter radioCheck={radioCheck} />}
			{tabIndex === 2 && <P2PTradesFilter />}
			<Tabs selectedIndex={tabIndex} onSelect={setTabIndex} className="settings-tabs">
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link"> Spot trades</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link"> Margin trades(cross/isolated)</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link"> P2P Trades</span>
					</Tab>
				</TabList>
				<TabPanel>
					<SpotTrades radioCheck="cross" />
				</TabPanel>
				<TabPanel>
					<MarginTrades radioCheck={radioCheck} setRadioCheck={setRadioCheck} />
				</TabPanel>
				<TabPanel>
					<P2PTrades radioCheck="cross" />
				</TabPanel>
			</Tabs>
		</div>
	);
};

export default Trades;
