/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { getTradesP2PTradesRequest, setFilterParams } from 'redux/reducers/trade/reducer';
import { getLoadingTrades, getTradesP2P, getFilterParams } from 'redux/reducers/trade/selectors';
import { P2PTradesTableHeader } from './P2PTradesTableHeader';
import { IMarginProps, IP2PTradesItem } from './types';
import P2PTradesTableItem from './P2PTradesTableItem';

export const P2PTrades: FC<IMarginProps> = ({ radioCheck }) => {
	const p2pTradesList = useSelector(getTradesP2P);
	const p2pTradeIsLoad = useSelector(getLoadingTrades);
	const filterParams = useSelector(getFilterParams);
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			per_page: 15,
			current_page: pageNumber || 1,
			start_date: startDate || null,
			end_date: endDate || null,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getTradesP2PTradesRequest(params));
	};
	useEffect(() => {
		const params = {
			per_page: 15,
			current_page: 1,
			start_date: startDate || null,
			end_date: endDate || null,
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getTradesP2PTradesRequest(params));
	}, [radioCheck, startDate, endDate]);

	useEffect(() => {
		return () => {
			dispatch(setFilterParams({ start_date: null, end_date: null }));
		};
	}, [dispatch]);

	return (
		<>
			{p2pTradeIsLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--P2P-trades">
							<P2PTradesTableHeader />
							<div className="table-body">
								{p2pTradesList?.data.map((item: IP2PTradesItem) => (
									<P2PTradesTableItem item={item} key={item.id} />
								))}
								{p2pTradesList?.data.length === 0 ? (
									<p style={{ padding: '20px' }}>Borrowing is empty</p>
								) : null}
							</div>
						</div>
					</div>
					<div style={{ padding: '14px' }}>
						{(p2pTradesList?.last_page ?? 0) > 1 && (
							<Pagination
								pageCount={p2pTradesList?.last_page ?? 0}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
