export const languages = [
	{
		name: 'en',
		label: 'English',
	},
	{
		name: 'ge',
		label: 'Georgian',
	},
	{
		name: 'ua',
		label: 'Ukrainian',
	},
	{
		name: 'ru',
		label: 'Russian',
	},
];

export const getLanguageLabel = (name: string) => {
	switch (name) {
		case 'en':
			return 'English';
		case 'ge':
			return 'Georgian';
		case 'ua':
			return 'Ukrainian';
		case 'ru':
			return 'Russian';
		default:
			return '';
	}
};
