import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getP2P, getP2PLoading } from 'redux/reducers/fees/selectors';
import { getP2PRequest } from 'redux/reducers/fees/reducer';
import Loader from 'ui/Loader';
import P2PItem from './P2PItem';

const P2P: FC = () => {
	const p2pList = useSelector(getP2P);
	const p2pListLoading = useSelector(getP2PLoading);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getP2PRequest());
	}, [dispatch]);

	return (
		<div className="content-block content-block--user-management">
			<div className="content-block__inside content-block__inside--bt content-block__inside--login-his">
				<div className="table-block">
					<div className="table-wrapper">
						<div className="table table--fee--p2p">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Currency</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Regular Buyer fee, %</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Regular Seller fee, %</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>VIP Buyer fee, %</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>VIP Seller fee, %</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Edit</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body">
								{p2pListLoading ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<>
										{p2pList?.map((item) => (
											<P2PItem item={item} key={item.id} />
										))}
										<div />
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default P2P;
