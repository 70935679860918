/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { externalWalletStatusUppdate } from 'redux/reducers/walletManagement/reducer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'utils/notificationContainer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IItemParams } from './types';

export const ExternalWalletItem: FC<IItemParams> = ({ data, onDelete, setIsWarning }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);

	const dispatch = useDispatch();

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.MANAGE_WALLETS);

	const handleDeleteExternalWalletItem = () => {
		onDelete(data.id);
	};

	const handleChangeWalletStatus = () => {
		const payload = {
			id: data.id,
			is_active: data.is_active === 0 ? 1 : 0,
		};
		dispatch(externalWalletStatusUppdate(payload));
	};

	const handleAddressCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Liquidity Provider Name</p>
				<p>{data.provider_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Created Date</p>
				<p style={{ whiteSpace: 'nowrap' }}>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> &bull; </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Coin</p>
				<p>{data.coin.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Wallet Address</p>
				<div className="copy-button">
					<p className="copy-button__text">{data.address}</p>
					<CopyToClipboard text={data?.address} onCopy={handleAddressCopy}>
						<button type="button" className="copy-button__item">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3.33337 10.0007H2.66671C2.31309 10.0007 1.97395 9.86018 1.7239 9.61013C1.47385 9.36008 1.33337 9.02094 1.33337 8.66732V2.66732C1.33337 2.3137 1.47385 1.97456 1.7239 1.72451C1.97395 1.47446 2.31309 1.33398 2.66671 1.33398H8.66671C9.02033 1.33398 9.35947 1.47446 9.60952 1.72451C9.85956 1.97456 10 2.3137 10 2.66732V3.33398"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</CopyToClipboard>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Chain</p>
				<p>{data.network.toUpperCase()}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">On/Off</p>
				<div className={`switch switch--type2 ${!canEdit ? 'switch--disabled' : ''}`}>
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={Boolean(data.is_active)}
							onChange={handleChangeWalletStatus}
							disabled={!canEdit}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
			<div className="td td--center">
				<p className="td-hidden-name">Action</p>
				<button type="button" disabled={!canEdit}>
					<span
						className="trash-icon trash-icon--action icon-trash-icon"
						onClick={() => handleDeleteExternalWalletItem()}
					/>
				</button>
			</div>
		</div>
	);
};
