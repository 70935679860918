/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';

export const BankAccountTableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Name</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Bank name</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>SWIFT</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>IBAN</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Reference</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Address</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Status</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Action</p>
					</div>
				</div>
			</div>
		</div>
	);
};
