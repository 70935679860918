import { useState, useEffect, useCallback } from 'react';
import { ExportIcon2 } from 'assets/img/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
	getPercentFiatFeeRequest,
	addPercentFiatFeeRequest,
	exportPercentFiatFeesRequest,
	getFiatListRequest,
	getChannelListRequest,
} from 'redux/reducers/fiatFees/reducer';
import Loader from 'ui/Loader';
import { getRangePercentLoading, getRangePercent } from 'redux/reducers/fiatFees/selectors';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import ChannelFilter from './ChannelFilter';
import CurrencyFilter from './CurrencyFilter';
import TableHeader from './TableHeader';
import RowItem from './RowItem';

const FeeManagementFiat = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getRangePercentLoading);
	const fiatFees = useSelector(getRangePercent);
	const [currency, setCurrency] = useState('usd');
	const [channel, setChannel] = useState('card_visa');
	const [activeId, setActiveId] = useState<number | null>(null);

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.SET_FEES);

	const fetchPercentFiatFee = useCallback(() => {
		dispatch(getPercentFiatFeeRequest({ asset_code: currency, channel_name: channel }));
	}, [channel, currency, dispatch]);

	useEffect(() => {
		dispatch(getFiatListRequest());
		dispatch(getChannelListRequest());
	}, [dispatch]);

	useEffect(() => {
		fetchPercentFiatFee();
	}, [fetchPercentFiatFee]);

	return (
		<>
			<div className="title-block">
				<p className="title">Fee management fiat</p>
			</div>
			<div className="count-label count-label--zindex count-label--filter-type">
				<button
					type="button"
					className="transaction-export-btn"
					onClick={() => dispatch(exportPercentFiatFeesRequest())}
				>
					Export fee management fiat
					<span className="transaction-export-btn__icon">
						<ExportIcon2 />
					</span>
				</button>
			</div>
			<div className="content-block content-block--user-management">
				<div className="content-block__inside  content-block__inside--type2">
					<div className="table-filters table-filters--fee-fiat">
						<div className="input">
							<p className="input__name input__name--fs-12 input__name--color2 input__name--fw-400">
								Currency
							</p>
							<CurrencyFilter value={currency} onChange={setCurrency} />
						</div>
						<div className="input">
							<p className="input__name input__name--fs-12 input__name--color2 input__name--fw-400">
								Chanel
							</p>
							<ChannelFilter value={channel} onChange={setChannel} />
						</div>
					</div>

					<div className="table-block table-block--mt-0">
						<div className="table-wrapper">
							<div className="table table--new-fee-fiat">
								<TableHeader />
								<div className="table-body">
									{loading ? (
										<div className="list-loader">
											<Loader />
										</div>
									) : (
										fiatFees?.map((item) => (
											<RowItem
												key={item.id}
												item={item}
												active={activeId === item.id}
												setActive={setActiveId}
												onRefetch={fetchPercentFiatFee}
											/>
										))
									)}
								</div>
								{canEdit && (
									<div className="table-footer table-footer--inside">
										<button
											className="button button--height-30 button--auto-width-16 button--outline"
											type="button"
											onClick={() =>
												dispatch(
													addPercentFiatFeeRequest({
														asset_code: currency,
														channel_name: channel,
													}),
												)
											}
										>
											<span className="btn-icon btn-icon--max-width">+</span>
											Add more
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default FeeManagementFiat;
