import { FC, useState, useEffect } from 'react';
import Loader from 'ui/Loader';
import { useSelector } from 'react-redux';
import { getBankTransferIsLoad } from 'redux/reducers/withdrawals/selectors';
import { WithdrawalItem } from './WithdrawalItem';
import { IWithdrawalListProps } from './types';

// ==========================================:
const WithdrawalList: FC<IWithdrawalListProps> = ({ bankTransfers, onRefetch }) => {
	const withdrawalsLoader = useSelector(getBankTransferIsLoad);

	const [invoiceOpenedId, setInvoiceOpenedId] = useState<number | null>(null);

	const onSetInvoiceOpenedId = (value: number | null) => {
		if (value) {
			document.body.classList.add('popup-open');
		} else {
			document.body.classList.remove('popup-open');
		}
		setInvoiceOpenedId(value);
	};

	return (
		<div className="table-block table-block--mt-0">
			<div className="table-wrapper">
				<div className="table table--pending-bank-transfer">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>User id</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Currency</p>
								</div>
							</div>
							{/* <div className="td">
								<div className="td-name">
									<p>Network</p>
								</div>
							</div> */}
							<div className="td">
								<div className="td-name">
									<p>To</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Comment</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Created</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Invoice</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name td-name--action">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>
					{!bankTransfers?.length && !withdrawalsLoader && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">No withdrawals found...</p>
						</div>
					)}
					{withdrawalsLoader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{bankTransfers?.map((item) => (
								<WithdrawalItem
									key={item.id}
									data={item}
									invoiceOpenedId={invoiceOpenedId}
									setInvoiceOpenedId={onSetInvoiceOpenedId}
									onRefetch={onRefetch}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default WithdrawalList;
