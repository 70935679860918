export const getStatusClassName = (status: string) => {
	switch (status) {
		case 'verified':
			return 'status--success';
		case 'unverified':
			return 'status--failed';
		default:
			return '';
	}
};
