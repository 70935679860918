import { FC } from 'react';
import { useSelect } from 'services/hooks/useSelect';
import { ISelectGoldAmount } from './types';

const SelectGoldAmount: FC<ISelectGoldAmount> = ({ options, value, onChange }) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelectChange = (_value: number) => {
		onChange?.(_value);
		setOpen(false);
	};

	return (
		<div className={`select select--regular2 select--height-48 ${open ? 'active' : ''}`}>
			<button
				type="button"
				ref={triggerRef}
				className={`select__current ${value == null ? 'select__current--unselect' : ''}`}
				onClick={toggleOpen}
			>
				<span className="select__current-text">
					{value != null ? `${value}g` : 'Select amount in the grams'}
				</span>
				<span className="select__current-arrow">
					<svg
						className="stroke"
						width="8"
						height="5"
						viewBox="0 0 8 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
					</svg>
				</span>
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select-drop__item">
						<ul>
							{options.map((opt) => (
								<li key={opt}>
									<button type="button" onClick={() => handleSelectChange(opt)}>
										{opt}g
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectGoldAmount;
