/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { putConvertLiquidityRequest } from 'redux/reducers/settings/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IAdminItemProps } from './types';

// ==========================================:
export const ConvertSettingsItem: FC<IAdminItemProps> = ({ data }) => {
	const [spread, setSetspread] = useState(String(data?.spread));
	const [isClosed, setIsClosed] = useState(false);
	const dispatch = useDispatch();

	const { checkEditable } = usePermission();

	const handleChangeSubmit = () => {
		if (spread === '') return;
		dispatch(putConvertLiquidityRequest({ asset_id: data?.asset_id, spread }));
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Spread</p>
				<p>{data?.asset.code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Value</p>
				{isClosed ? <div /> : <p>{spread}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input type="text" value={spread} onChange={(e) => setSetspread(e.target.value)} />
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					<button
						type="submit"
						className={`${isClosed ? '' : 'disable'}`}
						onClick={() => {
							setSetspread(String(data?.spread));
							setIsClosed(!isClosed);
						}}
					>
						<span className="edit-icon icon-cancel-icon" />
					</button>
					{isClosed ? (
						<button
							type="button"
							onClick={() => handleChangeSubmit()}
							className={spread === '' ? 'button-not-active' : ''}
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					) : (
						<button
							type="button"
							disabled={!checkEditable(permissions.MANAGE_CONVERT_SETTINGS)}
							onClick={() => setIsClosed(!isClosed)}
						>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
