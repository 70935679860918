import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChatRequest } from 'redux/reducers/chat/reducer';
import {
	getChatListBuyer,
	getChatListSeller,
	getChatListUser,
} from 'redux/reducers/chat/selectors';
import Popup from 'reactjs-popup';
import ChatsSellerMessagesItem from './ChatsSellerMessagesItem';
import { TProps } from '../types';

export const ChatsSeller: FC<TProps> = ({ info }) => {
	const [modalImage, setModalImage] = useState<string | null>(null);
	const wrapperRef = useRef<null | any>(info);
	const dispatch = useDispatch();
	const chatListSeller = useSelector(getChatListSeller);

	useEffect(() => {
		wrapperRef?.current?.scroll({
			top: wrapperRef.current.scrollHeight,
			left: 0,
		});
	}, [chatListSeller]);
	return (
		<>
			<div id={info} ref={wrapperRef} className="chat-history-box__scroll">
				{chatListSeller?.map((item: any) => {
					return (
						<ChatsSellerMessagesItem
							showImage={setModalImage}
							key={item.id}
							item={item}
							status={chatListSeller[0].from_username}
						/>
					);
				})}
			</div>
			<Popup
				open={!!modalImage}
				onClose={() => setModalImage(null)}
				overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
			>
				<div className="image-modal" onClick={() => setModalImage(null)}>
					<img src={modalImage as string} alt="document" />
				</div>
			</Popup>
		</>
	);
};
