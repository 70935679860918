/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAdminAuthUserResponseData,
	ILoginRequestPayload,
	IAdminLogoutData,
	IAuthStore,
} from './types';

// ==========================================:
export const initialState: IAuthStore = {
	accessToken: null,
	socketToken: null,
	isAuthenticated: false,
	loginLoader: false,
	adminLogoutLoader: false,
	email: null,
	loginEmail: null,
	loginPassword: null,
	remember: false,
	userData: null,
	tokenExpires: null,
};

// ==========================================:
const auth = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		loginRequest: (state, action: PayloadAction<ILoginRequestPayload>) => {
			const { payload } = action;
			const loginRequestState = state;

			loginRequestState.loginLoader = true;
			loginRequestState.remember = payload.remember;

			if (payload.remember) {
				loginRequestState.loginEmail = payload.email;
				loginRequestState.loginPassword = payload.password;
			}
		},
		loginSuccess: (state, action: PayloadAction<IAdminAuthUserResponseData>) => {
			const { payload } = action;
			const loginState = state;

			loginState.isAuthenticated = true;
			loginState.loginLoader = false;
			loginState.accessToken = payload.token;
			loginState.socketToken = payload.socket_token;
			loginState.userData = payload.user_data;
			loginState.email = payload.user_data.email;
			loginState.loginEmail = payload.user_data.email;
			loginState.tokenExpires = payload.token_expired_at;
		},
		logoutRequest: (state, action: PayloadAction<IAdminLogoutData>) => {
			const logoutState = state;

			logoutState.adminLogoutLoader = true;
		},
		authInitState: (state) => {
			const authInitStateState = state;

			if (authInitStateState.remember) {
				authInitStateState.isAuthenticated = false;
				authInitStateState.accessToken = null;
				authInitStateState.adminLogoutLoader = false;
				authInitStateState.loginLoader = false;
			} else {
				return initialState;
			}
		},
		logOutState: (state) => {
			const authInitState = state;
			authInitState.accessToken = null;
			authInitState.socketToken = null;
			authInitState.isAuthenticated = false;
			authInitState.loginLoader = false;
			authInitState.adminLogoutLoader = false;
			authInitState.email = null;
			authInitState.loginEmail = null;
			authInitState.loginPassword = null;
			authInitState.remember = false;
			authInitState.tokenExpires = null;
		},
	},
});

export default auth.reducer;
export const { loginRequest, loginSuccess, logoutRequest, authInitState, logOutState } =
	auth.actions;
