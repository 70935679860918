const TableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Currency</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Chanel name</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>
							Rate
							<br /> from
						</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>
							Rate
							<br /> to
						</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Deposit, %</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fixed amount</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Withdrawal, %</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fixed amount</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>VIP Deposit, %</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fixed amount</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>VIP Withdrawal, %</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fixed amount</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name td-name--action">
						<p>Edit</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
