import { FC, useState, useEffect, useCallback } from 'react';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import { ExportIcon } from 'assets/img/icons';
import { ITradeFilters } from './types';

export const TradeFilter: FC<ITradeFilters> = ({
	exportTitle,
	onDateSelect,
	onExport,
	radioCheck,
}) => {
	const { afterToday } = DateRangePicker;
	const [dateRangeValue, setDateRangeValue] = useState<DateRange | null>(null);

	const now = new Date();
	const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

	const changeDateRangeHandler = (d: DateRange | null) => {
		const startDate = d?.[0].toISOString().split('T')[0] || null;
		const endDate = d?.[1].toISOString().split('T')[0] || null;
		setDateRangeValue(d);
		onDateSelect(startDate, endDate);
	};

	useEffect(() => {
		changeDateRangeHandler(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [radioCheck]);

	return (
		<>
			<DateRangePicker
				format="dd/MM/yyyy"
				size="lg"
				block
				placement="bottomEnd"
				style={{ width: '308px' }}
				disabledDate={afterToday?.()}
				value={dateRangeValue}
				onChange={changeDateRangeHandler}
				defaultCalendarValue={[prevMonth, now]}
				ranges={[]}
			/>
			<button type="button" className="transaction-export-btn" onClick={onExport}>
				{exportTitle}
				<span className="transaction-export-btn__icon">
					<ExportIcon />
				</span>
			</button>
		</>
		// <div className="count-label count-label--user-management count-label--left transactions-data-picker">
		// 	<DateRangePicker
		// 		format="dd/MM/yyyy"
		// 		size="lg"
		// 		block
		// 		placement="bottomEnd"
		// 		style={{ width: '308px' }}
		// 		disabledDate={afterToday?.()}
		// 		value={dateRangeValue}
		// 		onChange={changeDateRangeHandler}
		// 		defaultCalendarValue={[prevMonth, now]}
		// 		ranges={[]}
		// 	/>

		// 	<button type="button" className="transaction-export-btn" onClick={onExport}>
		// 		{exportTitle}
		// 		<span className="transaction-export-btn__icon">
		// 			<ExportIcon />
		// 		</span>
		// 	</button>
		// </div>
	);
};
