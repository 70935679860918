/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
// import SearchBar from 'components/SearchBar';
import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Loader from 'ui/Loader';
import queryString from 'query-string';

import { getNotifications } from 'redux/reducers/notifications/selectors';
import { getNotificationsRequest } from 'redux/reducers/notifications/reducer';
import { INotificationRequestPayload } from 'redux/reducers/notifications/types';
import Pagination from 'ui/Pagination';
import NotificationsItem from './NotificationItem';
import SearchBar from '../SearchBar';

// ==========================================:
const Notifications: FC = () => {
	const nitificationList = useSelector(getNotifications);
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const dispatch = useDispatch();

	const { page, text, field } = queryString.parse(search);
	const searchFieldArray = ['name'];
	const [searchField, setSearchField] = useState(field ? String(field) : searchFieldArray[0]);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const perPage = 15;
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: `?page=${String(pageNumber)}`;

		const params: INotificationRequestPayload =
			verifyText.length > 0
				? {
						current_page: pageNumber || 1,
						search_field: searchField,
						search_value: verifyText,
						per_page: perPage,
				  }
				: {
						current_page: pageNumber || 1,
						per_page: perPage,
				  };
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getNotificationsRequest(params));
	};

	const handleSearch = (txt: string) => {
		const params = {
			current_page: 1,
			search_value: txt,
			search_field: searchField,
			per_page: perPage,
		};

		if (txt.length >= 1) {
			dispatch(getNotificationsRequest(params));
		} else {
			dispatch(getNotificationsRequest({ current_page: 1, per_page: perPage }));
		}
		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1
					? `?page=${String(page || 1)}&text=${String(txt)}&field=${searchField}`
					: `?page=${String(1)}`,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const searchFieldHandler = (txt: string) => {
		setSearchField(txt);
	};

	return (
		<>
			<div className="title-block">
				<p className="title">Notifications</p>
			</div>
			<div className="count-label count-label--user-management">
				<div />
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--notification">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Notifications</p>
									</div>
								</div>
								<div className="td td--center">
									<div className="td-name">
										<p>SMS</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Email</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!nitificationList?.all_notifications?.notifications?.length && (
								<div className="user-management-empty-data">
									<p className="user-management-empty__text">No notifications found...</p>
								</div>
							)}
							{nitificationList?.notificationsLoader ? (
								<div className="list-loader">
									<Loader />
								</div>
							) : (
								<>
									{nitificationList?.all_notifications?.notifications?.map((item) => (
										<NotificationsItem item={item} key={item.id} />
									))}
									<div />
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			{(nitificationList?.all_notifications?.total ?? 0) > perPage && (
				<Pagination
					pageCount={nitificationList?.all_notifications?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default Notifications;
