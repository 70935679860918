import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router';
import { getUnidentDepositsListRequest } from 'redux/reducers/unidentifiedDeposits/reducer';
import {
	getUnidentDepositsList,
	getUnidentDepositsLoader,
} from 'redux/reducers/unidentifiedDeposits/selectors';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import { Filter } from './Filter';

const UnidentifiedDeposits = () => {
	const dispatch = useDispatch();
	const deposits = useSelector(getUnidentDepositsList);
	const loading = useSelector(getUnidentDepositsLoader);
	const { search } = useLocation();
	const location = useLocation();
	const history = useHistory();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	useEffect(() => {
		dispatch(getUnidentDepositsListRequest({}));
	}, [dispatch]);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			per_page: 15,
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getUnidentDepositsListRequest(params));
	};

	return (
		<>
			<div className="title-block">
				<p className="title">Unidentified deposits</p>
			</div>
			<div className="count-label count-label--filter-between">
				<Filter />
			</div>
			{loading ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<div className="content-block">
					<div className="content-block__inside content-block__inside--type2">
						<div className="table-block table-block--mt-0">
							<div className="table-wrapper">
								<div className="table table--unidentified-deposits table--with-scroll">
									<TableHeader />
									<div className="table-body">
										{deposits?.data?.map((item) => (
											<TableRow key={item.id} item={item} />
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{(deposits?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={deposits?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default UnidentifiedDeposits;
