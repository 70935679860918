import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generate2faKeyRequest } from 'redux/reducers/settings/reducer';
import { get2faIsLoad } from 'redux/reducers/settings/selectors';
import Loader from 'ui/Loader';

// ================================================:
const Enable2faInfo: FC = () => {
	const account2faIsLoad = useSelector(get2faIsLoad);
	const dispatch = useDispatch();

	const handleGenerate2faKey = () => {
		dispatch(generate2faKeyRequest());
	};

	return (
		<div className="forms-block tfa-block">
			<div className="form form--settings">
				<div className="tfa-block__info">
					<h3 className="tfa-block__title">Enable Two-Factor Authentication</h3>
					<p className="tfa-block__text">
						For your security, we strongly recommend enabling 2FA on your account. After that, you
						will need to enter a one-time 6-digit code each time you are logging into your account,
						withdrawing funds or changing the password.
					</p>

					<div className="form-submit form-submit--settings">
						<button
							onClick={handleGenerate2faKey}
							type="button"
							disabled={account2faIsLoad}
							className="button button--full-width btn-2fa button--rounded"
						>
							{account2faIsLoad ? (
								<>
									<Loader small />
									<span className="btn-2fa-loading-text">Loading...</span>
								</>
							) : (
								'Enable 2FA'
							)}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Enable2faInfo;
