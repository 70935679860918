import { ChangeEvent, FC, useState } from 'react';
import { ILimitationsP2PItem } from 'redux/reducers/limitations/types';
import { changeLimitationsP2PRequest } from 'redux/reducers/limitations/reducer';
import { useDispatch } from 'react-redux';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';

const P2PLimitationsTableItem: FC<ILimitationsP2PItem> = ({ limits, code, id }) => {
	const dispatch = useDispatch();
	const [error, editError] = useState(false);
	const [inputEdit, inputEditChange] = useState({
		code,
		id,
		asset_id: limits[0].asset_id,
		lvl1min: limits[0].advertisement_min.toString(),
		lvl1max: limits[0].advertisement_max.toString(),
		lvl2min: limits[1].advertisement_min.toString(),
		lvl2max: limits[1].advertisement_max.toString(),
		toggleInputActions: false,
	});

	const { checkEditable } = usePermission();

	const editValue = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/[^\d\\.]/g, '');
		inputEditChange({
			...inputEdit,
			[e.target.name]: value,
		});
		editError(!value);
	};

	const inputEditToInit = () => {
		inputEditChange({
			...inputEdit,
			lvl1min: limits[0].advertisement_min.toString(),
			lvl1max: limits[0].advertisement_max.toString(),
			lvl2min: limits[1].advertisement_min.toString(),
			lvl2max: limits[1].advertisement_max.toString(),
			toggleInputActions: !inputEdit.toggleInputActions,
		});
	};

	const handleEdit = () => {
		inputEditChange({
			...inputEdit,
			toggleInputActions: !inputEdit.toggleInputActions,
		});
		dispatch(
			changeLimitationsP2PRequest({
				asset_id: inputEdit.asset_id,
				advertisement_min_level_one: inputEdit.lvl1min,
				advertisement_max_level_one: inputEdit.lvl1max,
				advertisement_min_level_two: inputEdit.lvl2min,
				advertisement_max_level_two: inputEdit.lvl2max,
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency name</p>
				<p style={{ textTransform: 'uppercase' }}>{code}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Min level 1</p>
				<p style={inputEdit.toggleInputActions ? { display: 'flex' } : { display: 'none' }}>
					<input type="number" name="lvl1min" value={inputEdit.lvl1min} onChange={editValue} />
				</p>
				<p style={inputEdit.toggleInputActions ? { display: 'none' } : { display: 'flex' }}>
					{limits[0]?.advertisement_min}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Max level 1</p>
				<p style={inputEdit.toggleInputActions ? { display: 'flex' } : { display: 'none' }}>
					<input type="number" name="lvl1max" value={inputEdit.lvl1max} onChange={editValue} />
				</p>
				<p style={inputEdit.toggleInputActions ? { display: 'none' } : { display: 'flex' }}>
					{limits[0]?.advertisement_max}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Min level 2</p>
				<p style={inputEdit.toggleInputActions ? { display: 'flex' } : { display: 'none' }}>
					<input type="number" name="lvl2min" value={inputEdit.lvl2min} onChange={editValue} />
				</p>
				<p style={inputEdit.toggleInputActions ? { display: 'none' } : { display: 'flex' }}>
					{limits[1]?.advertisement_min}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Max level 2</p>
				<p style={inputEdit.toggleInputActions ? { display: 'flex' } : { display: 'none' }}>
					<input type="number" name="lvl2max" value={inputEdit.lvl2max} onChange={editValue} />
				</p>
				<p style={inputEdit.toggleInputActions ? { display: 'none' } : { display: 'flex' }}>
					{limits[1]?.advertisement_max}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<button
					style={inputEdit.toggleInputActions ? { display: 'none' } : { display: 'flex' }}
					onClick={inputEditToInit}
					disabled={!checkEditable(permissions.SET_LIMITS)}
					type="submit"
				>
					<span className="edit-icon icon-edit-icon" />
				</button>
				<div
					style={inputEdit.toggleInputActions ? { display: 'flex' } : { display: 'none' }}
					className="table-buttons"
				>
					<button onClick={inputEditToInit} type="submit">
						<span className="edit-icon icon-cancel-icon" />
					</button>
					<button
						className={error ? 'button-not-active' : ''}
						disabled={error}
						onClick={handleEdit}
						type="button"
					>
						<svg
							width="18"
							height="19"
							viewBox="0 0 18 19"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
								stroke="#00DA83"
								strokeLinecap="square"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};
export default P2PLimitationsTableItem;
