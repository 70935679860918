import { FC, useState, useEffect, ChangeEvent } from 'react';
import Popup from 'reactjs-popup';
import { useSelector, useDispatch } from 'react-redux';
import { getAvailableGramListRequest } from 'redux/reducers/withdrawals/reducer';
import {
	getAvailableGramList,
	getAcceptRedemptionLoader,
} from 'redux/reducers/withdrawals/selectors';
import SelectGoldAmount from './SelectGoldAmount';
import { IAcceptWithdrawalsModalProps } from './types';

// ================================================:
export const AcceptWithdrawalsModal: FC<IAcceptWithdrawalsModalProps> = ({
	openModal,
	closeModal,
	handleAcceptWithdrawal,
	id,
}) => {
	const dispatch = useDispatch();
	const availableGramList = useSelector(getAvailableGramList);
	const redemptionLoading = useSelector(getAcceptRedemptionLoader);
	const [amountGrams, setAmountGrams] = useState<number>();
	const [fee, setFee] = useState<string>();
	const [releaseAmount, setReleaseAmount] = useState<string>();

	useEffect(() => {
		dispatch(getAvailableGramListRequest());
	}, [dispatch]);

	const handleFeeChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setFee(value);
	};

	const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setReleaseAmount(value);
	};

	const isDisabled =
		redemptionLoading || releaseAmount == null || fee == null || amountGrams == null;

	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window admin-managment-popup">
				<div className="popup-window__inside">
					<div className="popup">
						<button onClick={closeModal} type="button" className="popup__close">
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1.80005 2.30005L16.2 16.7M1.80005 16.7L16.2 2.30005" stroke="#011E26" />
							</svg>
						</button>
						<div className="popup-header">
							<h3 className="popup-header__title">Accept withdrawal</h3>
						</div>
						<div className="popup-body">
							<div className="popup-text popup-text--center">
								<p>Are you sure you want to confirm the redemption?</p>
							</div>

							<div className="input">
								<p className="input__name">Released amount</p>
								<SelectGoldAmount
									options={availableGramList || []}
									value={amountGrams}
									onChange={setAmountGrams}
								/>
							</div>

							<div className="input">
								<p className="input__name">Fee</p>
								<div className="input-wrapper">
									<input
										className="input-item input-item--right-icon"
										type="number"
										placeholder="Enter fee"
										value={fee}
										onChange={handleFeeChange}
									/>
									<div className="input-info">
										<span className="input-info__currency input-info__currency--type2">GOLDB</span>
									</div>
								</div>
							</div>

							<div className="input">
								<p className="input__name">Amount in the grams</p>
								<div className="input-wrapper">
									<input
										className="input-item"
										type="number"
										placeholder="Enter amount in the grams"
										value={releaseAmount}
										onChange={handleAmountChange}
									/>
								</div>
							</div>

							<div className="popup-submit popup-submit--sb ">
								<button
									onClick={closeModal}
									type="button"
									className="button button--cancel   button--full-width"
								>
									Cancel
								</button>
								<button
									className="button button--check button--full-width "
									type="button"
									disabled={isDisabled}
									onClick={() => {
										if (isDisabled) return;
										closeModal();
										handleAcceptWithdrawal({
											id,
											released_amount: Number(releaseAmount),
											fee: Number(fee),
											released_gold_grams: Number(amountGrams),
										});
									}}
								>
									Accept
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
