import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { getEmail2FARequest, getSms2FARequest } from 'redux/reducers/userManagement/reducer';
import { getSms2FA, getSms2FALoader } from 'redux/reducers/userManagement/selectors';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import { Filter } from './Filter';

const Sms2FAHistory = () => {
	const dispatch = useDispatch();
	const sms2FA = useSelector(getSms2FA);
	const loading = useSelector(getSms2FALoader);
	const { search } = useLocation();
	const location = useLocation();
	const history = useHistory();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	useEffect(() => {
		dispatch(getSms2FARequest({}));
	}, [dispatch]);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			per_page: 15,
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getSms2FARequest(params));
	};

	return (
		<>
			<div className="title-block">
				<p className="title">SMS</p>
			</div>
			<div className="count-label count-label--filter-between">
				<Filter />
			</div>
			{loading ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<div className="content-block">
					<div className="content-block__inside content-block__inside--type2">
						<div className="table-block table-block--mt-0">
							<div className="table-wrapper">
								<div className="table table--tfa-requests-sms">
									<TableHeader />
									<div className="table-body">
										{sms2FA?.data?.map((item) => (
											<TableRow key={item.id} item={item} />
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{(sms2FA?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={sms2FA?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default Sms2FAHistory;
