import { FC } from 'react';
import { IDeletePopup } from './types';

const DeletePopup: FC<IDeletePopup> = ({ onAccept, onCancel }) => {
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup ">
					<button type="button" className="popup__close" onClick={onCancel}>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15 3L3 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3 3L15 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">Delete range</p>
					</div>
					<div className="popup-body popup-body--type2">
						<div className="popup-text popup-text--center">
							<p>Are you sure you want to delete the range?</p>
						</div>
					</div>
					<div className="popup-submit popup-submit--type2">
						<button
							type="button"
							className="button button--cancel-type2  button--full-width "
							onClick={onCancel}
						>
							Cancel
						</button>
						<button
							type="button"
							className="button button--check  button--full-width "
							onClick={onAccept}
						>
							Accept
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DeletePopup;
