/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React, { FC, useState, MouseEvent, useEffect } from 'react';
import { numberFormat } from 'utils/numberFormat';
import Chart from '../Chart';
import { EFeeProfit, ITotalFeeCollectedProps } from './types';

// ==========================================:
const TotalFeeCollected: FC<ITotalFeeCollectedProps> = ({ data }) => {
	const [balanceTab, setBalanceTab] = useState<string>(data?.[0]?.asset_code);
	const [type, setType] = useState();

	const [totalBalance, setTotalBalance] = useState(0);

	const handleBalanceTab = (e: MouseEvent<HTMLButtonElement>): void => {
		setBalanceTab(e.currentTarget.name);
	};

	const handleTypeClick = (e: any) => {
		setType(e.target.value);
	};

	const charactersAfterZero = process.env.REACT_APP_NAME_CHARACTERS_AFTER_ZERO;

	useEffect(() => {
		if (!type) {
			setTotalBalance(
				data.reduce((acc, item) => {
					return (acc += item.balances[0].balance_usdt);
				}, 0),
			);
		} else {
			setTotalBalance(
				data.reduce((acc, item) => {
					return (acc += item.profits_by_type_usdt[type]);
				}, 0),
			);
		}
	}, [type, data]);

	return (
		<div className="content-block">
			<div className="content-block__inside">
				<div className="stats">
					<div className="stats__info">
						<div className="stats__header">
							<span className="stats__title">Total Fee Collected</span>
							<span className="stats__desc">
								Total balance: $ {numberFormat(totalBalance, 'en-EN')}
							</span>
						</div>
						<div className="buttons-box" onClick={handleTypeClick}>
							<button
								className={`buttons-group--button ${type === EFeeProfit.CONVERT ? 'active' : ''}`}
								value={EFeeProfit.CONVERT}
								type="button"
							>
								Convert
							</button>
							<button
								className={`buttons-group--button ${type === EFeeProfit.DEPOSIT ? 'active' : ''}`}
								value={EFeeProfit.DEPOSIT}
								type="button"
							>
								Deposit
							</button>
							<button
								className={`buttons-group--button ${type === EFeeProfit.SPOT ? 'active' : ''}`}
								type="button"
								value={EFeeProfit.SPOT}
							>
								Spot
							</button>
							<button
								className={`buttons-group--button ${type === EFeeProfit.MARGIN ? 'active' : ''}`}
								type="button"
								value={EFeeProfit.MARGIN}
							>
								Margin
							</button>
							<button
								className={`buttons-group--button ${type === EFeeProfit.WITHDRAW ? 'active' : ''}`}
								type="button"
								value={EFeeProfit.WITHDRAW}
							>
								Withdraw
							</button>
							<button
								className={`buttons-group--button ${type === EFeeProfit.P2P ? 'active' : ''}`}
								type="button"
								value={EFeeProfit.P2P}
							>
								P2P
							</button>
							<button
								className={`buttons-group--button ${type === '' || !type ? 'active' : ''}`}
								value=""
								type="button"
							>
								All
							</button>
						</div>
						<div className="stats__list">
							{data?.length
								? data.map((el) => {
										let balance;
										let balanceUsdt;
										if (type) {
											balance = el.profits_by_type[type];
											balanceUsdt = el.profits_by_type_usdt[type];
										} else {
											balance = el.balances[0].balance;
											balanceUsdt = el.balances[0].balance_usdt;
										}
										return (
											<button
												key={el.asset_code}
												name={el.asset_code}
												type="button"
												className={`stats-item ${
													balanceTab === el.asset_code ? 'stats-item--active' : ''
												}`}
												onClick={handleBalanceTab}
											>
												<div className="stats-item__name">
													<span className="stats-item__img">
														<img src={el.asset_logo_url} alt="bitcoin token" />
													</span>
													<span className="stats-item__title">{el.asset_code.toUpperCase()}</span>
												</div>
												<div className="stats-item__desc">
													<span className="stats-item__elem">
														{`${numberFormat(balance, 'en-EN')} / $
													${numberFormat(balanceUsdt, 'en-EN')}`}
													</span>
												</div>
											</button>
										);
								  })
								: null}
						</div>
					</div>
					<Chart type={type} data={data} currentBalanceItemName={balanceTab} />
				</div>
			</div>
		</div>
	);
};

export default TotalFeeCollected;
