import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'ui/Loader';
import { getLimitationsP2PRequest } from 'redux/reducers/limitations/reducer';

import {
	getLimitationsP2PIsLoad,
	getLimitationsP2PList,
} from 'redux/reducers/limitations/selectors';
import { P2PLimitationsTableHeader } from './P2PLimitationsTableHeader';

import P2PLimitationsTableItem from './P2PLimitationsTableItem';

export const P2PLimitations: FC = () => {
	const dispatch = useDispatch();

	const limitationsP2PList = useSelector(getLimitationsP2PList);
	const limitationsP2PLoad = useSelector(getLimitationsP2PIsLoad);

	const [openWhitelisting, setOpenWhitelisting] = useState(false);

	useEffect(() => {
		dispatch(getLimitationsP2PRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{limitationsP2PLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table-limitation--p2p">
							<P2PLimitationsTableHeader />
							<div className="table-body">
								{limitationsP2PList?.map((item) => (
									<P2PLimitationsTableItem {...item} key={item.id} />
								))}
								{limitationsP2PList?.length === 0 ? <p style={{ padding: '20px' }}>Empty</p> : null}
							</div>
						</div>
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
