export interface IFeesListRequestPayload {
	current_page?: number | string | string[] | null;
	search_query?: string;
}

export interface IChangeTradeFeesAndLimitsPayload {
	asset_pair_id: number;
	maker_fee: number;
	taker_fee: number;
	amount_min: number;
	amount_max: number;
	vip_amount_max: number;
	vip_amount_min: number;
	vip_maker_fee: number;
	vip_taker_fee: number;
}

export interface IFeesStore {
	fees: IFeesData;
	platformFees: IPlatformFeesData;
	cryptoFees: ICryptoFeesData;
	fiatFees: IFiatFeesData;
	volumeDependsFeeSettings: IVolumeDependsFeeSettingsData;
	changeVolumeDependsFeeSettingsLoader: boolean;
	feesLoader: boolean;
	changeExchangeFeeLoader: boolean;
	changeFeesLoader: boolean;
	changeCryptoFeesLoader: boolean;
	changeFiatFeesLoader: boolean;
	p2p: any;
	P2PLoading: boolean;
	marginBorrow: any;
	marginBorrowLoading: boolean;
}

export interface IVolumeDependsFeeSettingsItem {
	id: number;
	level: number;
	min_amount: number;
	max_amount: number;
	maker_fee: number;
	taker_fee: number;
	total_maker_fee: number;
	total_taker_fee: number;
	vip_maker_fee: number;
	vip_max_amount: number;
	vip_min_amount: number;
	vip_taker_fee: number;
	vip_total_maker_fee: number;
	vip_total_taker_fee: number;
}

export type IVolumeDependsFeeSettings = IVolumeDependsFeeSettingsItem[];

export type IVolumeDependsFeeSettingsData = IVolumeDependsFeeSettings | null;

export interface IFeeItem {
	id: number;
	code: string;
	maker_fee: number;
	taker_fee: number;
	amount_min: number;
	amount_max: number;
	vip_amount_max: number;
	vip_amount_min: number;
	vip_maker_fee: number;
	vip_taker_fee: number;
}

export type IFeesItem = IFeeItem[];

export type IFeesData = IFeesItem | null;

export type IFeesResponsePayload = IFeesData;

// =========================================

export interface IChangeExchangeFeePayload {
	asset_id: number;
	exchange_fee: number;
}

export interface IPlatformFeeItem {
	id: number;
	asset_id: number;
	code: string;
	name: string;
	exchange_fee: number;
	vip_exchange_fee: number;
	img_path: string;
}

export type IPlatformFeesItem = IPlatformFeeItem[];

export type IPlatformFeesData = IPlatformFeesItem | null;

export type IPlatformFeesResponsePayload = IPlatformFeesData;

// =========================================

export interface IChangeVolumeDependsFeeSettingsPayload {
	id: number;
	min_amount: number;
	max_amount: number;
	taker_fee: number;
	maker_fee: number;
}
export interface IChangeCryptoFeesPayload {
	id: number;
	deposit_fee?: number;
	withdraw_fee?: number;
	withdraw_min?: number;
	withdraw_max?: number;
}

export interface ICryptoFeeItem {
	id: number;
	asset_id: number;
	asset_code: string;
	network: string;
	network_name: string;
	deposit_fee?: number | null;
	withdraw_fee?: number | null;
	withdraw_min?: number | null;
	withdraw_max?: number | null;
	vip_deposit_fee?: number | null;
	vip_withdraw_fee?: number | null;
	vip_withdraw_max?: number | null;
	vip_withdraw_min?: number | null;
	fixed_deposit_fee?: number | null;
	fixed_withdraw_fee?: number | null;
	vip_fixed_deposit_fee?: number | null;
	vip_fixed_withdraw_fee?: number | null;
}

export type ICryptoFeesItem = ICryptoFeeItem[];

export type ICryptoFeesData = ICryptoFeesItem | null;

export type ICryptoFeesResponsePayload = ICryptoFeesData;

// =========================================

export interface IChangeFiatFeesPayload {
	asset_id: number;
	deposit_fee: number;
	vip_deposit_fee: number;
	vip_withdraw_fee: number;
	withdraw_fee: number;
}

export interface IFiatFeeItem {
	id: number;
	code: string;
	asset_id: number;
	deposit_fee: number;
	exchange_fee: number;
	withdraw_fee: number;
	vip_deposit_fee: number;
	vip_exchange_fee: number;
	vip_withdraw_fee: number;
	referral_bonus: number;
}

export type IFiatFees = IFiatFeeItem[];

export type IFiatFeesData = IFiatFees | null;

export type IFiatFeesResponsePayload = IFiatFeesData;

export enum EAccountLevel {
	VIP = 'vip',
	REGULAR = 'regular',
}
