export const USER_STATUS_APPROVED = 'success';
export const USER_STATUS_PENDING = 'pending';
export const USER_STATUS_REJECTED = 'fail';

export const USER_STATUS_NUM_APPROVED = 'success';
export const USER_STATUS_NUM_PENDING = 'pending';
export const USER_STATUS_NUM_REJECTED = 'fail';

export const USER_LEVEL_VIP = 'vip';
export const USER_LEVEL_REGULAR = 'regular';

export const USER_TYPE_COLD = 'to_cold';
export const USER_TYPE_HOT = 'to_hot';
export const USER_TYPE_USER_HOT = 'user_to_hot';
export const USER_TYPE_FEE_USER = 'fee_to_user';
export const USER_TYPE_EXTERNAL = 'to_external';

export const USER_TYPE_COLD_TEXT = 'To Cold';
export const USER_TYPE_HOT_TEXT = 'To Hot';
export const USER_TYPE_USER_HOT_TEXT = 'User to Hot';
export const USER_TYPE_FEE_USER_TEXT = 'Fee to User';
export const USER_TYPE_EXTERNAL_TEXT = 'To External';

export const USER_STATUS_ARRAY = [
	'all',
	USER_STATUS_NUM_APPROVED,
	USER_STATUS_NUM_PENDING,
	USER_STATUS_NUM_REJECTED,
];
export const USER_STATUS_TEXT_ARRAY = [
	'All',
	USER_STATUS_APPROVED,
	USER_STATUS_PENDING,
	USER_STATUS_REJECTED,
];

export const USER_TYPE_ARRAY = [
	'all',
	USER_TYPE_COLD,
	USER_TYPE_HOT,
	USER_TYPE_USER_HOT,
	USER_TYPE_FEE_USER,
	USER_TYPE_EXTERNAL,
];
export const USER_TYPE_TEXT_ARRAY = [
	'All',
	USER_TYPE_COLD_TEXT,
	USER_TYPE_HOT_TEXT,
	USER_TYPE_USER_HOT,
	USER_TYPE_FEE_USER_TEXT,
	USER_TYPE_EXTERNAL_TEXT,
];

export const USER_LEVEL_ARRAY = ['all', USER_LEVEL_REGULAR, USER_LEVEL_VIP];

export const USER_SEARCH_ARRAY = ['id', 'coin', 'type', 'status'];
export const USER_SEARCH_TEXT_ARRAY = ['ID', 'Coin', 'Type', 'Status'];
