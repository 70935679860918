import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import MarketMakerRobot from 'components/MarketMakerRobot';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const MarketMakerRobotPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.MANAGE_MARKET_MAKER}>
			<Dashboard title="Internal">
				<MarketMakerRobot />
			</Dashboard>
		</PermissionGate>
	);
};

export default MarketMakerRobotPage;
