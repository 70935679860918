import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IOrders } from './types';

// ==========================================:
export const orders: IOrders = {
	getOrdersSpot: (params) =>
		http.get<any>(endpoint.orders.ordersSpot, { params }).then((response) => response.data),
	getOrdersMargin: (params) =>
		http.get<any>(endpoint.orders.ordersSpot, { params }).then((response) => response.data),
	getOrdersP2P: (params) =>
		http.get<any>(endpoint.orders.ordersP2P, { params }).then((response) => response.data),
};
