/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import logo from 'assets/img/logo1.png';
import { IPDFFileDownload } from './types';

const styles = {
	body: {
		padding: 50,
		paddingBottom: 50,
		paddingTop: 50,
		// width: '500px',
		height: '785px',
	},
	logo: {
		// alignItems: 'center',
		fontSize: 24,
		width: 40,
		height: 40,
	},
	logoText: {
		margin: 12,
		fontSize: 37,
	},
	title: {
		fontSize: 24,
		marginTop: 60,
	},
	img: {
		width: 66,
		fontSize: 24,
		marginRight: 25,
		marginTop: 24,
		height: 34,
	},
	itemBlock: {
		fontFamily: 'Inter',
		display: 'flex',
		justifyContent: 'space-between',
		fontSize: 16,
		gap: 16,
	},
	logoBlock: {
		display: 'flex',
		alignItems: 'center',
	},
	container: {},
	itemTextLeft: {
		color: '#777E90',
	},
	text: {
		fontSize: 16,
	},
	block: {
		marginTop: 50,
		marginBottom: 50,
	},
};

const PDFFileDownload: FC<IPDFFileDownload> = ({ data }) => {
	const { total_amount, asset, bank_account } = data;
	const { beneficiary_name, bank_name, swift, iban, reference, address } = bank_account || {};
	return (
		<div style={styles.body} className="pdf-container">
			<div>
				<div style={styles.container}>
					<div style={styles.logoBlock} className="pdf-container--logo-block">
						<img src={logo} alt="logo" style={styles.logo} />
						<span style={styles.logoText}>Bitanica</span>
					</div>
					<p className="invoice_title">Invoice</p>
					<div style={styles.block}>
						<div style={styles.itemBlock} className="invoice_item-block">
							<div style={styles.itemTextLeft} className="invoice_item-text--left">
								Amount
							</div>
							<div className="pdf-container--invoice_text">
								{total_amount} {asset?.code?.toUpperCase()}
							</div>
						</div>
						<div style={styles.itemBlock} className="invoice_item-block">
							<div style={styles.itemTextLeft} className="invoice_item-text--left">
								Beneficiary name
							</div>
							<div className="pdf-container--invoice_text">{beneficiary_name}</div>
						</div>
						<div style={styles.itemBlock} className="invoice_item-block">
							<div style={styles.itemTextLeft} className="invoice_item-text--left">
								Bank Name
							</div>
							<div className="ipdf-container--invoice_text">{bank_name}</div>
						</div>
						<div style={styles.itemBlock} className="invoice_item-block">
							<div style={styles.itemTextLeft} className="invoice_item-text--left">
								Swift
							</div>
							<div className="pdf-container--invoice_text">{swift}</div>
						</div>
						<div style={styles.itemBlock} className="invoice_item-block">
							<div style={styles.itemTextLeft} className="invoice_item-text--left">
								IBAN
							</div>
							<div className="pdf-container--invoice_text">{iban}</div>
						</div>
						<div style={styles.itemBlock} className="invoice_item-block">
							<div style={styles.itemTextLeft} className="invoice_item-text--left">
								Reference
							</div>
							<div className="pdf-container--invoice_text">{reference}</div>
						</div>
						<div style={styles.itemBlock} className="invoice_item-block">
							<div style={styles.itemTextLeft} className="invoice_item-text--left">
								Address
							</div>
							<div className="invoice_text">{address}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PDFFileDownload;
