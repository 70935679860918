/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteAdminRequest } from 'redux/reducers/adminManagement/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions as PERMISSIONS } from 'services/constants/permissions';
// import DeleteAdminModal from '../DeleteAdminModal';
import { IReferralListItemProps } from './types';

// ==========================================:
const ReferralUserItem: FC<IReferralListItemProps> = ({ data }) => {
	const {
		user_id,
		email,
		full_name,
		total_traded_usd,
		status,
		referral_fee_usd,
		registration_date,
		total_fee_usd,
		level,
		country,
	} = data;
	const date = new Date(Number(registration_date) * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+registration_date + offset) * 1000);
	// const [openModal, setOpenModal] = useState(false);
	// const closeModal = () => setOpenModal(false);

	// const handleDeleteAdmin = (): void => {
	// 	const userId: number = id;
	// 	dispatch(
	// 		deleteAdminRequest({
	// 			userId,
	// 		}),
	// 	);
	// 	history.push({
	// 		pathname: location.pathname,
	// 		search: `?page=1`,
	// 	});
	// };

	// const handleEditAdmin = (): void => {
	// 	const userId: number = id;

	// 	history.push({
	// 		pathname: `/admin-management/edit/${userId}`,
	// 		state: {
	// 			data,
	// 		},
	// 	});
	// };

	// const sortedPermissionsByName = useMemo(() => {
	// 	return (
	// 		permissions.length &&
	// 		[...permissions].sort((a, b) => {
	// 			if (a.name < b.name) {
	// 				return -1;
	// 			}
	// 			if (a.name > b.name) {
	// 				return 1;
	// 			}
	// 			return 0;
	// 		})
	// 	);
	// }, [permissions]);

	return (
		<div className="tr">
			<Link className="td" to={`/user-management/${String(user_id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{user_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Full name</p>
				<p>{full_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Level</p>
				<p>{level}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<p>{email}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Country</p>
				<p>{country}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<div>
					<span
						className={`status ${status === 'unverified' ? 'status--failed' : 'status--success'}`}
					>
						{status}
					</span>
				</div>
				{/* <div className="permissions admin-info-dropdown">
					<p>Permissions</p>
					<span className="permissions-icon permissions-icon--action icon-info-icon" />
					<div className="admin-info-dropdown__menu">
						<div className="admin-info-dropdown__list">
							{sortedPermissionsByName && sortedPermissionsByName?.length
								? sortedPermissionsByName.map((item) => (
										<span key={item.id} className="admin-info-dropdown__item">
											{item?.name}
										</span>
								  ))
								: null}

							{sortedPermissionsByName && sortedPermissionsByName?.length === 0 && (
								<span className="admin-info-dropdown__item">
									There are no permissions for this user
								</span>
							)}
						</div>
					</div>
				</div> */}
			</div>
			<div className="td">
				<p className="td-hidden-name">Registration date</p>
				{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
				{dateOffset.toLocaleTimeString()}
			</div>
			<div className="td">
				<p className="td-hidden-name">Sum orders (USDb)</p>
				<p>{total_traded_usd}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Invited friends fee (USDb)</p>
				<p>{total_fee_usd}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Sum fees (USDb)</p>
				<p>{referral_fee_usd}</p>
			</div>
		</div>
	);
};

export default ReferralUserItem;
