import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IFiatFeesStore, TRangePercent } from './types';

const getFeesState = (state: IStoreState): IFiatFeesStore => state.fiatFees;
export const getFiatFees = createSelector([getFeesState], (fees: IFiatFeesStore) => fees);

export const getRangePercentLoading = createSelector(
	[getFiatFees],
	(fiatFees: IFiatFeesStore): boolean => fiatFees.rangePercentLoading,
);

export const getRangePercent = createSelector(
	[getFiatFees],
	(fiatFees: IFiatFeesStore): TRangePercent | null => fiatFees.rangePercent,
);

export const getFiatList = createSelector(
	[getFiatFees],
	(fiatFees: IFiatFeesStore): string[] | null => fiatFees.fiatList,
);

export const getChannelList = createSelector(
	[getFiatFees],
	(fiatFees: IFiatFeesStore): string[] | null => fiatFees.channelList,
);
