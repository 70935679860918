import { FC, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { withdrawToColdWalletRequest } from 'redux/reducers/walletManagement/reducer';
import { IWithdrawalModalProps } from './types';

// ================================================:
export const WithdrawalModal: FC<IWithdrawalModalProps> = ({
	openModal,
	closeModal,
	addressCold,
	asset_id,
	network,
}) => {
	const dispatch = useDispatch();
	const [amount, setAmount] = useState('');
	const [toCold, setToCold] = useState(false);
	const [refund, setRefund] = useState(false);
	const [showComment, setShowComment] = useState(false);
	const [address, setAddress] = useState<string>(addressCold || '');
	const [comment, setComment] = useState('');

	const cancel = () => {
		setAmount('');
		setToCold(false);
		setRefund(false);
		setAddress(addressCold || '');
		closeModal();
	};

	const withdrawToColdWalletHandler = () => {
		dispatch(
			withdrawToColdWalletRequest({
				asset_id,
				amount: Number(amount),
				to_cold: toCold ? 1 : 0,
				address,
				network,
				is_refund: refund ? 1 : 0,
				comment: comment || undefined,
			}),
		);
		cancel();
	};

	const toggleToCold = () => {
		setToCold((prev) => !prev);
	};

	const toggleRefund = () => {
		setRefund((prev) => !prev);
	};

	const toggleShowComment = () => {
		setShowComment((prev) => !prev);
	};

	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup">
						<button type="button" className="popup__close" onClick={cancel}>
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15 3L3 15"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3 3L15 15"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
						<div className="popup-header">
							<p className="popup-header__title">Withdrawal</p>
						</div>
						<div className="popup-body popup-body--type2">
							<div className="input">
								<div className="input-wrapper">
									<input
										className="input-item"
										type="text"
										placeholder="Enter amount"
										value={amount}
										onChange={(e) => setAmount(e.target.value.replace(/[^\d\\.]/g, ''))}
									/>
								</div>
							</div>
							<div className="input">
								<div className="input-wrapper">
									<input
										className="input-item"
										type="text"
										placeholder="Enter address"
										value={address}
										onChange={(e) => setAddress(e.target.value)}
									/>
								</div>
							</div>
							<div className="form-group">
								<div className="input-switch">
									<div className="switch switch--type2">
										<label className="switch__label">
											<input
												type="checkbox"
												className="hidden"
												checked={toCold}
												onChange={toggleToCold}
											/>
											<div className="switch__toggler" />
										</label>
									</div>
									<p className="input-switch__text input-switch__text--grey input-switch__text--fw-400">
										Withdrawal to cold wallet
									</p>
								</div>
								<div className="input-switch">
									<div className="switch switch--type2">
										<label className="switch__label">
											<input
												type="checkbox"
												className="hidden"
												checked={refund}
												onChange={toggleRefund}
											/>
											<div className="switch__toggler" />
										</label>
									</div>
									<p className="input-switch__text input-switch__text--grey input-switch__text--fw-400">
										Refund
									</p>
								</div>
							</div>
							<div className={`input ${showComment ? 'active' : ''}`}>
								<button
									type="button"
									className="input__header"
									style={{ width: '100%' }}
									onClick={toggleShowComment}
								>
									<p className="input__name input__name--fw-500">Comment</p>
									<div className="input-drop-btn">
										<svg
											width="12"
											height="7"
											viewBox="0 0 12 7"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											style={{ transform: `rotate(${showComment ? 180 : 0}deg)` }}
										>
											<path
												d="M1.5 1.25L6 5.75L10.5 1.25"
												stroke="#022A36"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</button>
								<div className="input__drop">
									<div className="textarea textarea--mt-16">
										<textarea
											className="textarea__item textarea__item--size2"
											placeholder="Comment"
											value={comment}
											onChange={(e) => setComment(e.target.value)}
										/>
									</div>
								</div>
							</div>
							<div className="popup-submit">
								<button
									type="button"
									className="button button--cancel button--medium-height"
									onClick={cancel}
								>
									Cancel
								</button>
								<button
									type="button"
									className={`button button--medium-height  ${
										!amount || Number(amount) === 0 || address?.length < 8
											? 'button-block'
											: 'button--check'
									}`}
									onClick={() => {
										if (!amount || Number(amount) === 0 || address?.length < 8) return;
										withdrawToColdWalletHandler();
									}}
								>
									Send
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
