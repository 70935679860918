import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IReferralStore, IReferralFee, IReferralResponse } from './types';

const getReferralState = (state: IStoreState): IReferralStore => state.referral;
export const getReferral = createSelector(
	[getReferralState],
	(referral: IReferralStore) => referral,
);

export const getReferralLoading = createSelector(
	[getReferral],
	(referral: IReferralStore): boolean => referral.referralLoading,
);

export const getReferralList = createSelector(
	[getReferral],
	(referral: IReferralStore): IReferralResponse | null => referral.referral,
);

export const getReferralFee = createSelector(
	[getReferral],
	(referral: IReferralStore): IReferralFee | null => referral.referralFee,
);

export const getUserReferralLoading = createSelector(
	[getReferral],
	(referral: IReferralStore): boolean => referral.userReferralLoading,
);

export const getUserReferralList = createSelector(
	[getReferral],
	(referral: IReferralStore): IReferralResponse | null => referral.userReferral,
);
