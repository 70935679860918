import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
	getBalanceIsLoad,
	getBalanceList,
	getHotBalanceIsLoad,
	getHotBalanceList,
	getTotalFeeCollectedIsLoad,
	getTotalFeeCollectedList,
} from 'redux/reducers/balance/selectors';
import Loader from 'ui/Loader';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import Balance from './Balances';
import HotBalance from './BalanceHotWallet';
import TotalFeeCollected from './TotalFeeCollected';

// ================================================:
const AdminPanel: FC = () => {
	const balanceList = useSelector(getBalanceList);
	const balanceListIsLoad = useSelector(getBalanceIsLoad);
	const hotBalanceList = useSelector(getHotBalanceList);
	const hotBalanceListIsLoad = useSelector(getHotBalanceIsLoad);
	const totalFeeCollectedList = useSelector(getTotalFeeCollectedList);
	const totalFeeCollectedListIsLoad = useSelector(getTotalFeeCollectedIsLoad);

	const { checkReadable } = usePermission();

	return (
		<>
			<div className="title-block">
				<p className="title">Admin system</p>
			</div>
			{balanceListIsLoad || !balanceList?.length ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<Balance data={balanceList} />
			)}
			{checkReadable(permissions.MANAGE_WALLETS) &&
				(hotBalanceListIsLoad || !hotBalanceList?.length ? (
					<div className="list-loader">
						<Loader />
					</div>
				) : (
					<HotBalance data={hotBalanceList} />
				))}
			{checkReadable(permissions.VIEW_PROFITS) &&
				(totalFeeCollectedListIsLoad || !totalFeeCollectedList?.length ? (
					<div className="list-loader">
						<Loader />
					</div>
				) : (
					<TotalFeeCollected data={totalFeeCollectedList} />
				))}
		</>
	);
};

export default AdminPanel;
