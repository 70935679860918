const TableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>User ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Email</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Request Creation Time</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Confirmation Time</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Code</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Event type</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Status</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
