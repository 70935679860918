/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import {
	getLoadingTradesMargin,
	getTradesMarginList,
	getFilterParams,
} from 'redux/reducers/trade/selectors';
import { getTradesMarginRequest, setFilterParams } from 'redux/reducers/trade/reducer';
import { IMarketMakerPageRequestPayload } from 'redux/reducers/trade/types';
import { MarginTradesTableHeader } from './MarginTradesTableHeader';
import { IMarginProps, IMarginTradeItem } from './types';
import MarginTradesTableItem from './MarginTradesTableItem';

export const MarginTrades: FC<IMarginProps> = ({ radioCheck, setRadioCheck }) => {
	const tradesMarginList = useSelector(getTradesMarginList);
	const tradesMarginListLoader = useSelector(getLoadingTradesMargin);
	const filterParams = useSelector(getFilterParams);
	const { start_date: startDate, end_date: endDate } = filterParams || {};
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params: IMarketMakerPageRequestPayload = {
			// id,
			margin: 'margin',
			current_page: pageNumber || 1,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getTradesMarginRequest(params));
	};
	useEffect(() => {
		const params: IMarketMakerPageRequestPayload = {
			margin: 'margin',
			current_page: 1,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getTradesMarginRequest(params));
	}, [radioCheck, startDate, endDate]);

	useEffect(() => {
		return () => {
			dispatch(setFilterParams({ start_date: null, end_date: null }));
		};
	}, [dispatch]);

	return (
		<div className="trades-marginTrades">
			<div className="table-panel__radio-group">
				<div className="table-panel__radio-group">
					<div className="radio">
						<label className="radio__label">
							<input
								onChange={() => {
									setRadioCheck('cross');
								}}
								checked={radioCheck === 'cross'}
								type="radio"
								className="hidden"
								name="margin-type"
							/>
							<span className="radio__item" />
							<span className="radio__text">Cross </span>
						</label>
					</div>
					<div className="radio">
						<label className="radio__label">
							<input
								onChange={() => {
									setRadioCheck('isolated');
								}}
								checked={radioCheck === 'isolated'}
								type="radio"
								className="hidden"
								name="margin-type"
							/>
							<span className="radio__item" />
							<span className="radio__text">Isolated</span>
						</label>
					</div>
				</div>
			</div>
			{tradesMarginListLoader ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--margin-trades">
							<MarginTradesTableHeader />
							<div className="table-body">
								{tradesMarginList?.data.map((item: IMarginTradeItem) => (
									<MarginTradesTableItem item={item} key={item.id} />
								))}
								{tradesMarginList?.data.length === 0 ? (
									<p style={{ padding: '20px' }}>Orders history is empty</p>
								) : null}
							</div>
						</div>
					</div>
					<div style={{ padding: '14px' }}>
						{(tradesMarginList?.last_page ?? 0) > 1 && (
							<Pagination
								pageCount={tradesMarginList?.last_page ?? 0}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</div>
	);
};
