import { FC } from 'react';
import { capitalizeFirstLetter } from 'utils/numberFormat';

import { ITableRow } from './types';
import { getStatusInfo } from './constants';

const TableRow: FC<ITableRow> = ({ item }) => {
	const {
		id,
		admin_id: adminId,
		type,
		resource_type: resourceType,
		amount,
		updated_at: updatedAt,
	} = item;

	const date = new Date(updatedAt * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+updatedAt + offset) * 1000);
	return (
		<div className="tr">
			<div className="td ">
				<p className="td-hidden-name">Include ID</p>
				<p>{id}</p>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Admin ID</p>
				<p>{adminId}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Sum in Tron</p>
				<p>{amount}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{resourceType && capitalizeFirstLetter(resourceType)?.replaceAll('_', ' ')}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={`status status--${type ? getStatusInfo(type)?.className : ''} `}>
						{type && getStatusInfo(type)?.name}
					</span>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Date</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} {dateOffset.toLocaleTimeString()}
				</p>
			</div>
		</div>
	);
};

export default TableRow;
