import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getTranslates } from 'redux/reducers/translates/selectors';
import LanguageSelect from './LanguageSelect';
import TopLevelSection from './TopLevelSection';
import { ITranslationsPage } from './types';

const TranslationSidebar: FC<ITranslationsPage> = ({ path, setPath }) => {
	const translates = useSelector(getTranslates);
	const topCategories = translates?.map((item) => Object.keys(item)[0]);

	return (
		<div className="translation-bar">
			<div className="translation-bar-header">
				<p className="input-name">Choose a language</p>
				<LanguageSelect />
			</div>
			<div className="translation-bar-group">
				<p className="translation-bar-group__name">Name of section</p>
				{topCategories?.map((sectionName, key) => (
					<TopLevelSection
						key={sectionName}
						sectionPath={sectionName}
						index={key}
						path={path}
						setPath={setPath}
						level={0}
					/>
				))}
			</div>
		</div>
	);
};

export default TranslationSidebar;
