import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { getBankAccounts } from 'redux/reducers/bankAccount/selectors';
import {
	getBankAccountsRequest,
	deleteBankAccountRequest,
} from 'redux/reducers/bankAccount/reducer';
import { IDeleteBankAccountPayload } from 'redux/reducers/bankAccount/types';
import { notificationContainer } from 'utils/notificationContainer';
import Pagination from 'ui/Pagination';
import TableHeader from './TableHeader';
import TableItem from './TableItem';
import DeletePopup from './DeletePopup';
import CreatePopup from './CreatePopup';

const BankAccount = () => {
	const dispatch = useDispatch();
	const bankAccounts = useSelector(getBankAccounts);
	const [editId, setEditId] = useState<number | null>(null);
	const [deleteId, setDeleteId] = useState<number | null>(null);
	const [createOpen, setCreateOpen] = useState(false);

	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const { page } = queryString.parse(search);

	const handlePageChange = (pageNumber: number) => {
		history.push({
			pathname: location.pathname,
			search: `?page=${String(pageNumber)}`,
		});
	};

	const toggleEditId = (id: number) => {
		setEditId((prev) => {
			return prev === id ? null : id;
		});
	};

	const { data = [] } = bankAccounts || {};

	useEffect(() => {
		dispatch(getBankAccountsRequest({ current_page: Number(page) || 1 }));
	}, [dispatch, page]);

	const handleDeleteClick = (id: number) => {
		setDeleteId(id);
	};

	const deleteItem = () => {
		if (deleteId == null) {
			return;
		}
		const payload: IDeleteBankAccountPayload = {
			id: deleteId,
			onSuccess: () => {
				notificationContainer('Bank account successfully deleted', 'success');
				setDeleteId(null);
			},
		};
		dispatch(deleteBankAccountRequest(payload));
	};

	return (
		<>
			<div className="title-block">
				<p className="title">Bank account</p>
			</div>
			<div className="count-label count-label--end">
				<div className="admin-managment-btn">
					<span className="admin-managment-btn__text" onClick={() => setCreateOpen(true)}>
						Add new account
					</span>
					<span className="plus-icon plus-icon--action icon-plus-icon" />
				</div>
			</div>

			<div className="table-block ">
				<div className="table-wrapper">
					<div className="table table--bank-account">
						<TableHeader />
						<div className="table-body">
							{data.map((item) => (
								<TableItem
									key={item.id}
									item={item}
									active={item.id === editId}
									toggleActive={toggleEditId}
									onDelete={handleDeleteClick}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
			{bankAccounts && bankAccounts?.last_page > 1 && (
				<Pagination
					pageCount={bankAccounts.last_page}
					forcePage={(Number(page) || 1) - 1}
					onPageChange={handlePageChange}
				/>
			)}
			{deleteId != null && <DeletePopup onAccept={deleteItem} onCancel={() => setDeleteId(null)} />}
			{createOpen && <CreatePopup onClose={() => setCreateOpen(false)} />}
		</>
	);
};

export default BankAccount;
