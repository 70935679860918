import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IDailyLimitationsData,
	ILimitationsData,
	ILimitationsP2PData,
	ILimitationsStore,
} from './types';

// ==========================================:
const getLimitationsState = (state: IStoreState): ILimitationsStore => state.limitations;
export const getLimitations = createSelector(
	[getLimitationsState],
	(limitations: ILimitationsStore) => limitations,
);
// ====================================================:
export const getLimitationsIsLoad = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore): boolean => limitations.limitationsLoader,
);

export const getLimitationsList = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore): ILimitationsData => limitations.limitations,
);
// fiat
export const getLimitationsFiatIsLoad = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore): boolean => limitations.limitationsFiatLoader,
);

export const getLimitationsListFiat = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore): any => limitations.limitationsFiat,
);
//
// ====================================================:
export const getDailyLimitationsIsLoad = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore): boolean => limitations.dailyLimitationsLoader,
);

export const getDailyLimitationsList = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore): IDailyLimitationsData => limitations.dailyLimitations,
);
// ====================================================:
export const getLimitationsP2PIsLoad = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore): boolean => limitations.limitationsP2PLoader,
);

export const getLimitationsP2PList = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore): ILimitationsP2PData => limitations.limitationsP2P,
);
// ====================================================:
