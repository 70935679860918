import { useSelector, useDispatch } from 'react-redux';
import { getWalletsRequest } from 'redux/reducers/walletManagement/reducer';
import { getWalletsIsExporting } from 'redux/reducers/walletManagement/selectors';
import { IWalletsRequestPayload } from 'redux/reducers/walletManagement/types';
import { ExportIcon } from 'assets/img/icons';

export const WalletsFilter = () => {
	const dispatch = useDispatch();
	const isExporting = useSelector(getWalletsIsExporting);

	const handleExport = () => {
		const params: IWalletsRequestPayload = {
			export: 1,
		};
		dispatch(getWalletsRequest(params));
	};
	return (
		<button
			type="button"
			className="transaction-export-btn"
			onClick={handleExport}
			disabled={isExporting}
		>
			Export wallets
			<span className="transaction-export-btn__icon">
				<ExportIcon />
			</span>
		</button>
	);
};
