import { ITotalFeeCollectedList } from 'redux/reducers/balance/types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ITotalFeeCollectedProps {
	data: ITotalFeeCollectedList;
}

export enum EFeeProfit {
	'CONVERT' = 'convert',
	'DEPOSIT' = 'deposit',
	'WITHDRAW' = 'withdraw',
	'SPOT' = 'spot_trading',
	'MARGIN' = 'margin_trading',
	'P2P' = 'p2p_trading',
}
