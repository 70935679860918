/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { getParameterByName } from 'utils/paramNames';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IApproveWithdrawalsRequestPayload,
	IApproveWithdrawalsResponsePayload,
	IRejectWithdrawalsRequestPayload,
	IRejectWithdrawalsResponsePayload,
	IWithdrawalsListRequestPayload,
	IWithdrawalsResponsePayload,
	IGetBankTransferPayload,
	IGetBankTransferResponse,
	IGetPendingRedemptionsPayload,
	IGetPendingRedemptionsResponse,
	TAvailableGramList,
	IApproveRedemptionPayload,
	IRejectRedemptionPayload,
} from './types';
import {
	getWithdrawalsRequest,
	getWithdrawalsSuccess,
	withdrawalsInitState,
	approveWithdrawalsRequest,
	approveWithdrawalsSuccess,
	rejectWithdrawalsRequest,
	rejectWithdrawalsSuccess,
	getFiatWithdrawalsRequest,
	getFiatWithdrawalsSuccess,
	approveFiatWithdrawalsRequest,
	rejectFiatWithdrawalsRequest,
	getBankTransferRequest,
	getBankTransferSuccess,
	getBankTransferError,
	getPendingRedemptionsRequest,
	getPendingRedemptionsSuccess,
	getAvailableGramListRequest,
	getAvailableGramListSuccess,
	getAvailableGramListError,
	getPendingRedemptionsError,
	approveRedemptionRequest,
	approveRedemptionFinally,
	rejectRedemptionRequest,
	rejectRedemptionFinally,
} from './reducer';

// ===================================================:
function* withdrawalsRequestWorker(action: PayloadAction<IWithdrawalsListRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWithdrawalsResponsePayload = yield call(
			api.withdrawals.getWithdrawals,
			payload,
		);
		yield put(getWithdrawalsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawalsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ==================================================:
function* approveWithdrawalsRequestWorker(
	action: PayloadAction<IApproveWithdrawalsRequestPayload>,
) {
	const {
		payload: { apiParams, onSuccess },
	} = action;
	const pageNumber = getParameterByName('page');
	const searchField = getParameterByName('field');
	const searchValue = getParameterByName('value');

	const params =
		searchField && searchValue && pageNumber
			? {
					current_page: pageNumber,
					search_field: searchField,
					search_value: searchValue,
			  }
			: pageNumber
			? { current_page: pageNumber }
			: {};

	try {
		yield put(showLoading());
		const response: IApproveWithdrawalsResponsePayload = yield call(
			api.withdrawals.approveWithdrawals,
			apiParams,
		);
		yield put(approveWithdrawalsSuccess(response));
		onSuccess?.();
		notificationContainer('Withdrawals has been successfully approved!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawalsInitState());
	} finally {
		yield put(hideLoading());
		yield put(getWithdrawalsRequest(params));
	}
}

// ==================================================:
function* rejectWithdrawalsRequestWorker(action: PayloadAction<IRejectWithdrawalsRequestPayload>) {
	const {
		payload: { apiParams, onSuccess },
	} = action;
	const pageNumber = getParameterByName('page');
	const searchField = getParameterByName('field');
	const searchValue = getParameterByName('value');

	const params =
		searchField && searchValue && pageNumber
			? {
					current_page: pageNumber,
					search_field: searchField,
					search_value: searchValue,
			  }
			: pageNumber
			? { current_page: pageNumber }
			: {};

	try {
		yield put(showLoading());
		const response: IRejectWithdrawalsResponsePayload = yield call(
			api.withdrawals.rejectWithdrawals,
			apiParams,
		);
		yield put(rejectWithdrawalsSuccess(response));
		onSuccess?.();
		notificationContainer('Withdrawals has been successfully rejected!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawalsInitState());
	} finally {
		yield put(hideLoading());
		yield put(getWithdrawalsRequest(params));
	}
}

// ===================================================:
function* fiatWithdrawalsRequestWorker(action: PayloadAction<IWithdrawalsListRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWithdrawalsResponsePayload = yield call(
			api.withdrawals.getFiatWithdrawals,
			payload,
		);
		yield put(getFiatWithdrawalsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawalsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* approveFiatWithdrawalsRequestWorker(
	action: PayloadAction<IApproveWithdrawalsRequestPayload>,
) {
	const {
		payload: { apiParams, onSuccess },
	} = action;

	try {
		yield put(showLoading());
		const response: IApproveWithdrawalsResponsePayload = yield call(
			api.withdrawals.approveFiatWithdrawals,
			apiParams,
		);
		yield put(approveWithdrawalsSuccess(response));
		onSuccess?.();
		notificationContainer('Withdrawals has been successfully approved!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawalsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* rejectFiatWithdrawalsRequestWorker(
	action: PayloadAction<IRejectWithdrawalsRequestPayload>,
) {
	const {
		payload: { apiParams, onSuccess },
	} = action;
	const pageNumber = getParameterByName('page');
	const searchField = getParameterByName('field');
	const searchValue = getParameterByName('value');

	const params =
		searchField && searchValue && pageNumber
			? {
					current_page: pageNumber,
					search_field: searchField,
					search_value: searchValue,
			  }
			: pageNumber
			? { current_page: pageNumber }
			: {};

	try {
		yield put(showLoading());
		const response: IRejectWithdrawalsResponsePayload = yield call(
			api.withdrawals.rejectFiatWithdrawals,
			apiParams,
		);
		yield put(rejectWithdrawalsSuccess(response));
		onSuccess?.();
		notificationContainer('Withdrawals has been successfully rejected!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawalsInitState());
	} finally {
		yield put(hideLoading());
		yield put(getFiatWithdrawalsRequest(params));
	}
}

function* getBankTransferWorker({ payload }: PayloadAction<IGetBankTransferPayload>) {
	try {
		const response: IGetBankTransferResponse = yield call(
			api.withdrawals.getBankTransfers,
			payload,
		);
		yield put(getBankTransferSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getBankTransferError());
	}
}

function* getPendingRedemptionsWorker({ payload }: PayloadAction<IGetPendingRedemptionsPayload>) {
	try {
		const response: IGetPendingRedemptionsResponse = yield call(
			api.withdrawals.getPendingRedemptions,
			payload,
		);
		yield put(getPendingRedemptionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getPendingRedemptionsError());
	}
}

function* getAvailableGramListWorker() {
	try {
		const response: TAvailableGramList = yield call(api.withdrawals.getAvailableGramList);
		yield put(getAvailableGramListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getAvailableGramListError());
	}
}

function* approveRedemptiontWorker(action: PayloadAction<IApproveRedemptionPayload>) {
	const {
		payload: { apiParams, onSuccess },
	} = action;

	try {
		yield call(api.withdrawals.approveRedemption, apiParams);
		onSuccess?.();
		notificationContainer('Redemption has been successfully approved!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(approveRedemptionFinally());
	}
}

function* rejectRedemptiontWorker(action: PayloadAction<IRejectRedemptionPayload>) {
	const {
		payload: { apiParams, onSuccess },
	} = action;

	try {
		yield call(api.withdrawals.rejectRedemption, apiParams);
		onSuccess?.();
		notificationContainer('Redemption has been successfully rejectedd!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(rejectRedemptionFinally());
	}
}

export function* withdrawalsSaga() {
	yield takeEvery(getWithdrawalsRequest.type, withdrawalsRequestWorker);
	yield takeEvery(approveWithdrawalsRequest.type, approveWithdrawalsRequestWorker);
	yield takeEvery(rejectWithdrawalsRequest.type, rejectWithdrawalsRequestWorker);
	yield takeEvery(getFiatWithdrawalsRequest.type, fiatWithdrawalsRequestWorker);
	yield takeEvery(approveFiatWithdrawalsRequest.type, approveFiatWithdrawalsRequestWorker);
	yield takeEvery(rejectFiatWithdrawalsRequest.type, rejectFiatWithdrawalsRequestWorker);
	yield takeEvery(getBankTransferRequest.type, getBankTransferWorker);
	yield takeEvery(getPendingRedemptionsRequest.type, getPendingRedemptionsWorker);
	yield takeEvery(getAvailableGramListRequest.type, getAvailableGramListWorker);
	yield takeEvery(approveRedemptionRequest.type, approveRedemptiontWorker);
	yield takeEvery(rejectRedemptionRequest.type, rejectRedemptiontWorker);
}
