export const USER_STATUS_APPROVED = 'confirmed';
export const USER_STATUS_PENDING = 'unverified';
export const USER_STATUS_REJECTED = 'expired';

export const USER_STATUS_NUM_APPROVED = 'confirmed';
export const USER_STATUS_NUM_UNVERIFIED = 'unverified';
export const USER_STATUS_NUM_REJECTED = 'expired';

export const USER_STATUS_ARRAY = [
	'all',
	USER_STATUS_NUM_APPROVED,
	USER_STATUS_NUM_UNVERIFIED,
	USER_STATUS_NUM_REJECTED,
];

export const USER_STATUS_TEXT_ARRAY = [
	'All',
	USER_STATUS_APPROVED,
	USER_STATUS_PENDING,
	USER_STATUS_REJECTED,
];

export const USER_SEARCH_ARRAY = ['user_id', 'phone'];

export const USER_SEARCH_TEXT_ARRAY = ['User ID', 'Phone'];
