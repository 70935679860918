import { createSelector } from '@reduxjs/toolkit';
import { IOrdersStore } from './types';

const getOrdersState = (state: any): IOrdersStore => state.orders;

export const getOrdersSpotList = createSelector(
	[getOrdersState],
	(trades: IOrdersStore) => trades.ordersSpot,
);
export const getLoadingOrdersSpot = createSelector(
	[getOrdersState],
	(trades: IOrdersStore) => trades.ordersSpotLoading,
);
export const getOrdersMarginList = createSelector(
	[getOrdersState],
	(trades: IOrdersStore) => trades.ordersMargin,
);
export const getLoadingOrdersMargin = createSelector(
	[getOrdersState],
	(trades: IOrdersStore) => trades.ordersMarginLoading,
);
export const getOrdersP2P = createSelector(
	[getOrdersState],
	(trades: IOrdersStore) => trades.ordersP2P,
);
export const getLoadingP2POrders = createSelector(
	[getOrdersState],
	(trades: IOrdersStore) => trades.ordersP2PLoading,
);

export const getFilterParams = createSelector(
	[getOrdersState],
	(trades: IOrdersStore) => trades.filterParams,
);
