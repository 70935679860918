import { endpoint } from 'services/endpoint';

import { http } from 'services/http';
import {
	IBankAccountResponse,
	IAddBankAccountRequest,
	TEditBankAccountRequest,
	IBankAccountItem,
} from 'redux/reducers/bankAccount/types';
import { IBankAccountApi } from './types';

// ==========================================:
export const bankAccount: IBankAccountApi = {
	getBankAccount: (params) =>
		http
			.get<IBankAccountResponse>(endpoint.bankAccount.BANK_ACCOUNT, { params })
			.then((response) => response.data),
	addBankAccount: (params) =>
		http
			.post<IBankAccountItem>(endpoint.bankAccount.BANK_ACCOUNT, params)
			.then((response) => response.data),
	editBankAccount: (params) =>
		http
			.put<IBankAccountItem>(endpoint.bankAccount.BANK_ACCOUNT, params)
			.then((response) => response.data),
	deleteBankAccount: (id) =>
		http
			.delete<IBankAccountItem>(endpoint.bankAccount.DELETE_BANK_ACCOUNT(id))
			.then((response) => response.data),
};
