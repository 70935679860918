/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { IExternalMakerItem } from 'redux/reducers/externalMaker/types';
import { getOrderHistoryRequest, deleteExpiredRequest } from 'redux/reducers/externalMaker/reducer';
import { getDeleteExpiredLoader, getFilterParams } from 'redux/reducers/externalMaker/selectors';
import { IOrderHistoryRequestPayload } from 'redux/reducers/walletManagement/types';
import { ExportIcon } from 'assets/img/icons';
import { OrderHistoryFilter } from './OrderHistory/OrderHistoryFilter';
import AddNewPair from './Popups/CreatePopup';
import { OrderHistory } from './OrderHistory';
import { Pairs } from './Pairs';
import DeletePopup from './DeletePopup';
import EditPair from './Popups/EditPopup';

export const ExternalMakerBot: FC = () => {
	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState(0);
	const [addNewPairPopupOpen, setAddNewPairPopupOpen] = useState(false);
	const [editPairPopupOpen, setEditPairPopupOpen] = useState(false);
	const [editItemData, setEditItemData] = useState<null | IExternalMakerItem>(null);
	const [startDate, setStartDate] = useState<string | null>(null);
	const [endDate, setEndDate] = useState<string | null>(null);
	const [opedDeletePopup, setOpenDeletePopup] = useState(false);
	const history = useHistory();
	const { search, pathname } = useLocation();
	const deleteExpiredLoader = useSelector(getDeleteExpiredLoader);
	const filterParams = useSelector(getFilterParams);

	const { search_field: searchField, search_value: searchValue } = filterParams || {};

	const handleCloseAddNewPair = () => {
		setAddNewPairPopupOpen(false);
	};

	const handleEditPairOpen = (data: IExternalMakerItem) => {
		setEditPairPopupOpen(true);
		setEditItemData(data);
	};

	const handleEditPairClose = () => {
		setEditPairPopupOpen(false);
		setEditItemData(null);
	};

	const handleOrderHistoryExport = () => {
		dispatch(
			getOrderHistoryRequest({
				export: 1,
				start_date: startDate || undefined,
				end_date: endDate || undefined,
			}),
		);
	};

	const onDateSelect = (_startDate: string | null, _endDate: string | null) => {
		setStartDate(_startDate);
		setEndDate(_endDate);
	};

	const handleDeleteExpired = () => {
		dispatch(
			deleteExpiredRequest({
				onSuccess: () => {
					setOpenDeletePopup(false);
					const params: IOrderHistoryRequestPayload = {
						start_date: startDate || undefined,
						end_date: endDate || undefined,
						search_field: searchValue ? searchField : undefined,
						search_value: searchValue,
					};
					dispatch(getOrderHistoryRequest(params));
				},
			}),
		);
	};

	return (
		<>
			<div className="title-block title-block--admin-managment">
				<p className="title">External</p>
			</div>
			<div className="admin-managment-buttons">
				{tabIndex === 1 && <OrderHistoryFilter onDateSelect={onDateSelect} />}
				<div className="admin-managment-btn" onClick={() => setAddNewPairPopupOpen(true)}>
					<span className="admin-managment-btn__text">Add New Pair</span>
					<span className="plus-icon plus-icon--action icon-plus-icon" />
				</div>
				{tabIndex === 1 && (
					<>
						<button
							type="button"
							className="transaction-export-btn"
							onClick={handleOrderHistoryExport}
						>
							Export order history
							<span className="transaction-export-btn__icon">
								<ExportIcon />
							</span>
						</button>
						<button
							type="button"
							className="transaction-export-btn transaction-export-btn--type2 transaction-export-btn--red-text"
							onClick={() => setOpenDeletePopup(true)}
						>
							<span className="transaction-export-btn__text">
								Delete Expired Orders
								<span className="hint-item">
									<span className="hint-item__text">
										Expired orders with 0% fill will be deleted.
									</span>
								</span>
							</span>
						</button>
					</>
				)}
			</div>
			{addNewPairPopupOpen && <AddNewPair onClose={handleCloseAddNewPair} />}
			{editPairPopupOpen && editItemData && (
				<EditPair onClose={handleEditPairClose} pairData={editItemData} />
			)}
			<Tabs
				className="settings-tabs"
				selectedIndex={tabIndex}
				onSelect={(index) => {
					if (search) {
						history.replace({
							pathname,
							search: undefined,
						});
					}
					setTabIndex(index);
				}}
			>
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link">Pairs</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Order History</span>
					</Tab>
				</TabList>
				<TabPanel>
					<Pairs onOpenEditPair={handleEditPairOpen} />
				</TabPanel>
				<TabPanel>
					<OrderHistory filterParams={{ startDate, endDate }} />
				</TabPanel>
			</Tabs>
			{tabIndex === 1 && opedDeletePopup && (
				<DeletePopup
					isLoader={deleteExpiredLoader}
					onAccept={handleDeleteExpired}
					onCancel={() => setOpenDeletePopup(false)}
				/>
			)}
		</>
	);
};
