import { FC } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'ui/Loader';
import { getPendingDepositsLoad } from 'redux/reducers/pendingDeposits/selectors';
import { DepositItem } from './DepositItem';
import { IDepositsListProps } from './types';

const DepositsList: FC<IDepositsListProps> = ({
	deposits,
	handleClearSearch,
	selectedItems,
	onSelectItem,
	onSelectAll,
}) => {
	const loading = useSelector(getPendingDepositsLoad);

	const isAllSelected =
		deposits && deposits.length > 0 ? deposits.every((item) => selectedItems[item.id]) : false;

	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--pending-deposits">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="checkbox checkbox--no-mb">
									<label className="checkbox__label">
										<input
											type="checkbox"
											className="hidden"
											checked={isAllSelected}
											onChange={() => onSelectAll(!isAllSelected)}
										/>
										<div className=" checkbox__item">
											<span className="checkbox__item-icon">
												<svg
													className="fill"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 26 26"
													width="26"
													height="26"
													fill="#1a1a1a"
												>
													<path d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z" />
												</svg>
											</span>
										</div>
										<p className="checkbox__text checkbox__text--for-mobile">Select all</p>
									</label>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>User id</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Game User ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Time</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Asset</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Amount</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Amount in USD</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name td-name--action">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>
					{!deposits?.length && !loading && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">No deposits found...</p>
						</div>
					)}
					{loading ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{deposits?.map((item) => (
								<DepositItem
									key={item.id}
									data={item}
									handleClearSearch={handleClearSearch}
									selectedItems={selectedItems}
									onSelectItem={onSelectItem}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default DepositsList;
