/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiChangeShoulderCrossParams,
	IApiChangeShoulderIsolateParams,
	IApiGetMarginIsolatedResponse,
	IApiToggleActiveIsolateParams,
} from 'services/api/tradingPairs/types';
import {
	IAddAssetsPairPayload,
	IAssetsResponsePayload,
	IEditTradingPairsPayload,
	ITradingPairsResponsePayload,
	ITradingPairsStore,
} from './types';

export const initialState: ITradingPairsStore = {
	tradingPairs: null,
	tradingPairsLoader: false,
	editTradingPairsLoader: false,

	marginCrossList: [],
	marginIsolatedList: [],
	marginIsolatedLoading: false,
	marginIsolatedActionsLoading: false,

	assets: null,
	assetsLoader: false,
	addAssetsPairLoader: false,
};

// ==========================================:
const tradingPairs = createSlice({
	name: '@@tradingPairs',
	initialState,
	reducers: {
		getTradingPairsRequest: (state) => {
			const tradingPairsRequestState = state;
			tradingPairsRequestState.tradingPairsLoader = true;
		},
		getTradingPairsSuccess: (state, action: PayloadAction<ITradingPairsResponsePayload>) => {
			const { payload } = action;
			const tradingPairsSuccessState = state;
			tradingPairsSuccessState.tradingPairs = payload;
			tradingPairsSuccessState.tradingPairsLoader = false;
		},

		editTradingPairsRequest: (state, action: PayloadAction<IEditTradingPairsPayload>) => {
			const editTradingPairsState = state;

			editTradingPairsState.editTradingPairsLoader = true;
		},
		editTradingPairsSuccess: (state) => {
			const editTradingPairsState = state;

			editTradingPairsState.editTradingPairsLoader = false;
		},

		getMarginIsolatedRequest: (state) => {
			state.marginIsolatedLoading = true;
		},
		getMarginIsolatedSuccess: (
			state,
			{ payload }: PayloadAction<IApiGetMarginIsolatedResponse>,
		) => {
			state.marginIsolatedList = payload.pairs;
			state.marginCrossList = payload.assets;
			state.marginIsolatedLoading = false;
		},

		toggleActiveIsolateRequest: (state, action: PayloadAction<IApiToggleActiveIsolateParams>) => {
			state.marginIsolatedActionsLoading = true;
		},
		toggleActiveIsolateSuccess: (state) => {
			state.marginIsolatedActionsLoading = false;
		},
		toggleActiveIsolateError: (state) => {
			state.marginIsolatedActionsLoading = false;
		},

		toggleActiveCrossRequest: (state, action: PayloadAction<IApiToggleActiveIsolateParams>) => {
			state.marginIsolatedActionsLoading = true;
		},
		toggleActiveCrossSuccess: (state) => {
			state.marginIsolatedActionsLoading = false;
		},
		toggleActiveCrossError: (state) => {
			state.marginIsolatedActionsLoading = false;
		},

		changeShoulderIsolateRequest: (
			state,
			action: PayloadAction<IApiChangeShoulderIsolateParams>,
		) => {
			state.marginIsolatedActionsLoading = true;
		},
		changeShoulderIsolateSuccess: (state) => {
			state.marginIsolatedActionsLoading = false;
		},
		changeShoulderIsolateError: (state) => {
			state.marginIsolatedActionsLoading = false;
		},

		changeShoulderCrossRequest: (state, action: PayloadAction<IApiChangeShoulderCrossParams>) => {
			state.marginIsolatedActionsLoading = true;
		},
		changeShoulderCrossSuccess: (state) => {
			state.marginIsolatedActionsLoading = false;
		},
		changeShoulderCrossError: (state) => {
			state.marginIsolatedActionsLoading = false;
		},

		getAssetsRequest: (state) => {
			const assetsRequestState = state;
			assetsRequestState.assetsLoader = true;
		},
		getAssetsSuccess: (state, action: PayloadAction<IAssetsResponsePayload>) => {
			const { payload } = action;
			const assetsSuccessState = state;
			assetsSuccessState.assets = payload;
			assetsSuccessState.assetsLoader = false;
		},

		addAssetsPairRequest: (state, action: PayloadAction<IAddAssetsPairPayload>) => {
			const addAssetsPairState = state;

			addAssetsPairState.addAssetsPairLoader = true;
		},
		addAssetsPairSuccess: (state) => {
			const addAssetsPairState = state;

			addAssetsPairState.addAssetsPairLoader = false;
		},

		tradingPairsInitState: () => initialState,
	},
});

export default tradingPairs.reducer;
export const {
	getTradingPairsSuccess,
	getTradingPairsRequest,
	editTradingPairsRequest,
	editTradingPairsSuccess,
	getMarginIsolatedRequest,
	getMarginIsolatedSuccess,
	toggleActiveIsolateRequest,
	toggleActiveIsolateSuccess,
	toggleActiveIsolateError,
	toggleActiveCrossRequest,
	toggleActiveCrossSuccess,
	toggleActiveCrossError,
	changeShoulderIsolateRequest,
	changeShoulderIsolateSuccess,
	changeShoulderIsolateError,
	getAssetsRequest,
	getAssetsSuccess,
	addAssetsPairRequest,
	addAssetsPairSuccess,
	changeShoulderCrossRequest,
	changeShoulderCrossSuccess,
	changeShoulderCrossError,
	tradingPairsInitState,
} = tradingPairs.actions;
