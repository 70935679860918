import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { ExportIcon } from 'assets/img/icons';
import { getReferralListRequest } from 'redux/reducers/referral/reducer';
import { getFilterParams } from 'redux/reducers/userManagement/selectors';

export const UserFilter = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);

	const handleExport = () => {
		const payload = {
			params: {
				current_page: Number(page) || 1,
				export: 1,
				search_field: field ? String(field) : null,
				search_value: text ? String(text) : null,
			},
		};
		dispatch(getReferralListRequest(payload));
	};
	return (
		<div className="count-label--user-management count-label--left transactions-data-picker">
			<button type="button" className="transaction-export-btn" onClick={handleExport}>
				Export referrals
				<span className="transaction-export-btn__icon">
					<ExportIcon />
				</span>
			</button>
		</div>
	);
};
