import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletsRequest } from 'redux/reducers/walletManagement/reducer';
import {
	getResetColdBalanceLoader,
	getWalletsIsLoad,
	getWalletsList,
} from 'redux/reducers/walletManagement/selectors';
import Loader from 'ui/Loader';
import { WalletItem } from './WalletItem';

export const Wallet: FC = () => {
	const wallets = useSelector(getWalletsList);
	const walletsLoader = useSelector(getWalletsIsLoad);
	const resetColdBalanceLoader = useSelector(getResetColdBalanceLoader);

	const [editId, setEditId] = useState<number | null>(null);

	const dispatch = useDispatch();

	const toggleEditId = (id: number) => {
		setEditId((prev) => {
			return prev === id ? null : id;
		});
	};

	useEffect(() => {
		dispatch(getWalletsRequest({}));
	}, [dispatch]);

	useEffect(() => {
		const intervalId = setInterval(() => dispatch(getWalletsRequest({})), 10000);
		return () => clearInterval(intervalId);
	}, [dispatch]);

	return (
		<div className="content-block content-block--walletman">
			<div>
				<div className="table-block table-block--walletman">
					<div className="table-wrapper">
						<div className="table table--walletman">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Currency</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Balance</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Cold Balance</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Min alert</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Max alert</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Reset cold balance</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Deposit / Withdrawal</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Edit</p>
										</div>
									</div>
								</div>
							</div>
							{!wallets?.length && !walletsLoader && (
								<div className="user-management-empty-data">
									<p className="user-management-empty__text">No wallets found...</p>
								</div>
							)}

							{(!wallets?.length && walletsLoader) || resetColdBalanceLoader ? (
								<div className="list-loader">
									<Loader />
								</div>
							) : (
								<div className="table-body">
									{wallets?.map((wallet) =>
										wallet.balances?.map((item) => (
											<WalletItem
												key={item.network_id}
												data={item}
												asset_code={wallet.asset_code}
												asset_id={wallet.asset_id}
											/>
										)),
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
