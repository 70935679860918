import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

export const getChannelName = (channel: string) => {
	switch (channel) {
		case 'paypal':
			return 'Pay Pal';
		case 'bank_transfer':
			return 'Partner bank';
		case 'bank_transfer_other':
			return 'Other bank';
		case 'card_visa':
			return 'Visa';
		case 'card_mastercard':
			return 'Mastercard';
		case 'card_other':
			return 'Other card';
		default:
			return capitalizeFirstLetter(channel.replaceAll('_', ' '));
	}
};
