import { FC, useState } from 'react';
import { noExponent, capitalizeFirstLetter } from 'utils/numberFormat';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTabMenuUserIndex } from 'redux/reducers/userManagement/reducer';
import { IP2PTradesProps } from './types';

const P2PTradesTableItem: FC<IP2PTradesProps> = ({ item }) => {
	const dispatch = useDispatch();
	const [created] = useState(item?.created_at);
	const [amount] = useState(item.amount);
	const [price] = useState(item.price);
	const [assetCode] = useState(item.asset_code);
	const [currencyCode] = useState(item.currency_code);
	const [buyerIdd] = useState(item.buyer_id);
	const [sellerId] = useState(item.seller_id);
	const [status] = useState(item.status);
	const [type] = useState(item.type);
	const date = new Date(Date.parse(`${created.replace(' ', 'T')}Z`));
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Date</p>
				<p className="grey-text">
					{date.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{date.toLocaleTimeString()}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fiat amount</p>
				<p>
					{noExponent(Number(price).toString())} {currencyCode.toUpperCase()}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Crypto amount</p>
				<p>
					{noExponent(Number(amount).toString())} {assetCode.toUpperCase()}
				</p>
			</div>
			<div className="td td--right">
				<Link
					onClick={() => {
						dispatch(setTabMenuUserIndex(0));
					}}
					to={`/user-management/${String(buyerIdd)}`}
				>
					<p className="td-hidden-name">Buyer ID</p>
					<p>{buyerIdd}</p>
				</Link>
			</div>
			<div className="td td--right">
				<Link
					onClick={() => {
						dispatch(setTabMenuUserIndex(0));
					}}
					to={`/user-management/${String(sellerId)}`}
				>
					<p className="td-hidden-name">Seller ID</p>
					<p>{sellerId}</p>
				</Link>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<p>{capitalizeFirstLetter(status)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Type</p>
				<p className="td-hidden-name">Side</p>
				<p
					style={{
						color:
							capitalizeFirstLetter(type).toLowerCase().indexOf('buy') === -1
								? '#F44336'
								: '#03BC70',
					}}
				>
					{capitalizeFirstLetter(type)}
				</p>
			</div>
		</div>
	);
};
export default P2PTradesTableItem;
