/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCardsLoader, getCardsList } from 'redux/reducers/userManagement/selectors';
import { IUserCardsItem } from 'redux/reducers/userManagement/types';
import { useHistory, useLocation, useParams } from 'react-router';
import { getUserCardsRequest } from 'redux/reducers/userManagement/reducer';
import Loader from 'ui/Loader';
import { CardsTableHeader } from './CardsTableHeader';
import CardsTableItem from './CardsTableItem';

export const Cards = () => {
	const cardsList = useSelector(getCardsList);
	const cardsLoader = useSelector(getCardsLoader);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		const params = {
			id,
		};
		// history.push({
		// 	pathname: location.pathname,
		// });
		dispatch(getUserCardsRequest(params));
	}, []);

	return (
		<>
			{cardsLoader ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<>
					<CardsTableHeader />
					<div className="table-body">
						{cardsList?.map((item: IUserCardsItem) => (
							<CardsTableItem item={item} key={item.id} />
						))}
						{cardsList?.length === 0 ? (
							<p style={{ padding: '20px' }}>Cards list is empty</p>
						) : null}
					</div>
				</>
			)}
		</>
	);
};
