/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IUnidentifiedDepositsResponse,
	IUnidentifiedDepositsRequest,
	IUserRequest,
	IUserItem,
	IAcceptPayload,
	IRejectPayload,
	IUnidentifiedDepositItem,
	IAcceptSuccessPayload,
} from './types';
import {
	getUnidentDepositsListRequest,
	getUnidentDepositsListSuccess,
	getUnidentDepositsListError,
	findUsersRequest,
	findUsersSuccess,
	findUsersError,
	acceptDepositRequest,
	acceptDepositSuccess,
	acceptDepositError,
	rejectDepositRequest,
	rejectDepositSuccess,
	rejectDepositError,
} from './reducer';

// ===================================================:
function* getUnidentifiedDepositsWorker({ payload }: PayloadAction<IUnidentifiedDepositsRequest>) {
	try {
		yield put(showLoading());
		const response: IUnidentifiedDepositsResponse = yield call(
			api.unidentifiedDeposits.getList,
			payload,
		);
		if (payload.export === 1) {
			notificationContainer('Deposits successfully exported, check your mailbox!', 'info');
		} else yield put(getUnidentDepositsListSuccess(response));
	} catch (error) {
		yield put(getUnidentDepositsListError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* findUserWorker(action: PayloadAction<IUserRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IUserItem[] = yield call(api.unidentifiedDeposits.findUsers, payload);

		yield put(findUsersSuccess(response));
	} catch (error) {
		yield put(findUsersError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* acceptDepositWorker({ payload }: PayloadAction<IAcceptPayload>) {
	const { apiParams, onSuccess, onError } = payload;

	try {
		yield put(showLoading());
		const response: IAcceptSuccessPayload = yield call(api.unidentifiedDeposits.confirm, apiParams);

		yield put(acceptDepositSuccess(response.data));
		onSuccess?.(apiParams.id);
	} catch (error) {
		yield put(acceptDepositError());
		onError?.();
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* rejectDepositWorker({ payload }: PayloadAction<IRejectPayload>) {
	const { apiParams, onSuccess, onError } = payload;

	try {
		yield put(showLoading());
		const response: IAcceptSuccessPayload = yield call(api.unidentifiedDeposits.reject, apiParams);

		yield put(rejectDepositSuccess(response.data));
		onSuccess?.(apiParams.id);
	} catch (error) {
		yield put(rejectDepositError());
		onError?.();
		onError?.();
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// ==================================================:
export function* unidentifiedDepositsSaga() {
	yield takeEvery(getUnidentDepositsListRequest.type, getUnidentifiedDepositsWorker);
	yield takeEvery(findUsersRequest.type, findUserWorker);
	yield takeEvery(acceptDepositRequest.type, acceptDepositWorker);
	yield takeEvery(rejectDepositRequest.type, rejectDepositWorker);
}
