/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */
/* eslint-disable no-extend-native */
export const numberFormat = (value: number, format: string) => {
	const transformNumber = new Intl.NumberFormat(format, { maximumFractionDigits: 2 }).format(value);
	return transformNumber;
};

export const numberFormat8Fraction = (value: number, format: string) => {
	const transformNumber = new Intl.NumberFormat(format, { maximumFractionDigits: 8 }).format(value);
	return transformNumber;
};

export const noExponent = (n: string | number) => {
	if (isNaN(+n)) return n;
	const sign = +n < 0 ? '-' : '';
	const toStr = n.toString();
	if (!/e/i.test(toStr)) {
		return n;
	}
	const [lead, decimal, pow] = n
		.toString()
		.replace(/^-/, '')
		.replace(/^([0-9]+)(e.*)/, '$1.$2.$3.$4.$5.$6')
		.split(/e|\./);
	return +pow < 0
		? `${sign}0.${'0'.repeat(Math.max(Math.abs(Number(pow)) - 1 || 0, 0))}${lead}${decimal}`
		: sign +
				lead +
				(+pow >= decimal.length
					? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
					: `${decimal.slice(0, +pow)}.${decimal.slice(+pow)}`);
};
export const capitalizeFirstLetter = (text: string) => {
	const string = text.replace('_', ' ');
	const string2 = string.replace('_', ' ');
	return string2.charAt(0).toUpperCase() + string2.slice(1);
};

export const getTimeParserDateFromTimestamp = (timestamp: string): string => {
	return new Date(timestamp).toLocaleDateString();
};
export const getTimeParserTimeFromTimestamp = (timestamp: string): string => {
	return new Date(timestamp).toLocaleTimeString();
};
