/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSingleFiatLimitationsRequest } from 'redux/reducers/limitations/reducer';
import { LimitationsWithdrawal } from './Withdrawal';
import { LimitationsDeposit } from './Deposit';

export const SingleFiat = () => {
	const [tab, setTab] = useState<'withdrawal' | 'deposit'>('withdrawal');
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getSingleFiatLimitationsRequest());
	}, [dispatch]);

	return (
		<div className="content-block content-block--user-management">
			<div className="content-block content-block--transaction">
				<div className="content-block__inside content-block__inside--deposit-his">
					<div className="deposit-history deposit-history--filter">
						<ul className="deposit-history__list">
							<li className={`deposit-history__item ${tab === 'withdrawal' ? 'active' : ''}`}>
								<a
									className="deposit-history__link"
									onClick={() => {
										setTab('withdrawal');
									}}
								>
									Withdrawal
								</a>
							</li>
							<li className={`deposit-history__item ${tab === 'deposit' ? 'active' : ''}`}>
								<a
									className="deposit-history__link"
									onClick={() => {
										setTab('deposit');
									}}
								>
									Deposit
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="content-block__inside content-block__inside--login-his">
					{tab === 'deposit' ? <LimitationsDeposit /> : <LimitationsWithdrawal />}
				</div>
			</div>
		</div>
	);
};
