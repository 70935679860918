import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getDisplayDisableBlock } from 'redux/reducers/settings/selectors';
import Enable2faBlock from 'components/Settings2fa/Enable2faBlock';
import Disable2faBlock from 'components/Settings2fa/Disable2faBlock';

// ================================================:
const Google2faActions: FC = () => {
	const displayDisableBlock = useSelector(getDisplayDisableBlock);

	return displayDisableBlock === '2fa_enabled' ? <Disable2faBlock /> : <Enable2faBlock />;
};

export default Google2faActions;
