import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IBalanceApi } from './types';

// ==========================================:
export const balance: IBalanceApi = {
	getBalance: () => http.get(endpoint.wallet.BALANCE).then((response) => response.data),
	getHotBalance: () => http.get(endpoint.wallet.HOT_BALANCE).then((response) => response.data),
	getTotalFeeCollected: () =>
		http.get(endpoint.wallet.TOTAL_FEE_COLLECTED).then((response) => response.data),
};
