import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
	changeShoulderIsolateRequest,
	toggleActiveIsolateRequest,
} from 'redux/reducers/tradingPairs/reducer';
import { ReactComponent as CheckIcon } from 'assets/img/icons/check.svg';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IMarginIsolatedItemProps } from './types';

export const MarginIsolatedItem: FC<IMarginIsolatedItemProps> = ({ item }) => {
	const dispatch = useDispatch();

	const [activeIsolate, setActiveIsolate] = useState(!!item.active_isolate);
	const [shoulderIsolate, setShoulderIsolate] = useState(String(item.shoulder_isolate));
	const [isEdit, setIsEdit] = useState(false);

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.MANAGE_ASSET_PAIRS);

	const isNotValid = shoulderIsolate === '';

	const changeShoulderIsolate = () => {
		if (isNotValid) return;

		dispatch(
			changeShoulderIsolateRequest({
				pair_id: item.id,
				shoulder: Number(shoulderIsolate),
			}),
		);
	};

	const toggleActiveIsolate = () => {
		setActiveIsolate((state) => !state);
		dispatch(toggleActiveIsolateRequest({ pairId: item.id }));
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency Pair</p>
				<p>{item.code.toUpperCase().replace('_', '/')}</p>
			</div>

			<div className="td td--center">
				<p className="td-hidden-name">Isolated margin Leverage</p>
				{!isEdit && <p>{shoulderIsolate}</p>}

				{isEdit && (
					<input
						value={shoulderIsolate}
						onChange={(e) => setShoulderIsolate(e.target.value.replace(/[^\d]/g, ''))}
					/>
				)}
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Isolated margin status</p>
				<div className={`switch switch--type2 ${!canEdit ? 'switch--disabled' : ''}`}>
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={activeIsolate}
							onChange={toggleActiveIsolate}
							disabled={!canEdit}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>

			<div className="td">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					{isEdit && (
						<>
							<button
								type="button"
								onClick={() => {
									setShoulderIsolate(String(item.shoulder_isolate));
									setIsEdit(false);
								}}
							>
								<span className="edit-icon icon-cancel-icon" />
							</button>

							<button
								type="button"
								onClick={changeShoulderIsolate}
								className={isNotValid ? 'button-not-active' : ''}
							>
								<CheckIcon />
							</button>
						</>
					)}

					{!isEdit && (
						<button type="button" disabled={!canEdit} onClick={() => setIsEdit(true)}>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
