import { IDisputeStore } from './types';

const getDisputeState = (state: any): IDisputeStore => state.dispute;

export const getDisputeList = (state: any) => {
	return getDisputeState(state).disputeList;
};

export const getDisputeLoading = (state: any) => {
	return getDisputeState(state).disputeLoading;
};

export const getDetailsItem = (state: any) => {
	return getDisputeState(state).detailsItem;
};

export const getDetailsLoading = (state: any) => {
	return getDisputeState(state).detailsLoading;
};

export const getDetailsActionsLoading = (state: any) => {
	return getDisputeState(state).detailsActionsLoading;
};
