import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	I2FAData,
	IDepositAndWithdrawHistoryData,
	ILoginHistoryData,
	IUserBalancesData,
	IUsersData,
	IUsersStore,
	IWhitelistIpListData,
	IUserCardsResponse,
	IUserBankAccountResponse,
	IEmail2Fa,
} from './types';
import { CryptoDeposit } from '../../../components/UserManagement/SelectUser/TransactionHistory/TransactionHistoryBox/CryptoDeposit/CryptoDeposit';
import { FiatDeposit } from '../../../components/UserManagement/SelectUser/TransactionHistory/TransactionHistoryBox/FiatDeposit/FiatDeposit';

// ==========================================:
const getUsersState = (state: IStoreState): IUsersStore => state.userManagement;
export const getUsers = createSelector([getUsersState], (users: IUsersStore) => users);

// ====================================================:
export const getUsersIsLoad = createSelector(
	[getUsers],
	(users: IUsersStore): boolean => users.usersLoader,
);

// ====================================================:
export const getUsersList = createSelector(
	[getUsers],
	(users: IUsersStore): IUsersData => users.users,
);

// ==========================================:
export const getSelectUser = createSelector([getUsers], (user: IUsersStore) => user);

export const getSelectUserIsLoad = createSelector(
	[getUsers],
	(user: IUsersStore) => user.userLoader,
);

export const getOneUserDateRange = (state: IStoreState) => getUsersState(state).oneUserDateRange;

// ==========================================:
export const getUserBalances = createSelector(
	[getUsers],
	(userBalances: IUsersStore) => userBalances,
);

// ==========================================:
export const getUserBalancesIsLoad = createSelector(
	[getUsers],
	(userBalances: IUsersStore) => userBalances.userBalancesLoader,
);
export const getOrdersHistoryList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userOrdersHistory,
);
export const getOrdersHistoryIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userOrdersHistoryLoader,
);
export const getOrdersHistoryMarginList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userOrdersHistoryMargin,
);
export const getOrdersHistoryMarginIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userOrdersHistoryMarginLoader,
);
export const getBorrowingMarginList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userBorrowingMargin,
);
export const getBorrowingMarginIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userBorrowingLoader,
);
// ====================================================:
export const getUserBalancesList = createSelector(
	[getUsers],
	(userBalances: IUsersStore): IUserBalancesData => userBalances.userBalances,
);

// ==========================================:
export const getRepaymentMarginList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userRepaymentMargin,
);
export const getRepaymentMarginIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userRepaymentLoader,
);
export const getInterestMarginList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userInterestMargin,
);
export const getInterestMarginIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userInterestLoader,
);

export const getLiquidationMarginList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userLiquidationMargin,
);
export const getLiquidationMarginIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userLiquidationLoader,
);
export const getBalancesMarginList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userBalancesMargin,
);
export const getBalancesMarginIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userBalancesMarginLoader,
);
export const getMarginCallMarginList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userMarginCallMargin,
);
export const getMarginCallMarginIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userMarginCallMarginLoader,
);
export const getAdvertisementsP2PList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userAdvertisementsP2P,
);
export const getAdvertisementsP2PIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userAdvertisementsP2PLoader,
);
export const getDisputeP2PList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userDisputeP2P,
);
export const getDisputeP2PIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userDisputeP2PLoader,
);
export const getTradesP2PList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userTradesP2P,
);
export const getTradesP2PIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userTradesP2PLoader,
);
export const getBalancesP2PList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userBalancesP2P,
);
export const getBalancesP2PIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userBalancesP2PLoader,
);
export const getCryptoDepositList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userCryptoDeposit,
);

export const getCryptoDepositIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userCryptoDepositLoader,
);
export const getFiatDepositList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userFiatDeposit,
);

export const getFiatDepositIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userFiatDepositLoader,
);
export const getConvertsList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userConverts,
);

export const getConvertsIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userCryptoDepositLoader,
);
export const getTransfersList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userTransfers,
);

export const getTransfersIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userTransfersLoader,
);
export const getRedemptionsList = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userRedemptions,
);

export const getRedemptionsIsLoad = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.userRedemptionsLoader,
);
export const tabMenuUserIndex = createSelector(
	[getUsers],
	(ordersHistory: IUsersStore) => ordersHistory.tabMenuUserIndex,
);
export const getWhitelistIp = createSelector([getUsers], (whitelistIp: IUsersStore) => whitelistIp);

// ==========================================:
export const getWhitelistIpIsLoad = createSelector(
	[getUsers],
	(whitelistIp: IUsersStore) => whitelistIp.whitelistIpLoader,
);

// ====================================================:
export const getWhitelistIpList = createSelector(
	[getUsers],
	(whitelistIp: IUsersStore): IWhitelistIpListData => whitelistIp.whitelistIp,
);

// ====================================================:
export const getUserBlockIsLoad = createSelector(
	[getUsers],
	(users: IUsersStore): boolean => users.userBlockUnblockLoader,
);

// ====================================================:
export const get2FAIsLoad = createSelector(
	[getUsers],
	(users: IUsersStore): boolean => users.twoFALoader,
);

// ====================================================:
export const get2FAList = createSelector([getUsers], (twoFA: IUsersStore): I2FAData => twoFA.twoFA);

// ==========================================:

export const getLoginHistory = createSelector(
	[getUsers],
	(loginHistory: IUsersStore) => loginHistory,
);

// ==========================================:
export const getLoginHistoryIsLoad = createSelector(
	[getUsers],
	(loginHistory: IUsersStore) => loginHistory.loginHistoryLoader,
);

// ====================================================:
export const getLoginHistoryList = createSelector(
	[getUsers],
	(loginHistory: IUsersStore): ILoginHistoryData => loginHistory.loginHistory,
);

// ====================================================:

export const getDepositAndWithdrawHistory = createSelector(
	[getUsers],
	(depositAndWithdrawHistory: IUsersStore) => depositAndWithdrawHistory,
);

// ==========================================:
export const getDepositAndWithdrawHistoryIsLoad = createSelector(
	[getUsers],
	(depositAndWithdrawHistory: IUsersStore) =>
		depositAndWithdrawHistory.depositAndWithdrawHistoryLoader,
);

// ====================================================:
export const getDepositAndWithdrawHistoryList = createSelector(
	[getUsers],
	(depositAndWithdrawHistory: IUsersStore): IDepositAndWithdrawHistoryData =>
		depositAndWithdrawHistory.depositAndWithdrawHistory,
);

// ====================================================:
export const getCardsList = createSelector(
	[getUsers],
	(cards: IUsersStore): IUserCardsResponse | null => cards.userCardsPayment,
);

export const getCardsLoader = createSelector(
	[getUsers],
	(cards: IUsersStore): boolean => cards.userCardsLoader,
);

// ====================================================:
export const getBankAccountList = createSelector(
	[getUsers],
	(account: IUsersStore): IUserBankAccountResponse | null => account.userBankAccountPayment,
);

export const getBankAccountLoader = createSelector(
	[getUsers],
	(account: IUsersStore): boolean => account.userBankAccountLoader,
);

// ====================================================:
export const getFilterParams = createSelector(
	[getUsers],
	(account: IUsersStore) => account.filterParams,
);

export const getBankAccountStatusLoader = createSelector(
	[getUsers],
	(account: IUsersStore) => account.updateBankAccountStateLoader,
);

// ====================================================:
export const getEmail2FA = createSelector(
	[getUsers],
	(account: IUsersStore): IEmail2Fa | null => account.email2FA,
);

export const getEmail2FALoader = createSelector(
	[getUsers],
	(account: IUsersStore): boolean => account.email2FALoading,
);

// ====================================================:
export const getSms2FA = createSelector(
	[getUsers],
	(account: IUsersStore): IEmail2Fa | null => account.sms2FA,
);

export const getSms2FALoader = createSelector(
	[getUsers],
	(account: IUsersStore): boolean => account.sms2FALoading,
);
