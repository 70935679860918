import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { Transactions } from 'components/Transactions';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const TransactionsPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.MANAGE_CRYPTO_TRANSFERS}>
			<Dashboard title="Transactions">
				<Transactions />
			</Dashboard>
		</PermissionGate>
	);
};

export default TransactionsPage;
