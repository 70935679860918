import {
	IChangeCryptoFeesPayload,
	IChangeExchangeFeePayload,
	IChangeFiatFeesPayload,
	IChangeTradeFeesAndLimitsPayload,
} from 'redux/reducers/fees/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IFeesApi } from './types';

// ==========================================:
export const fees: IFeesApi = {
	getFeesAndLimits: () =>
		http.get(endpoint.fee.ORDER_FEES_AND_LIMITS).then((response) => response.data),
	getPlatformFees: () => http.get(endpoint.fee.PLATFORM_FEES).then((response) => response.data),
	getCryptoFees: () => http.get(endpoint.fee.CRYPTO_FEES).then((response) => response.data),
	getFiatFees: () => http.get(endpoint.fee.FIAT_FEES).then((response) => response.data),
	getVolumeDependsFeeSettings: () =>
		http.get(endpoint.fee.VOLUME_SETTINGS).then((response) => response.data),
	changeVolumeDependsFeeSettings: (payload) =>
		http.put<IChangeExchangeFeePayload>(endpoint.fee.VOLUME_SETTINGS, payload),
	changeInPlatformExchangeFee: (payload) =>
		http.put<IChangeExchangeFeePayload>(endpoint.fee.UPDATE_PLATFORM_FEES, payload),
	changeFeesAndLimits: (payload) =>
		http.put<IChangeTradeFeesAndLimitsPayload>(endpoint.fee.UPDATE_ORDER_FEES_AND_LIMITS, payload),
	changeCryptoFees: (payload) =>
		http.put<IChangeCryptoFeesPayload>(endpoint.fee.UPDATE_CRYPTO_FEES, payload),
	changeFiatFees: (payload) =>
		http.put<IChangeFiatFeesPayload>(endpoint.fee.UPDATE_FIAT_FEES, payload),
	getP2PFees: () => http.get(endpoint.fee.P2P_FEES).then((response) => response.data),
	changeP2PFees: (body) => http.put(endpoint.fee.P2P_FEES, body).then((response) => response.data),
	getMarginBorrowFees: () =>
		http.get(endpoint.fee.MARGIN_BORROW).then((response) => response.data.fees),
	changeMarginBorrowFees: (body) =>
		http.post(endpoint.fee.MARGIN_BORROW_UPDATE, body).then((response) => response),
};
