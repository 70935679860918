/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import {
	getLoadingOrdersMargin,
	getOrdersMarginList,
	getFilterParams,
} from 'redux/reducers/orders/selectors';
import { getOrdersMarginRequest, setFilterParams } from 'redux/reducers/orders/reducer';
import { MarginOrdersTableHeader } from './MarginOrdersTableHeader';
import { IMarginOrdersItem, IMarginProps } from './types';
import MarginOrdersTableItem from './MarginOrdersTableItem';

export const MarginOrders: FC<IMarginProps> = ({ radioCheck, setRadioCheck }) => {
	const ordersMarginList = useSelector(getOrdersMarginList);
	const ordersMarginListLoader = useSelector(getLoadingOrdersMargin);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const filterParams = useSelector(getFilterParams);

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = text?.length
			? `?page=${String(pageNumber)}&text=${String(text)}&field=${field}`
			: `?page=${String(pageNumber)}`;
		let searchValue = text?.length ? String(text) : undefined;
		if (field === 'pair') {
			searchValue = searchValue?.toLowerCase().replace('/', '_');
		}
		const params = {
			id,
			per_page: 15,
			[radioCheck]: radioCheck,
			current_page: pageNumber || 1,
			start_date: startDate || null,
			end_date: endDate || null,
			search_value: searchValue,
			search_field: text?.length ? String(field) : undefined,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getOrdersMarginRequest(params));
	};

	useEffect(() => {
		return () => {
			dispatch(setFilterParams({ start_date: null, end_date: null }));
		};
	}, [dispatch]);

	useEffect(() => {
		const params = {
			per_page: 15,
			[radioCheck]: radioCheck,
			current_page: 1,
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getOrdersMarginRequest(params));
	}, [radioCheck]);

	useEffect(() => {
		setCurrentPage(Number(page) || 1);
	}, [page]);

	return (
		<div className="trades-marginTrades">
			<div className="table-panel__radio-group">
				<div className="table-panel__radio-group">
					<div className="radio">
						<label className="radio__label">
							<input
								onChange={() => {
									setRadioCheck('margin');
								}}
								checked={radioCheck === 'margin'}
								type="radio"
								className="hidden"
								name="margin-type"
							/>
							<span className="radio__item" />
							<span className="radio__text">Cross </span>
						</label>
					</div>
					<div className="radio">
						<label className="radio__label">
							<input
								onChange={() => {
									setRadioCheck('isolated');
								}}
								checked={radioCheck === 'isolated'}
								type="radio"
								className="hidden"
								name="margin-type"
							/>
							<span className="radio__item" />
							<span className="radio__text">Isolated</span>
						</label>
					</div>
				</div>
			</div>
			{ordersMarginListLoader ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--margin-orders">
							<MarginOrdersTableHeader />
							<div className="table-body">
								{ordersMarginList?.data.map((item: IMarginOrdersItem) => (
									<MarginOrdersTableItem item={item} key={item.id} />
								))}
								{ordersMarginList?.data.length === 0 ? (
									<p style={{ padding: '20px' }}>Orders history is empty</p>
								) : null}
							</div>
						</div>
					</div>
					<div style={{ padding: '14px' }}>
						{(ordersMarginList?.last_page ?? 0) > 1 && (
							<Pagination
								pageCount={ordersMarginList?.last_page ?? 0}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</div>
	);
};
