import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import MarketBot from 'components/MarketBot';

// ================================================:
const MarketBotPage: FC = () => {
	return (
		<Dashboard title="Market bot">
			<MarketBot />
		</Dashboard>
	);
};

export default MarketBotPage;
