import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IBankAccountStore,
	IBankAccountRequest,
	IBankAccountResponse,
	IAddBankAccountPayload,
	IEditBankAccountPayload,
	IDeleteBankAccountPayload,
	IBankAccountItem,
} from './types';

const initialState: IBankAccountStore = {
	bankAccountsLoader: false,
	bankAccounts: null,
};

const bankAccount = createSlice({
	name: '@@bankAccount',
	initialState,
	reducers: {
		getBankAccountsRequest: (state, action: PayloadAction<IBankAccountRequest>) => {
			const bankAccountState = state;

			bankAccountState.bankAccountsLoader = true;
		},
		getBankAccountsSuccess: (state, { payload }: PayloadAction<IBankAccountResponse>) => {
			const balanceListSuccessState = state;

			balanceListSuccessState.bankAccounts = payload;
			balanceListSuccessState.bankAccountsLoader = false;
		},
		addBankAccountRequest: (state, action: PayloadAction<IAddBankAccountPayload>) => {},
		addBankAccountSuccess: (state, action: PayloadAction) => {},
		editBankAccountRequest: (state, action: PayloadAction<IEditBankAccountPayload>) => {},
		editBankAccountSuccess: (state, { payload }: PayloadAction<IBankAccountItem>) => {
			const balanceListSuccessState = state;
			if (!balanceListSuccessState.bankAccounts) {
				return;
			}
			const result = balanceListSuccessState.bankAccounts.data.map((item) => {
				return Number(item.id) === Number(payload.id) ? payload : item;
			});
			balanceListSuccessState.bankAccounts.data = result;
		},
		deleteBankAccountRequest: (state, action: PayloadAction<IDeleteBankAccountPayload>) => {},
		deleteBankAccountSuccess: (state, { payload: id }: PayloadAction<number>) => {
			const balanceListSuccessState = state;

			if (!balanceListSuccessState.bankAccounts) {
				return;
			}
			balanceListSuccessState.bankAccounts.data = balanceListSuccessState.bankAccounts.data.filter(
				(item) => item.id !== id,
			);
		},
		bankAccountInitState: () => initialState,
	},
});

export default bankAccount.reducer;

export const {
	getBankAccountsRequest,
	getBankAccountsSuccess,
	addBankAccountRequest,
	addBankAccountSuccess,
	editBankAccountRequest,
	editBankAccountSuccess,
	deleteBankAccountRequest,
	deleteBankAccountSuccess,
	bankAccountInitState,
} = bankAccount.actions;
