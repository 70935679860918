/* eslint-disable react/button-has-type */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserBlockUnblockRequest } from 'redux/reducers/userManagement/reducer';
import {
	approveFiatWithdrawalsRequest,
	rejectFiatWithdrawalsRequest,
} from 'redux/reducers/withdrawals/reducer';
import { RejectWithdrawalsModal } from './RejectWithdrawalsModal';
import { IActionsButtonProps } from './types';
import { AcceptWithdrawalsModal } from './AcceptWithdrawalsModal';

export const ActionsButtons: FC<IActionsButtonProps> = ({ id, onRefetch }) => {
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);
	const [openModalAccept, setOpenModalAccept] = useState(false);
	const closeModal = () => setOpenModal(false);
	const closeModalAccept = () => setOpenModalAccept(false);

	const handleApprove = () => {
		dispatch(
			approveFiatWithdrawalsRequest({
				apiParams: { id },
				onSuccess: onRefetch,
			}),
		);
	};
	const handleReject = (comment: string) => {
		dispatch(
			rejectFiatWithdrawalsRequest({
				apiParams: { id, comment },
				onSuccess: () => onRefetch,
			}),
		);
	};

	return (
		<div className="table-buttons">
			<button onClick={() => setOpenModal(true)}>
				<span className="edit-icon icon-cancel-icon" />
			</button>
			<button onClick={() => setOpenModalAccept(true)}>
				<span className="edit-icon icon-succes-icon" />
			</button>
			<RejectWithdrawalsModal
				openModal={openModal}
				closeModal={closeModal}
				handleRejectWithdrawal={handleReject}
			/>
			<AcceptWithdrawalsModal
				openModal={openModalAccept}
				closeModal={closeModalAccept}
				handleRejectWithdrawal={handleApprove}
			/>
		</div>
	);
};
