/* eslint-disable react/button-has-type */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserBlockUnblockRequest } from 'redux/reducers/userManagement/reducer';
import {
	approveFiatWithdrawalsRequest,
	rejectFiatWithdrawalsRequest,
	approveRedemptionRequest,
	rejectRedemptionRequest,
} from 'redux/reducers/withdrawals/reducer';
import {
	IApproveRedemptionParams,
	IRejectRedemptionParams,
} from 'redux/reducers/withdrawals/types';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { RejectWithdrawalsModal } from './RejectWithdrawalsModal';
import { IActionsButtonProps } from './types';
import { AcceptWithdrawalsModal } from './AcceptWithdrawalsModal';

export const ActionsButtons: FC<IActionsButtonProps> = ({ id, onRefetch }) => {
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);
	const [openModalAccept, setOpenModalAccept] = useState(false);
	const closeModal = () => setOpenModal(false);
	const closeModalAccept = () => setOpenModalAccept(false);

	const { checkEditable } = usePermission();

	const isEditable = checkEditable(permissions.MANAGE_REDEMPTIONS);

	const handleApprove = (params: IApproveRedemptionParams) => {
		if (!isEditable) {
			return;
		}
		dispatch(
			approveRedemptionRequest({
				apiParams: params,
				onSuccess: onRefetch,
			}),
		);
	};
	const handleReject = (params: IRejectRedemptionParams) => {
		if (!isEditable) {
			return;
		}
		dispatch(
			rejectRedemptionRequest({
				apiParams: params,
				onSuccess: () => onRefetch,
			}),
		);
	};

	return (
		<div className="table-buttons">
			<button onClick={() => setOpenModal(true)} disabled={!isEditable}>
				<span className="edit-icon icon-cancel-icon" />
			</button>
			<button onClick={() => setOpenModalAccept(true)} disabled={!isEditable}>
				<span className="edit-icon icon-succes-icon" />
			</button>
			<RejectWithdrawalsModal
				openModal={openModal}
				closeModal={closeModal}
				handleRejectWithdrawal={handleReject}
				id={id}
			/>
			<AcceptWithdrawalsModal
				openModal={openModalAccept}
				closeModal={closeModalAccept}
				handleAcceptWithdrawal={handleApprove}
				id={id}
			/>
		</div>
	);
};
