import { FC } from 'react';
import Loader from 'ui/Loader';
import { useSelector } from 'react-redux';
import { getWithdrawalsIsLoad } from 'redux/reducers/withdrawals/selectors';
import { WithdrawalItem } from './WithdrawalItem';
import { IWithdrawalListProps } from './types';

// ==========================================:
const WithdrawalList: FC<IWithdrawalListProps> = ({ withdrawals, handleClearSearch }) => {
	const withdrawalsLoader = useSelector(getWithdrawalsIsLoad);

	return (
		<div className="table-block table-block--mt-0">
			<div className="table-wrapper">
				<div className="table table--withdrawals-pending">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>User id</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Currency</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Network</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Value</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Fee</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Created</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Message</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name td-name--action">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>
					{!withdrawals?.length && !withdrawalsLoader && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">No withdrawals found...</p>
						</div>
					)}
					{withdrawalsLoader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{withdrawals?.map((item) => (
								<WithdrawalItem key={item.id} data={item} handleClearSearch={handleClearSearch} />
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default WithdrawalList;
