export type TTypeTransactions = typeof TYPE_ORDERS | typeof TYPE_MARGIN | typeof TYPE_P2P;

export const TYPE_ORDERS = 'orders';
export const TYPE_MARGIN = 'margin';
export const TYPE_P2P = 'p2p';

export const USER_STATUS_BUY = 'buy';
export const USER_STATUS_SELL = 'sell';
export const USER_STATUS_MARKET_BUY = 'market_buy';
export const USER_STATUS_MARKT_SELL = 'market_sell';

export const USER_LEVEL_VIP = 'vip';
export const USER_LEVEL_REGULAR = 'regular';

export const USER_TYPE_ARRAY = [
	'all',
	USER_STATUS_BUY,
	USER_STATUS_SELL,
	USER_STATUS_MARKET_BUY,
	USER_STATUS_MARKT_SELL,
];
export const USER_TYPE_TEXT_ARRAY = [
	'All',
	USER_STATUS_BUY,
	USER_STATUS_SELL,
	USER_STATUS_MARKET_BUY,
	USER_STATUS_MARKT_SELL,
];

export const USER_P2P_TYPE_ARRAY = ['all', USER_STATUS_BUY, USER_STATUS_SELL];
export const USER_P2P_TYPE_TEXT_ARRAY = ['All', USER_STATUS_BUY, USER_STATUS_SELL];

export const USER_LEVEL_ARRAY = ['all', USER_LEVEL_REGULAR, USER_LEVEL_VIP];

export const USER_P2P_SEARCH_ARRAY = ['user_id', 'type', 'coin'];
export const USER_P2P_SEARCH_TEXT_ARRAY = ['User ID', 'Type', 'Coin'];

// *************************************************

export const STATUS_CLOSED = 'closed';
export const STATUS_CANCELLED = 'cancelled';

export const STATUS_NUM_CLOSED = 'closed';
export const STATUS_NUM_CANCELLED = 'expired';

export const USER_STATUS_ARRAY = ['all', STATUS_NUM_CLOSED, STATUS_NUM_CANCELLED];
export const USER_STATUS_TEXT_ARRAY = ['All', STATUS_CLOSED, STATUS_CANCELLED];

export const SEARCH_ARRAY = ['status'];
export const SEARCH_TEXT_ARRAY = ['Status'];
