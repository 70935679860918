import { useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';

export default function usePermission() {
	const userData = useSelector(getUserData);
	const { permissions } = userData || {};

	const checkReadable = (names: string | string[]) => {
		if (Array.isArray(names)) {
			const permissionNames = permissions?.map((item) => item.name);
			return names.every((name) => permissionNames?.includes(name));
		}
		return !!permissions?.find((item) => item.name === names);
	};

	const checkEditable = (names: string | string[]) => {
		if (Array.isArray(names)) {
			return names.every((name) => permissions?.find((item) => item.name === name)?.pivot.editable);
		}
		return !!permissions?.find((item) => item.name === names)?.pivot.editable;
	};

	return {
		checkReadable,
		checkEditable,
	};
}
