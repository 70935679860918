/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { noExponent } from 'utils/numberFormat';
import { IAdminManagementListItemProps } from './types';
import { ActionsButtons } from './ActionButtons';

// ==========================================:
export const WithdrawalItem: FC<IAdminManagementListItemProps> = ({ data, onRefetch }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);
	const { checkReadable } = usePermission();

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			{data.user_id &&
				(checkReadable(permissions.MANAGE_USERS) ? (
					<Link className="td" to={`/user-management/${String(data.user_id)}`}>
						<p className="td-hidden-name">User Id</p>
						<p>{data.user_id}</p>
					</Link>
				) : (
					<div className="td">
						<p className="td-hidden-name">User Id</p>
						<p>{data.user_id}</p>
					</div>
				))}
			<div className="td">
				<p className="td-hidden-name">Phone number</p>
				<p>{data.phone}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Initiated amount</p>
				<p>{data.init_gold_grams}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Available GOLDb Amount</p>
				<p>{data.available_amount != null ? noExponent(data.available_amount) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Released Amount</p>
				<p>{data.released_amount != null ? `${noExponent(data.released_amount)} GOLDB` : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{data.fee != null ? noExponent(data.fee) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Gold in grams</p>
				<p>{data.init_gold_grams != null ? data.init_gold_grams : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Reject reason</p>
				<div className="hint-block">
					<div className="hint-block__text">
						<p>{data.reject_reason}</p>
					</div>
					<div className="hint-item">
						<p>{data.reject_reason}</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Created</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> &bull; </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Action</p>
				<ActionsButtons id={data.id} onRefetch={onRefetch} />
			</div>
		</div>
	);
};
