import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFees } from 'redux/reducers/fees/selectors';
import { getFeesRequest } from 'redux/reducers/fees/reducer';
import { EAccountLevel } from 'redux/reducers/fees/types';
import Loader from 'ui/Loader';
import TradeFeesAndLimitsItem from './TradeFeesAndLimitsItem';
import VipTradeFeesAndLimitsItem from './VipTradeFeesAndLimitsItem';

interface ITradeFeesAndLimits {
	accountLevel: EAccountLevel;
}

const TradeFeesAndLimits: FC<ITradeFeesAndLimits> = ({ accountLevel }) => {
	const feesList = useSelector(getFees);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getFeesRequest());
	}, [dispatch]);

	return (
		<div className="content-block content-block--user-management">
			<div className="content-block__inside content-block__inside--bt content-block__inside--login-his">
				<div className="table-block">
					<div className="table-wrapper">
						<div className="table table--fee-trade">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Pair</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>{accountLevel === EAccountLevel.VIP ? 'Vip' : ''} Spot Taker Fee, %</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>{accountLevel === EAccountLevel.VIP ? 'Vip' : ''} Spot Maker Fee, %</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>{accountLevel === EAccountLevel.VIP ? 'Vip' : ''} Amount Min</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>{accountLevel === EAccountLevel.VIP ? 'Vip' : ''} Amount Max</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name td-name--action">
											<p>Edit</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body">
								{feesList?.feesLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<>
										{accountLevel === EAccountLevel.REGULAR &&
											feesList?.fees?.map((item) => (
												<TradeFeesAndLimitsItem item={item} key={item.id} />
											))}
										{accountLevel === EAccountLevel.VIP &&
											feesList?.fees?.map((item) => (
												<VipTradeFeesAndLimitsItem item={item} key={item.id} />
											))}
										<div />
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TradeFeesAndLimits;
