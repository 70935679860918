import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IExternalMakerStore } from './types';

const getExternalMakerState = (state: IStoreState): IExternalMakerStore => state.externalMaker;

export const getExternalMaker = createSelector(
	[getExternalMakerState],
	(externalMaker: IExternalMakerStore) => externalMaker.externalMakerData,
);
export const getExternalMakerPairs = createSelector(
	[getExternalMakerState],
	(externalMaker: IExternalMakerStore) => externalMaker.externalMakerData?.pairs,
);
export const getExternalMakerLoader = createSelector(
	[getExternalMakerState],
	(externalMaker: IExternalMakerStore) => externalMaker.externalMakerLoader,
);

export const getOrderHistory = createSelector(
	[getExternalMakerState],
	(externalMaker: IExternalMakerStore) => externalMaker.orderHistoryData,
);
export const getOrderHistoryLoader = createSelector(
	[getExternalMakerState],
	(externalMaker: IExternalMakerStore) => externalMaker.orderHistoryLoader,
);

export const getExternalMarkerAssets = createSelector(
	[getExternalMakerState],
	(externalMaker: IExternalMakerStore) => externalMaker.externalMakerData?.assets,
);

export const getFilterParams = createSelector(
	[getExternalMakerState],
	(externalMarker: IExternalMakerStore) => externalMarker.filterParams,
);

export const getDeleteExpiredLoader = createSelector(
	[getExternalMakerState],
	(externalMarker: IExternalMakerStore) => externalMarker.deleteExpiredLoader,
);
