/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAdvertisementsP2PIsLoad,
	getAdvertisementsP2PList,
	getSelectUser,
} from 'redux/reducers/userManagement/selectors';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import { getUserAdvertisementsP2PRequest } from 'redux/reducers/userManagement/reducer';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { AdvertisementsTableHeader } from './AdvertisementsTableHeader';
import { IAdvertisementsP2PItem } from './types';
import AdvertisementsTableItem from './AdvertisementsTableItem';

export const Advertisements = () => {
	const user = useSelector(getSelectUser);
	const advertisementsP2PList = useSelector(getAdvertisementsP2PList);
	const advertisementsP2PIsLoad = useSelector(getAdvertisementsP2PIsLoad);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const handlePageChange = (pageNumber: number) => {
		if (user?.user?.p2p_created) {
			setCurrentPage(pageNumber);
			const searchParams = `?page=${String(pageNumber)}`;
			const params = {
				id,
				per_page: 15,
				current_page: pageNumber || 1,
			};
			history.push({
				pathname: location.pathname,
				search: searchParams,
			});
			dispatch(getUserAdvertisementsP2PRequest(params));
		}
	};
	useEffect(() => {
		if (user?.user?.p2p_created) {
			const params = {
				id,
				per_page: 15,
				current_page: 1,
			};
			setCurrentPage(1);
			history.push({
				pathname: location.pathname,
			});
			dispatch(getUserAdvertisementsP2PRequest(params));
		}
	}, []);

	return (
		<>
			{advertisementsP2PIsLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<>
					<AdvertisementsTableHeader />
					<div className="table-body">
						{advertisementsP2PList?.data.map((item: IAdvertisementsP2PItem) => (
							<AdvertisementsTableItem item={item} key={item.id} />
						))}
						{advertisementsP2PList?.data.length === 0 ? (
							<p style={{ padding: '20px' }}>Advertisements is empty</p>
						) : null}
						{user?.user?.p2p_created ? null : (
							<p style={{ padding: '20px' }}>User does not have p2p enabled!</p>
						)}
					</div>
				</>
			)}

			<div style={{ padding: '14px' }}>
				{(advertisementsP2PList?.last_page ?? 0) > 1 && (
					<Pagination
						pageCount={advertisementsP2PList?.last_page ?? 0}
						forcePage={currentPage - 1}
						onPageChange={handlePageChange}
					/>
				)}
			</div>
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
