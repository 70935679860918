/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAdminRequest } from 'redux/reducers/adminManagement/reducer';
import { getReferralFee } from 'redux/reducers/referral/selectors';
import usePermission from 'services/hooks/usePermission';
import { permissions as PERMISSIONS } from 'services/constants/permissions';
// import DeleteAdminModal from '../DeleteAdminModal';
import { IReferralListItemProps } from './types';

// ==========================================:
const ReferralItem: FC<IReferralListItemProps> = ({
	data,
	index,
	isAdminManagementPermissionUpdateRules,
}) => {
	const referralFee = useSelector(getReferralFee);
	const location = useLocation();
	const dispatch = useDispatch();

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(PERMISSIONS.MANAGE_ADMINS);

	const {
		user_id,
		email,
		full_name,
		referral_fee,
		referrals_count,
		total_fee_in_usdb,
		total_referrals_traded,
	} = data;

	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);

	// const handleDeleteAdmin = (): void => {
	// 	const userId: number = id;
	// 	dispatch(
	// 		deleteAdminRequest({
	// 			userId,
	// 		}),
	// 	);
	// 	history.push({
	// 		pathname: location.pathname,
	// 		search: `?page=1`,
	// 	});
	// };

	// const handleEditAdmin = (): void => {
	// 	const userId: number = id;

	// 	history.push({
	// 		pathname: `/admin-management/edit/${userId}`,
	// 		state: {
	// 			data,
	// 		},
	// 	});
	// };

	// const sortedPermissionsByName = useMemo(() => {
	// 	return (
	// 		permissions.length &&
	// 		[...permissions].sort((a, b) => {
	// 			if (a.name < b.name) {
	// 				return -1;
	// 			}
	// 			if (a.name > b.name) {
	// 				return 1;
	// 			}
	// 			return 0;
	// 		})
	// 	);
	// }, [permissions]);

	return (
		<div className="tr">
			<Link className="td" to={`/user-management/${String(user_id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{user_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Full name</p>
				<p>{full_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<p>{email}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Referral fee %</p>
				<p>{referral_fee || referralFee?.fee}</p>
			</div>
			<Link className="td" to={{ pathname: `/referral/${String(user_id)}`, state: full_name }}>
				<p className="td-hidden-name">Referral quantity</p>
				<p>{referrals_count}</p>
			</Link>

			<div className="td">
				<p className="td-hidden-name">Total referrals (USDb)</p>
				<p>{total_referrals_traded}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Total Fee in USDb</p>
				<p>{total_fee_in_usdb}</p>
			</div>
		</div>
	);
};

export default ReferralItem;
