import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeVolumeDependsFeeSettingsRequest } from 'redux/reducers/fees/reducer';
import { noExponent, numberFormat8Fraction } from 'utils/numberFormat';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IChangeTradeFeesAndLimitsItemProps } from '../types';

const VolumeDependsFeeSettingsItem: FC<IChangeTradeFeesAndLimitsItemProps> = ({ item }) => {
	const [amountMin, setAmountMin] = useState(String(item.min_amount));
	const [amountMax, setAmountMax] = useState(String(item.max_amount));
	const [makerFee, setMakerFee] = useState(String(item.maker_fee));
	const [takerFee, setTakeFee] = useState(String(item.taker_fee));
	const [totalMakerFee, setTotalMakerFee] = useState(String(item.total_maker_fee));
	const [totalTakerFee, setTotalTakerFee] = useState(String(item.total_taker_fee));

	const [isClosed, setIsClosed] = useState(false);
	const dispatch = useDispatch();
	const { checkEditable } = usePermission();
	const canEdit = checkEditable(permissions.SET_FEES);

	const handleTradeFeesAndLimitsSubmit = () => {
		if (
			makerFee === '' ||
			takerFee === '' ||
			amountMin === '' ||
			amountMax === '' ||
			totalMakerFee === '' ||
			totalTakerFee === ''
		)
			return;
		dispatch(
			changeVolumeDependsFeeSettingsRequest({
				id: item.id,
				maker_fee: Number(makerFee),
				taker_fee: Number(takerFee),
				min_amount: Number(amountMin),
				max_amount: Number(amountMax),
				vip_min_amount: item.vip_min_amount,
				vip_max_amount: item.vip_max_amount,
				vip_maker_fee: item.vip_maker_fee,
				vip_taker_fee: item.vip_taker_fee,
			}),
		);
	};
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Level</p>
				{item.level}
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Amount Min</p>
				{isClosed ? <div /> : <p>{numberFormat8Fraction(Number(amountMin), 'en-En')}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="number"
						value={noExponent(amountMin)}
						onChange={(e) => {
							setAmountMin(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Amount Max</p>
				{isClosed ? <div /> : <p>{numberFormat8Fraction(Number(amountMax), 'en-En')}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="number"
						value={amountMax}
						onChange={(e) => {
							setAmountMax(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Taker Fee, %</p>
				{isClosed ? <div /> : <p>{takerFee}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="number"
						value={takerFee}
						onChange={(e) => {
							setTakeFee(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Maker Fee, %</p>
				{isClosed ? <div /> : <p>{makerFee}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="number"
						value={makerFee}
						onChange={(e) => {
							setMakerFee(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Total Taker Fee, %</p>
				<p>{totalTakerFee}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Total Maker Fee, %</p>
				<p>{totalMakerFee}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					<button
						type="button"
						className={`${isClosed ? '' : 'disable'}`}
						onClick={() => {
							setTakeFee(String(item.taker_fee));
							setMakerFee(String(item.maker_fee));
							setTotalMakerFee(String(item.total_maker_fee));
							setTotalTakerFee(String(item.total_taker_fee));
							setAmountMin(String(item.min_amount));
							setAmountMax(String(item.max_amount));
							setIsClosed(!isClosed);
						}}
					>
						<span className="edit-icon icon-cancel-icon" />
					</button>
					{isClosed ? (
						<button
							type="button"
							onClick={() => {
								handleTradeFeesAndLimitsSubmit();
							}}
							className={
								makerFee === '' || takerFee === '' || amountMin === '' || amountMax === ''
									? 'button-not-active'
									: ''
							}
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					) : (
						<button type="submit" disabled={!canEdit} onClick={() => setIsClosed(!isClosed)}>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default VolumeDependsFeeSettingsItem;
