import { FC, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { password, confirmPassword } from 'utils/formValidation';
import Input from 'ui/Formik/Input';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IChangePassForm, IChangePassFormValue } from './types';

// ================================================:
const ChangePassForm: FC<IChangePassForm> = ({ changePassFormSubmit }) => {
	const [isShowOldPass, setIsShowOldPass] = useState(false);
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowPassConfirm, setIsShowPassConfirm] = useState(false);

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.CHANGE_PASSWORD);

	const initialValues = {
		old_password: '',
		password: '',
		password_confirmation: '',
	};

	const validationSchema = yup.object().shape({
		old_password: password,
		password,
		password_confirmation: confirmPassword,
	});

	const handleOldPassDisplay = (): void => {
		setIsShowOldPass(!isShowOldPass);
	};

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handlePassConfirmDisplay = (): void => {
		setIsShowPassConfirm(!isShowPassConfirm);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IChangePassFormValue, { setSubmitting, resetForm }) => {
				changePassFormSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, setFieldValue }) => {
				return (
					<Form>
						<div className="form__content">
							<Field
								title="Old password"
								type={isShowOldPass ? 'text' : 'password'}
								placeholder="Password"
								name="old_password"
								required
								component={Input}
								ariaLabel="show-old-password"
								showPass={setIsShowOldPass}
								setIsShowPass={handleOldPassDisplay}
								setValue={() => {
									setFieldValue('old_password', '');
								}}
								fieldIcon="icon-password-icon"
								disabled={!canEdit}
							/>

							<Field
								title="New password"
								type={isShowPass ? 'text' : 'password'}
								placeholder="New password"
								name="password"
								required
								component={Input}
								ariaLabel="show-new-password"
								showPass={setIsShowPass}
								setValue={() => {
									setFieldValue('password', '');
								}}
								setIsShowPass={handlePassDisplay}
								fieldIcon="icon-password-icon"
								disabled={!canEdit}
							/>

							<Field
								title="Confirm password"
								type={isShowPassConfirm ? 'text' : 'password'}
								placeholder="Confirm password"
								name="password_confirmation"
								required
								component={Input}
								ariaLabel="show-confirm-password"
								showPass={setIsShowPassConfirm}
								setValue={() => {
									setFieldValue('password_confirmation', '');
								}}
								setIsShowPass={handlePassConfirmDisplay}
								fieldIcon="icon-password-icon"
								disabled={!canEdit}
							/>
						</div>

						<div className="form-submit form-submit--settings">
							<button
								type="submit"
								disabled={!(isValid && dirty) || isSubmitting || !canEdit}
								className="button button--full-width button--rounded"
							>
								Save
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default ChangePassForm;
