export function convertCamelCaseToWords(str: string) {
	return str
		.split('')
		.reduce((acc: string[], curr: string, idx) => {
			if ((curr !== curr.toLowerCase() || /\d/.test(curr)) && idx > 0) {
				return [...acc, ' ', curr];
			}
			return [...acc, curr];
		}, [])
		.join('')
		.replaceAll('2 F A', '2FA')
		.replaceAll('P 2 P', 'P2P')
		.replaceAll('F A Q', 'FAQ');
}
