import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import { IPagination, IHandlePage } from './types';

// ================================================:
const Pagination: FC<IPagination> = (props) => {
	const { pageCount, forcePage, onPageChange, type } = props;
	const history = useHistory();
	const { search } = useLocation();
	const { page, action, crc, startDate, endDate, text } = queryString.parse(search);

	const handlePage = (propsValue: IHandlePage) => {
		const { selected } = propsValue;

		const actionParam = `&action=${String(action)}`;
		const currencyParam = `&crc=${String(crc)}`;
		const startDateParam = `&startDate=${String(startDate)}`;
		const endDateParam = `&endDate=${String(endDate)}`;

		const textParam = Number(text?.length) >= 3 ? `&text=${String(text)}` : '';

		const param = `${(action && actionParam) || ''}${(crc && currencyParam) || ''}${
			(startDate && startDateParam) || ''
		}${(endDate && endDateParam) || ''}${(text && textParam) || ''}`;

		if (type === 'user-transaction') {
			history.push(`?page=${Number(page)}&userpage=${Number(selected) + 1}${param}`);
		} else {
			history.push(`?page=${Number(selected) + 1}${param}`);
		}

		return onPageChange(Number(selected) + 1);
	};

	return (
		<div className="pagination-block">
			<ReactPaginate
				pageCount={pageCount}
				pageRangeDisplayed={2}
				marginPagesDisplayed={1}
				containerClassName="pagination"
				nextLabel={
					<span className="arrow">
						<svg
							width="14px"
							height="14px"
							viewBox="0 0 1024 1024"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							overflow="hidden"
						>
							<path d="M343.552 66.048l-72.704 72.704L644.608 512l-373.76 373.248 72.704 72.704L788.992 512 343.552 66.048z" />
						</svg>
					</span>
				}
				previousLabel={
					<span className="arrow">
						<svg
							width="14px"
							height="14px"
							viewBox="0 0 1024 1024"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							overflow="hidden"
						>
							<path d="M699.7 63.9l72.8 72.8L397.1 512l375.4 375.3-72.8 72.8L251.5 512z" />
						</svg>
					</span>
				}
				activeClassName="active"
				forcePage={forcePage}
				onPageChange={handlePage}
			/>
		</div>
	);
};

export default Pagination;
