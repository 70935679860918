/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getBankTransfers } from 'redux/reducers/withdrawals/selectors';
import { IGetBankTransferPayload } from 'redux/reducers/withdrawals/types';
import { getBankTransferRequest } from 'redux/reducers/withdrawals/reducer';
import WithdrawalList from './WithdrawalList';

// ==========================================:
const BankTransfer: FC = () => {
	const bankTransfers = useSelector(getBankTransfers);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();

	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params: IGetBankTransferPayload = {
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getBankTransferRequest(params));
	};

	const fetchBankTransfer = useCallback(() => {
		dispatch(getBankTransferRequest({ current_page: currentPage }));
	}, [dispatch]);

	useEffect(() => {
		fetchBankTransfer();
	}, [fetchBankTransfer]);

	return (
		<>
			<div className="count-label" />
			<WithdrawalList bankTransfers={bankTransfers?.data} onRefetch={fetchBankTransfer} />

			{(bankTransfers?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={bankTransfers?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default BankTransfer;
