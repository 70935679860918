import { FC, useState } from 'react';

import { spotOrdersHistoryStatus } from 'redux/reducers/transactions/constants';
import { noExponent } from 'utils/numberFormat';
import { IOrdersHistoryProps } from './types';

const SpotOrderHistory: FC<IOrdersHistoryProps> = ({ item }) => {
	const [id] = useState(item.id);
	const [pair] = useState(item.pair);
	const [type] = useState(item.type);
	const [amount] = useState(item.quantity);
	const [total] = useState(item.total);
	const [price] = useState(item.price);
	const [filling] = useState(item.filling);
	const [created] = useState(item.created_at);
	const date = new Date(created * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+created + offset) * 1000);
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				{id}
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Pair</p>
				<p style={{ textTransform: 'uppercase' }}>{pair.replace('_', '/')}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Type</p>
				<p>{type.replace('_', ' ')}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Filling</p>
				<p>{filling}%</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Total</p>
				<p>{noExponent(Number(total).toString())}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Price</p>
				<p>{price !== null ? noExponent(Number(price).toString()) : '-'}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Average price</p>
				<p>{noExponent(item.average)}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={`status ${spotOrdersHistoryStatus?.[item.status]?.statusClass ?? ''}`}>
						{spotOrdersHistoryStatus?.[item.status]?.statusText}
					</span>
				</div>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Created</p>
				<p>{dateOffset.toLocaleDateString('en-GB')}</p>
			</div>
		</div>
	);
};

export default SpotOrderHistory;
