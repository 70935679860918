/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	TYPE_TRANSACTIONS_CRYPTO,
	TYPE_TRANSACTIONS_FIAT,
} from 'redux/reducers/transactions/constants';
import { getAssetsRequest } from 'redux/reducers/tradingPairs/reducer';
import {
	getCryptoTransactionsRequest,
	getFiatTransactionsRequest,
} from 'redux/reducers/transactions/reducer';
import { setUsers } from 'redux/reducers/userManagement/reducer';
import { PlusIcon } from 'assets/img/icons';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { Crypto } from '../Crypto';
import { Fiat } from '../Fiat';
import { CreateTransaction } from '../Popups/CreateTransaction';
import { Success } from '../Popups/Success/Success';
import { IDepositProps } from './types';

export const Deposit: FC<IDepositProps> = ({ setTypeTransactions }) => {
	const dispatch = useDispatch();
	const [typeDeposit, setTypeDeposit] = useState<'crypto' | 'fiat'>('crypto');
	const [openModal, setOpenModal] = useState(false);
	const [successedModal, setSuccessedModal] = useState(false);

	const { checkReadable, checkEditable } = usePermission();

	useEffect(() => {
		dispatch(getAssetsRequest());
	}, [dispatch]);

	const createTransactionVisible =
		(typeDeposit === 'crypto' && checkEditable(permissions.MANAGE_CRYPTO_TRANSFERS)) ||
		(typeDeposit === 'fiat' && checkEditable(permissions.MANAGE_FIAT_BANK_TRANSFERS));

	return (
		<>
			<div className="content-block content-block--user-management">
				<div className="content-block content-block--transaction">
					<div className="content-block__inside content-block__inside--deposit-his">
						<div className="deposit-history deposit-history--filter">
							<ul className="deposit-history__list">
								{checkReadable(permissions.MANAGE_CRYPTO_TRANSFERS) && (
									<li
										className={`deposit-history__item ${
											typeDeposit === TYPE_TRANSACTIONS_CRYPTO ? 'active' : ''
										}`}
									>
										<a
											className="deposit-history__link"
											onClick={() => {
												setTypeDeposit(TYPE_TRANSACTIONS_CRYPTO);
												setTypeTransactions(TYPE_TRANSACTIONS_CRYPTO);
											}}
										>
											Crypto
										</a>
									</li>
								)}
								{checkReadable(permissions.MANAGE_FIAT_BANK_TRANSFERS) && (
									<li
										className={`deposit-history__item ${
											typeDeposit === TYPE_TRANSACTIONS_FIAT ? 'active' : ''
										}`}
									>
										<a
											className="deposit-history__link"
											onClick={() => {
												setTypeDeposit(TYPE_TRANSACTIONS_FIAT);
												setTypeTransactions(TYPE_TRANSACTIONS_FIAT);
											}}
										>
											Fiat
										</a>
									</li>
								)}
							</ul>
							{createTransactionVisible && (
								<div className="transaction-filter">
									<button
										onClick={() => setOpenModal(true)}
										className="button button--type3 button--br-12"
										type="button"
									>
										<span className="btn-icon">
											<PlusIcon />
										</span>
										Create Transaction
									</button>
								</div>
							)}
						</div>
					</div>
					<div className="content-block__inside content-block__inside--login-his">
						{typeDeposit === TYPE_TRANSACTIONS_CRYPTO ? <Crypto /> : <Fiat />}
					</div>
				</div>
			</div>
			{createTransactionVisible && (
				<>
					<CreateTransaction
						openModal={openModal}
						closeModal={() => {
							setOpenModal(false);
							dispatch(setUsers(null));
						}}
						onSuccessed={() => {
							typeDeposit === 'crypto'
								? dispatch(getCryptoTransactionsRequest({}))
								: dispatch(getFiatTransactionsRequest({}));
							setSuccessedModal(true);
						}}
						type={typeDeposit}
					/>
					<Success
						openModal={successedModal}
						closeModal={() => {
							setSuccessedModal(false);
							dispatch(setUsers(null));
						}}
					/>
				</>
			)}
		</>
	);
};
