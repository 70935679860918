/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCryptoDepositIsLoad,
	getCryptoDepositList,
} from 'redux/reducers/userManagement/selectors';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import { getUserCryptoDepositRequest } from 'redux/reducers/userManagement/reducer';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { CryptoWithdrawalTableHeader } from './CryptoWithdrawalTableHeader';
import { ICryptoDepositItem } from './types';
import CryptoWithdrawalTableItem from './CryptoWithdrawalTableItem';

export const CryptoWithdrawal = () => {
	const cryptoDepositList = useSelector(getCryptoDepositList);
	const cryptoDepositIsLoad = useSelector(getCryptoDepositIsLoad);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			id,
			type: 'withdrawal',
			per_page: 15,
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getUserCryptoDepositRequest(params));
	};

	useEffect(() => {
		const params = {
			id,
			type: 'withdrawal',
			per_page: 15,
			current_page: 1,
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getUserCryptoDepositRequest(params));
	}, []);

	return (
		<>
			{cryptoDepositIsLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<>
					<CryptoWithdrawalTableHeader />
					<div className="table-body">
						{cryptoDepositList?.data.map((item: ICryptoDepositItem) => (
							<CryptoWithdrawalTableItem item={item} key={item.id} />
						))}
						{cryptoDepositList?.data.length === 0 ? (
							<p style={{ padding: '20px' }}>Crypto withdraw is empty</p>
						) : null}
					</div>
				</>
			)}

			<div style={{ padding: '14px' }}>
				{(cryptoDepositList?.last_page ?? 0) > 1 && (
					<Pagination
						pageCount={cryptoDepositList?.last_page ?? 0}
						forcePage={currentPage - 1}
						onPageChange={handlePageChange}
					/>
				)}
			</div>
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
