import { createSelector } from '@reduxjs/toolkit';
import { IGeneralStore } from './types';

const getCurrenciesState = (state: any): IGeneralStore => state.currencies;

export const getCurrenciesList = createSelector(
	[getCurrenciesState],
	(trades: IGeneralStore) => trades.general,
);
export const getCurrenciesLoading = createSelector(
	[getCurrenciesState],
	(trades: IGeneralStore) => trades.generalLoading,
);
export const getP2PList = createSelector(
	[getCurrenciesState],
	(trades: IGeneralStore) => trades.p2p,
);
export const getP2PLoading = createSelector(
	[getCurrenciesState],
	(trades: IGeneralStore) => trades.p2pLoading,
);
