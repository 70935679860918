/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	deleteExternalPairRequest,
	patchExternalMakerUppdateStatusRequest,
} from 'redux/reducers/externalMaker/reducer';
import { IExternalMakerUpdatePayload } from 'redux/reducers/externalMaker/types';
import DeletePairModal from 'components/ExternalMakerBot/DeletePairModal';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { IItemParams } from './types';

export const PairsItem: FC<IItemParams> = ({ data, onOpenEditPair }) => {
	const [isDeletePairModalOpen, setIsDeletePairModalOpen] = useState(false);
	const dispatch = useDispatch();

	const handleChangePairStatus = () => {
		const params: IExternalMakerUpdatePayload = {
			id: data.id,
			body: {
				is_active: !!data.is_active === true ? 0 : 1,
			},
		};
		dispatch(patchExternalMakerUppdateStatusRequest(params));
	};

	const handleOpenEditPopup = () => {
		onOpenEditPair(data);
	};

	const handleDeletePair = () => {
		dispatch(deleteExternalPairRequest({ pair_id: data.id }));
	};

	const handleOpenDeleteModalStatus = () => {
		setIsDeletePairModalOpen(true);
	};

	const handleCloseDeleteModalStatus = () => {
		setIsDeletePairModalOpen(false);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Provider Name</p>
				<p style={{ whiteSpace: 'nowrap' }}>{capitalizeFirstLetter(data.provider_name)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency Pair</p>
				<p>{`${data.currency_pair_first.code}/${data.currency_pair_second.code}`.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Order type</p>
				<p className="copy-button__text">
					{data.order_type === 'market' ? 'Market order' : 'Limit Order'}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Timing Frequency (Sec)</p>
				<p>{data.timing_frequency}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Sizing</p>
				{data.sizing_type === 'random' ? (
					<p>{`${data.sizing_from} - ${data.sizing_to}`}</p>
				) : (
					<p>{data.sizing_to}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Pricing</p>
				<p>{data.pricing_type === 'auto' ? 'Auto' : data.price}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">On/Off</p>
				<div className="switch switch--type2">
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={!!data.is_active}
							onChange={() => handleChangePairStatus()}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					<button type="button" onClick={handleOpenEditPopup}>
						<span className="edit-icon icon-edit-icon" />
					</button>
					<button type="button" onClick={handleOpenDeleteModalStatus}>
						<span className="trash-icon trash-icon--action icon-trash-icon" />
					</button>
					<DeletePairModal
						isModalOpen={isDeletePairModalOpen}
						closeModal={handleCloseDeleteModalStatus}
						onDeletePair={handleDeletePair}
					/>
				</div>
			</div>
		</div>
	);
};
