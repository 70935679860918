/* eslint-disable react/button-has-type */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserBlockUnblockRequest } from 'redux/reducers/userManagement/reducer';
import {
	approveWithdrawalsRequest,
	rejectWithdrawalsRequest,
} from 'redux/reducers/withdrawals/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { RejectWithdrawalsModal } from './RejectWithdrawalsModal';
import { IActionsButtonProps } from './types';
import { AcceptWithdrawalsModal } from './AcceptWithdrawalsModal';

export const ActionsButtons: FC<IActionsButtonProps> = ({ id, handleClearSearch }) => {
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);
	const [openModalAccept, setOpenModalAccept] = useState(false);
	const { checkEditable } = usePermission();

	const closeModal = () => setOpenModal(false);
	const closeModalAccept = () => setOpenModalAccept(false);

	const handleApprove = () => {
		if (!checkEditable(permissions.MANAGE_WITHDRAWALS)) return;
		dispatch(approveWithdrawalsRequest({ apiParams: { id } }));
		handleClearSearch();
	};
	const handleReject = (comment: string) => {
		if (!checkEditable(permissions.MANAGE_WITHDRAWALS)) return;
		dispatch(rejectWithdrawalsRequest({ apiParams: { id, comment } }));
		handleClearSearch();
	};

	return (
		<div className="table-buttons">
			<button
				disabled={!checkEditable(permissions.MANAGE_WITHDRAWALS)}
				onClick={() => setOpenModal(true)}
			>
				<span className="edit-icon icon-cancel-icon" />
			</button>
			<button
				disabled={!checkEditable(permissions.MANAGE_WITHDRAWALS)}
				onClick={() => setOpenModalAccept(true)}
			>
				<span className="edit-icon icon-succes-icon" />
			</button>
			<RejectWithdrawalsModal
				openModal={openModal}
				closeModal={closeModal}
				handleRejectWithdrawal={handleReject}
			/>
			<AcceptWithdrawalsModal
				openModal={openModalAccept}
				closeModal={closeModalAccept}
				handleRejectWithdrawal={handleApprove}
			/>
		</div>
	);
};
