import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import UnidentifiedDeposits from 'components/UnidentifiedDeposits';

// ================================================:
const UnidentifiedDepositsPage: FC = () => {
	return (
		<Dashboard title="Unidentified deposits">
			<UnidentifiedDeposits />
		</Dashboard>
	);
};

export default UnidentifiedDepositsPage;
