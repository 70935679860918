import { FC } from 'react';
import Loader from 'ui/Loader';
import { useSelector } from 'react-redux';
import { getUsersIsLoad } from 'redux/reducers/userManagement/selectors';
import UserManagementItem from './UserManagementItem';
import { IUserManagementListProps } from './types';

// ==========================================:
const UserManagementList: FC<IUserManagementListProps> = ({ users, handleClearSearch }) => {
	const userManagementListLoader = useSelector(getUsersIsLoad);

	return (
		<div className="content-block">
			<div className="content-block__inside content-block__inside--type2">
				<div className="table-block table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--user-management-business table--with-scroll">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>ID</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>P2P nickname</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Company name</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Full name</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Level</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>E-mail</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Phone number</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Country</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Invited By</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name td-name--status">
											<p>Status</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name td-name--action">
											<p>Registration date</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name td-name--action">
											<p>Verification date</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>
												Referral <br /> fess %
											</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name td-name--action">
											<p>Edit</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name td-name--action">
											<p>Action</p>
										</div>
									</div>
								</div>
							</div>
							{!users?.length && !userManagementListLoader && (
								<div className="user-management-empty-data">
									<p className="user-management-empty__text">No users found...</p>
								</div>
							)}
							{userManagementListLoader ? (
								<div className="list-loader">
									<Loader />
								</div>
							) : (
								<div className="table-body">
									{users?.map((item) => (
										<UserManagementItem
											key={item.id}
											data={item}
											handleClearSearch={handleClearSearch}
										/>
									))}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserManagementList;
