/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
	getReferralTransactionsList,
	getReferralTransactionsIsLoad,
} from 'redux/reducers/transactions/selectors';
import Loader from 'ui/Loader';
import { ReferralItem } from './ReferralItem';

export const Referral: FC = () => {
	const referral = useSelector(getReferralTransactionsList);
	const referralLoader = useSelector(getReferralTransactionsIsLoad);

	return (
		<div className="content-block content-block--transaction">
			<div className="content-block__inside content-block__inside--login-his">
				{(referral?.data?.length ?? 0) < 1 && !referralLoader && (
					<div className="user-management-empty-data">
						<p className="user-management-empty__text">Referral not found...</p>
					</div>
				)}
				{referralLoader && (
					<div className="list-loader">
						<Loader />
					</div>
				)}
				{(referral?.data?.length ?? 0) > 0 && !referralLoader ? (
					<div className="table-block">
						<div className="table-wrapper">
							<div className="table table--transaction-referral table--with-scroll">
								<div className="table-header">
									<div className="tr">
										<div className="td">
											<div className="td-name">
												<p>Refrral ID</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Referral Fee</p>
											</div>
										</div>

										<div className="td">
											<div className="td-name">
												<p>Referral fee in USD</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Data</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>User ID 1</p>
											</div>
										</div>

										<div className="td">
											<div className="td-name">
												<p>User ID 2</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Pair</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Side</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Matching order ID</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Order ID</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Executed</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Fee</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Bitanica Fee</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Bitanica Fee USD</p>
											</div>
										</div>
										<div className="td td--right">
											<div className="td-name">
												<p>Amount</p>
											</div>
										</div>
									</div>
								</div>

								<div className="table-body">
									{referral?.data.map((item) => (
										<ReferralItem data={item} key={item.created_at} />
									))}
								</div>
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};
