/* eslint-disable no-debugger */
import { FC, useState } from 'react';
import { ArrowDownIcon } from 'assets/img/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslates } from 'redux/reducers/translates/selectors';
import { setCurrentIndex } from 'redux/reducers/translates/reducer';
import { ITranslates } from 'redux/reducers/translates/types';
import { getValueByPath } from 'utils/object';
import { convertCamelCaseToWords } from '../../utils';
import { ITopLevelSection } from './types';

const TopLevelSection: FC<ITopLevelSection> = ({ sectionPath, index, path, setPath, level }) => {
	const dispatch = useDispatch();
	const sectionPathParts = sectionPath.split('.');
	const [open, setOpen] = useState(false);
	const translates = useSelector(getTranslates);

	const toggleOpen = () => {
		dispatch(setCurrentIndex(index));
		setOpen((prev) => !prev);
		setPath(sectionPath);
	};

	const targetPart = translates?.[index] && getValueByPath(translates?.[index], sectionPath);

	// Отфильтровываем следующий уровень вложенности, значения которых не являются строками
	// eslint-disable-next-line prefer-const
	let innerKeys = Object.keys(targetPart || {}).filter(
		(k) => typeof (targetPart as ITranslates)?.[k] !== 'string',
	);

	// Список внутренних ключей с уровнями вложенности больше 1
	const childInnerKeys = innerKeys.reduce((acc: string[], curr: string) => {
		const innerKeys2 = Object.keys(targetPart?.[curr]);
		if (innerKeys2.filter((k) => typeof targetPart?.[curr]?.[k] !== 'string').length > 0) {
			return [...acc, curr];
		}
		return acc;
	}, []);

	return (
		<ul className="translation-bar-list active">
			<li>
				<button type="button" onClick={toggleOpen}>
					{convertCamelCaseToWords(sectionPathParts[sectionPathParts.length - 1])}
					{innerKeys.length > 0 && childInnerKeys.length > 0 && (
						<span className="bar-list-arrow">
							<ArrowDownIcon />
						</span>
					)}
				</button>
				{open &&
					innerKeys.length > 0 &&
					childInnerKeys.length > 0 &&
					innerKeys.map((k) => (
						<ul className="translation-bar-sublist" style={{ paddingLeft: level * 40 }}>
							<li>
								<TopLevelSection
									key={k}
									sectionPath={`${sectionPath}.${k}`}
									index={index}
									path={path}
									setPath={setPath}
									level={level + 1}
								/>
							</li>
						</ul>
					))}
			</li>
		</ul>
	);
};

export default TopLevelSection;
