/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { useSelect } from 'services/hooks/useSelect';
import { ISelectCurrency } from './types';

const orderTypes = ['Market order', 'Limit Order'];

const SelectOrderType: FC<ISelectCurrency> = (props) => {
	const {
		field: { name, value },
		form: { setFieldValue },
		className = '',
	} = props;

	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (type: string) => {
		if (type === 'Market order') {
			setFieldValue('pricing_type', 'auto');
			setFieldValue('price', 1);
		}
		setFieldValue(name, type.toLowerCase().split(' ')[0]);
		setOpen(false);
	};

	const selectedOrderType = orderTypes.find((item) => item.toLowerCase().split(' ')[0] === value);

	const handleSelectClick = () => {
		toggleOpen();
	};

	return (
		<div
			className={`select select--regular2 select--height-48 ${open ? 'active' : ''} ${className}`}
		>
			<button
				type="button"
				className={`select__current ${selectedOrderType ? '' : 'select__current--unselect'}`}
				ref={triggerRef}
				onClick={handleSelectClick}
			>
				<span className="select__current-text">{selectedOrderType || 'Select'}</span>
				<span className="select__current-arrow">
					<svg
						className="stroke"
						width="8"
						height="5"
						viewBox="0 0 8 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
					</svg>
				</span>
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select-drop__item">
						<ul>
							{orderTypes?.map((item, index) => (
								<li key={index}>
									<button type="button" onClick={() => handleSelect(item)}>
										{item}
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectOrderType;
