/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IMarketMakerPageRequestPayload,
	IPairsSettingsItemsEdit,
	IPairsSettingsStore,
	IDeletePairBotPayload,
} from './types';
// ==========================================:

export const initialState: IPairsSettingsStore = {
	global_enabled: null,
	pairs_settings: null,
	marketMakerLoader: false,
	total: null,
	render: false,
	per_page: null,
	last_page: null,
	current_page: null,
	deletePairLoader: false,
};

// ==========================================:

const marketMakerBot = createSlice({
	name: '@@marketMakerBot',
	initialState,
	reducers: {
		getMarketMakerRequest: (state, action: PayloadAction<IMarketMakerPageRequestPayload>) => {
			const marketMakerRequestState = state;
			marketMakerRequestState.marketMakerLoader = true;
		},
		getMarketMakerRequestSuccess: (state, action: any) => {
			const { payload } = action;
			const marketMakerRequestState = state;
			marketMakerRequestState.global_enabled = payload.global_enabled;
			marketMakerRequestState.pairs_settings = payload.pairs_settings;

			marketMakerRequestState.total = payload.total;
			marketMakerRequestState.per_page = payload.per_page;
			marketMakerRequestState.last_page = payload.last_page;
			marketMakerRequestState.current_page = payload.current_page;

			marketMakerRequestState.marketMakerLoader = false;
		},
		putMarketMakerBotToggleRequest: (state) => {},
		putMarketMakerBotToggleSuccess: (state, action: any) => {
			const { payload } = action;
			const marketMakerRequestState = state;
			marketMakerRequestState.global_enabled = payload.enabled;
		},
		putMarketMakerBotEditSettingsRequest: (
			state,
			action: PayloadAction<IPairsSettingsItemsEdit>,
		) => {
			const marketMakerRequestState = state;
			marketMakerRequestState.marketMakerLoader = true;
		},
		renderRequest: (state) => {
			const marketMakerRequestState = state;
			marketMakerRequestState.render = !marketMakerRequestState.render;
		},
		deletePairBotRequest: (state, action: PayloadAction<IDeletePairBotPayload>) => {
			const deleteAdminState = state;

			deleteAdminState.deletePairLoader = true;
		},
		deletePairBotSuccess: (state) => {
			const deleteAdminState = state;

			deleteAdminState.deletePairLoader = false;
		},
		marketMakerBotInitState: () => initialState,
	},
});
export default marketMakerBot.reducer;
export const {
	marketMakerBotInitState,
	getMarketMakerRequest,
	getMarketMakerRequestSuccess,
	putMarketMakerBotToggleRequest,
	putMarketMakerBotToggleSuccess,
	putMarketMakerBotEditSettingsRequest,
	renderRequest,
	deletePairBotRequest,
	deletePairBotSuccess,
} = marketMakerBot.actions;
