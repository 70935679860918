/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { deleteAdminRequest } from 'redux/reducers/adminManagement/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions as PERMISSIONS } from 'services/constants/permissions';
import DeleteAdminModal from '../DeleteAdminModal';
import { IAdminManagementListItemProps } from './types';

// ==========================================:
const AdminManagementItem: FC<IAdminManagementListItemProps> = ({
	data,
	index,
	isAdminManagementPermissionUpdateRules,
}) => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(PERMISSIONS.MANAGE_ADMINS);

	const { id, email, first_name, last_name, created_at, permissions } = data;

	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);

	const handleDeleteAdmin = (): void => {
		const userId: number = id;
		dispatch(
			deleteAdminRequest({
				userId,
			}),
		);
		history.push({
			pathname: location.pathname,
			search: `?page=1`,
		});
	};

	const handleEditAdmin = (): void => {
		const userId: number = id;

		history.push({
			pathname: `/admin-management/edit/${userId}`,
			state: {
				data,
			},
		});
	};

	const sortedPermissionsByName = useMemo(() => {
		return (
			permissions.length &&
			[...permissions].sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			})
		);
	}, [permissions]);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">&#8470;</p>
				<p>{index}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Admin ID</p>
				<p>{id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Full name</p>
				<p>
					{first_name} {last_name}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">E-mail</p>
				<p>{email}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Permissions</p>
				<div className="permissions admin-info-dropdown">
					<p>Permissions</p>
					<span className="permissions-icon permissions-icon--action icon-info-icon" />
					<div className="admin-info-dropdown__menu">
						<div className="admin-info-dropdown__list">
							{sortedPermissionsByName && sortedPermissionsByName?.length
								? sortedPermissionsByName.map((item) => (
										<span key={item.id} className="admin-info-dropdown__item">
											{item?.name}
										</span>
								  ))
								: null}

							{sortedPermissionsByName && sortedPermissionsByName?.length === 0 && (
								<span className="admin-info-dropdown__item">
									There are no permissions for this user
								</span>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Registration date</p>
				<p>{created_at}</p>
			</div>
			{isAdminManagementPermissionUpdateRules && (
				<div className="td">
					<p className="td-hidden-name">Edit</p>
					<div className="table-buttons">
						<button type="button" disabled={!canEdit} onClick={handleEditAdmin}>
							<span className="edit-icon edit-icon--action icon-edit-icon" />
						</button>
						<button
							type="button"
							disabled={!canEdit}
							onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
						>
							<span className="trash-icon trash-icon--action icon-trash-icon" />
						</button>
						<DeleteAdminModal
							openModal={openModal}
							closeModal={closeModal}
							handleDeleteAdmin={handleDeleteAdmin}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default AdminManagementItem;
