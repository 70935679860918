/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getBalancesP2PIsLoad,
	getBalancesP2PList,
	getSelectUser,
} from 'redux/reducers/userManagement/selectors';
import { useParams } from 'react-router';
import { getUserBalancesP2PRequest } from 'redux/reducers/userManagement/reducer';
import { IBalancesP2PItem } from 'redux/reducers/userManagement/types';
import Loader from 'ui/Loader';
import { BalancesTableHeader } from './BalancesTableHeader';
import BalancesTableItem from './BalancesTableItem';

export const Balances = () => {
	const user = useSelector(getSelectUser);
	const advertisementsP2PList = useSelector(getBalancesP2PList);
	const advertisementsP2PIsLoad = useSelector(getBalancesP2PIsLoad);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const [openWhitelisting, setOpenWhitelisting] = useState(false);

	useEffect(() => {
		if (user?.user?.p2p_created) {
			const params = {
				id,
			};
			dispatch(getUserBalancesP2PRequest(params));
		}
	}, []);

	return (
		<>
			{advertisementsP2PIsLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<>
					<BalancesTableHeader />
					<div className="table-body">
						{advertisementsP2PList?.map((item: IBalancesP2PItem) => (
							<BalancesTableItem item={item} key={item.id} />
						))}
						{advertisementsP2PList?.length === 0 ? (
							<p style={{ padding: '20px' }}>Balances is empty</p>
						) : null}
						{user?.user?.p2p_created ? null : (
							<p style={{ padding: '20px' }}>User does not have p2p enabled!</p>
						)}
					</div>
				</>
			)}

			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
