import { FC, useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { TStakingResourceType } from 'redux/reducers/staking/types';
import { getStakeLoading } from 'redux/reducers/staking/selectors';
import { IStakingModal } from './types';

const StakingModal: FC<IStakingModal> = ({ onClose, isUnstaking, onSubmit }) => {
	const [amount, setAmount] = useState('');
	const [resourceType, setResourceType] = useState<TStakingResourceType>('energy');
	const stakeLoading = useSelector(getStakeLoading);

	const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (!/^(?:\d+|\d*\.\d*|\.)?$/.test(value)) {
			return;
		}

		setAmount(value);
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup ">
					<button type="button" className="popup__close" onClick={onClose}>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15 3L3 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3 3L15 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title ">{isUnstaking ? 'Unstaking' : 'Staking'}</p>
					</div>
					<div className="popup-body popup-body--type2">
						<div className="input ">
							<div className="input-wrapper">
								<input
									className="input-item input-item--right-icon"
									type="number"
									placeholder="Enter amount"
									value={amount}
									onChange={handleAmountChange}
								/>
								<div className="input-info">
									<span className="input-info__currency input-info__currency--type2">TRX</span>
								</div>
							</div>
						</div>
						<div className="switcher">
							<button
								type="button"
								className={`switcher__item ${resourceType === 'energy' ? 'active' : ''}`}
								onClick={() => setResourceType('energy')}
							>
								Energy
							</button>
							<button
								type="button"
								className={`switcher__item ${resourceType === 'bandwidth' ? 'active' : ''}`}
								onClick={() => setResourceType('bandwidth')}
							>
								Bandwidth
							</button>
						</div>
					</div>
					<div className="popup-submit popup-submit--type2 popup-submit--big-mt">
						<button
							type="button"
							className="button button--cancel button--full-width "
							onClick={onClose}
						>
							Cancel
						</button>
						<button
							type="button"
							className="button button--type3 button--full-width "
							disabled={stakeLoading || !amount}
							onClick={() =>
								onSubmit({
									amount: Number(amount),
									resource_type: resourceType,
								})
							}
						>
							{isUnstaking ? 'Unstake' : 'Stake'}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StakingModal;
