/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { Cards } from './PaymentMethodBox/Cards/Cards';
// import { FiatDeposit } from './TransactionHistoryBox/FiatDeposit/FiatDeposit';
// import { FiatWithdrawal } from './TransactionHistoryBox/FiatWithdrawal/FiatWithdrawal';
// import { Converts } from './TransactionHistoryBox/Converts/Converts';
// import { Transfers } from './TransactionHistoryBox/Transfers/Transfers';
import { BankAccount } from './PaymentMethodBox/BankAccount/BankAccount';

export const PaymentMethod: FC = () => {
	const [activeMenu, setActiveMenu] = useState('Cards');
	const [radioCheck, setRadioCheck] = useState('Cross');
	let className = '';
	if (activeMenu === 'Cards') {
		className = 'table--payment-cards';
	} else if (activeMenu === 'Bank account') {
		className = 'table--payment-bank-transfer';
	}
	const arrayMenu = [
		{
			id: 1,
			name: 'Cards',
		},
		{
			id: 2,
			name: 'Bank account',
		},
	];
	const menuTop = () => {
		return arrayMenu.map((e) => {
			return (
				<li
					key={e.id}
					className={`table-panel-tab__item ${activeMenu === e.name ? 'active' : ''}`}
					onClick={() => setActiveMenu(e.name)}
				>
					<span className="table-panel-tab__link">{e.name}</span>
				</li>
			);
		});
	};

	const menuContent = () => {
		switch (activeMenu) {
			case 'Cards':
				return <Cards />;
			case 'Bank account':
				return <BankAccount />;
			default:
				null;
		}
		return null;
	};
	return (
		<div className="margin">
			<div className="content-block content-block--user-management">
				<div>
					<div className="content-block__inside content-block__inside--panel-only">
						<div className="table-panel">
							<ul className="table-panel-tab">{menuTop()}</ul>
						</div>
					</div>
				</div>
				<div className="content-block__inside content-block__inside--login-his">
					<div className="table-block table-block--ip">
						<div className="table-wrapper">
							<div className={`table ${className}`}>{menuContent()}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
