/* eslint-disable */
import { FC, useEffect, useState, ChangeEvent } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
	USER_SEARCH_ARRAY,
	USER_SEARCH_TEXT_ARRAY,
	USER_LEVEL_ARRAY,
} from 'redux/reducers/referral/constants';
import { getReferralList, getReferralFee } from 'redux/reducers/referral/selectors';
import {
	getReferralListRequest,
	getReferralFeeRequest,
	updateReferralFeeRequest,
} from 'redux/reducers/referral/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { UserFilter } from './filters/UserFilter';
import SearchBar from '../SearchBar';
import ReferralTable from './ReferralTable';
import { notificationContainer } from 'utils/notificationContainer';

// ==========================================:
const Referral: FC = () => {
	const referralList = useSelector(getReferralList);
	const referralFee = useSelector(getReferralFee);

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(field ? String(field) : 'user_id');
	const [dropdownArray, setDropdownArray] = useState<{
		dropdownArray: string[];
		dropdownTextArray: string[];
	}>({ dropdownArray: ['all'], dropdownTextArray: ['all'] });
	const [typeSearchDropdown, setTypeSearchDropdown] = useState(false);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [fee, setFee] = useState<string>('');

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: `?page=${String(pageNumber)}`;

		const payload = {
			params:
				verifyText.length > 0
					? {
							current_page: pageNumber || 1,
							search_field: searchField,
							search_value: verifyText,
					  }
					: {
							current_page: pageNumber || 1,
					  },
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getReferralListRequest(payload));
	};

	const handleSearch = (txt: string) => {
		const payload = {
			params: {
				current_page: 1,
				search_value: txt ? txt : null,
				search_field: txt ? searchField : null,
			},
		};

		if (txt.length >= 1) {
			dispatch(getReferralListRequest(payload));
		} else {
			dispatch(getReferralListRequest(payload));
		}
		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1
					? `?page=${String(page || 1)}&text=${String(txt)}&field=${searchField}`
					: `?page=${String(1)}`,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};
	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const searchFieldHandler = (txt: string) => {
		if (txt === 'level') {
			setDropdownArray({
				dropdownArray: USER_LEVEL_ARRAY,
				dropdownTextArray: USER_LEVEL_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		setSearchField(txt);
		setTypeSearchDropdown(false);
	};

	useEffect(() => {
		dispatch(getReferralFeeRequest());
	}, [dispatch]);

	const handleFee = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		setFee(value);
	};

	useEffect(() => {
		if (referralFee?.fee) setFee(String(referralFee?.fee));
	}, [referralFee]);

	const updateFee = () => {
		const payload = {
			fee: fee,
			onSuccess: () => {
				notificationContainer('fee update', 'success');
			},
		};
		dispatch(updateReferralFeeRequest(payload));
	};

	return (
		<>
			<div className="title-block">
				<p className="title">Referral</p>
			</div>
			<div className="count-label count-label--filter-type">
				<div className="filter-group">
					<SearchBar
						setDebouncedTerm={setDebouncedTerm}
						debouncedTerm={debouncedTerm}
						searchFieldArray={USER_SEARCH_ARRAY}
						searchField={searchField}
						setSearchField={searchFieldHandler}
						handleClearSearch={handleClearSearch}
						searchFieldTextArray={USER_SEARCH_TEXT_ARRAY}
						typeSearch={typeSearchDropdown ? 'dropdown' : undefined}
						dropdownArray={dropdownArray.dropdownArray}
						dropdownTextArray={dropdownArray.dropdownTextArray}
						inputWidth="120px"
					/>
					<UserFilter />
				</div>
			</div>
			<div className="content-block">
				<div className="content-block__inside content-block__inside--type2">
					<div className="value-form">
						<div className="input ">
							<p className="input__name">Referral Fee in %</p>
							<div className="input-and-btn">
								<div className="input-wrapper">
									<input
										className="input-item input-item--medium"
										type="text"
										placeholder=""
										value={fee}
										onChange={handleFee}
									/>
								</div>
								<button
									onClick={updateFee}
									className="button button--check button--medium-height"
									type="button"
								>
									Save
								</button>
							</div>
						</div>
					</div>
					<ReferralTable users={referralList?.data} />
				</div>
			</div>

			{(referralList?.total ?? 1) > 1 && (
				<Pagination
					pageCount={referralList?.last_page ?? 1}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default Referral;
