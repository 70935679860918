/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserUpdateVipStatusRequest } from 'redux/reducers/userManagement/reducer';
import { getSelectUser, getSelectUserIsLoad } from 'redux/reducers/userManagement/selectors';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import VipIcon from 'assets/img/vip-icon1.png';
import Loader from 'ui/Loader';

export const Account: FC = () => {
	const dispatch = useDispatch();
	const selectUser = useSelector(getSelectUser);
	const selectUserLoader = useSelector(getSelectUserIsLoad);

	// eslint-disable-next-line no-unsafe-optional-chaining
	// const { street, city, state, country, post_code } = selectUser.selectUser?.data;
	const addressArray = [];
	if (selectUser.selectUser?.data?.street) addressArray.push(selectUser.selectUser?.data?.street);

	if (selectUser.selectUser?.data?.city) addressArray.push(selectUser.selectUser?.data?.city);

	if (selectUser.selectUser?.data?.state) addressArray.push(selectUser.selectUser?.data?.state);

	if (selectUser.selectUser?.data?.country) addressArray.push(selectUser.selectUser?.data?.country);

	if (selectUser.selectUser?.data?.post_code)
		addressArray.push(selectUser.selectUser?.data?.post_code);
	const date = selectUser.selectUser?.data.dob
		? new Date(selectUser.selectUser.data.dob).toLocaleDateString('en-GB')
		: '';

	const gameDateTime = new Date(Number(selectUser.user?.game_linked_date) * 1000);
	const gameOffset = (-gameDateTime.getTimezoneOffset() * 60) / 1000;
	const gameDateOffset = selectUser.user?.game_linked_date
		? new Date((+Number(selectUser.user?.game_linked_date) + gameOffset) * 1000)
		: null;

	const { checkEditable } = usePermission();

	const changeUserLevel = (e: ChangeEvent<HTMLInputElement>) => {
		if (!checkEditable(permissions.MANAGE_USERS)) return;
		dispatch(
			setUserUpdateVipStatusRequest({
				id: selectUser?.selectUser?.id || 0,
				is_vip: { is_vip: e.target.checked ? 1 : 0 },
			}),
		);
	};

	return (
		<>
			{!selectUser && !selectUserLoader && (
				<div className="user-management-empty-data">
					<p className="user-management-empty__text">No user found...</p>
				</div>
			)}
			{selectUserLoader ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<div className="content-block content-block--user-management">
					<div className="content-block__inside">
						<div className="account-panel account-panel--type2">
							<div className="account-header">
								<p className="block-subtitle">User data</p>
							</div>
							<div className="account-panel--top-right">
								<p>
									Account type:{' '}
									<span className="account-type-value">
										{selectUser.selectUser?.is_business ? 'Buisiness' : 'Personal'}
									</span>
								</p>
								<div className="account-vip">
									<div className="account-vip__icon">
										<img src={VipIcon} alt="vip" />
									</div>
									<p className="account-vip__text">VIP</p>

									<div
										className={`switch  switch--type3 ${
											!checkEditable(permissions.MANAGE_USERS) ? 'switch--disabled' : ''
										}`}
									>
										<label className="switch__label">
											<input
												type="checkbox"
												className="hidden"
												onChange={changeUserLevel}
												checked={!!selectUser.selectUser?.is_vip}
												disabled={!checkEditable(permissions.MANAGE_USERS)}
											/>
											<div className="switch__toggler" />
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className="table-block table-block--mt-24">
							<div className="table-wrapper">
								<div className="table table--user-acc">
									<div className="table-body">
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">First Name:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{selectUser.selectUser?.data.first_name || '-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Last Name:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{selectUser.selectUser?.data.last_name || '-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Company name:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{selectUser.selectUser?.data.company_name || '-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Country:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{selectUser.selectUser?.data.country || '-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Address:</p>
											</div>
											<div className="td ">
												{/* <p>{addressArray.join(', ') || '-----------------------'}</p> */}
												<p className="td-value">
													{selectUser.selectUser?.data.city || '-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Phone Number:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{selectUser.selectUser?.data.phone || '-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Date of Birth:</p>
											</div>
											<div className="td">
												<p className="td-value">{date || '-----------------------'}</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">E-mail:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{selectUser.selectUser?.email || '-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">KYC:</p>
											</div>
											<div
												className={`td ${
													selectUser.selectUser?.status.name === 'unverified' ? 'rad-status' : ''
												}`}
											>
												<p className="td-value">
													{selectUser.selectUser?.status.name.toUpperCase()}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Personal ID number:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{selectUser.selectUser?.data?.personal_code || '-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Document type:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{selectUser.selectUser?.data?.document_type || '-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Document number:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{selectUser.selectUser?.data?.document_number ||
														'-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Reference number:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{selectUser.selectUser?.payment_reference || '-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Game ID:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{selectUser.user?.game_id || '-----------------------'}
												</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p className="td-value td-value--grey">Game linked date:</p>
											</div>
											<div className="td ">
												<p className="td-value">
													{gameDateOffset && selectUser.user?.game_id ? (
														<>
															{gameDateOffset.toLocaleDateString('en-GB')}{' '}
															<span className="custom-dot"> • </span>
															{gameDateOffset.toLocaleTimeString()}
														</>
													) : (
														'-----------------------'
													)}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
