import { FC } from 'react';
import { IExternalWalletWarningPopup } from './types';

const ExternalWalletWarningPopup: FC<IExternalWalletWarningPopup> = ({ onAccept }) => {
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--medium">
					<div className="popup-body">
						<div className="popup-text popup-text--center popup-text--bigger">
							<p>Switcher cannot be activated, the currency is already activatedr.</p>
						</div>
					</div>
					<div className="popup-submit popup-submit--column">
						<button type="button" className="button button--wider button--text" onClick={onAccept}>
							Ok, got it
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExternalWalletWarningPopup;
