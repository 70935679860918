/* eslint-disable react/jsx-no-useless-fragment */
import { useDispatch, useSelector } from 'react-redux';
import { FC } from 'react';
import { getDisplayDisable2faForm } from 'redux/reducers/settings/selectors';
import { disable2faRequest } from 'redux/reducers/settings/reducer';
import Google2faForm from 'components/Forms/Google2faForm';
import Disable2faInfo from 'components/Settings2fa/Disable2faBlock/Disable2faInfo';
import { IGoogle2faFormData } from 'components/Forms/Google2faForm/types';

// ================================================:
const Disable2faBlock: FC = () => {
	const dispatch = useDispatch();
	const displayDisable2faForm = useSelector(getDisplayDisable2faForm);

	const handleGoogle2faSubmit = (value: IGoogle2faFormData) => {
		dispatch(disable2faRequest(value));
	};

	return (
		<>
			{displayDisable2faForm ? (
				<div className="forms-block tfa-block">
					<div className="form form--settings">
						<div className="tfa-block__info">
							<div className="tfa-block__item">
								<Google2faForm google2faSubmit={handleGoogle2faSubmit} btnText="Disable" />
							</div>
						</div>
					</div>
				</div>
			) : (
				<Disable2faInfo />
			)}
		</>
	);
};

export default Disable2faBlock;
