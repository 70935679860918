/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { formatDateString } from 'utils/dateAndTimeHelpers';
import { IItemParams } from './types';

function formatPrice(str: string | number | null): string {
	return Number(str).toFixed(8);
}

export const OrderHistoryItem: FC<IItemParams> = ({ data }) => {
	const getStatusClass = () => {
		let statusClass = 'status status--close';

		if (data.status === 'filled') {
			statusClass = 'status status--filled';
		} else if (data.status === 'canceled') {
			statusClass = 'status status--failed';
		}

		return statusClass;
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Provider</p>
				<p>{capitalizeFirstLetter(data.provider_name)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<p style={{ whiteSpace: 'nowrap' }}>{formatDateString(data.created_at)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<p>{data.pair.toUpperCase().split('_').join('/')}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<p>
					{data.internal_pair !== data.pair
						? data.internal_pair.toUpperCase().split('_').join('/')
						: ''}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type of order</p>
				<p className="copy-button__text">{capitalizeFirstLetter(data.type)} Order</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Side</p>
				<p className={data.side === 'sell' ? 'red-text' : 'green-text'}>
					{capitalizeFirstLetter(data.side)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Price requested</p>
				<p>
					{formatPrice(data.price)} {data.pair.toUpperCase().split('_')[1]}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Executed price</p>
				<p>
					{formatPrice(data.price_filled)} {data.pair.toUpperCase().split('_')[1]}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">GEL Amount</p>
				<p>{data.gel_amount}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">GEL Rate</p>
				<p>{data.gel_rate}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Filled</p>
				<p>{Math.round(data.filled)}%</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<p className={getStatusClass()}>{capitalizeFirstLetter(data.status)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>
					{formatPrice(data.fee)} {data.pair.toUpperCase().split('_')[0]}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total</p>
				<p>
					{formatPrice(data.total)} {data.pair.toUpperCase().split('_')[0]}
				</p>
			</div>
		</div>
	);
};
