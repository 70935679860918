/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { noExponent, capitalizeFirstLetter } from 'utils/numberFormat';
import { IRedemptionItemProps } from './types';

export const RedemptionItem: FC<IRedemptionItemProps> = ({ data }) => {
	const getStatusClass = () => {
		switch (data.status) {
			case 'approved':
				return 'status status--success';
			case 'rejected':
				return 'status status--rejected';
			default:
				return '';
		}
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Referral Fee</p>
				<p>{data.id}</p>
			</div>
			<Link className="td" to={`/user-management/${String(data.user_id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{data.user_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Phone number</p>
				<p>{data.phone}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Initiated amount (gram)</p>
				{data.init_gold_grams}
			</div>
			<div className="td">
				<p className="td-hidden-name">Available GOLDB amount</p>
				<p>{data.available_amount != null ? noExponent(String(data.available_amount)) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Released Amount</p>
				<p>{data.released_amount != null ? noExponent(String(data.released_amount)) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{data.fee != null && noExponent(String(data.fee))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Gold (gram)</p>
				<p>
					{data.released_gold_grams != null ? noExponent(String(data.released_gold_grams)) : '-'}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Reason of reject</p>
				<div className="hint-block">
					<div className="hint-block__text">
						<p>{data.reject_reason}</p>
					</div>
					<div className="hint-item">
						<p>{data.reject_reason}</p>
					</div>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={getStatusClass()}>{data.status}</span>
				</div>
			</div>
		</div>
	);
};
