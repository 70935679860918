import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getExternalWalletHistory,
	getExternalWalletHistoryLoader,
	getFilterParams,
} from 'redux/reducers/walletManagement/selectors';
import Loader from 'ui/Loader';
import {
	getCoinsListWithChainsRequest,
	getExternalWalletHistoryRequest,
	setFilterParams,
} from 'redux/reducers/walletManagement/reducer';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { IExternalWaletsHistoryRequestPayload } from 'redux/reducers/walletManagement/types';
import Pagination from 'ui/Pagination';
import { ExternalWalletHistoryItem } from './ExternalWalletHistoryItem';

export const ExternalWalletHistory: FC = () => {
	const externalWalletHistoryLoader = useSelector(getExternalWalletHistoryLoader);
	const externalWalletHistory = useSelector(getExternalWalletHistory);
	const filterParams = useSelector(getFilterParams);
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params: IExternalWaletsHistoryRequestPayload = {
			current_page: pageNumber || 1,
			start_date: startDate || null,
			end_date: endDate || null,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getExternalWalletHistoryRequest(params));
	};

	useEffect(() => {
		const params: IExternalWaletsHistoryRequestPayload = {
			current_page: 1,
			start_date: startDate || null,
			end_date: endDate || null,
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getExternalWalletHistoryRequest(params));
	}, [dispatch, endDate, history, location.pathname, startDate]);

	useEffect(() => {
		dispatch(getExternalWalletHistoryRequest({}));
		dispatch(getCoinsListWithChainsRequest());
	}, [dispatch]);

	useEffect(() => {
		return () => {
			dispatch(setFilterParams({ start_date: null, end_date: null }));
		};
	}, [dispatch]);

	return (
		<>
			<div className="content-block content-block--walletman">
				<div>
					<div className="table-block table-block--mt-0 table-block--border-none">
						<div className="table-wrapper">
							<div className="table table--wallet-external-history">
								<div className="table-header">
									<div className="tr">
										<div className="td">
											<div className="td-name">
												<p>User ID</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Liquidity Provider Name</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Date</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Coin</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Amount</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Address to</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Hash</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Address from</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Chain</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Status</p>
											</div>
										</div>
									</div>
								</div>

								{!externalWalletHistory?.data.length && !externalWalletHistoryLoader && (
									<div className="user-management-empty-data">
										<p className="user-management-empty__text">No history found...</p>
									</div>
								)}

								{externalWalletHistoryLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<div className="table-body">
										{externalWalletHistory?.data?.map((item) => (
											<ExternalWalletHistoryItem key={item.id} data={item} />
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{(externalWalletHistory?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={externalWalletHistory?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};
