/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import { Form, Formik } from 'formik';
import { getFiatFeeSchema } from 'utils/formValidation';
import { notificationContainer } from 'utils/notificationContainer';
import {
	deletePercentFiatFeeRequest,
	updatePercentFiatFeeRequest,
	updateRangePercentRequest,
} from 'redux/reducers/fiatFees/reducer';
import { getRangePercent } from 'redux/reducers/fiatFees/selectors';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { getChannelName } from '../utils';
import DeletePopup from './DeletePopup';
import RowField from './RowField';
import { IRowItem } from './types';

const RowItem: FC<IRowItem> = ({ item, active, setActive, onRefetch }) => {
	const dispatch = useDispatch();
	const fiatFees = useSelector(getRangePercent);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const {
		id,
		asset_code,
		channel_name,
		min_amount,
		max_amount,
		deposit_fee,
		withdraw_fee,
		vip_deposit_fee,
		vip_withdraw_fee,
		min_deposit_fee,
		min_withdraw_fee,
		min_vip_deposit_fee,
		min_vip_withdraw_fee,
	} = item;

	const itemIndex = item && fiatFees?.indexOf(item);

	const prevMax = (itemIndex && itemIndex > 0 && fiatFees?.[itemIndex - 1].max_amount) || 0;
	const nextMin =
		(itemIndex &&
			fiatFees?.length &&
			itemIndex < fiatFees.length - 1 &&
			fiatFees?.[itemIndex + 1].min_amount) ||
		999999999;

	const initialValues = {
		min_amount: String(min_amount),
		max_amount: String(max_amount),
		deposit_fee: String(deposit_fee),
		withdraw_fee: String(withdraw_fee),
		vip_deposit_fee: String(vip_deposit_fee),
		vip_withdraw_fee: String(vip_withdraw_fee),
		min_deposit_fee: String(min_deposit_fee),
		min_withdraw_fee: String(min_withdraw_fee),
		min_vip_deposit_fee: String(min_vip_deposit_fee),
		min_vip_withdraw_fee: String(min_vip_withdraw_fee),
	};
	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.SET_FEES);

	const handleDelete = () => {
		dispatch(
			deletePercentFiatFeeRequest({
				id,
				onSuccess: () => {
					setDeleteOpen(false);
				},
			}),
		);
	};

	const validationSchema = getFiatFeeSchema(prevMax, nextMin);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				const rangeChanged =
					values.min_amount !== String(min_amount) || values.max_amount !== String(max_amount);

				const otherFieldsChanged = Object.keys(values)
					.filter((fieldName) => !['min_amount', 'max_amount'].includes(fieldName))
					.some(
						(fieldName) =>
							values[fieldName as keyof typeof values] !==
							String(item[fieldName as keyof typeof item]),
					);

				const fiatFeeApiParams = {
					id,
					min_amount: values.min_amount !== String(min_amount) ? +values.min_amount : undefined,
					max_amount: values.max_amount !== String(max_amount) ? +values.max_amount : undefined,
					deposit_fee: values.deposit_fee !== String(deposit_fee) ? +values.deposit_fee : undefined,
					vip_deposit_fee:
						values.vip_deposit_fee !== String(vip_deposit_fee)
							? +values.vip_deposit_fee
							: undefined,
					withdraw_fee:
						values.withdraw_fee !== String(withdraw_fee) ? +values.withdraw_fee : undefined,
					vip_withdraw_fee:
						values.vip_withdraw_fee !== String(vip_withdraw_fee)
							? +values.vip_withdraw_fee
							: undefined,
					min_deposit_fee:
						values.min_deposit_fee !== String(min_deposit_fee)
							? +values.min_deposit_fee
							: undefined,
					min_vip_deposit_fee:
						values.min_vip_deposit_fee !== String(min_vip_deposit_fee)
							? +values.min_vip_deposit_fee
							: undefined,
					min_withdraw_fee:
						values.min_withdraw_fee !== String(min_withdraw_fee)
							? +values.min_withdraw_fee
							: undefined,
					min_vip_withdraw_fee:
						values.min_vip_withdraw_fee !== String(min_vip_withdraw_fee)
							? +values.min_vip_withdraw_fee
							: undefined,
				};

				if (rangeChanged) {
					dispatch(
						updateRangePercentRequest({
							apiParams: {
								id,
								min_amount: +values.min_amount,
								max_amount: +values.max_amount,
							},
							onSuccess: () => {
								if (otherFieldsChanged) {
									dispatch(
										updatePercentFiatFeeRequest({
											apiParams: fiatFeeApiParams,
											onSuccess: () => {
												notificationContainer(
													'Fiat fee and ranges were updated sucessfully',
													'success',
												);
												setActive(null);
												onRefetch?.();
											},
										}),
									);
									return;
								}
								notificationContainer('Ranges were updated sucessfully', 'success');
								setActive(null);
								onRefetch?.();
							},
						}),
					);
				} else if (otherFieldsChanged) {
					dispatch(
						updatePercentFiatFeeRequest({
							apiParams: fiatFeeApiParams,
							onSuccess: () => {
								notificationContainer('Fiat fee was updated sucessfully', 'success');
								setActive(null);
								onRefetch?.();
							},
						}),
					);
				}
			}}
		>
			{() => {
				return (
					<Form className={`tr ${active ? 'tr--editable' : ''}`}>
						<div className="td">
							<p className="td-hidden-name">Currency</p>
							<p>{asset_code.toUpperCase()}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Chanel name</p>
							<p>{getChannelName(channel_name)}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Rate From</p>
							{active ? <RowField name="min_amount" /> : <p>&lt; {min_amount}</p>}
						</div>
						<div className="td">
							<p className="td-hidden-name">Rate To</p>
							{active ? <RowField name="max_amount" /> : <p>&gt; {max_amount}</p>}
						</div>
						<div className="td ">
							<p className="td-hidden-name">Deposit, %</p>
							{active ? <RowField name="deposit_fee" /> : <p>{deposit_fee}%</p>}
						</div>
						<div className="td ">
							<p className="td-hidden-name">Fixed amount</p>
							{active ? (
								<div className="table-input">
									<RowField name="min_deposit_fee" />
								</div>
							) : (
								<p>{min_deposit_fee}$</p>
							)}
						</div>
						<div className="td ">
							<p className="td-hidden-name">Withdrawal, %</p>
							{active ? <RowField name="withdraw_fee" /> : <p>{withdraw_fee}%</p>}
						</div>
						<div className="td ">
							<p className="td-hidden-name">Fixed amount</p>
							{active ? <RowField name="min_withdraw_fee" /> : <p>{min_withdraw_fee}$</p>}
						</div>
						<div className="td ">
							<p className="td-hidden-name">VIP Deposit, %</p>
							{active ? <RowField name="vip_deposit_fee" /> : <p>{vip_deposit_fee}%</p>}
						</div>
						<div className="td ">
							<p className="td-hidden-name">Fixed amount</p>
							{active ? <RowField name="min_vip_deposit_fee" /> : <p>{min_vip_deposit_fee}$</p>}
						</div>
						<div className="td ">
							<p className="td-hidden-name">VIP Withdrawal, %</p>
							{active ? <RowField name="vip_withdraw_fee" /> : <p>{vip_withdraw_fee}%</p>}
						</div>
						<div className="td ">
							<p className="td-hidden-name">Fixed amount</p>
							{active ? <RowField name="min_vip_withdraw_fee" /> : <p>{min_vip_withdraw_fee}$</p>}
						</div>
						<div className="td td--right">
							<p className="td-hidden-name">Edit</p>
							<div className="table-buttons">
								{active ? (
									<button type="button" onClick={() => setActive(null)}>
										<span className="edit-icon icon-cancel-icon" />
									</button>
								) : (
									<>
										<button type="button" disabled={!canEdit} onClick={() => setDeleteOpen(true)}>
											<span className="trash-icon trash-icon--action icon-trash-icon" />
										</button>
										<button type="button" disabled={!canEdit}>
											<span
												className="edit-icon icon-edit-icon"
												onClick={() => setActive(item.id)}
											/>
										</button>
									</>
								)}
								{/* 
									Кнопка submit вынесена отдельно и показывается/скрывается через стиль display
									Иначе происходит ложное срабатывание onSubmit когда active === true 
								*/}
								<button type="submit" style={{ display: active ? 'block' : 'none' }}>
									<span className="edit-icon icon-succes-icon" />
								</button>
								{deleteOpen &&
									createPortal(
										<DeletePopup onAccept={handleDelete} onCancel={() => setDeleteOpen(false)} />,
										document.getElementsByClassName('content')[0],
									)}
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default RowItem;
