import React, { FC } from 'react';
import { IAdminFormConrol } from './types';

// ==========================================:
const AdminFormConrol: FC<IAdminFormConrol> = (props) => {
	const { form, field, title, children, fieldIcon, fieldRightIcon } = props;

	const errors: string = form?.errors?.[field?.name];
	const touched: string = form?.touched?.[field.name];

	let inputStatus = '';

	if (errors && touched) {
		inputStatus = 'input--error';
	} else if (!errors && touched) {
		inputStatus = 'admin-input--success';
	} else {
		inputStatus = '';
	}

	return (
		<div className={`input ${inputStatus}`}>
			<label>
				{title && <p className="input__name">{title}</p>}
				<div className={`input-wrapper ${errors && touched ? 'input--error' : ''}`}>
					{children}
					{fieldIcon}
					{fieldRightIcon}
				</div>
				{errors && touched && (
					<div className="input__notification">
						<p className="word-wrap">{errors && touched && errors}</p>
					</div>
				)}
			</label>
		</div>
	);
};

export default AdminFormConrol;
