export const TWO_FA_STATUS_REJECT = 'Reject';
export const TWO_FA_STATUS_NEW = 'New';
export const TWO_FA_STATUS_ACCEPT = 'Accept';

export const TWO_FA_STATUS_NUM_REJECT = 0;
export const TWO_FA_STATUS_NUM_NEW = 1;
export const TWO_FA_STATUS_NUM_ACCEPT = 2;

export const USER_LEVEL_VIP = 'vip';
export const USER_LEVEL_REGULAR = 'regular';

export const USER_LEVEL_ARRAY = ['all', USER_LEVEL_REGULAR, USER_LEVEL_VIP];

export const USER_SEARCH_ARRAY = ['user_id', 'full_name', 'email'];
export const USER_SEARCH_TEXT_ARRAY = ['User ID', 'Full name', 'E-mail'];
