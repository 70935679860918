import { MouseEvent, FC, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { FEES_MANAGEMENT_LEVEL_SWITCHER_VALUES } from 'redux/reducers/fees/constants';
import { EAccountLevel } from 'redux/reducers/fees/types';
import TradeFeesAndLimits from './TradeFeesAndLimits';
import CryptoFees from './CryptoFees';
import PlatformFees from './PlatformFees';
import VolumeDependsFeeSettings from './VolumeDependsFeeSettings/VolumeDependsFeeSettings';
import MarginBorrow from './MarginBorrow';
import P2P from './P2P';

const Fees: FC = () => {
	const [tabIndex, setTabIndex] = useState(0);
	const [levelSwitcherValue, setLevelSwitcherValue] = useState<EAccountLevel>(EAccountLevel.VIP);

	const handleLevelSwitcherChange = (event: MouseEvent<HTMLButtonElement>) => {
		const value = event.currentTarget.innerText.toLowerCase() as EAccountLevel;
		setLevelSwitcherValue(value);
	};

	const handleTabIndexChange = (index: number) => {
		setTabIndex(index);
	};

	return (
		<>
			<div className="title-block title-block--between">
				<p className="title">Fee Management</p>
				<div className="button-group">
					{[2, 3, 4].includes(tabIndex) &&
						FEES_MANAGEMENT_LEVEL_SWITCHER_VALUES.map((el) => (
							<button
								key={el}
								type="button"
								onClick={handleLevelSwitcherChange}
								className={`
									button-group__item
									${el === EAccountLevel.VIP ? 'button-group__vip' : ''}
									${el === EAccountLevel.REGULAR ? 'button-group__regular' : ''}
									${levelSwitcherValue === el ? 'active' : ''}
								`}
							>
								{el}
							</button>
						))}
				</div>
			</div>
			<Tabs className="settings-tabs" onSelect={handleTabIndexChange}>
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link">Platform fees</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Crypto fees</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Trade fees and limits</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Volume depends fee settings</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Margin borrow</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">P2P</span>
					</Tab>
				</TabList>
				<TabPanel>
					<PlatformFees />
				</TabPanel>
				<TabPanel>
					<CryptoFees />
				</TabPanel>
				<TabPanel>
					<TradeFeesAndLimits accountLevel={levelSwitcherValue} />
				</TabPanel>
				<TabPanel>
					<VolumeDependsFeeSettings accountLevel={levelSwitcherValue} />
				</TabPanel>
				<TabPanel>
					<MarginBorrow />
				</TabPanel>
				<TabPanel>
					<P2P />
				</TabPanel>
			</Tabs>
		</>
	);
};

export default Fees;
