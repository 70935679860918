import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { getTradesP2PTradesRequest, setFilterParams } from 'redux/reducers/trade/reducer';
import { IMarketMakerPageRequestPayload } from 'redux/reducers/trade/types';
import { getP2PTradesExporting, getFilterParams } from 'redux/reducers/trade/selectors';
import { ExportIcon } from 'assets/img/icons';
import { TradeFilter } from '../Filter';

export const P2PTradesFilter = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const filterParams = useSelector(getFilterParams);
	const isExporting = useSelector(getP2PTradesExporting);
	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const handleDataSelect = (start_date: string | null, end_date: string | null) => {
		dispatch(setFilterParams({ start_date, end_date }));
	};

	const handleExport = () => {
		const params: IMarketMakerPageRequestPayload = {
			current_page: page || 1,
			export: 1,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};
		dispatch(getTradesP2PTradesRequest(params));
	};
	return (
		// <div className="count-label count-label--user-management count-label--left transactions-data-picker">
		// 	<button
		// 		type="button"
		// 		className="transaction-export-btn"
		// 		onClick={handleExport}
		// 		disabled={isExporting}
		// 	>
		// 		Export P2P Trades
		// 		<span className="transaction-export-btn__icon">
		// 			<ExportIcon />
		// 		</span>
		// 	</button>
		// </div>
		<TradeFilter
			exportTitle="Export P2P Trades"
			onDateSelect={handleDataSelect}
			onExport={handleExport}
		/>
	);
};
