import { FC, useState } from 'react';
import Popup from 'reactjs-popup';
import { useSelector } from 'react-redux';
import { getRejectRedemptionLoader } from 'redux/reducers/withdrawals/selectors';
import { IRejectWithdrawalsModalProps } from './types';

// ================================================:
export const RejectWithdrawalsModal: FC<IRejectWithdrawalsModalProps> = ({
	openModal,
	closeModal,
	handleRejectWithdrawal,
	id,
}) => {
	const [comment, setComment] = useState('');
	const redemptionLoading = useSelector(getRejectRedemptionLoader);
	const isDisabled = !comment || comment.length < 3 || redemptionLoading;
	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window admin-managment-popup">
				<div className="popup-window__inside">
					<div className="popup">
						<button
							onClick={() => {
								closeModal();
								setComment('');
							}}
							type="button"
							className="popup__close"
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1.80005 2.30005L16.2 16.7M1.80005 16.7L16.2 2.30005" stroke="#011E26" />
							</svg>
						</button>
						<div className="popup-header">
							<h3 className="popup-header__title">Reject withdrawal</h3>
						</div>
						<div className="popup-body">
							<div className="popup-text popup-text--center">
								<p>Are you sure you want to reject the redemption?</p>
							</div>
							<div className="textarea textarea--mt-16">
								<textarea
									className="textarea__item textarea__item--size2 textarea__item--type2"
									onChange={(e) => setComment(e.target.value)}
									placeholder="Comment"
									maxLength={256}
								/>
							</div>

							<div className="popup-submit popup-submit--sb ">
								<button
									onClick={() => {
										closeModal();
										setComment('');
									}}
									type="button"
									className="button button--cancel   button--full-width "
								>
									Cancel
								</button>
								<button
									className={`button button--red-type button--full-width   ${
										comment.length < 3 ? 'button-block' : 'button--del'
									}`}
									type="button"
									disabled={isDisabled}
									onClick={() => {
										if (isDisabled) return;
										closeModal();
										handleRejectWithdrawal({
											id,
											reject_reason: comment,
										});
									}}
								>
									Reject
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
