const TableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Pair</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Market range %</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Ask max</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Bid max</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Spread</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Frequency</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Current ask volume</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Current bid volume</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Time</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>On/Off</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name td-name--action">
						<p>Action</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
